<template>
  <v-container fluid fill-height>
    <v-layout justify-center align-center>
      <v-flex xs12 fill-height>
        <v-card height="95%">

          <iframe src="https://firecodeit-apps.herokuapp.com/relatorioGerencial/" height="100%" width="100%" frameborder="0"  />

        </v-card>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
};
</script>

<style>
</style>