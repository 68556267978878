/* eslint-disable */
import HttpService from './HttpService'

export default class ComentsService {
  constructor() {
    this._httpService = new HttpService();
  }

  async FindById(id, model, type, subtype) {
    return await this._httpService.get('/api/odoo/custom_case_comment', { documentId: id, model: model, type: type, subTypeName: subtype});
  }

  async Create(data) {
    return await this._httpService.post('/api/odoo/custom_case_comment', data);
  }
}