<template>
  <v-container fluid grid-list-md class="px-0">
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"/>
    </v-overlay>
    <v-card>
      <v-card-title class="primary--text">
        Público Alvo
        <v-spacer />
        <v-btn
            fab
            top
            right
            color="primary"
            dark
            v-show="currentUser.salesforceUser"
            @click="$router.push(`${this.urlPublish}/${feed.id}/0`)"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-flex xs12>
          <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
            <ProgressiveLoader :disabled="busy" @reachedEnd="getTargetPublic">
              <v-data-table
                id="target-public-listar"
                loading-text="Carregando itens..."
                disable-filtering
                disable-pagination
                disable-sort
                hide-default-footer
                :headers="headers"
                :items="filteredItems"
                :loading="loading"
                item-key="id"
                @click:row="(item) => handleItemSelection(item)"
            >
              <template v-slot:header></template>
              <template v-slot:no-data>
                <v-alert :value="true" type="info" v-show="!loading" outlined
                >Nenhum item foi encontrado
                </v-alert
                >
              </template>
            </v-data-table>
          </ProgressiveLoader>
        </v-layout>
        <template v-else>
          <FabFilterer
            title="Público Alvo"
            :filters="filters"
            :data="items"
            @queryUpdate="loadItemupdate"
            @filtersRemoved="loadInitialItens"
            @clearFilters="loadInitialItens"
          />
          <ProgressiveLoader :disabled="busy" @reachedEnd="getTargetPublic">
            <v-data-iterator
                hide-default-footer
                hide-default-header
                disable-pagination
                :items="filteredItems"
            >
              <template v-slot:no-data>
                <v-alert :value="false" type="info" v-show="!loading" outlined
                >Nenhum item foi encontrado
                </v-alert
                >
              </template>
              <template v-slot:default="props">
                <v-row v-for="item in props.items" :key="item.id" @click="handleItemSelection(item)">
                  <v-col cols="12">
                    <v-card-text class="pb-0">
                      <v-row dense class="mb-4">
                        <template v-for="header in headers">
                          <v-col :cols="header.cols" :key="header.value">
                            <v-row no-gutters>
                              <v-col
                                  cols="12"
                                  class="black--text font-weight-bold"
                              >{{ header.text }}
                              </v-col
                              >
                              <v-col
                                  cols="12"
                                  :class="
                                (header.bold ? 'font-weight-bold' : '') +
                                ' silver--text pa-0'
                              "
                              >{{ item[header.value] }}
                              </v-col
                              >
                            </v-row>
                          </v-col>
                        </template>
                        <v-col
                            cols="12"
                            class="d-flex primary--text justify-center text-sm-center mb-1"
                        >
                          <label class="primary--text">
                            Clique para detalhar
                          </label>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </ProgressiveLoader>
        </template>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import FabFilterer from "../../../components/fab-filterer";
import DrawerFilterer from "../../../components/drawer-filterer";
import ProgressiveLoader from "../../../components/progressive-loader";
import SocialNetworkService from "../../../services/socialnetwork/SocialNetworkService";
import ContractService from "../../../services/odoo/ContractService";
import CarrierService from "../../../services/odoo/CarrierService";

export default {
  name: "TargetPublicListar",
  components: {FabFilterer, DrawerFilterer, ProgressiveLoader},
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    items: [],
    currentUser: null,
    filteredItems: [],
    loading: true,
    search: "",
    busy: true,
    totalPages: 1,
    page: 0,
    urlPublish: '/target-upsert',
    contractSearchFields: ['id','name','benefit_id','carrier_id','carrier_id.xipp_commercial_name','contract_owner_id', 'contract_owner_id.legal_name', 'contract_owner_id.name', 'policy'],
  }),
  computed: {
    ...mapGetters(["user","getSelectedContracts","getSelectedFinancialGroups","getSelectedSubcontracts","getSelectedCarriers"]),
    ...mapState(["window"]),
    headers() {
      const baseHeaders = [
        {text: "Tipo", value: "typeLabel", cols: "12"},
        {text: "Identificador", value: "identifierLabel", cols: "12"},
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({text: "", value: "", sortable: false, cols: ""});
      }
      return baseHeaders;
    },
  },
  methods: {
    ...mapMutations(["showmsg"]),
    ...mapActions(["setTargetFinancialGroups","setSelectedItem","setSelectedFinancialGroups",
      "setSelectedContracts","setSelectedSubContracts","setSelectedCarriers", "setSelectedCarrier"]),
    addItem(obj) {
      this.items.push(obj);
      this.filteredItems.push(obj);
    },
    setFilteredItems(newItems) {
      this.items = [];
      this.filteredItems = [];
      for (const item of newItems) {
        this.addItem(item);
      }
    },
    handleItemSelection(item) {
      this.setSelectedItem(item);
      this.$router.push(`${this.urlPublish}/${this.feed.id}/${item.id}`);
    },
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    async loadItemupdate(data) {
      if (data) {
        data = data.trim();

        // parametros do requests
        var rq = [];

        rq.push({chave: "feedId", valor: this.feed.id.toString()});

        const filters = data.trim().split("andd");

        //percorrer query e montar os parametros
        filters.forEach((fil) => {
          fil = fil.trim();

          // criar parametro
          let q = fil.split("in");
          if (q[0] && q[0].length > 0) {
            // montar chave e valor
            rq.push({chave: q[0], valor: q[1]});
          }
        });

        // tratando os parametrosrequest
        rq.forEach((res) => {
          res.chave = res.chave.trim();
          res.valor = res.valor.trim();
          res.valor = res.valor.replace("(", "");
          res.valor = res.valor.replace(")", "");

          // replace so tira um por vez
          for (let index = 0; index < 20; index++) {
            res.valor = res.valor.replace("'", "");
          }
        });

        // passar parametros de filtros
        let path = "";
        rq.forEach((parametro) => {
          if (path && path.length > 0) {
            path += "&";
          } else {
            path = "?";
          }
          path += parametro.chave;
          path += "=" + parametro.valor;
        });

        this.loadItemsWithCount(path);
      }
    },

    async loadItemsWithCount(params = "") {
      this.busy = true;
      this.loading = true;
      if (params && params.length > 0) {
        let stories = [];
        let self = this;
        this._socialNetworkService
            .findTargetByFeedFilter(params)
            .then((_res) => {
              if (_res.data && _res.data.length > 0) {
                _res.data.forEach(function (elem) {
                  let store = {
                    id: elem.id,
                    identifier: elem.identifier,
                    typeLabel: self.getTypeLabel(elem.type),
                    type: elem.type,
                  };
                  stories.push(store);
                });
              }
            })
            .catch((e) =>
                this.showmsg({
                  text: e,
                  type: "error",
                })
            )
            .finally(() => {
              if (stories && stories.length > 0) {
                this.setFilteredItems(stories);
              }
              this.loading = false;
              this.page = 1;
              this.totalPages = 1;
            });
      } else {
        this.items = [];
        this.filteredItems = [];
        this.page = 0;
        this.totalPages = 1;
        this.getTargetPublic();
      }
    },

    getIdentifierLabel(type, val) {
      if(val === 'ALL') return 'Todos';

      if(type === 'IDADE_MAIOR_QUE' || type === 'IDADE_MENOR_QUE')
        return `${val} ano(s)`;

      if(val === 'ATIVO') return 'Ativos';

      if(val === 'INATIVO') return 'Inativos';

      return val
    },

    getTypeLabel(type) {
      if(type === 'GRUPO_FINANCEIRO') return 'Grupo Financeiro';
      if(type === 'SITUACAO_BENEFICIARIO') return 'Situação do Beneficiário';
      if(type === 'CONTRATO') return 'Contrato';
      if(type === 'SUBCONTRATO') return 'Subcontrato';
      if(type === 'IDADE_MAIOR_QUE') return 'Idade maior que...';
      if(type === 'IDADE_MENOR_QUE') return 'Idade menor que...';
      if(type === 'SEXO') return 'Sexo';
      if(type === 'OPERADORA') return 'Operadora';
    },

    formatContractText(contract){
      return (`${(contract.benefit_id ? contract.benefit_id : '-')} / ${(contract.carrier_id && contract.carrier_id.xipp_commercial_name ?
          contract.carrier_id.xipp_commercial_name : '-')} - ${contract.policy ? contract.policy : '-'} - ${contract.contract_owner_id && contract.contract_owner_id.legal_name ? contract.contract_owner_id.legal_name : '-'}`)
    },

    async loadInitialItens() {
      this.currentUser = this.$util.user();
      await this.loadItemsWithCount();
    },
    getTargetPublic() {
      if (this.page < this.totalPages) {
        let stories = [];
        this.busy = true;
        this.loading = true;
        let self = this
        this._socialNetworkService
            .findTargetByFeed(this.feed.id, this.page)
            .then(async (_res) => {
              if (_res.data && _res.data.totalElements > 0) {
                this.totalPages = _res.data.totalPages;

                _res.data.content.forEach(function (elem) {
                  let store = {
                    id: elem.id,
                    identifier: elem.identifier,
                    identifierLabel: self.getIdentifierLabel(elem.type, elem.identifier),
                    type: elem.type,
                    typeLabel: self.getTypeLabel(elem.type),
                  };
                  stories.push(store);
                });
                for (const store of stories) {
                  const targetValue = await this.getSelectedTargetValue(store);
                  if (targetValue != null) {
                    store.identifierLabel = targetValue.text ? targetValue.text : targetValue.name;
                  }
                }
                // this.setSelectedTargets(stories);
              } else {
                this.busy = true;
                this.loading = false;
                this.page++;
              }
            })
            .catch(() =>
                this.showmsg({
                  text: "Erro ao carregar público alvo!",
                  type: "error",
                })
            )
            .finally(() => {
              if (stories && stories.length > 0) {
                for (const item of stories) {
                  this.addItem(item);
                }
                this.busy = false;
                this.loading = false;
                this.page++;
              }
            });
      } else {
        this.busy = true;
      }
    },
    setSelectedTargets(stories) {
      var financialGroup = stories.find(store => store.type == "GRUPO_FINANCEIRO");
      if(financialGroup) {
        this.getFinancialGroupById(financialGroup.identifier)
      }
      var contract = stories.find(store => store.type == "CONTRATO");
      if(contract) {
        this.getContractById(contract.identifier);
      }
      var subcontract = stories.find(store => store.type == "SUBCONTRATO");
      if(subcontract) {
        this.getSubcontractById(contract.identifier);
      }
      var carrier = stories.find(store => store.type == "OPERADORA");
      if(carrier) {
        this.getCarrierById(carrier.identifier);
      }
    },
    async getSelectedTargetValue(store) {
      if(store.type === "GRUPO_FINANCEIRO"){
        return await this.getFinancialGroupById(store.identifier)
      } else if(store.type === "CONTRATO"){
        return await this.getContractById(store.identifier)
      } else if(store.type === "SUBCONTRATO"){
        return await this.getSubcontractById(store.identifier)
      } else if(store.type === "OPERADORA"){
        return await this.getCarrierById(store.identifier)
      }
      return null;
    },
    getTypeList() {
      this._socialNetworkService
          .getTargetTypeList()
          .then((_res) => {
            if (_res && _res.data && _res.data.length > 0) {
              let types = [];
              _res.data.forEach((element) => {
                types.push({
                  name: element.label,
                  value: element.value,
                  type: "select",
                  active: false,
                  quotes: false,
                });
              });
            }
          })
          .catch()
          .finally(() => {
          });
    },
    loadUrlCases(){
      const {hideMenu} = this.$route.meta
      if(hideMenu){
        this.urlPublish = '/target-salesforce-upsert'
      }
    },

    async getContractById(contractId) {
      if(contractId.toUpperCase() === 'ALL'){
        const contract = {text: 'Todos', id: 0}
        this.setSelectedContracts(contract)
        return contract;
      } else {
        const params = new URLSearchParams();

        params.append('fields', this.contractSearchFields);
        params.append('id', contractId);
        return await this._contractService.FindAllContractV2(params)
            .then(res => {
              if(res && res.data && res.data) {
                const contract = {
                  ...res.data[0],
                  text: this.formatContractText(res.data[0])
                }
                this.setSelectedContracts(contract);
                return contract;
              } else {
                return null;
              }
            })
            .catch(() => {
                  this.busy = false;
                  return null;
                }
            );
      }
    },
    async getFinancialGroupById(financialGroupId) {
      if(financialGroupId.toUpperCase() === 'ALL'){
        const financialGroup = {text: 'Todos', id: 0};
        this.setSelectedFinancialGroups(financialGroup)
        return financialGroup;
      } else {
        return await this._contractService.FindAllFinancialGroupById(financialGroupId)
            .then(res => {
              if(res && res.data) {
                const record = res.data;
                this.setSelectedFinancialGroups({text: record.name, id: record.id});
                return record;
              } else {
                return null;
              }
            })
            .catch(() => {
                  this.busy = false;
                  return null;
                }
            );
      }
    },

    async getSubcontractById(contractId) {
      if(contractId.toUpperCase() === 'ALL'){
        const subContract = {text: 'Todos', id: 0}
        this.setSelectedSubContracts(subContract);
        return subContract;
      } else {
        const params = new URLSearchParams();
        params.append('fields', this.contractSearchFields);
        params.append('includeSubContracts', 'true');
        params.append('id', `${contractId}`);
        return await this._contractService.FindAllContractV2(params)
            .then(response => {
              if(response && response.data) {
                const subcontract = {
                  ...response.data[0],
                  text: this.formatContractText(response.data[0])
                }
                this.setSelectedSubContracts(subcontract);
                return subcontract;
              } else {
                return null;
              }
            }).catch(() => {
                  this.busy = false;
                  return null;
                }
            );
      }
    },

    async getCarrierById(carrierId) {
      const params = new URLSearchParams();
      params.append('fields', ['id','name']);
      params.append('id', carrierId);
      return await this._carrierService.findAllCarriersByFiltersV2(params).then(
      (response) => {
        if(response && response.data.length) {
          const carrier = response.data[0];
          this.setSelectedCarrier(response.data[0]);
          return carrier;
        } else {
          return null;
        }
      }).catch(() => {
            this.busy = false;
            return null;
          }
      );
    }

  },
  async created() {
    this._contractService = new ContractService();
    this._socialNetworkService = new SocialNetworkService();
    this._carrierService = new CarrierService();
    this.loadInitialItens();
    this.getTypeList();
    this.loadUrlCases();
  },
};
</script>

<style>
#atendimento-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}
</style>
