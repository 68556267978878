import HttpService from './HttpService'

export default class CaseService {
  constructor() {
    this._httpService = new HttpService("/socialnetwork/user_messages");
  }

  async getNotifications(page, size) {
    return await this._httpService.get('/', {page, size})
        .then(res => {
          if(res.data.content.length){
            res.data.content.forEach(notification => {
              notification.notificationOpenTime = this.getNotificationOpenTime(notification);
            })
          }
          return res.data;
    });
  }

  getNotificationOpenTime(notification){
    if(notification.createDateTime){
      let notificationCreateDateTime = new Date(notification.createDateTime);

      // get total seconds between the times
      var delta = Math.abs(new Date().getTime() - notificationCreateDateTime.getTime()) / 1000;

      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      let formatOpenTime = 'há ';
      if(days > 0) formatOpenTime+=days + (days == 1 ? ' dia' : ' dias');
      else if(hours > 0) formatOpenTime+=hours + (hours == 1 ? ' hora' : ' horas');
      else if(minutes > 0) formatOpenTime+=minutes + (minutes == 1 ? ' minuto' : ' minutos');
      else formatOpenTime = 'agora';

      return formatOpenTime;
    }
  }

  async getUnreadNotifications(page, size) {
    return await this._httpService.get('/getUnreadMessages', {page, size})
        .then(res => {
          if(res.data.content.length){
            res.data.content.forEach(notification => {
              notification.notificationOpenTime = this.getNotificationOpenTime(notification);
            })
          }
          return res.data;
        });
  }

  async removeNotification(id) {
    return await this._httpService.delete('/'+id);
  }

  async markAllNotificationsAsRead() {
    return await this._httpService.post('/markAllMessagesAsRead');
  }

  async markNotificationAsRead(id) {
    return await this._httpService.post('/markMessageAsRead/'+id);
  }

  async markNotificationAsUnread(id) {
    return await this._httpService.post('/markMessageAsUnread/'+id);
  }

  async countUnreadNotifications() {
    return await this._httpService.get('/countUnreadMessages');
  }

}
