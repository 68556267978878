export default {
  state: {
    availableFinancialGroups: [],
    selectedFinancialGroups: [],

    availableContracts: [],
    selectedContracts: [],

    availableSubontracts: [],
    selectedSubContracts: [],
    selectedCarriers: [],

    selectedItem: {},
  },
  getters: {
    getAvailableFinancialGroups: (state) => state.availableFinancialGroups,
    getSelectedFinancialGroups: (state) => state.selectedFinancialGroups,

    getAvailableContracts: (state) => state.availableContracts,
    getSelectedContracts: (state) => state.selectedContracts,

    getAvailableSubcontracts: (state) => state.availableSubontracts,
    getSelectedSubcontracts: (state) => state.selectedSubContracts,
    getSelectedCarriers: (state) => state.selectedCarriers,

    getSelectedItem: (state) => state.selectedItem,
  },
  mutations: {
    setAvailableFinancialGroups(state, financialGroup) {
      state.availableFinancialGroups = financialGroup;
    },
    setSelectedFinancialGroups(state, financialGroup) {
      state.selectedFinancialGroups = financialGroup;
    },

    setSelectedContracts(state, contracts){
      state.selectedContracts = contracts;
    },
    setAvailableContracts(state, contracts){
      state.availableContracts = contracts;
    },

    setAvailableSubcontracts(state, subContracts){
      state.availableSubontracts = subContracts;
    },
    setSelectedSubContracts(state, subContracts){
      state.selectedSubContracts = subContracts;
    },
    setSelectedCarriers(state, carrier){
      state.selectedCarriers = carrier;
    },
    setSelectedCarrier(state, carrier){
      state.selectedCarrier = carrier;
    },

    setSelectedItem(state, item){
      state.selectedItem = item;
    },

    resetPostsStore(state){
      state.availableFinancialGroups = [];
      state.selectedFinancialGroups = [];
      state.availableContracts = [];
      state.selectedContracts = [];
      state.availableSubontracts = [];
      state.selectedSubContracts = [];
      state.selectedCarrier = {};
      state.selectedItem = {};
    }
  },
  actions: {
    setAvailableFinancialGroups(context, financialGroup) {
      context.commit('setAvailableFinancialGroups', financialGroup);
    },
    setSelectedFinancialGroups(context, financialGroup) {
      context.commit('setSelectedFinancialGroups', financialGroup);
    },

    setSelectedContracts(context, contracts) {
      context.commit('setSelectedContracts', contracts);
    },
    setAvailableContracts(context, contracts) {
      context.commit('setAvailableContracts', contracts);
    },

    setAvailableSubcontracts(context, subContracts){
      context.commit('setAvailableSubcontracts', subContracts);
    },
    setSelectedSubContracts(context, subContracts){
      context.commit('setSelectedSubContracts', subContracts);
    },
    setSelectedCarrier(context, carrier){
      context.commit('setSelectedCarrier', carrier);
    },
    setSelectedCarriers(context, carrier){
      context.commit('setSelectedCarriers', carrier);
    },

    setSelectedItem(context, item){
      context.commit('setSelectedItem', item);
    },

    resetStore(context) {
      context.commit('resetStore');
    }
  },
}

