<template>
  <v-card flat class="pa-0" elevation="0">
    <v-card-text v-if="noMatches">Nenhum reajuste aplicado!</v-card-text>
    <v-row v-else>
      <v-row v-for="readjust in readjusts" :key="readjust.id" style="padding-top: 20px; padding-bottom: 10px;">
        <ReadjustmentDetail :readjustment="readjust" />
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import ContractReajustService from "../../services/odoo/ContractReajustService";
import ReadjustmentDetail from "@/components/contracts/readjustmentDetail.vue";

export default {
  components: {ReadjustmentDetail},
  props: {
    contractId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    noMatches: false,
    readjusts: {}
  }),
  beforeMount() {

    this._contractReajustService = new ContractReajustService();
    var data = {
      contracts: this.contractId,
      status: 'closed'
    }
    this._contractReajustService.FindAll(data)
      .then(res => {
        if (res.data.records.length) {
          this.readjusts = res.data.records;
        }else{
          this.noMatches = true;
        }
      })
      .catch(error => this.showmsg({text: "Erro ao carregar", type: "error"})
      );
  },
};
</script>

<style>
</style>
