<template>
    <iframe :src="`${accountChangePasswordURL}`" height="100%" width="100%" scrolling="no" frameborder="0"/>
</template>

<script>
    import {mapGetters, mapMutations} from "vuex";

    export default {
        name: "AlerarSenha",
        data: () => ({}),
        computed: {
            ...mapGetters(["user"]),
            accountChangePasswordURL() {
                let appbgcolor = escape(this.$vuetify.theme.themes.light.backgroundColor);
                return `${process.env.VUE_APP_ACCOUNT_URL}${process.env.VUE_APP_ACCOUNT_CHANGE_PASSWORD_PATH}?callback=${window.location.origin}${process.env.VUE_APP_ACCOUNT_DEFAULT_CALLBACK_PATH}&token=${this.$store.state.session.tokenSecurityService.access_token}&appbgcolor=${appbgcolor}&appCode=${process.env.VUE_APP_ACCOUNT_APP_CODE}`;
            }
        },
        methods: {
            ...mapMutations(["setCurrentMenu"]),
        },
        created() {
            this.setCurrentMenu(this.user.menu[0]);
        }
    };
</script>
