export default {
  state: {
    keyValue: 0,
  },
  getters: {
    getKeyValue(state) {
      return state.keyValue;
    },
  },
  mutations: {
    setKeyValue(state, newValue) {
      state.keyValue = newValue;
    },
  },
};
