<template>
  <v-container fluid>
    <v-card min-height="400">
      <v-card-text>
        <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            grow
            centered
            show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-form-target">Público Alvo</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item :value="'tab-form-target'">

            <v-row class="pt-2">
              <v-col cols="12" md="6">
                <v-autocomplete
                    ref="comboFinancialGroups"
                    class="pt-0 pb-0"
                    label="Grupo Financeiro"
                    multiple
                    small-chips
                    deletable-chips
                    v-model="financialGroupSelecteds"
                    :items="financialGroups"
                    :loading="loadingFinancialGroups"
                    :disabled="loadingFinancialGroups || loadingContracts || loadingSubcontracts"
                    item-text="name"
                    item-value="id"
                    :readonly="!sfUser"
                    @change="loadAllContractsWithDebounce()"
                    outlined />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                    ref="comboContracts"
                    class="pt-0 pb-0"
                    label="Contrato"
                    multiple
                    small-chips
                    deletable-chips
                    v-model="contractSelecteds"
                    :items="contracts"
                    :loading="loadingContracts"
                    :disabled="loadingFinancialGroups || loadingContracts || loadingSubcontracts"
                    item-text="text"
                    item-value="id"
                    @change="loadAllSubContractsWithDebounce()"
                    outlined />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                    ref="comboSubContracts"
                    class="pt-0 pb-0"
                    label="SubContrato"
                    multiple
                    small-chips
                    deletable-chips
                    v-model="subcontractSelecteds"
                    :items="subcontracts"
                    :loading="loadingSubcontracts"
                    :disabled="loadingFinancialGroups || loadingContracts || loadingSubcontracts"
                    item-text="text"
                    item-value="id"
                    outlined />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                    class="pt-0 pb-0"
                    label="Centro de Custo"
                    multiple
                    small-chips
                    deletable-chips
                    v-model="costCenterSelecteds"
                    :items="costCenters"
                    :loading="loadingFinancialGroups || loadingCostCenters || (parseInt(paramId) > 0 && (loadingFinancialGroups || loadingContracts || loadingSubcontracts))"
                    :disabled="loadingFinancialGroups || loadingCostCenters || (parseInt(paramId) > 0 && (loadingFinancialGroups || loadingContracts || loadingSubcontracts))"
                    item-text="name"
                    item-value="id"
                    outlined />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                    class="pt-0 pb-0"
                    label="Cargos"
                    multiple
                    small-chips
                    deletable-chips
                    v-model="insuredPositionSelecteds"
                    :items="insuredPositions"
                    :loading="loadingFinancialGroups || loadingInsuredPositions || (parseInt(paramId) > 0 && (loadingFinancialGroups || loadingContracts || loadingSubcontracts))"
                    :disabled="loadingFinancialGroups || loadingInsuredPositions || (parseInt(paramId) > 0 && (loadingFinancialGroups || loadingContracts || loadingSubcontracts))"
                    item-text="name"
                    item-value="id"
                    outlined />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                    class="pt-0 pb-0"
                    label="Grupo de Usuário"
                    multiple
                    small-chips
                    deletable-chips
                    v-model="beneficiaryGroupSelecteds"
                    :items="beneficiaryGroups"
                    :loading="loadingBeneficiaryGroups || (parseInt(paramId) > 0 && (loadingFinancialGroups || loadingContracts || loadingSubcontracts))"
                    :disabled="loadingBeneficiaryGroups || (parseInt(paramId) > 0 && (loadingFinancialGroups || loadingContracts || loadingSubcontracts))"
                    item-text="title"
                    item-value="id"
                    outlined />
              </v-col>
            </v-row>

          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import OdooService from "../../services/odoo/ContractService";
import ContractService from "../../services/contract/ContractService";
import SurveyService from "../../services/survey/SurveyService";

export default {
  name: "managementTargets",
  data: () => ({
    busy: false,
    sfUser: false,
    debounceTimeout: 3000,
    urlAssessment: "assesments",
    assessmentModel: {
      name: '',
      presentation: '',
      startDate: '',
      endDate: '',
      status: '',
      topics: null,
    },
    paramId: 0,
    selectedTab: '',
    financialGroups: [],
    financialGroupSelecteds: [],
    hasSelectedAllFinancialGroups: false,
    loadingFinancialGroups: false,
    contracts: [],
    contractSelecteds: [],
    loadingContracts: false,
    subcontracts: [],
    subcontractSelecteds: [],
    loadingSubcontracts: false,
    costCenters: [],
    costCenterSelecteds: [],
    loadingCostCenters: false,
    beneficiaryGroups: [],
    beneficiaryGroupSelecteds: [],
    loadingBeneficiaryGroups: false,
    insuredPositions: [],
    insuredPositionSelecteds: [],
    loadingInsuredPositions: false,
  }),
  props: {
    assessmentParam: {
      type: Object,
    }
  },
  watch: {
    async assessmentParam(newValue) {
      if(newValue) {
        await this.loadAssessmentTargets(newValue);
      }
    },
    feedbacksList(val) {
      if (val.length < 1) {
        this.minRangeValidation = 0;
      }
    },
    financialGroupSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(id => id === 0)) {
          if(this.financialGroups.length > 1) {
            this.financialGroups.forEach(financialGroup => {
              financialGroup.disabled = true;
            })
            this.financialGroups[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.financialGroupSelecteds = [0];
          }
        } else {
          if(this.financialGroups.length > 1) {
            this.financialGroups.forEach(financialGroup => {
              financialGroup.disabled = false;
            })
          }
        }

        this.setTargetFinancialGroups(this.financialGroupSelecteds);
      },
      deep: true
    },
    contractSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(id => id === 0)) {
          if(this.contracts.length > 1) {
            this.contracts.forEach(contract => {
              contract.disabled = true;
            })
            this.contracts[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.contractSelecteds = [0];
          }
        } else {
          if(this.contracts.length > 1) {
            this.contracts.forEach(contract => {
              contract.disabled = false;
            })
          }
        }

        this.setTargetContracts(this.contractSelecteds);
      },
      deep: true
    },
    subcontractSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(id => id === 0)) {
          if(this.subcontracts.length > 1) {
            this.subcontracts.forEach(subcontract => {
              subcontract.disabled = true;
            })
            this.subcontracts[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.subcontractSelecteds = [0];
          }
        } else {
          if(this.subcontracts.length > 1) {
            this.subcontracts.forEach(subcontract => {
              subcontract.disabled = false;
            })
          }
        }

        this.setTargetSubContracts(this.subcontractSelecteds);
      },
      deep: true
    },
    costCenterSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(id => id === 0)) {
          if(this.costCenters.length > 1) {
            this.costCenters.forEach(costCenter => {
              costCenter.disabled = true;
            })
            this.costCenters[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.costCenterSelecteds = [0];
          }
        } else {
          if(this.costCenters.length > 1) {
            this.costCenters.forEach(costCenter => {
              costCenter.disabled = false;
            })
          }
        }

        this.setTargetCostCenters(this.costCenterSelecteds);
      },
      deep: true
    },
    insuredPositionSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(id => id === 'ALL')) {
          if(this.insuredPositions.length > 1) {
            this.insuredPositions.forEach(costCenter => {
              costCenter.disabled = true;
            })
            this.insuredPositions[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.insuredPositionSelecteds = ['ALL'];
          }
        } else {
          if(this.insuredPositions.length > 1) {
            this.insuredPositions.forEach(costCenter => {
              costCenter.disabled = false;
            })
          }
        }

        this.setTargetInsuredPositions(this.insuredPositionSelecteds);
      },
      deep: true
    },
    beneficiaryGroupSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(id => id === 0)) {
          if(this.beneficiaryGroups.length > 1) {
            this.beneficiaryGroups.forEach(beneficiaryGroup => {
              beneficiaryGroup.disabled = true;
            })
            this.beneficiaryGroups[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.beneficiaryGroupSelecteds = [0];
          }
        } else {
          if(this.beneficiaryGroups.length > 1) {
            this.beneficiaryGroups.forEach(beneficiaryGroup => {
              beneficiaryGroup.disabled = false;
            })
          }
        }

        this.setTargetBeneficiaryGroups(this.beneficiaryGroupSelecteds);
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    ...mapActions(['setTargetFinancialGroups', 'setTargetContracts', 'setTargetSubContracts',
      'setTargetBeneficiaryGroups', 'setTargetInsuredPositions', 'setTargetCostCenters']),
    loadUrlAssessment() {
      const { hideMenu } = this.$route.meta;
      if (hideMenu) {
        this.urlAssessment = "assessments-salesforce";
      }
    },
    loadAllItemFromCombos() {
      this.financialGroups.push({ name: 'Todos', id: 0 });
      this.contracts.push({ text: 'Todos', id: 0 });
      this.subcontracts.push({ text: 'Todos', id: 0 });
      this.costCenters.push({ name: 'Todos', id: 0 });
      this.beneficiaryGroups.push({ title: 'Todos', id: 0 });
      this.insuredPositions.push({ name: 'Todos', id: 'ALL' });
    },
    loadAllContractsWithDebounce() {
      clearTimeout(this._timerId)

      this._timerId = setTimeout( () => {
        this.$refs.comboFinancialGroups.blur();
        this.loadAllContractsByFinancialGroup();
      }, this.debounceTimeout);
    },
    loadAllSubContractsWithDebounce() {
      clearTimeout(this._timerId)

      this._timerId = setTimeout( () => {
        this.$refs.comboContracts.blur();
        this.loadAllSubContractsByContract();
      }, this.debounceTimeout);
    },
    async loadAllFinancialGroups() {
      this.loadingFinancialGroups = true;

      if(this.sfUser) {
        await this._odooService.FindAllFinancialGroup()
            .then(response => {
              if(response && response.data && response.data.records) {
                this.financialGroups = this.financialGroups.concat(response.data.records);
              }
            })
            .catch(() => {
              this.showmsg({text: "Erro ao tentar carregar os grupos financeiros.", type: "error"})
            });

      } else {

        let financialGroupId = null;
        if(this.paramId && parseInt(this.paramId) === 0) {
          const session = this.$util.getSession();
          financialGroupId = (session && session.financialGroup && session.financialGroup.id) ? session.financialGroup.id : null;
          this.financialGroupSelecteds.push(financialGroupId);
        } else {
          financialGroupId = this.financialGroupSelecteds[0];
        }

        if(financialGroupId) {

          await this._odooService.FindAllFinancialGroupById(financialGroupId)
              .then(response => {
                if(response && response.data && response.data) {
                  this.financialGroups.push(response.data);
                  this.financialGroupSelecteds = [];
                  this.financialGroupSelecteds.push(this.financialGroups[1].id)
                }
              })
              .catch(() => {
                this.showmsg({text: "Erro ao tentar carregar os grupos financeiros.", type: "error"})
              });

          if(this.financialGroups.length) {
            this.loadAllCostCenters();
            this.loadAllInsuredPositionsByFinancialGroup();
            this.loadAllBeneficiaryGroups();
            await this.loadAllContractsByFinancialGroup();
          }
        }
      }

      this.setTargetFinancialGroups(this.financialGroupSelecteds);
      this.loadingFinancialGroups = false;
    },
    async loadAllContractsByFinancialGroup() {
      let firstContract = null;
      if(this.contracts.length) {
        firstContract = this.contracts[0];
      }
      this.contracts = [];
      this.contracts.push(firstContract);

      let firstSubContract = null;
      if(this.subcontracts.length) {
        firstSubContract = this.subcontracts[0];
      }
      this.subcontracts = [];
      this.subcontracts.push(firstSubContract);

      let firstCostCenter = null;
      if(this.costCenters.length) {
        firstCostCenter = this.costCenters[0];
      }
      this.costCenters = [];
      this.costCenters.push(firstCostCenter);

      let firstInsuredPosition = null;
      if(this.insuredPositions.length) {
        firstInsuredPosition = this.insuredPositions[0];
      }
      this.insuredPositions = [];
      this.insuredPositions.push(firstInsuredPosition);

      let firstBeneficiaryGroup = null;
      if(this.beneficiaryGroups.length) {
        firstBeneficiaryGroup = this.beneficiaryGroups[0];
      }
      this.beneficiaryGroups = [];
      this.beneficiaryGroups.push(firstBeneficiaryGroup);

      this.contractSelecteds = [];
      this.subcontractSelecteds = [];
      this.costCenterSelecteds = [];
      this.insuredPositionSelecteds = [];
      this.beneficiaryGroupSelecteds = [];

      if(this.financialGroupSelecteds.length) {
        this.loadingContracts = true;

        this.loadAllCostCenters();
        this.loadAllInsuredPositionsByFinancialGroup();
        this.loadAllBeneficiaryGroups();

        const params = new URLSearchParams();
        params.append('subcontract', 'false');
        if(!(this.financialGroupSelecteds.length === 1 && this.financialGroupSelecteds.some(id => id === 0))) {
          this.financialGroupSelecteds.forEach((financialGroupId) => {
            params.append('financialGroupIds', `${financialGroupId}`);
          });
        }

        if(!this.sfUser) {
          await this._odooService.FindAllByFilters(params)
              .then(response => {
                let contractsLoaded = [];
                if(response && response.data && response.data.records) {
                  response.data.records.forEach(contract => {
                     contractsLoaded.push(contract);
                  })
                }

                if (contractsLoaded.length) {
                  contractsLoaded = contractsLoaded.map((item) => ({
                    ...item,
                    disabled: false,
                    text: (`${(item.benefit_id ? item.benefit_id : '-')} / ${(item.carrier_id && item.carrier_id.xipp_commercial_name ?
                        item.carrier_id.xipp_commercial_name : '-')} - ${item.policy ? item.policy : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
                  }));
                }

                this.contracts = this.contracts.concat(contractsLoaded);
              })
              .catch(() => {
                this.showmsg({text: "Erro ao tentar carregar os contratos.", type: "error"})
              });

        } else {

          await this._odooService.FindAll(params)
              .then(response => {
                let contractsLoaded = [];
                if(response && response.data && response.data.records) {
                  response.data.records.forEach(contract => {
                     contractsLoaded.push(contract);
                  })
                }

                if (contractsLoaded.length) {
                  contractsLoaded = contractsLoaded.map((item) => ({
                    ...item,
                    disabled: false,
                    text: (`${(item.benefit_id ? item.benefit_id : '-')} / ${(item.carrier_id && item.carrier_id.xipp_commercial_name ?
                        item.carrier_id.xipp_commercial_name : '-')} - ${item.policy ? item.policy : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
                  }));
                }

                this.contracts = this.contracts.concat(contractsLoaded);
              })
              .catch(() => {
                this.showmsg({text: "Erro ao tentar carregar os contratos.", type: "error"})
              });
        }

        this.loadingContracts = false;
      }
    },
    async loadAllSubContractsByContract() {
      let firstSubContract = null;
      if(this.subcontracts.length) {
        firstSubContract = this.subcontracts[0];
      }
      this.subcontracts = [];
      this.subcontracts.push(firstSubContract);
      this.subcontractSelecteds = [];

      if(this.contractSelecteds.length) {
        this.loadingSubcontracts = true;

        const contractsParams = this.contractSelecteds;
        contractsParams.unshift();
        const params = new URLSearchParams();
        params.append('subcontract', 'true');
        if(!(this.contractSelecteds.length === 1 && this.contractSelecteds.some(id => id === 0))) {
          contractsParams.forEach((contractId) => {
            params.append('parentContractIds', `${contractId}`);
          });
        }

        await this._odooService.FindAll(params)
            .then(response => {
              let subcontractsLoaded = [];
              if(response && response.data && response.data.records) {
                subcontractsLoaded = response.data.records;
              }

              if (subcontractsLoaded.length) {
                subcontractsLoaded = subcontractsLoaded.map((item) => ({
                  ...item,
                  text: (`${(item.benefit_id ? item.benefit_id : '-')} / ${(item.carrier_id && item.carrier_id.xipp_commercial_name ?
                      item.carrier_id.xipp_commercial_name : '-')} - ${item.policy ? item.policy : '-'} - ${item.contract_owner_id && item.contract_owner_id.legal_name ? item.contract_owner_id.legal_name : '-'}`),
                }));
              }

              this.subcontracts = this.subcontracts.concat(subcontractsLoaded);
            })
            .catch(() => {
              this.showmsg({text: "Erro ao tentar carregar os subcontratos.", type: "error"})
            });

        this.loadingSubcontracts = false;
      }
    },
    loadAllCostCenters() {
      this.loadingCostCenters = true;
      if(this.financialGroupSelecteds && this.financialGroupSelecteds.length) {
        const stringFinancialGroups = this.financialGroupSelecteds.map((item) => item.toString());

        const params = new URLSearchParams();
        stringFinancialGroups.forEach((financialGroupId) => {
          params.append('financialGroupIds', financialGroupId);
        });

        this._contractService.findCostCentersByFinancialGroupId(params)
            .then(response => {
              if(response && response.data && response.data) {
                this.costCenters = this.costCenters.concat(response.data);
              }
            })
            .finally(() => {
              this.loadingCostCenters = false;
            });
      }
    },
    loadAllInsuredPositionsByFinancialGroup() {
      this.loadingInsuredPositions = true;

      if(this.financialGroupSelecteds && this.financialGroupSelecteds.length) {
        const stringFinancialGroups = this.financialGroupSelecteds.map((item) => item.toString());

        const params = new URLSearchParams();
        stringFinancialGroups.forEach((financialGroupId) => {
          params.append('financialGroupIds', financialGroupId);
        });

        this._contractService.findInsuredPositionsByFinancialGroupId(params)
            .then(response => {
              if(response && response.data) {
                let insuredLoaded = response.data;

                insuredLoaded = insuredLoaded.map((item) => ({
                  name: item,
                  id: item
                }));

                this.insuredPositions = this.insuredPositions.concat(insuredLoaded);
              }
            })
            .catch(() => {
              this.showmsg({text: "Erro ao tentar carregar os cargos.", type: "error"})
            });
      }

      this.loadingInsuredPositions = false;
    },
    loadAllBeneficiaryGroups() {
      this.loadingBeneficiaryGroups = true;

      const stringFinancialGroups = this.financialGroupSelecteds.map((item) => item.toString());
      const params = new URLSearchParams();
      params.append('size', '100');
      stringFinancialGroups.forEach((financialGroupId) => {
        params.append('financialGroupIds', financialGroupId);
      });

      this._contractService.findAllBeneficiaryGroupsByFilters(params)
          .then(response => {
            if(response && response.data && response.data.content) {
              this.beneficiaryGroups = this.beneficiaryGroups.concat(response.data.content);
            }
          })
          .catch(() => {
            this.showmsg({text: "Erro ao tentar carregar os grupos de usuários.", type: "error"})
          });

      this.loadingBeneficiaryGroups = false;
    },
    async loadAssessmentTargets(newValue) {
      if(newValue && newValue.financialGroups.length) {
        this.financialGroupSelecteds = newValue.financialGroups;
        this.loadAllCostCenters();
        this.loadAllInsuredPositionsByFinancialGroup();
        this.loadAllBeneficiaryGroups();
        await this.loadAllFinancialGroups();
        await this.loadAllContractsByFinancialGroup();
      }

      if(newValue && newValue.contracts.length) {
        this.contractSelecteds = newValue.contracts;
        await this.loadAllSubContractsByContract();
      }

      if(newValue && newValue.costCenters.length) {
        this.costCenterSelecteds = newValue.costCenters;
      }

      if(newValue && newValue.insuredPositions.length) {
        this.insuredPositionSelecteds =  newValue.insuredPositions;
      }

      if(newValue && newValue.beneficiaryGroups.length) {
        this.beneficiaryGroupSelecteds = newValue.beneficiaryGroups;
      }

      if(newValue && newValue.subContracts.length) {
        this.subcontractSelecteds = newValue.subContracts;
      }
    },
  },
  beforeCreate() {
    this._surveyService = new SurveyService();
    this._odooService = new OdooService();
    this._contractService = new ContractService();
  },
  async created() {
    const odooToken = localStorage.getItem('LOCALIZED_TOKEN');
    this.sfUser = odooToken ? true : false;
    this.paramId = this.$route.params.id;

    this.loadAllItemFromCombos();
    this.loadUrlAssessment();

    this.busy = true;
    this.loading(this.busy);
    if(this.paramId && parseInt(this.paramId) === 0){
      await this.loadAllFinancialGroups();
    }
    this.busy = false;
    this.loading(this.busy);
  },
};
</script>
