<template>
  <v-container fluid grid-list-md class="px-2">
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>

    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <DrawerFilterer
        title="Mensageria"
        :filters="filters"
        :data="items"
        @queryUpdate="loadFilterItems"
        @filtersRemoved="loadAllItems"
        @clearFilters="loadAllItems"
      />
      <v-flex xs12>
        <ProgressiveLoader :disabled="busy">
          <v-card>
            <v-card-title class="primary--text">
              <v-spacer />
              <v-btn
                  fab
                  bottom
                  right
                  fixed
                  color="primary"
                  dark
                  @click="toggleFilterDrawer"
              >
                <v-icon>fas fa-filter</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title>
              <v-spacer />
              <v-btn
                  color="grey"
                  text
                  icon
                  @click="$router.push(`${urlMessage}/inserir`)"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
                id="mensageria-listar"
                single-expand
                show-expand
                loading-text="Carregando itens..."
                disable-filtering
                disable-pagination
                disable-sort
                hide-default-footer
                :headers="headers"
                :items="items"
                :loading="loading"
                item-key="id"
            ><!--@click:row="(item) => handleItemSelection(item)"-->
              <template v-slot:header></template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-3">
                  <span style="font-weight: bold">Mensagem: </span>{{ item.body }}
                </td>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" type="info" v-show="!loading" outlined>
                  Nenhum item foi encontrado.
                </v-alert>
              </template>
              <template v-slot:footer>
                <v-pagination
                    v-model="filter.selectedPage"
                    :length="filter.totalPages"
                    @input="loadItems()"
                />
              </template>
            </v-data-table>
          </v-card>
        </ProgressiveLoader>
      </v-flex>
    </v-layout>
    <template v-else>
      <FabFilterer
        title="Mensageria"
        :filters="filters"
        :data="items"
        @queryUpdate="loadFilterItems"
        @filtersRemoved="loadAllItems"
        @clearFilters="loadAllItems"
      />
      <v-btn
        color="primary"
        dark
        icon
        @click="$router.push(`${urlMessage}/inserir`)"
      >
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
      <ProgressiveLoader :disabled="busy">
        <v-data-iterator
            single-expand
            hide-default-footer
            hide-default-header
            disable-pagination
            :items="items"
        >
          <template v-slot:no-data>
            <v-alert :value="false" type="info" v-show="!loading" outlined
            >Nenhum item foi encontrado</v-alert
            >
          </template>
          <template v-slot:default="props">
            <v-row v-for="item in props.items" :key="item.id">
              <v-col cols="12">
                <!--@click="handleItemSelection(item)"-->
                <v-card
                    class="elevation-5 pb-0"
                >
                  <v-card-text class="pb-0">
                    <v-row dense class="mb-4">
                      <template v-for="header in headers">
                        <v-col :cols="header.cols" :key="header.value">
                          <v-row no-gutters>
                            <v-col
                                cols="12"
                                class="black--text font-weight-bold"
                            >{{ header.text }}</v-col
                            >
                            <v-col
                                cols="12"
                                :class="
                                (header.bold ? 'font-weight-bold' : '') +
                                ' silver--text pa-0'
                              "
                            >{{ item[header.value] }}</v-col>
                          </v-row>
                        </v-col>
                      </template>
                      <v-col
                          cols="12"
                          class="d-flex my-0 py-0 font-weight-bold"
                          style="color: black;"
                      >
                        Mensagem
                      </v-col>
                      <v-col
                          cols="12"
                          class="d-flex mb-2 silver--text py-0"
                      >
                        {{item['body']}}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </ProgressiveLoader>
    </template>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import FabFilterer from "@/components/fab-filterer";
import DrawerFilterer from "@/components/drawer-filterer";
import ProgressiveLoader from "@/components/progressive-loader";
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import SecurityService from "../../services/security/SecurityService";
import moment from "moment";
import AuthService from "../../services/auth/AuthService";

export default {
  name: "MensageriaListar",
  components: { FabFilterer, DrawerFilterer, ProgressiveLoader },
  data: () => ({
    items: [],
    filteredItems: [],
    loading: false,
    search: "",
    busy: false,
    paginations: 0,
    maximumItems: 0,
    loadedItems: 0,
    describe: null,
    pageNext: null,
    urlMessage: '/mensageria',
    nameMessageInsert: 'mensageriainserir',
    sfUser: null,
    filter: {
      title: null,
      recipientId: null,
      finalDate: null,
      initialDate: null,
      totalPages: 0,
      selectedPage: 1,
      page: 0,
      size: 10,
    }
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapState(["window"]),
    filters() {
      let filters = [
        {
          name: "Título",
          key: "title",
          types: [
            {
              name: "Título",
              value: "",
              type: "input",
              active: true,
            },
          ],
        },
        {
          name: "Data Inicial",
          key: "initialDate",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Data Inicial",
              value: "",
              type: "date",
              mask: '##/##/####',
              active: true,
            },
          ]
        },
        {
          name: "Data Final",
          key: "finalDate",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Data Final",
              value: "",
              type: "date",
              mask: '##/##/####',
              active: true,
            },
          ]
        },
      ];

      if(this.sfUser)
        filters.push({
          name: "Recebedor",
          key: "recipientId",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Nome do Grupo ou E-mail do Usuário",
              value: "",
              type: "input",
              active: true,
            },
          ],
        })

      return filters;
    },
    headers() {
      let baseHeaders = [
        { text: "Titulo", value: "title", cols: "6" },
        { text: "Recebedor", value: "recipient", cols: "12" },
        { text: "Status", value: "status", cols: "6" },
        { text: "Data e Hora de Envio", value: "sendDate", cols: "6" },
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({ text: "", value: "", sortable: false, cols: "" });
      }
      return baseHeaders;
    },
    defaultQuery() {
      let query = "?IsClosed=false";

      //Types
      query += this.queryTypes;

      return query;
    },
    queryTypes() {
      let types = this.filters
          .filter((filter) => filter.key === "type")
          .map((filter) => filter.types)
          .reduce(Array)
          .map((types) => types.value)
          .join(",");

      return "&type=" + types;
    },
  },
  methods: {
    ...mapMutations(["showmsg"]),
    addItem(obj) {},
    setFilteredItems(newItems) {},
    concatenFilteredItems(newItems) {},
    handleItemSelection(item) {
      this.$router.push({ name: this.nameMessageInsert, params: { id: item.id } });
    },
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    loadAllItems() {
      this.clearFilter();
      this.loadItems();
    },
    loadFilterItems(data) {
      if (data) {
        if(data && !data.includes('finalDate')){
          this.filter.finalDate = null;
        }

        if(data && !data.includes('initialDate')){
          this.filter.initialDate = null;
        }

        if(data && !data.includes('title')){
          this.filter.title = null;
        }

        this.filterItemsSplit(data)
            .then(() => this.loadItems())
      }
    },
    async filterItemsSplit(filters) {
      let newFilters = filters.trim().split('and');

      await newFilters.forEach((filter) => {
        if(filter.includes('title')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          this.filter.title = value;
        }

        if(filter.includes('recipientId')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          this.filter.recipientId = value;
        }

        if(filter.includes('finalDate')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          if(moment(value, "DD/MM/YYYY").isValid()) {
            this.filter.finalDate = moment(value + ' 23:59:59', "DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
          }else
            this.showmsg({ text: 'A data final do filtro não é válida.', type: 'error' })
        }

        if(filter.includes('initialDate')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          if(moment(value).isValid())
            this.filter.initialDate = moment(value + ' 00:00:00', "DD/MM/YYYY HH:mm:ss").format('YYYY-MM-DD HH:mm:ss')
          else
            this.showmsg({ text: 'A data inicial do filtro não é válida.', type: 'error' })
        }
      })
    },
    clearFilter() {
      this.filter = {
        title: null,
        recipientId: null,
        finalDate: null,
        initialDate: null,
        totalPages: 0,
        selectedPage: 1,
        page: 0,
        size: 10,
      }
    },
    async loadItems() {
      let messages = []
      this.items = [];

      this.loading = true;
      this.filter.page = this.filter.selectedPage - 1;
      this.filter.totalPages = 0;
      await this._socialnetworkService.FindAllMessagesByFinancialGroup(this.filter)
        .then(response => {
          messages = response.data.content
          this.filter.totalPages = response.data.totalPages
          messages.sort((a, b) => this.orderById(a, b)).reverse()
          messages.forEach(message => {
            this.createItem(message);
          })
        })
        .catch(() => {
          this.showmsg({text: "Ocorreu um erro ao listar as listar as mensagens por grupo financeiro.", type: "error"});
        })
        .finally(()=>{
          this.loading = false;
        })
    },
    orderById(a, b){
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    },
    createItem(message) {
      this.items.push({
        id: message.id,
        title: message.title,
        body: message.body,
        recipient: this.convertRecipientType(message.recipientType, message.userEmail),
        status: message.sent ? 'Enviado' : 'Pendente',
        sendDate: this.$util.vueFormatDateHourToBR(message.sentDateTime),
      });
    },
    convertRecipientType(recipientType, userEmail) {
      switch (recipientType){
        case "FINANCIAL_GROUP":
          return "Grupo Financeiro";
        case "USER":
          return "Usuário: " + userEmail;
        default:
          return "Indisponível";
      }
    },
    loadUrlCases(){
      const {hideMenu} = this.$route.meta
      if(hideMenu){
        this.urlMessage = '/mensageria-salesforce'
        this.nameMessageInsert = 'mensageria-salesforce-inserir'
      }
    },
    loadStorageSalesforce(){
      const {token, origin} = this.$route.query;
      if (token) {
          console.log('token: ', token)
        localStorage.setItem("LOCALIZED_TOKEN", token)
        localStorage.setItem("LOCALIZED_ORIGIN", origin)
        localStorage.removeItem("auth_user");
        localStorage.removeItem("token");
        this.sfUser = localStorage.getItem('LOCALIZED_ORIGIN') === 'salesforce'
      }
    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
        .then((response) => {
            if(response && response.data) {
                localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
        });
    },
  },
  async created() {
    this.loading = true;
    this.loadUrlCases();
    this.loadStorageSalesforce();
    await this.loadJWTToken();

    this._socialnetworkService = new SocialNetworkService();
    this._securityService = new SecurityService();
    await this.loadItems();
    this.loading = false;
  },
  beforeMount() {
    this.sfUser = localStorage.getItem('LOCALIZED_ORIGIN') === 'salesforce'
  }
};
</script>

<style>
#atendimento-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}
</style>
