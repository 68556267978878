import axios from 'axios';
import $util from "../../common/util";

export default class HttpService {
  constructor() {
    this.ws = this._loadAxios();
  }

  _loadAxios(_responseType = "") {
    return axios.create({
      baseURL: process.env.VUE_APP_SALESFORCE_SERVICE_URL,
      headers: this._loadHeaders(),
      responseType: _responseType ? _responseType : null,
    });
  }

  _loadHeaders() {
    let token_active = $util.getSession();

    let current_token = (token_active
        && token_active.tokenSecurityService
        && token_active.tokenSecurityService.access_token) || $util.getLocalizedToken();

    return {
      Authorization: `Bearer ${current_token}`,
      'Access-Control-Max-Age': 600,
      'Cache-Control': 'max-age=600'
    }
  }

  get(path, params = null) {
    this.ws = this._loadAxios()
    return this.ws.get(path, {
      params: params,
    });
  }

  post(path, data) {
    return this.ws.post(path, data);
  }

  getBlob(path) {
    this.ws = this._loadAxios("blob")
    return this.ws.get(path);
  }
}