import axios from 'axios';

const NEW_SPREADSHEETS_URL = 'https://content-sheets.googleapis.com/v4/spreadsheets/';

const getGoogleSheets = (key, apiKey, range) => {
    const url = `${NEW_SPREADSHEETS_URL}${key}/values/${range}?key=${apiKey}`;

    return axios({
        method: 'get',
        url: url,
    }).then(response => response.data.values);
};

export {
  getGoogleSheets,
};
