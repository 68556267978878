<template>
    <div class="avatar-upload-component mr-2">
        <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Arraste aqui para atualizar!</h3>
        </div>
        <div>
            <label for="avatar">
                <v-avatar size="60" style="overflow: visible">
                    <img :src="userProfilePicture" />
                    <i class="edit-profile-picture fas fa-pen"/>
                </v-avatar>
            </label>
            <file-upload
                    extensions="gif,jpg,jpeg,png,webp"
                    accept="image/png,image/gif,image/jpeg,image/webp"
                    name="avatar"
                    :drop="!edit"
                    v-model="files"
                    @input-filter="inputFilter"
                    @input-file="inputFile"
                    ref="upload"
                    v-show="false"
                    :custom-action="customAction"
            />
        </div>
        <v-dialog v-model="edit" v-if="files.length" persistent max-width="400px">
            <div justify-center align-center>
                <v-card>
                    <v-card-text class="pa-0">
                        <img ref="editImage" :src="files[0].url" style="max-width: 400px" />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block large color="primary" @click.prevent="editSave">Atualizar</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn block large color="secondary" class="black--text" @click.prevent="cancel()">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import { mapMutations, mapActions, mapGetters } from "vuex";
    import Cropper from 'cropperjs'
    import FileUpload from 'vue-upload-component'
    import { updateProfilePicture } from "../../common/securityserver";

    export default {
        components: {
            FileUpload,
        },

        data() {
            return {
                files: [],
                edit: false,
                cropper: false,
            }
        },

        beforeMount() {
            if(this.profilePictureSrc){
                this.files.push({
                    url: this.profilePictureSrc
                })
            }
        },

        watch: {
            edit(value) {
                if (value) {
                    this.$nextTick(function () {
                        if (!this.$refs.editImage) {
                            return
                        }
                        let cropper = new Cropper(this.$refs.editImage, {
                            aspectRatio: 1 / 1,
                            viewMode: 1,
                        })
                        this.cropper = cropper
                    })
                } else {
                    if (this.cropper) {
                        this.cropper.destroy()
                        this.cropper = false
                    }
                }
            }
        },

        computed: {
            ...mapGetters(["userProfilePicture"])
        },

        methods: {
            ...mapMutations(["showmsg", "loading", "setUserProfilePicture"]),
            ...mapActions(["changeProfilePicture"]),
            editSave() {
                this.edit = false

                let oldFile = this.files[0]

                let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }

                let file = new File([arr], oldFile.name, { type: oldFile.type })

                this.$refs.upload.update(oldFile.id, {
                    file,
                    type: file.type,
                    size: file.size,
                    active: true,
                })
            },

            inputFile(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                        this.edit = true
                    })
                }
                if (!newFile && oldFile) {
                    this.edit = false
                }
            },

            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
                        this.showmsg({ text: "Sua escolha não é uma imagem!", type: "error" });
                        return prevent()
                    }
                }

                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    newFile.url = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.url = URL.createObjectURL(newFile.file)
                    }
                }
            },

            cancel(){
                this.$refs.upload.clear();
                if(this.profilePictureSrc){
                    this.files.push({
                        url: this.profilePictureSrc
                    })
                }
            },

            async customAction(file, component) {
                var reader = new FileReader();
                var thizz = this;

                reader.onload = e => {
                    thizz.uploadProfilePicture(e.target.result);
                };
                reader.readAsDataURL(file.file);
            },

            uploadProfilePicture(data) {
                const imgContent = data.split(",")[1];

                this.loading(true);

                updateProfilePicture(imgContent)
                    .then(response => {
                        if (response.data === true) {
                            this.setUserProfilePicture(imgContent);
                            this.showmsg({ text: "Imagem atualizada!", type: "success" });
                        } else {
                            this.showmsg({ text: "Erro ao alterar imagem!", type: "error" });
                        }
                    })
                    .catch(() =>
                        this.showmsg({ text: "Erro ao alterar imagem!", type: "error" })
                    ).finally(() => (this.loading(false)));
            },
        },
    }
</script>

<style>

    .avatar-upload-component .drop-active {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: 9999;
        opacity: .6;
        text-align: center;
        background: #000;
    }

    .avatar-upload-component .drop-active h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #fff;
        padding: 0;
    }

    .edit-profile-picture {
        position: absolute;
        top: 40px;
        right: -7px;
        border-radius: 50%;
        height: 20px !important;
        width: 20px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: var(--v-primary-base);
        box-shadow: 0 0 8px 3px #B8B8B8;
        font-size: 10px;
    }

</style>
