import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":"","tile":"","height":"100%"}},[_c(VCardActions,{staticClass:"justify-space-between"},[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.prev}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VItemGroup,{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.dashboards),function(n){return _c(VItem,{key:`btn-${n}`,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c(VBtn,{attrs:{"input-value":active,"icon":""},on:{"click":toggle}},[_c(VIcon,[_vm._v("mdi-record")])],1)]}}],null,true)})}),1),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.next}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1),_c(VWindow,{staticStyle:{"height":"100%"},attrs:{"vertical":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.dashboards),function(dashboard){return _c(VWindowItem,{key:`card-${dashboard}`,staticStyle:{"height":"100%"}},[_c('Metabase',{attrs:{"resourceId":dashboard,"path-params":"#bordered=true&titled=false"}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }