import ContractService from "../../services/contract/ContractService";

let  _contractService = new ContractService();
const _manuallyNotificationType = 'CARRIER_BILLING';
const _carrierBillingActionUpdatable = 'updatable';

export default {
    state: {
        invoices: { content: [] },
        invoice: {},
        invoiceTypes: [],
        attachments: [],
        downloadAttachment: null,
        downloadHistory: [],
    },
    mutations: {
        setInvoices(state, newValue) {
            state.invoices = newValue;
        },
        setInvoice(state, newValue) {
            state.invoice = newValue;
        },
        addInvoice(state, newValue) {
            state.invoices.content.push(newValue);
        },
        addAttachment(state, newValue) {
            state.attachments.push(newValue);
        },
        setAttachments(state, newValue) {
            state.attachments = newValue;
        },
        setAttachment(state, newValue) {
            state.downloadAttachment = newValue;
        },
        setAttachmentHistory(state, newValue) {
            state.downloadHistory = newValue;
        },
        setInvoiceTypes(state, newValue) {
            state.invoiceTypes = newValue;
        },
    },
    actions: {
        async updateInvoiceAttachments(store, { invoiceId, attList }) {
            store.commit('loading', true);
            _contractService = new ContractService();
            await _contractService.putBillingAttachmentsbyId(invoiceId, attList)
            .finally(() => {
                store.commit('loading', false);
            })
        },
        async newInvoice(store, invoice) {
            store.commit('loading', true);

            _contractService = new ContractService();
            await _contractService.saveBilling(invoice)
                .then(({ data }) => {
                    store.commit('setInvoice', data);
                    store.commit('addInvoice', data);
                    store.commit('showmsg', {
                        text: 'Fatura salva com sucesso!',
                        type: 'success',
                    })
                })
                .catch(() =>
                    store.commit('showmsg', {
                        text: 'Erro ao criar fatura.',
                        type: 'error',
                    })
                )
                .finally(() => {
                    store.commit('loading', false);
                    store.dispatch('getInvoices');
                })
        },
        async updateInvoice(store, { invoice, id }) {
            store.commit('loading', true);

            _contractService = new ContractService();
            await _contractService.updateBilling(id, invoice)
                .then(({ data }) => {
                    store.commit('setInvoice', data);
                    store.dispatch('getInvoices');
                    store.commit('showmsg', {
                        text: 'Fatura editada com sucesso!',
                        type: 'success',
                    })
                    if(invoice && invoice.carrierBillingStatus === 'PUBLISHED') {
                        store.commit('setIsOpenDialog', true);
                        store.commit('setCarrierBillingId', data.id)
                        store.commit('setManuallyNotificationType', _manuallyNotificationType)
                        store.commit('setCarrierBillingAction', _carrierBillingActionUpdatable)
                    }
                })
                .catch(() =>
                    store.commit('showmsg', {
                        text: 'Erro ao editar a fatura.',
                        type: 'error',
                    })
                )
                .finally(() => {
                    store.commit('loading', false);
                })
        },
        async newAttachment(store, { data, id }) {
            store.commit('loading', true);

            if(id) {
                _contractService = new ContractService();
                await _contractService.saveBillingAttachment(id, data)
                    .then(() => {
                        store.dispatch('getAttachments');
                    })
                    .finally(() => {
                        store.commit('loading', false);
                    })
            }

        },
        async getInvoices(store, { financialGroupId, contractId, subcontractId, billingNumber, carrierBillingType,
            competenceDate, dueDateStart, dueDateEnd, page, size }) {
            _contractService = new ContractService();
            await _contractService.findAllBillings(financialGroupId, contractId, subcontractId, billingNumber, carrierBillingType,
                competenceDate, dueDateStart, dueDateEnd, page, size)
                .then(({ data }) => {
                    store.commit('setInvoices', data);
                })
                .catch(() => {
                    store.commit('showmsg', {
                        text: 'Erro ao carregar as faturas.',
                        type: 'error',
                    })
                })
                .finally(() => {
                    store.commit('loading', false);
                })

        },
        async getAttachments(store, id) {
            store.commit('loading', true);

            if(id) {
                _contractService = new ContractService();
                await _contractService.getAllBillingAttachments(id)
                    .then(({data}) => {
                        if (data.length > 0)
                            store.commit('setAttachments', data);
                        else
                            store.commit('setAttachments', data);
                    })
                    .catch(() =>
                        store.commit('showmsg', {
                            text: 'Erro ao carregar anexos.',
                            type: 'error',
                        })
                    )
                    .finally(() => {
                        store.commit('loading', false);
                    })
            }
        },
        async downloadBillingAttachment(store, { id, blobId }) {
            store.commit('loading', true);

            _contractService = new ContractService();
            await _contractService.downloadBillingAttachment(id, blobId)
                .then(({ data }) => {
                    store.commit('setAttachment', data);
                })
                .catch(() =>
                    store.commit('showmsg', {
                        text: 'Erro ao baixar o anexo',
                        type: 'error',
                    })
                )
                .finally(() => {
                    store.commit('loading', false);
                })
        },
        async getHistoryAttachment(store, id) {
            store.commit('loading', true);

            _contractService = new ContractService();
            await _contractService.downloadBillingAttachmentHistory(id)
                .then(({ data }) => {
                    store.commit('setAttachmentHistory', data);
                })
                .catch(() =>
                    store.commit('showmsg', {
                        text: 'Erro ao buscar histórico de download',
                        type: 'error',
                    })
                )
                .finally(() => {
                    store.commit('loading', false);
                })
        },
        async getInvoice(store, id) {
            store.commit('loading', true);

            _contractService = new ContractService();
            await _contractService.getBillingById(id)
                .then(({ data }) => {
                    data.contractId = parseInt(data.contractId)
                    data.financialGroupId = parseInt(data.financialGroupId)
                    data.subcontractId = parseInt(data.subcontractId)
                    store.commit('setInvoice', data);
                })
                .catch(() =>
                    store.commit('showmsg', {
                        text: 'Erro ao carregar fatura.',
                        type: 'error',
                    })
                )
                .finally(() => store.commit('loading', false))
        },
        async getInvoiceTypes(store) {
            store.commit('loading', true);

            _contractService = new ContractService();
            await _contractService.getAllBillingTypes()
                .then(({ data }) => {
                    store.commit('setInvoiceTypes', data);
                })
                .catch(() =>
                    store.commit('showmsg', {
                        text: 'Erro ao carregar tipos de fatura.',
                        type: 'error',
                    })
                )
                .finally(() => store.commit('loading', false))

        },
    }
};
