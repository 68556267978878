import HttpService from '../HttpService'

export default class MetabaseService {
  constructor() {
    this._httpService = new HttpService("/euprotegido");
  }

  async validateScreeningQRCode(data) {
    return await this._httpService.post('/screening/validateScreeningQRCode/', data);
  }

}
