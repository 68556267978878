import HttpService from '../HttpService'

export default class MetabaseService {
  constructor() {
    this._httpService = new HttpService("/security");
  }

  async EncryptData(data){
    return await this._httpService.post('/cryptography/encrypt', data)
  }

  async FindAllEuProtegidoUsersIdByFinancialGroupExternalID(financialGroupId) {
    return await this._httpService.get('/user/findAllEuProtegidoUsersIdByFinancialGroupExternalID', {financialGroupId});
  }

  async findAllEuProtegidoUsersByFinancialGroupExternalID(financialGroupId) {
    return await this._httpService.get('/user/findAllEuProtegidoUsersByFinancialGroupExternalID', {financialGroupId});
  }

  async getUserAccessAnalyticalReport(params) {
    return await this._httpService.get('/user-report-euprotegido', params);
  }

  async getUserAccessSyntheticReport(params) {
    return await this._httpService.get('/user-report-euprotegido/synthetic', params);
  }

  async getUserAccessSDIReport(params) {
    return await this._httpService.get('/user-report-sdi', params);
  }

  async FindById(userId){
    return await this._httpService.get('/user/' + userId);
  }

  async getCurrent(){
    return await this._httpService.get('/internal_auth');
  }

  async FindAllUsersConnectedByAppCode(params){
    return await this._httpService.get('/user/findAllConnectedByAppCode', params);
  }

  async FindAllProfilesByAppCode(params){
    return await this._httpService.get('/profile/findAllByAppCode', params)
  }

  async FindAllProfiles(){
    return await this._httpService.get('/profile')
  }

  async FindAllProfilesPermitteds(){
    return await this._httpService.get('/profile/permitteds')
  }

  async FindAllCarrierBillingDefaultTemplates(){
    return await this._httpService.get('/carrier_billing_default_template')
  }

  async FindAllCarrierBillingEmailTemplates(){
    return await this._httpService.get('/carrier_billing_email_template')
  }

  async FindAllUsersToNotifyByAppCodeAndFinancialGroup(params){
    return await this._httpService.get('/user/findAllUsersToNotifyByAppCodeAndFinancialGroup', params)
  }

  async findCaseUsersByCaseId(caseId){
    return await this._httpService.get('/case_user/find_all_by_case_id/' + caseId);
  }

  async getUserReportData(params){
    return await this._httpService.get('/user-report/data', params);
  }

  async generateUserReport(params){
    return await this._httpService.get('/user-report', params);
  }
}
