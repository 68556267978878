/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Vue from 'vue'

import infiniteScroll from 'vue-infinite-scroll'
import { MediaQueries } from 'vue-media-queries';
import customvuetify from './plugins/vuetify'
import './plugins/fontawesome'
import 'babel-polyfill';
import store from './store/store.js'
import router from './router/router.js'
import './registerServiceWorker.js'
import util from './common/util.js'
import VueCurrencyFilter from 'vue-currency-filter'
import App from './App'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { mapMutations } from 'vuex';
import './plugins/filters'

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core';

const { PushNotifications } = Plugins;

const mediaQueries = new MediaQueries();

Vue.use(infiniteScroll);
Vue.use(mediaQueries);

import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

Vue.config.productionTip = false

Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});

Vue.filter('brl', (value) => {
  value = value && value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });

  return value;
})

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV,
  dsn: "https://b78b96cc5bb740469f22dc7c7d4e1fc0@o955208.ingest.sentry.io/5905769",
  ignoreUrls: [/tnk\.rhprotegido\.app/, /https?:\/\/tnk\.rhprotegido\.app\/atendimentos/],
  ignoreErrors: [/^Error createError\(axios\/lib\/core\/createError\)$/, /^Request failed with status code 401$/],
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["app.stg.rhprotegido.firecodeit.net","app.rhprotegido.com.br"],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

var vm = new Vue({
  store,
  router,
  render: h => h(App),
  vuetify: customvuetify,
  mediaQueries,
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize();

    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll();

    window.addEventListener('beforeinstallprompt', (event) => {
      try {
        event.prompt();
        //eslint-disable-next-line
      } catch (err) {}
    });

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
        'registration',
        (token) => {
          // alert('Push registration success, token: ' + token.value);
          // console.log('pushtoken:' + token.value);

          localStorage.setItem("PUSH_NOTIFICATION_TOKEN", token.value);
        },
    );

    PushNotifications.addListener('registrationError', (error) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          alert('Push received: ' + JSON.stringify(notification));
        },
    );

    PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          alert('Push action performed: ' + JSON.stringify(notification));
        },
    );
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations(['handleResize', 'handleScroll'])
  }
})

Vue.use(util, {
  vm: vm
})

vm.$mount('#app')

Vue.use(VueCurrencyFilter, {
  symbol : 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true
})

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})
