import store from '@/store/store'

const buildBreadCrumbItem = (ritem) => {
  // console.log(ritem)
  return {
    name: ritem.name,
    text: ritem.meta.title,
    icon: ritem.meta.icon,
    params: ritem.params,
    disabled: true,
  }
}

export default {
  state: {
    navigation: [],
  },
  mutations: {
    buildBreadCrumb(state, $vue_instance) {
      const route = $vue_instance.$route

      if (!route.meta.root) {
        return;
      }

      state.navigation.splice(0);

      let currentMenu = store.getters.currentMenu;

      if(!currentMenu || currentMenu.id == "" ){
        currentMenu = store.getters.user.menu[0];
        store.commit('setCurrentMenu', currentMenu);
      }

      route.meta.title = currentMenu.title;
      route.meta.icon = currentMenu.icon;

      state.navigation.push(buildBreadCrumbItem(route))

    },
    toggleBreadcrumb(state) {
      state.navigation = !state.navigation ? [] : false;
    }
  },
}
