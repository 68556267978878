<template>
  <v-container fluid>
    <v-form ref="assessmentForm">
      <v-row>
        <v-col class="pt-0 mt-0" cols="12" md="6">
          <managementHeader :assessmentParam="assessmentLoaded"></managementHeader>
        </v-col>

        <v-col class="pt-0 mt-0" cols="12" md="6">
          <managementTargets :assessmentParam="assessmentLoaded"></managementTargets>
        </v-col>

        <v-col class="pt-0 mt-0 mb-0 pb-0" cols="12">
          <managementTopics :assessmentParam="assessmentLoaded" @resetFormValidation="resetFormValidation" @scrollTo="scrollTo" :busy="busy"></managementTopics>
        </v-col>

        <v-col class="pt-0 mt-0 mb-0 pb-0" cols="12" v-if="assessmentTopicIndex !== -1">
          <managementQuestions id="questions" ref="manageQuestions" @resetFormValidation="resetFormValidation"></managementQuestions>
        </v-col>

        <v-col class="pt-0 mt-0 mb-0 pb-0" cols="12">
          <v-card class="pt-0 mt-0 mx-2">
            <v-card-actions>
              <v-spacer />
              <v-btn width="120" large light @click="cancel()" style="color: var(--v-primary-base)">
                Cancelar
              </v-btn>
              <v-btn width="120" large color="primary" @click="confirm()" :loading="busy" :disabled="busy">
                {{this.paramId && parseInt(this.paramId) > 0 ? 'Alterar' : 'Cadastrar'}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import SurveyService from './../../services/survey/SurveyService'
import OdooService from "../../services/odoo/ContractService";
import ContractService from "../../services/contract/ContractService";
import ManagementHeader from "../../components/assessment/managementHeader.vue";
import ManagementTargets from "../../components/assessment/managementTargets.vue";
import ManagementTopics from "../../components/assessment/managementTopics.vue";
import ManagementQuestions from "../../components/assessment/managementQuestions.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment-timezone";
import AuthService from "../../services/auth/AuthService";

export default {
  name: "assessmentManagement",
  components: {
    ManagementHeader,
    ManagementTargets,
    ManagementTopics,
    ManagementQuestions,
  },
  data: () => ({
    paramId: null,
    busy: false,
    sfUser: false,
    assessmentRouteName: 'assessment-feedback',
    assessmentLoaded: null,
  }),
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    ...mapActions(['setAssessment', 'setAssessmentTopics']),
    isValidAssessmentFields() {
      if(!this.assessment || !this.assessment.name || this.assessment.name.trim().length === 0
       || !this.assessment.presentation || this.assessment.presentation.trim().length === 0
       || !this.assessment.status || !this.assessment.startDate) {
        this.showmsg({ text: "Preencha os campos das informações do questionário:<br/>Nome, Apresentação, Status e Data de Início.", type: "error" });
        return false;
      }

      if(this.assessment && (!this.assessmentTopics || !this.assessmentTopics.length)) {
        this.showmsg({ text: "Preencha pelo menos 01 tópico.", type: "error" });
        return false;
      }

      if(this.assessment && this.assessmentTopics && this.assessmentTopics.length) {
        const isNotEmpty = this.assessmentTopics.every(item => item.topic.questions && item.topic.questions.length);
        if(!isNotEmpty){
          this.showmsg({ text: "Preencha as questões referentes ao(s) tópico(s) cadastrado(s).", type: "error" });
          return isNotEmpty;
        }
      }

      if(this.assessment && this.assessmentTopics && this.assessmentTopics.length) {
        let isNotEmpty = true;
        this.assessmentTopics.forEach(item => {
          isNotEmpty = item.topic.questions.every(item => item.triggers && item.triggers.length)
          if(!isNotEmpty) {
            return;
          }
        });

        if(!isNotEmpty) {
          this.showmsg({ text: "Preencha as respostas e pontuações referentes à(s) questão(ões) cadastrada(s).", type: "error" });
          return isNotEmpty;
        }
      }

      return true;
    },
    async loadAssessmentById() {
      await this._surveyService.FindAssessmentById(this.paramId)
          .then((response) => {
            if(response && response.data) {
              const result = response.data;
              result.financialGroups = result.financialGroups.map((item) => item.financialGroupId);
              result.contracts = result.contracts.map((item) => item.contractId);
              result.subContracts = result.subContracts.map((item) => item.subContractId);
              result.beneficiaryGroups = result.beneficiaryGroups.map((item) => item.beneficiaryGroupId);
              result.costCenters = result.costCenters.map((item) => item.costCenterId);
              result.insuredPositions = result.insuredPositions.map((item) => item.insuredPosition);

              result.topics.forEach(item => {
                item.topic.questions.forEach((question, indexQuestion) => {
                  indexQuestion++;
                  question.ordination = question && question.ordination ? question.ordination : indexQuestion;
                  question.triggers = question.triggers.map((trigger, index) => ({
                    ...trigger,
                    index,
                  }));
                });
              });

              result.topics.forEach(item => {
                const questionsOfTopic = item.topic.questions;
                item.topic.questions.forEach((question) => {
                  question.parentTriggers.forEach((trigger) => {
                    if(question.parentTriggers){
                      const questionFound = questionsOfTopic.find(item => item.id === trigger.assessmentQuestionId)
                      let triggerFound = questionFound.triggers.find(item => item.id === trigger.id)
                      let index = questionFound.triggers.indexOf(triggerFound)
                      if(triggerFound && !triggerFound.childQuestionsDescription || triggerFound.childQuestionsDescription.length === 0){
                        triggerFound.childQuestionsDescription = []
                      }
                      triggerFound.childQuestionsDescription.push(question.description)
                      questionFound.triggers[index] = triggerFound
                    }
                  });
                });
              });

              this.assessmentLoaded = Object.assign({}, {
                id: result.id,
                name: result.name,
                presentation: result.presentation,
                status: result.status,
                startDate: result.startDate,
                endDate: result.endDate,
                topics: Object.assign([], result.topics),
                financialGroups: Object.assign([], result.financialGroups),
                contracts: Object.assign([], result.contracts),
                subContracts: Object.assign([], result.subContracts),
                beneficiaryGroups: Object.assign([], result.beneficiaryGroups),
                costCenters: Object.assign([], result.costCenters),
                insuredPositions: Object.assign([], result.insuredPositions),
              });

              this.setAssessment(Object.assign({}, this.assessmentLoaded));
              this.setAssessmentTopics(Object.assign([], this.assessmentLoaded.topics));
            }
          }).catch(() => {
            this.showmsg({text: "Ocorreu um erro ao tentar carregar o questionário.", type: "error"});
          });
    },
    async createOrUpdateAssessment() {
      this.busy = true;
      this.loading(this.busy);

      const assessmentToSave = Object.assign({}, this.assessment);
      assessmentToSave.topics = Object.assign([], this.assessmentTopics);

      const currentHour = moment.utc().tz("America/Sao_Paulo").format("HH:mm:ss");
      const startDate = assessmentToSave.startDate ? moment(assessmentToSave.startDate).format('YYYY-MM-DD') : null;
      const endDate = assessmentToSave.endDate ? moment(assessmentToSave.endDate).format('YYYY-MM-DD') : null;
      assessmentToSave.startDate = assessmentToSave.startDate ? moment.utc(startDate + " " + currentHour).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'): null;
      assessmentToSave.endDate = assessmentToSave.endDate ? moment.utc(endDate + " " + currentHour).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') : null;

      let hasDefault = assessmentToSave.financialGroups.some(item => item === 0);
      if(assessmentToSave.financialGroups && assessmentToSave.financialGroups.length === 1 && hasDefault) {
        assessmentToSave.financialGroups = []
      }
      hasDefault = assessmentToSave.contracts.some(item => item === 0);
      if(assessmentToSave.contracts && assessmentToSave.contracts.length === 1 && hasDefault) {
        assessmentToSave.contracts = []
      }
      hasDefault = assessmentToSave.subContracts.some(item => item === 0);
      if(assessmentToSave.subContracts && assessmentToSave.subContracts.length === 1 && hasDefault) {
        assessmentToSave.subContracts = []
      }
      hasDefault = assessmentToSave.beneficiaryGroups.some(item => item === 0);
      if(assessmentToSave.beneficiaryGroups && assessmentToSave.beneficiaryGroups.length === 1 && hasDefault) {
        assessmentToSave.beneficiaryGroups = []
      }
      hasDefault = assessmentToSave.costCenters.some(item => item === 0);
      if(assessmentToSave.costCenters && assessmentToSave.costCenters.length === 1 && hasDefault) {
        assessmentToSave.costCenters = []
      }
      hasDefault = assessmentToSave.insuredPositions.some(item => item === 'ALL');
      if(assessmentToSave.insuredPositions && assessmentToSave.insuredPositions.length === 1 && hasDefault) {
        assessmentToSave.insuredPositions = []
      }

      assessmentToSave.financialGroups = assessmentToSave.financialGroups.map((item) => ({financialGroupId: item}));
      assessmentToSave.contracts = assessmentToSave.contracts.map((item) => ({contractId: item}));
      assessmentToSave.subContracts = assessmentToSave.subContracts.map((item) => ({subContractId: item}));
      assessmentToSave.beneficiaryGroups = assessmentToSave.beneficiaryGroups.map((item) => ({beneficiaryGroupId: item}));
      assessmentToSave.costCenters =  assessmentToSave.costCenters.map((item) => ({costCenterId: item}));
      assessmentToSave.insuredPositions = assessmentToSave.insuredPositions.map((item) => ({insuredPosition: item}));

      if (this.paramId && parseInt(this.paramId) === 0) {
        await this._surveyService.CreateAssessment(assessmentToSave)
            .then(response => {
              if(response.data){
                this.showmsg({text: "Questionário cadastrado com sucesso.", type: "success"});
                this.clearFields();
                this.$router.push({name: this.assessmentRouteName,
                  params: {assessment: response.data},
                  query: {assessmentId: response.data.id}
                });
              }
            })
            .catch(()=>{
              this.showmsg({ text: 'Ocorreu um erro ao tentar cadastrar o questionário.', type: 'error' });
            })
            .finally(()=>{
              this.busy = false;
              this.loading(this.busy);
            });
      } else {
        await this._surveyService.UpdateAssessment(assessmentToSave)
            .then(response => {
              this.showmsg({text: "Questionário alterado com sucesso.", type: "success"});
              this.clearFields();
              this.$router.push({name: this.assessmentRouteName,
                params: {assessment: response.data},
                query: {assessmentId: response.data.id}
              });
            })
            .catch(()=>{
              this.showmsg({ text: 'Ocorreu um erro ao tentar alterar o questionário.', type: 'error' });
            })
            .finally(()=>{
              this.busy = false;
              this.loading(this.busy);
            });
      }
    },
    clearFields() {
      this.setAssessment({
        name: null,
        presentation: null,
        status: null,
        startDate: null,
        endDate: null,
        topics: null,
        beneficiaryGroups: [],
        insuredPositions: [],
        costCenters: [],
        financialGroups: [],
        contracts: [],
        subContracts: [],
      });
    },
    resetFormValidation() {
      this.$refs.assessmentForm.resetValidation();
    },
    scrollTo() {
      setTimeout(() => {
        const element = document.getElementById('questions');
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 250);
      this.$refs.manageQuestions.resetTriggers();
    },
    cancel() {
      this.$router.back();
    },
    async confirm() {
      if(this.isValidAssessmentFields()) {
        await this.createOrUpdateAssessment();
      }
    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if (response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    },
  },
  computed: {
    ...mapGetters(['assessmentTopicIndex', 'assessment', 'assessmentTopics']),
  },
  async created() {
    this.busy = true;
    this.loading(this.busy);

    this._surveyService = new SurveyService();
    this._odooService = new OdooService();
    this._contractService = new ContractService();

    this.clearFields();
    this.setAssessmentTopics(JSON.parse(JSON.stringify([])));

    this.paramId = this.$route.params.id;

    const odooToken = localStorage.getItem('LOCALIZED_TOKEN');
    this.sfUser = odooToken != null;

    if (this.sfUser) {
      this.assessmentRouteName = "assessment-feedback-backoffice";
    }

    if(this.paramId && parseInt(this.paramId) > 0) {
      await this.loadAssessmentById();
    }

    this.busy = false;
    this.loading(this.busy);
  },
};
</script>
