import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"open":_vm.toggleFab},model:{value:(_vm.fabIsOpen),callback:function ($$v) {_vm.fabIsOpen=$$v},expression:"fabIsOpen"}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VIcon,{on:{"click":_vm.cancel}},[_vm._v("fas fa-times")]),_c(VSpacer),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_c(VIcon,{on:{"click":_vm.toggleFab}},[_vm._v("fas fa-check")])],1),_c(VCard,[_c(VCardText,{staticClass:"pa-0 ma-0",staticStyle:{"height":"300px"}},[_c(VList,{attrs:{"two-line":"","subheader":""}},[_vm._t("default")],2)],1)],1)],1),_c(VBtn,{attrs:{"fab":"","fixed":"","right":"","bottom":"","color":"primary"},on:{"click":_vm.toggleFab}},[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }