<template>
  <v-container fluid>
    <PreviousPage :route="'faturas'" />
    <v-card>
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.id" label="Nº Fatura" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.contrato" label="Contrato" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.cnpj" label="CNPJ" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.competencia" label="Competencia" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.vencimento" label="Vencimento" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.valor" label="Valor (R$)" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.status" label="Status" readonly></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { getGoogleSheets } from "./../../common/gsxrest";
import { mapGetters, mapMutations } from "vuex";
import PreviousPage from "../../components/structure/previousPage";

const { VUE_APP_SHEETS_BILLS, VUE_APP_SHEETS_API_KEY } = process.env;

export default {
  components: {PreviousPage},
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["showmsg"]),
    loadItem(obj) {
      const load = obj.id == this.id;
      if (load) this.item = Object.assign({}, obj);
      return load;
    }
  },
  props: {
    id: null
  },
  data() {
    return {
      item: {}
    };
  },
  mounted() {
    getGoogleSheets(VUE_APP_SHEETS_BILLS, VUE_APP_SHEETS_API_KEY, 'Sheet1!A2:G100')
      .then(data => {
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of data.entries()) {
          const gsxobj = {
            id: item[0],
            contrato: item[1],
            cnpj: item[2],
            competencia: item[3],
            vencimento: item[4],
            valor: item[5],
            status: item[6],
          };

          if (this.loadItem(gsxobj)) return;
        }
      })
      .catch(() => {
        this.showmsg({
          text: "Erro ao carregar",
          type: "error"
        });
      });
  }
};
</script>

<style>
</style>
