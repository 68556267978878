<template>
  <v-container fluid>
    <v-dialog v-model="getIsOpenDialog" max-width="1024" persistent>
      <v-card>
        <v-card-title class="text-h5 pb-0">
          Emitir Notificação
        </v-card-title>

        <v-card-text>
          <v-row v-if="!isSendNotification">
            <v-col cols="12">
              <p>Deseja emitir notificações aos usuários?</p>
            </v-col>
          </v-row>

          <ConfigureNotificationForm v-else isManuallyNotification/>
        </v-card-text>

        <v-card-actions v-if="!isSendNotification">
          <v-spacer></v-spacer>
          <v-btn class="mr-2" color="outline-primary" @click="closeDialog()">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="enableNotificationConfig()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import ConfigureNotificationForm from "./configureNotificationForm"
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  name: "manuallyNotificationDialog",
  components: { ConfigureNotificationForm },
  data: () => ({
    isSendNotification: false,
  }),
  watch: {
    getIsOpenDialog: function (newValue, old) {
      if (!newValue) {
        this.isSendNotification = false;
      }
    }
  },
  beforeCreate() {
    this._socialNetworkService = new SocialNetworkService();
  },
  async created() {
  },
  computed:{
    ...mapGetters(['getIsOpenDialog']),
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    ...mapActions(['setIsOpenDialog']),
    closeDialog(){
      this.setIsOpenDialog(false);
    },
    enableNotificationConfig(){
      this.isSendNotification = true;
    }
  }
}

</script>
