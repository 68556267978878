<template>
  <v-container fluid style="height: 100%">
    <v-card class="elevation-0" height="100%">
      <v-tabs
              v-model="tab"
              background-color="transparent"
              grow
              show-arrows
              center-active
      >
        <v-tab
                v-for="item in items"
                :key="'tab'+item.id"
                @click="createLogSentry(item)"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" style="height: 100%">
        <v-tab-item
                v-for="item in items"
                :key="'tab-item'+item.id"
                style="height: 100%"
        >
          <Metabase
                  :serverCode="parentItem.options"
                  :resourceId="Number(item.link)"
                  :url-param="parentItem.link"
                  path-params="#bordered=true&titled=false"
          />
        </v-tab-item>
      </v-tabs-items>

    </v-card>
  </v-container>
</template>

<script>
  import Metabase from "../../components/reports/Metabase";
  import * as Sentry from "@sentry/vue";

  export default {
    components:{ Metabase },
    data: () => ({
      onboarding: 0,
      tab: null,
    }),
    computed:{
      items(){
        return this.$route.params.items;
      },
      parentItem(){
        return this.$route.params.parentItem;
      }
    },
    methods: {
      createLogSentry(item) {
        if(this.parentItem && (this.parentItem.title === 'Gestão de Riscos' || this.parentItem.title === 'Gestão de Riscos PRT')) {
          this.createLogSentryFromMetabase(item);
        }
      },
      createLogSentryFromMetabase(item) {
        const session = this.$util.getSession();

        if(session) {
          const menuTitle = this.parentItem.title.replaceAll(" ", "_").toLowerCase();

          let tabTitle = null;
          if(item && item.title) {
            tabTitle = item.title.replaceAll(" ", "_").toLowerCase();
          }

          let menuCarouselTab = null;
          if(menuTitle && tabTitle) {
            menuCarouselTab = menuTitle + '_' + tabTitle;
          }

          if(menuCarouselTab) {
            Sentry.setTag("usuario", session.email);
            Sentry.captureMessage(menuCarouselTab);
          }
        }
      },
      next () {
        this.onboarding = this.onboarding + 1 === this.items.length
                ? 0
                : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
                ? this.items.length - 1
                : this.onboarding - 1
      },
    },
  }
</script>
