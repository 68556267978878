import HttpService from '../HttpService'

export default class MetabaseService {
  constructor() {
    this._httpService = new HttpService("/socialnetwork");
  }

  async FindAllMessagesByFinancialGroup({ title = null, recipientId = null, finalDate = null, page = 0, size = 10, initialDate = null }) {
    let url = `/messages/findAllMessagesByFinancialGroup?page=${page}&size=${size}&`;

    if(title)
      url += `title=${title}&`
    if(recipientId)
      url += `recipientId=${recipientId}&`
    if(finalDate)
      url += `finalDate=${finalDate}&`
    if(initialDate)
      url += `initialDate=${initialDate}&`

    if(url.substring(url.length -1 ,url.length) === '&'){
      url = url.slice(0, -1)
    }
    return await this._httpService.get(url);
  }

  async FindAllMessagesByEntity() {
    return await this._httpService.get('/messages/findAllMessagesByEntity');
  }

  async SendMessageByFinancialGroup(data){
    return await this._httpService.post('/messages/sendMessageByFinancialGroup', data)
  }

  async SendMessage(data){
    return await this._httpService.post('/messages/sendMessage', data)
  }

  findFeedItemUserAdmin(idUser, parentId, page) {
    return this._httpService.get(`/feed_item/findFeedItemUserAdmin?idUser=${idUser ? idUser : ''}&size=25&page=${page}&parentId=${parentId}`);
  }

  findFeedItemUserAdminFilter(params) {
    return this._httpService.get(`/feed_item/findFeedItemUserAdminFilter${params}&size=25`);
  }

  getFeedId(idFeed) {
    return this._httpService.get(`/feed_item/${idFeed}`);
  }

  getTargetTypeList() {
    return this._httpService.get(`/target/getTargetType`);
  }

  findTargetByFeedFilter(params) {
    return this._httpService.get(`/target/findTargetByFeedFilter${params}`);
  }

  GetMidiaFeed(idFeed) {
    return this._httpService.get(`/media/findMediaFeed/${idFeed}`);
  }

  findTargetByFeed(idFeed, page) {
    return this._httpService.get(`/target/findTargetByFeed?idFeed=${idFeed}&size=10&page=${page}`);
  }

  getEntityListPerUser(idUser) {
    return this._httpService.get(`/entity/getEntityPerUser/${idUser ? idUser: 0}`);
  }

  SavePost(data) {
    return this._httpService.post("/feed/save", data);
  }

  UpdateTarget(data) {
    return this._httpService.put(`/target/${data.id}`, data);
  }

  SaveTarget(data) {
    return this._httpService.post(`/target/save`, data);
  }

  getTarget(idTarget) {
    return this._httpService.get(`/target/${idTarget}`);
  }

  async FindEntityByType(type) {
    return await this._httpService.get(`entity/getEntityByType/${type}`);
  }

  async GetAllEntities(){
    return await this._httpService.get('/entity/findAll');
  }

  async SaveNotificationConfig(notificationConfig){
    return await this._httpService.post('/notification_config', notificationConfig);
  }

  async UpdateNotificationConfig(notificationConfig, id){
    return await this._httpService.put(`/notification_config/${id}`, notificationConfig);
  }

  async DeleteNotificationConfig(id){
    return await this._httpService.delete(`/notification_config/${id}`);
  }

  async FindNotificationConfigById(id){
    return await this._httpService.get(`/notification_config/${id}`);
  }

  async FindAllCarrierBillingNotificationConfig(){
    return await this._httpService.get('/notification_config/findAllCarrierBillingNotificationConfig');
  }

  async CreateCarrierBillingManuallyNotification(notificationConfig, carrierBillingId, notificationInfoAction){
    return await this._httpService.post(`/notification_config/createCarrierBillingManuallyNotification/${carrierBillingId}/${notificationInfoAction}`, notificationConfig);
  }
}

