<template>
    <v-overlay :value="loading" :opacity="0" z-index="9999">
        <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
</template>

<script>
    export default {
        name: "Loading",
        computed:{
            loading(){
                return this.$store.state.app.loading;
            }
        },
    }
</script>
