<template>
  <v-btn fab fixed right bottom color="primary" @click="toggleFab">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'GenericFAB',
  props: {
    icon: {
      type: String,
      required: false,
      default: 'fas fa-plus',
    },
  },
  data: () => ({
    isOpen: false,
  }),
  methods: {
    cancel() {
      this.fabIsOpen = false;
    },
    toggleFab() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>