<template>
  <div style="height: 100%">
    <div class="link-wrapper">
      <a :href="iframeUrl" target="_blank" >Versão para impressão</a>
    </div>
    <iframe
      v-if="!error"
      :src="iframeUrl"
      frameborder="0"
      width="100%"
      height="100%"
      allowtransparency
      :class="classParam"
      :style="styleParam"
      class="pa-8"
    ></iframe>
    <h3 v-else style="color: gray" class="pt-10">Estamos processando dados nesse momento, volte daqui uns minutos!</h3>
  </div>
</template>

<script>
import MetabaseService from "../../services/reports/MetabaseService"
import {mapMutations} from "vuex";

export default {
  props: {
    resourceId: {
      type: Number
    },
    serverCode: {
      type: String
    },
    type: null,
    classParam: null,
    styleParam: null,
    pathParams: null,
    params: {
      type: Object,
      default: () => ({})
    },
  },
  data: function() {
    return {
      iframeUrl: null,
      error: false,
    };
  },
  methods:{
    ...mapMutations(["showmsg"]),
  },
  created() {
    this._metabaseService = new MetabaseService();
  },
  async mounted() {
    await this._metabaseService.getMetabaseURLByServerCodeAndResourceId(this.serverCode, this.resourceId)
            .then((res) => {
              this.error = false;
              this.iframeUrl = res.data+this.pathParams;
            })
            .catch(error => {
              this.error = true;
              // console.log(error.response.data.message);
              // this.showmsg({ text: error.response.data.message, type: "error" })
            });
  }
};
</script>

<style>
.link-wrapper {
  position: absolute;
  right: 4%;
  top: 1%;
  color: var(--v-primary-base);
  font-family: Open Sans;
}
</style>
