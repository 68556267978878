<template>
  <v-container fluid>
    <v-card min-height="530">
      <v-card-text>
        <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            grow
            centered
            show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-form-register">Informações do Questionário</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item :value="'tab-form-register'">
            <v-row class="pt-2">
              <v-col cols="12">
                <v-text-field
                    label="Nome do Questionário"
                    v-model="assessmentModel.name"
                    maxlength="100"
                    outlined
                    counter
                    :rules="[formrules.required]"
                    @input="() => {setAssessmentName(assessmentModel.name)}"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Apresentação"
                    v-model="assessmentModel.presentation"
                    outlined
                    :rules="[formrules.required]"
                    @input="() => {setAssessmentPresentation(assessmentModel.presentation)}"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    :disabled="!paramId || parseInt(this.paramId) === 0"
                    :items="status"
                    v-model="assessmentModel.status"
                    placeholder="Selecione o Status"
                    outlined
                    :rules="[formrules.required]"
                    @change="setAssessmentStatus(assessmentModel.status)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                    ref="menuStartDate"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    :return-value.sync="assessmentModel.startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        :value="formatStartDate"
                        label="Data de Início"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[formrules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="assessmentModel.startDate"
                      no-title
                      scrollable
                      @change="() => {$refs.menuStartDate.save(assessmentModel.startDate); isDateGreaterThan(); setAssessmentStartDate(assessmentModel.startDate);}"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                    ref="menuEndDate"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    :return-value.sync="assessmentModel.endDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        outlined
                        :value="formatEndDate"
                        label="Data de Expiração"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="assessmentModel.endDate"
                      no-title
                      scrollable
                      @change="() => {$refs.menuEndDate.save(assessmentModel.endDate); isDateGreaterThan(); setAssessmentEndDate(assessmentModel.endDate);}"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { formrules } from "./../../common/formrules";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "managementHeader",
  data: () => ({
    formrules,
    selectedTab: '',
    assessmentModel: {
      name: '',
      presentation: '',
      startDate: '',
      endDate: '',
      status: '',
      topics: null,
    },
    status: [
      { value: 'CANCELADO', text: 'Cancelado' },
      { value: 'ENVIADO', text: 'Enviado' },
      { value: 'RASCUNHO', text: 'Rascunho' },
    ],
    menuStartDate: false,
    menuEndDate: false,
    paramId: 0,
  }),
  props: {
    assessmentParam: {
      type: Object,
    }
  },
  methods: {
    ...mapMutations(['showmsg']),
    ...mapActions(['setAssessmentName', 'setAssessmentPresentation', 'setAssessmentStatus', 'setAssessmentStartDate', 'setAssessmentEndDate']),
    isDateGreaterThan() {
      if(this.assessmentModel.startDate && this.assessmentModel.endDate) {
        if(moment(this.assessmentModel.startDate, 'YYYY-MM-DD').diff(moment(this.assessmentModel.endDate, 'YYYY-MM-DD')) > 0) {
          setTimeout(() => {
            this.menuEndDate = ''
            this.assessmentModel.endDate = '';
          }, 100);
          this.showmsg({ text: 'A data de início não pode ser maior que a expiração.', type: 'error' });
        }
      }
    },
  },
  watch: {
    assessmentParam(newValue) {
      if(newValue && newValue.name) {
        this.assessmentModel.name = newValue.name;
      }
      if(newValue && newValue.presentation) {
        this.assessmentModel.presentation = newValue.presentation;
      }
      if(newValue && newValue.status) {
        this.assessmentModel.status = newValue.status;
      }
      if(newValue && newValue.startDate) {
        this.assessmentModel.startDate = newValue.startDate;
      }
      if(newValue && newValue.endDate) {
        this.assessmentModel.endDate = newValue.endDate;
      }
    }
  },
  computed: {
    ...mapGetters(['assessment', 'fullAssessment']),
    formatStartDate() {
      return this.assessmentModel.startDate
          ? moment(this.assessmentModel.startDate).format("DD/MM/YYYY")
          : "";
    },
    formatEndDate() {
      return this.assessmentModel.endDate
          ? moment(this.assessmentModel.endDate).format("DD/MM/YYYY")
          : "";
    },
  },
  beforeCreate() {
  },
  created() {
    this.paramId = this.$route.params.id;
    if(!this.paramId || parseInt(this.paramId) === 0) {
      this.assessmentModel.status = this.status[2].value;
      this.setAssessmentStatus(this.assessmentModel.status);
    }
  },
};
</script>
