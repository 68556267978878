<template>
  <v-navigation-drawer app hide-overlay :right="right" :mobile-break-point="window.mobileSize" v-model="isOpen">
    <slot/>
    <template v-slot:append>
      <slot name="append"/>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'GenericRightDrawer',
  props: {  
    open: {
      type: Boolean,
      required: true,
    },
    right: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    ...mapState(['window']),
  },
  watch: {
    open(newValue) {
      this.isOpen = newValue;
    },
  },
  mounted() {
    this.isOpen = this.open;
  }
};
</script>
