<template>
  <v-container fluid>
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-card>
      <v-card-text>
      <v-form
        id="userCreationForm"
        ref="userCreationForm"
        @submit.prevent="existentUser ? updateUser() : createUser()"
      >
          <v-row dense>
            <v-col cols="12" md="4" >
              <v-text-field v-model="newUser.name" type="text" label="Nome" placeholder="Digite aqui o nome do usuário" :rules="[required]" outlined/>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="newUser.email"
                type="email"
                label="Email"
                placeholder="Digite aqui o e-mail do usuário"
                :rules="[required]"
                :error-messages="emailErroValidation"
                outlined
                :readonly="existentUser ? true : false"
              />
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="CPF"
                placeholder="Digite aqui o CPF do usuário"
                v-model="newUser.cpf"
                :rules="[required]"
                v-mask="'###.###.###-##'"
                outlined
                :readonly="existentUser && userHasCPF"
              />
            </v-col>

            <v-col cols="12" md="4" v-show="existentUser">
              <label>Status</label>
              <v-switch class="pl-5 mt-1" :loading="busy" v-model="newUser.active" :label="`Usuário ${newUser.active ? 'Ativo' : 'Inativo'}`"></v-switch>
            </v-col>
            <v-col cols="12" >
              <label class="mb-6 mr-2">Alçada de Contrato</label>
              <v-progress-circular v-if="busy" indeterminate size="20" color="primary" />
              <ProgressiveLoader :disabled="busy">
                <v-treeview
                  v-model="newUser.contracts"
                  :items="contracts"
                  hoverable
                  selectable
                  activatable
                  open-on-click
                  return-object
                  open-all
                />
              </ProgressiveLoader>
            </v-col>

            <v-col cols="12">
              <label class="mb-6">Perfil</label>
              <v-item-group multiple v-model="newUser.profiles">
                <v-row>
                  <v-col v-for="(profile) in profiles" :key="profile.id" cols="12" md="4">
                    <v-item v-slot:default="{ active, toggle }" :value="profile.id">
                      <v-card
                        :color="active ? 'primary' : 'contrast'"
                        class="d-flex align-center"
                        dark
                        @click="toggle"
                      >
                        <v-card-text>
                          <span class="title white--text">{{ profile.name }}</span>
                          <p v-html="$util.sanitize(profile.description)" class="white--text"></p>
                        </v-card-text>
                        <v-scroll-y-transition>
                          <div v-if="active" class="display-3 flex-grow-1 text-center mr-4">
                            <v-icon>fa-check</v-icon>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-col>
          </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer />
        <v-btn color="sedundary" @click="cancelar()" :disabled="busy || loading">Cancelar</v-btn>
        <v-btn
                color="primary"
                type="submit"
                form="userCreationForm"
                :loading="busy"
                :disabled="busy || loading"
        >{{ existentUser ? "Atualizar" : "Criar" }}</v-btn>
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn block
                 large
                 color="primary"
                 type="submit"
                 form="userCreationForm"
                 :loading="busy"
                 :disabled="busy || loading"
          >{{ existentUser ? "Atualizar" : "Criar" }}</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn block large color="secondary" light @click="cancelar()" :disabled="busy || loading" class="black--text">Cancelar</v-btn>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import ContractService from "../../services/odoo/ContractService";
import { mapState, mapGetters, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
import ProgressiveLoader from "@/components/progressive-loader";
import {
  createUserByMasterUser,
  updateUser,
  updateUserStatus,
  findActiveProfilesByApp,
  existsEmaillLoginAndAppCode
} from "@/common/securityserver";

export default {
  name: "CriarUsuario",
  components: { ProgressiveLoader },
  directives: {
    mask
  },
  data: () => ({
    newUser: {
      email: "",
      name: "",
      cpf: "",
      profiles: null,
      status: "Ativo",
      contracts: [],
      contractBag: []
    },
    busy: false,
    emailErroValidation: [],
    profiles: [],
    contracts: [],
    contractsSelect: [],
    dataContracts: [],
    userHasCPF: true,
    existentUser: false,
    required: value => !!value || "Preenchimento obrigatório.",
    loading: false
  }),
  watch: {
    "newUser.email": {
      handler: function(val, oldVal) {
        this.validateLogin(val);
      }
    }
  },
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user", "users"]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    }
  },
  methods: {
    ...mapMutations(["showmsg"]),
    validateLogin(login) {
      if (!login) {
        this.emailErroValidation = "E-mail é obrigatório";
        return;
      }

      if (!/.+@.+\..+/.test(login)) {
        this.emailErroValidation = "E-mail inválido";
        return;
      }

      if (this.existentUser) {
        this.emailErroValidation = null;
        return;
      }

      existsEmaillLoginAndAppCode(login).then(res => {
        this.emailErroValidation = res.data ? ["E-mail não disponível"] : null;
        if (res.data) {
          return false
        }
      });
    },

    jurisdictionIsValid(jurisdiction) {
      if (!jurisdiction || !jurisdiction.length) {
        this.showmsg({
          text: "Nenhuma alçada de contrato selecionada!",
          type: "warning"
        });
        return false;
      } else {
        return true;
      }
    },

    createUser() {

      if (!this.$refs.userCreationForm.validate()) {
        setTimeout(this.$refs.userCreationForm.resetValidation, 3000);
        return;
      }

      //Profile Validate
      if (!this.newUser.profiles || !this.newUser.profiles.length) {
        this.showmsg({
          text: "Nenhum perfil selecionado!",
          type: "warning"
        });
        return;
      }

      if(!this.jurisdictionIsValid(this.newUser.contracts)) {
        return;
      }

      //pegar todos os contratos
      this.dataContracts.forEach(fo => {
        this.newUser.contractBag.push({ id: fo.id, access: false, subContract: fo.subcontract, parentContract: fo.parent_contract });
      });

      // defini bags
      if (this.newUser.contracts && this.newUser.contracts.length > 0) {
        this.newUser.contracts.forEach(contract => {
          var con = this.newUser.contractBag.filter(
            f => f.id == contract.id
          )[0];
          con.access = true;
        });
      }

      this.busy = true;
      this.newUser.login = this.newUser.email;

      createUserByMasterUser(this.newUser, this.user.tokenSecurityService)
        .then(user => {
          this.newUser.profiles = user.profiles;
          const _users = [...this.users, { id: user.id, ...this.newUser }];
          this.$store.commit("setUsers", _users);

          this.$router.push("/usuarios");
        })
        .catch(error => {
          console.log("reason", error.response);
          var message = "Erro ao criar usuário!";
          if (
            error.response.data.message &&
            error.response.data.message == "User Email Alredy Exists"
          ) {
            message = "Email informado já foi cadastrado";
          } else if (
            error.response.data.message &&
            error.response.data.message == "User Invalid CPF"
          ) {
            message = "CPF informado é inválido";
          }
          this.showmsg({
            text: message,
            type: "error"
          });
        })
        .finally(() => {
          this.busy = false;
        });
    },
    async updateUser() {
      if (!this.jurisdictionIsValid(this.newUser.contracts)) {
        return false
      }

      if (!this.$refs.userCreationForm.validate()) {
        setTimeout(this.$refs.userCreationForm.resetValidation, 3000);
        return;
      }

      //pegar todos os contratos
      this.dataContracts.forEach(fo => {
        this.newUser.contractBag.push({ id: fo.id, access: false, subContract: fo.subcontract, parentContract: fo.parent_contract });
      });

      // defini bags
      if (this.newUser.contracts && this.newUser.contracts.length > 0) {
        this.newUser.contracts.forEach(contract => {
          var con = this.newUser.contractBag.filter(
            f => f.id == contract.id
          )[0];
          con.access = true;
        });
      }

      this.busy = true;
      this.newUser = await updateUser(
        this.newUser,
        this.user.tokenSecurityService
      )
        .then(res => this.$router.push("/usuarios"))
        .catch(error => {
          console.log("reason", error.response);
          var message = "Erro ao editar usuário!";
          if (
            error.response.data.message &&
            error.response.data.message == "User Email Alredy Exists"
          ) {
            message = "Email informado já foi cadastrado";
          } else if (
            error.response.data.message &&
            error.response.data.message == "User Invalid CPF"
          ) {
            message = "CPF informado é inválido";
          }
          this.showmsg({
            text: message,
            type: "error"
          });
        })
        .finally(() => (this.busy = false));
    },
    configureUserEdition() {
      if (!this.$route.params.userId) {
        return;
      }

      const newUser = this.$store.getters.users.find(
        user => user.id == this.$route.params.userId
      );

      if (newUser) {
        this.existentUser = true;
        this.userHasCPF = newUser.cpf && newUser.cpf.length !== 0;
        newUser.profiles = newUser.profiles.map(function(elem) {
          return elem.id;
        });
        this.newUser = { ...newUser, contractBag: [] };
      }
    },
    generateTreeHierarchy(toTraverse) {
      const tree = {
        root: {
          id: "contratos",
          name: "Todos os Contratos",
          children: []
        }
      };
      toTraverse.forEach(item => {
        if (item.subContract) {
          const parentContractAdded = tree.root.children.find(
            contract => contract.id === item.parentContract
          );
          if (parentContractAdded) {
            tree.root.children = tree.root.children.filter(
              contract => contract.id !== item.parentContract
            );
          }
          if (!tree[item.parentContract]) {
            tree[item.parentContract] = [item];
          } else {
            tree[item.parentContract].push(item);
          }
        } else {
          tree.root.children.push(item);
        }
      });
      return tree;
    },

    generateTree(toTraverse) {
      if (toTraverse) {
        toTraverse = toTraverse.sort(function(a, b) {
          return a.subContract ? 0 : -1;
        });
      }

      const tree = this.generateTreeHierarchy(toTraverse);

      const finalTree = [tree.root];
      delete tree.root;

      Object.keys(tree).forEach(itemKey => {
        itemKey = parseFloat(itemKey)
        const parentContract = toTraverse.find(
          contract => contract.id === itemKey
        );
        if(parentContract){
          const contactChildren = tree[itemKey].map(child => ({
            id: child.id,
            name: child.name,
            children: child.children
          }));
          finalTree[0].children.push({
            id: itemKey,
            name: parentContract.name,
            children: contactChildren
          });
        }
      });

      return finalTree;
    },

    getcontratosUser() {
      this.busy = true;
      if (this.newUser.email) {
        this._contractService
          .FindIncludeSubContracts(this.newUser.email)
          .then(res => {
            if (res.data.records) {
              const configuredRecords = res.data.records.map(
                (record, index) => {
                  const informationsList = [
                    record.contract_owner_id.name,
                    record.cnpj,
                    record.subcontract
                      ? record.subcontract_number
                      : record.policy,
                    record.carrier_id.xipp_commercial_name,
                    record.benefit_id
                  ];
                  return {
                    id: record.id,
                    name: informationsList.join(" - "),
                    children: [],
                    subContract: record.subcontract,
                    parentContract: record.parent_contract
                  };
                }
              );

              var _contratos = [];
              configuredRecords.forEach(_re => {
                // subContract
                if (_re.subContract) {
                  // verifica se esta
                  if (
                    this.dataContracts.some(s => s.id == _re.parentContract)
                  ) {
                    // não duplicar
                    if (!_contratos.some(s => s.id == _re.parentContract)) {
                      // add contrat pai
                      var pai = this.dataContracts.filter(
                        s => s.id == _re.parentContract
                      )[0];

                      const informationsList = [
                        pai.contract_owner_id.name,
                        pai.cnpj,
                        pai.subcontract
                          ? pai.subcontract_number
                          : pai.policy,
                        pai.carrier_id.xipp_commercial_name,
                        pai.benefit_id
                      ];

                      var _infoPai = {
                        id: pai.id,
                        name: informationsList.join(" - "),
                        children: [],
                        subContract: pai.subcontract,
                        parentContract: pai.parent_contract
                      };

                      _contratos.push(_infoPai);
                    }
                  }
                }
              });

              // adicionar filhos
              _contratos.push(...configuredRecords);

              //
              this.contractsSelect = this.generateTree(_contratos);
              this.newUser.contracts = [];
              if (this.contractsSelect && this.contractsSelect.length > 0) {
                if (
                  this.contractsSelect[0].children &&
                  this.contractsSelect[0].children.length > 0
                ) {
                  // filhos
                  this.contractsSelect[0].children.forEach(_child => {
                    if (_child.children && _child.children.length > 0) {
                      this.newUser.contracts.push(..._child.children);
                    } else {
                      this.newUser.contracts.push(_child);
                    }
                  });
                }
              }
            }
          })
          .catch(reason => console.log(reason))
          .finally(() => (this.busy = false));
      } else {
        this.busy = false;
      }
    },

    cancelar() {
      this.$router.go(-1);
    },
    mudarstatus() {
      console.log('this.user.tokenSecurityService', this.user.tokenSecurityService);
      this.newUser.active = !this.newUser.active;


      this.busy = true;
      updateUserStatus(this.newUser, this.user.tokenSecurityService)
        .then(res => {})
        .finally(() => (this.busy = false));
    },
  },
  async beforeMount() {
    this.loading = true;
    this.busy = true;
    this._contractService = new ContractService();
    this.configureUserEdition();

    this._contractService
      .FindFinancialGroupContracts(this.user.financialGroup.id)
      .then(res => {
        this.dataContracts = res.data.records;
        const configuredRecords = res.data.records.map((record, index) => {
          const informationsList = [
            record.contract_owner_id.name,
            record.cnpj,
            record.subcontract
              ? record.subcontract_number
              : record.policy,
            record.carrier_id ? (record.carrier_id.xipp_commercial_name ? record.carrier_id.xipp_commercial_name : "") : "",
            record.benefit_id
          ];

          return {
            id: record.id,
            name: informationsList.join(" - "),
            children: [],
            subContract: record.subcontract,
            parentContract: record.parent_contract
          };
        });
        this.contracts = this.generateTree(configuredRecords);
        this.getcontratosUser();
      })
      .catch(reason => {
        this.busy = false;
        console.log(reason);
      });

    await findActiveProfilesByApp(this.user.tokenSecurityService).then(
        response => (this.profiles = response)
    );

    this.loading = false;
  }
};
</script>
