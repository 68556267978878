<template>
  <v-container fluid grid-list-md style="height: 100%">
    <v-overlay v-model="computedLoading"></v-overlay>

    <v-row v-show="!showFormNewDemand" style="height: 100%">
      <DrawerFilterer
        title="Atendimentos"
        :filters="computedFilters"
        :data="items"
        @queryUpdate="loadItemupdate"
        @filtersRemoved="getServiceHistoric"
        @clearFilters="getServiceHistoric"
      />
      <v-col cols="7" class="pr-2" style="height: 100%;">
        <v-card class="d-flex flex-column" style="height: 96%;">
          <div v-show="!showRequestDetails">
            <v-card-title>
              Como podemos te ajudar?
            </v-card-title>

            <!-- <v-expansion-panels>
              <v-expansion-panel v-for="card in cards" :key="card.index" @click="getCardContent(card.type)">
                <v-expansion-panel-header>
                  <v-row>
                    <v-col cols="1">
                      <v-icon>{{ card.icon }}</v-icon>
                    </v-col>
                    <v-col cols="11">
                      <b>{{ card.title }}</b>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12">
                      <b>{{ computedSelectecCard.question }}</b>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <span v-html="computedSelectecCard.answer"/>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels> -->

            <v-card-text>
              <v-list>
                <v-list-item v-for="card in cards" :key="card.index" @click="getCardContent(card.type)">
                  <v-list-item-icon>
                    <v-icon>{{ card.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title><b>{{ card.title }}</b></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions style="position: absolute; bottom: 0; margin-bottom: 4%; left: 20%;">
              <v-row>
                <v-col cols="12" class="d-flex justify-center align-center pa-0">
                  <span style="color: #54569F;">Não encontrou o que precisa?</span>
                </v-col>
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-btn color="primary" @click="toggleShowFormNewDemand()">
                    <v-img :src="imgNewTicketPath"/>
                    Novo Atendimento
                  </v-btn>
                </v-col>
                <!-- <v-col cols="2" class="pr-0 mr-0 d-flex justify-end">
                  <v-hover v-slot:default="{ hover }">
                    <div>
                      <v-btn style="background-color: #5EE6BE">
                        <v-icon>mdi-phone</v-icon>
                        <v-scroll-x-transition>
                          <span v-if="hover">0800-100122</span>
                        </v-scroll-x-transition>
                      </v-btn>
                    </div>
                  </v-hover>
                </v-col> -->
              </v-row>
            </v-card-actions>
          </div>

          <div v-show="showRequestDetails">
            <v-card-title>
              <button @click="toggleRequestDetails()">
                <v-icon>mdi-arrow-left-circle</v-icon>
                Voltar
              </button>
            </v-card-title>
            <br>
            <v-card-subtitle>
              <b>{{ computedSelectecCard.question }}</b>
            </v-card-subtitle>

            <v-card-text>
              <span v-html="computedSelectecCard.answer"/>
            </v-card-text>

          </div>

        </v-card>
      </v-col>

      <v-col cols="5" class="pl-2" style="height: 100%;">
        <v-card style="height: 96%;">
          <div v-show="!showServiceDetails">
            <v-card-title class="pt-5 pl-5">
              <v-row>
                <v-col cols="12">
                  <v-row>
                    Histórico
                  </v-row>
                  <v-row>
                    <v-col cols="11">
                      <v-text-field
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        aria-placeholder="Busca de Atendimento"
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-icon
                      @click="toggleFilterDrawer"
                    >
                      mdi-filter
                    </v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <div v-for="item in serviceItems" :key="item.id">
                <v-row dense class="fill-height" @click="toggleServiceDetails(item)" style="cursor: pointer">
                  <v-col cols="2" class="d-flex align-center">
                    <v-avatar style="background-color: #54569F;">
                      <img
                        :src="imgUserPath"
                        alt="user-icon"
                        style="width: 70%; height: 70%;"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <p>{{ item.subject }}</p>
                    <p>Protocolo {{ item.protocolo }} - {{ item.abertura }}</p>
                    <v-chip :class="chipColorByStatus(item.status)">{{ item.status }}</v-chip>
                  </v-col>
                  <v-col cols="1">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-col>
                </v-row>

                <br/>
                <v-divider :thickness="5"></v-divider>
                <br/>

              </div>
            </v-card-text>

            <v-pagination
              style="position: absolute; bottom: 0; margin-bottom: 5%; left: 50%; right: 50%"
              v-model="currentPage"
              :length="totalPages"
              :total-visible="pageSize"
              @input="getServiceHistoric()"
            />

          </div>

          <div v-show="showServiceDetails" class="scrollable">
            <v-card-subtitle>

              <button @click="toggleServiceDetails(service={})" class="mb-3">
                <v-icon>mdi-arrow-left-circle</v-icon>
                Voltar
              </button>

              <h3>Detalhes do atendimento</h3>
              <v-card class="grey lighten-3">
                <v-card-text>
                  <v-row>
                    <v-col cols="11">
                      <p>Solicitação</p>
                      <p class="paragraphDetailsText"><b>
                        {{ this.selectedService.subject }}
                      </b></p>

                      <div v-show="expandServiceDetails">
                        <p>Protocolo</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.protocol }}
                        </b></p>

                        <p>Status</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.status }}
                        </b></p>

                        <p>Contrato</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.accountName }}
                        </b></p>

                        <p>Beneficio</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.benefit }}
                        </b></p>

                        <p>Abertura</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.createDate }}
                        </b></p>

                        <p>Atendido</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.attendee }}
                        </b></p>

                        <p>Descrição</p>
                        <p class="paragraphDetailsText"><b>
                          {{ this.selectedService.description }}
                        </b></p>

                      </div>
                    </v-col>
                    <v-col cols="1">
                      <button @click="toggleExpandedServiceDetails()">
                        <font-awesome-icon :icon="['fas', 'chevron-down']" size="lg"/>
                      </button>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>
            </v-card-subtitle>

            <v-card-subtitle>
              <h3>Eventos</h3>

              <div v-for="log in serviceHistoric" :key="log.id">
                {{ log.create_date }}
                <v-sheet
                  :class="isClientItem(log) ? 'pa-2 rounded-t-xl rounded-br-xl grey lighten-3' : 'pa-2 rounded-t-xl rounded-bl-xl blue'"
                  style="margin: 5px;"
                >
                  <span v-html="log.body"></span>
                  <v-chip v-for="attch in log.attachment_ids" :key="attch.id" @click="downloadFile(attch)">
                      {{ attch.name }}
                  </v-chip>
                </v-sheet>
              </div>
            </v-card-subtitle>

            <br>

            <v-container>
              <v-row align="center" justify="center" dense>
                <v-col cols="1">
                  <v-icon class="mdi-rotate-315" @click="toggleAttachmentComponent()">mdi-attachment</v-icon>
                </v-col>
                <v-col cols="10" class="pa-0">
                  <v-textarea
                    v-model="newComment.body"
                    label="Mensagem"
                    outlined
                    placeholder="Digite sua mensagem..."
                  />
                </v-col>
                <v-col cols="1">
                  <v-icon @click="addComment">mdi-send</v-icon>
                </v-col>
              </v-row>
              <v-row v-show="this.showAttachmentComponent">
                <v-col cols="12">
                  <form-file-upload
                    ref="customFileUpload"
                    :maximum="10"
                    :limit-file-size="12"
                    @update="setAttachments"
                  />
                </v-col>
              </v-row>
            </v-container>

          </div>

        </v-card>
      </v-col>

    </v-row>

    <v-row v-show="showFormNewDemand">
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="cards"
                :value="solicitation"
                item-text="title"
                item-value="type"
                label="Solicitação"
                v-model="solicitation"
                outlined
                :rules="[formrules.required]"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="contractID"
                :items="contractList"
                :loading="loadingContracts"
                :disabled="loadingContracts"
                label="Contrato"
                placeholder="Selecione um contrato"
                outlined
                @change="loadUsersByContract"
                :rules="[formrules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="serviceAttendee"
                label="Atendido"
                outlined
                placeholder="Se necessário, informe o nome do colaborador a ser atendido com respectivo CPF."
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Descrição"
                auto-grow outlined
                placeholder="Descreva sua Solicitação"
                v-model="serviceDescription"
                :rules="[formrules.required]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <form-file-upload
                ref="customFileUpload"
                :maximum="10"
                :limit-file-size="12"
                @update="setAttachments"
              />
            </v-col>
          </v-row>

          <v-card-actions class="justify-end">
            <v-btn color="primary" @click="toggleShowFormNewDemand()">
              cancelar
            </v-btn>
            <v-btn @click="createTicket()">
              solicitar
            </v-btn>
          </v-card-actions>

        </v-card>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { formrules } from "./../../common/formrules";
import { findAllUsersByFinancialGroupAndContract } from "@/common/securityserver";
import { saveAs } from "file-saver";
import { extension, lookup } from "mime-types";
import DrawerFilterer from "@/components/drawer-filterer";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import CaseService from "../../services/odoo/CaseService";
import ComentsService from "../../services/odoo/ComentsService";
import ContentVersionService from "../../services/odoo/ContentVersionService";
import ContractService from "../../services/odoo/ContractService";
import SecurityService from "../../services/security/SecurityService";
import Vue from "vue";
import moment from "moment";

export default {
  name: "Atendimento",
  components: { FormFileUpload, DrawerFilterer },
  data: () => ({
    formrules,
    showRequestDetails: false,
    showServiceDetails: false,
    expandServiceDetails: false,
    showFormNewDemand: false,
    showAttachmentComponent: false,
    loadingContracts: false,
    solicitation: false,
    viewerPermissionCode: "m",
    contractID: false,
    serviceType: false,
    serviceRequest: false,
    serviceAttendee: "",
    serviceDescription: "",
    caseUsers: false,
    search: null,
    loading: false,
    currentPage: 1,
    totalPages: 1,
    pageSize: 5,
    selectedTab: "active",
    selectedService: {},
    selectedCard: {},
    newComment: {
      name: moment(new Date()).format("DD/MM/YYYY - HH:mm"),
      res_id: null,
      body: null,
      model: "helpdesk.ticket",
      message_type: "comment",
      email_from: null,
    },
    serviceItems: [],
    serviceHistoric: [],
    contractList: [],
    users: [],
    attachments: [],
    usersWithAccess: [],
    cards: [],
    filters: [{
      name: "Protocolo",
      key: "id",
      active: false,
      allowClose: false,
      types: [{
        name: "Número do protocolo",
        value: "",
        type: "input",
        active: true
      }]
    },
    {
      name: "Protocolo - ...",
      key: "externalProtocol",
      active: false,
      allowClose: false,
      types: [{
        name: "Protocolo",
        value: "",
        type: "input",
        active: true
      }]
    },
    {
      name: "Data de Abertura",
      key: "monthPeriods",
      types: [{
        name: "Este mês",
        value: `THIS_MONTH`,
        type: "select",
        active: false,
        quotes: false
      },
      {
        name: "Mês passado",
        value: `LAST_MONTH`,
        type: "select",
        active: false,
        quotes: false
      },
      {
        name: "Este trimestre",
        value: `THIS_QUARTER`,
        type: "select",
        active: false,
        quotes: false
      }]
    },
    {
      name: "Status",
      key: "status",
      types: [{
        name: "Em Andamento",
        value: "in_progress",
        type: "select",
        active: false,
        selected: "in_progress"
      },
      {
        name: "Novos",
        value: "draft",
        type: "select",
        active: true,
        selected: "draft"
      }]
    }],
    items: [],
  }),
  methods: {
    ...mapMutations(["showmsg"]),

    setAttachments(attachments) {
      this.attachments = attachments;
    },

    toggleRequestDetails() {
      this.showRequestDetails = !this.showRequestDetails;
    },

    async toggleServiceDetails(service) {
      this.serviceHistoric = [];
      this.caseUsers = false;
      this.selectedService = service;
      this.newComment.body = '';
      this.expandServiceDetails = false;
      if (!this.showServiceDetails) {
        this.caseUsers = await this._securityService.findCaseUsersByCaseId(this.selectedService.id);
        await this.getServiceById();
        await this.loadServiceComents();
      }
      this.showServiceDetails = !this.showServiceDetails;
    },

    toggleShowFormNewDemand() {
      this.showFormNewDemand = !this.showFormNewDemand;
    },

    toggleExpandedServiceDetails() {
      this.expandServiceDetails = !this.expandServiceDetails
    },

    toggleAttachmentComponent() {
      this.showAttachmentComponent = !this.showAttachmentComponent;
    },

    async loadServiceComents(){
      this.loading = true;
      await this._comentsService.FindById(this.selectedService.protocol, 'helpdesk.ticket', 'comment', 'Discussões')
          .then(async res => {
            if (res.data.records) {
              res.data.records.forEach(item => {
                item.create_date = moment(item.create_date).format("DD/MM/YYYY - HH:mm");
              });
              this.serviceHistoric = res.data.records;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },

    getTopicCards() {
      if (this.$route.params.parameters && this.$route.params.parameters.selectedTab) {
        this.selectedTab = this.$route.params.parameters.selectedTab;
      }

      let caseTypes = this.user.menu
        .filter((menu) => menu.id == this.$store.state.currentMenu.id)
        .map((menu) => menu.subMenus)
        .reduce(Array)
        .map((menu) => ({
          title: menu.title,
          subtitle: menu.subTitle,
          icon: menu.icon,
          type: menu.options,
        }));

      this.cards = Array.from(caseTypes);

      let index = this.cards.findIndex(
        (menu) => menu.title === "ATENDIMENTOS - USUÁRIOS"
      );
      if (index && index > -1) {
        this.cards.splice(index, 1);
      }
    },

    async getServiceHistoric(path) {
      let params = ['?'];
      if (path) {
        params = path;
      }
      params += `pageNumber=${this.currentPage}&pageSize=${this.pageSize}`;
      params += this.defaultQueryFields
      this.loading = true;
      this._caseService.FindByParam(params)
          .then(res => {
            if (res.data.content) {
              let formatedItems = [];
              let pages = parseInt(Math.ceil(res.data.totalElements / this.pageSize))
              this.totalPages = pages > 0 ? pages : 1;
              res.data.content.forEach(item => {
                let formatedItem;
                formatedItem = {
                  id: item.id,
                  protocolo: item.id,
                  abertura: Vue.filter('formatDate')(item.create_date),
                  contractName: item.xipp_contract_id ? item.xipp_contract_id.policy + " - " + item.partner_id.name : "",
                  accountName: item.partner_id ? item.partner_id.name : "",
                  status: item.xipp_helpdesk_display_stage ? item.xipp_helpdesk_display_stage : item.stage_id,
                  type: item.ticket_type_id ? item.ticket_type_id : item.name,
                  subject: item.name ? item.name : "",
                  lastModified: Vue.filter('formatDate')(item.__last_update),
                  attendee: item.xipp_attendee_name ? item.xipp_attendee_name : '',
                  xipp_external_protocol: item.xipp_external_protocol,
                  creatorName: item.xipp_name_web
                };
                if (item.contract) {
                  if (item.contract.policy) {
                    formatedItem.accountName = item.contract.policy + " - " + formatedItem.accountName;
                  }
                }
                formatedItems.push(formatedItem)
              });
              this.serviceItems = formatedItems;
              this.items = formatedItems;
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },

    async getContracts() {
      const params = new URLSearchParams();
      params.append('status', 'active')
      params.append('fields', ['id','name','benefit_id','carrier_id','carrier_id.xipp_commercial_name',
        'policy','contract_owner_id','contract_owner_id.name'])
      this.loading = true;
      this._contractService.FindAllByFiltersV2(params).then((res) => {
        res.data.forEach((contract) => {
          var texto = contract.contract_owner_id ? contract.contract_owner_id.name : "";
          texto += contract.policy ? " - " + contract.policy : "";
          texto += contract.benefit_id ? " - " + contract.benefit_id : "";
          texto += contract.carrier_id && contract.carrier_id.xipp_commercial_name ? "/" + contract.carrier_id.xipp_commercial_name : "";

          if (contract.contract_owner_id) {
            this.contractList.push({
              partner_id: contract.contract_owner_id.id,
              value: contract.id,
              text: texto,
            });
          }
        });
      })
      .finally(() => {
        this.loadingContracts = false;
        this.loading = false;
      });
    },

    async loadUsersByContract(){
      this.users = [];
      this.users =  await findAllUsersByFinancialGroupAndContract(this.user.tokenSecurityService, this.contractID);
      this.users.sort( (u1, u2 ) => {
        if ( u1.name < u2.name){
          return -1;
        }
        if ( u1.name > u2.name ){
          return 1;
        }
        return 0;
      });
    },

    async createTicket() {
      let emptyFile = false;
      let emptyFileNames = "";
      let configuredCaseData = {
        xipp_contract_id: {
          id: this.contractID
        },
        partner_id: {
          id: this.contractList.find(
              (contract) => contract.value === this.contractID
          ).partner_id
        },
        description: this.serviceDescription,
        xipp_origin_id: "rh_protegido",
        visible_to_the_customer: true,
        ticket_type_id: this.solicitation,
        xipp_request_id: this.serviceRequest,
        xipp_attendee_name: this.serviceAttendee,
        xipp_email_web: this.user.email,
        xipp_viewer_permission_type_id: {"code": this.viewerPermissionCode}
      };
      let solicitationName = this.cards.find((card) => this.solicitation === card.type);
      configuredCaseData.name = solicitationName ? solicitationName.title : '';
      const newAttachments = this.attachments.map((file) => {
        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames+= file.name + "<br/>"
        }

        let fileName = file.name;

        let f = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };
        return f;
      });

      if(emptyFile){
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        return;
      }

      let caseEvent = {
        caseObject: configuredCaseData,
        files: newAttachments,
        usersPermissionView: this.usersWithAccess
      };
      this.loading = true;
      await this._caseService
        .CreateCaseWithAttachment(caseEvent)
        .then((res) => {
          this.getServiceHistoric();
          this.showFormNewDemand = false;
        })
        .catch((error) => {
          alert(error)
          this.showmsg(this.defaultErrorObject)
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loadQueryParameters() {
      const { solicitation, type, request } = this.$route.query;
      this.solicitation = solicitation;
      this.serviceType = type;
      this.serviceRequest = request;
    },

    chipColorByStatus(status) {
      switch (status) {
        case 'Fechado':
          return "red lighten-4 red--text"
        case 'Novo':
          return "green lighten-4 green--text"
        case 'Em Andamento':
          return "blue lighten-4 blue--text"
      }
      return "grey"
    },

    isClientItem(item) {
      return item.email_from != this.getUserEmail();
    },

    getUserEmail() {
      return this.$util.getSession() && this.$util.getSession().email ? this.$util.getSession().email : null;
    },

    async getServiceById() {
      const params = new URLSearchParams();
      params.append('id', this.selectedService.id)
      params.append('fields', ['id','name','xipp_external_protocol','create_date','partner_id',
        'partner_id.name','partner_name','stage_id','xipp_helpdesk_display_stage','description','xipp_attendee_name','ticket_type_id','xipp_origin_id'
        ,'xipp_resolution', 'xipp_viewer_permission_type_id.code','xipp_contract_id','xipp_contract_id.carrier_id',
        'xipp_contract_id.carrier_id.xipp_commercial_name','xipp_contract_id.policy','xipp_benefit_id','xipp_viewer_permission_type_id',
        'xipp_viewer_permission_type_id.code']);
      this.loading = true;
      await this._caseService.FindByIdV2(params)
      .then(res => {
        if (res.data.content.length > 0) {
          let ticket = res.data.content[0];

          this.selectedService = {
            protocol: ticket.id,
            externalProtocol: ticket.xipp_external_protocol ? ticket.xipp_external_protocol : null,
            createDate: Vue.filter('formatDate')(ticket.create_date),
            accountName: ticket.partner_id.name,
            suppliedName: ticket.partner_name,
            status: ticket.xipp_helpdesk_display_stage ? ticket.xipp_helpdesk_display_stage : ticket.stage_id,
            description: ticket.description ? ticket.description : " ",
            attendee: ticket.xipp_attendee_name ? ticket.xipp_attendee_name : " ",
            type: ticket.ticket_type_id ? ticket.ticket_type_id : " ",
            subject: ticket.name,
            origin: ticket.xipp_origin_id ? ticket.xipp_origin_id : " ",
            resolution: ticket.xipp_resolution ? ticket.xipp_resolution : null,
            xipp_external_protocol: ticket.xipp_external_protocol,
            viewer_permission_type_code: ticket.xipp_viewer_permission_type_id ? ticket.xipp_viewer_permission_type_id.code : null,
            users: this.caseUsers.data ? this.caseUsers.data.map(u => u.id.userId) : null
          };
          const contract = ticket.xipp_contract_id;
          if (contract) {
            this.selectedService.benefit = ticket.xipp_benefit_id ? ticket.xipp_benefit_id : "";
            this.selectedService.benefit += contract.carrier_id ? " / " + contract.carrier_id.xipp_commercial_name : " ";
            if (contract.policy) {
                this.selectedService.accountName = contract.policy + " - " + this.selectedService.accountName;
            }
          }
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },

    getViewPermissionDescription(code) {
      switch(code) {
        case 'm':
          return "Minha Organização"
        case 'p':
          return "Privado (somente eu)"
        case 'g':
          return "Grupo"
      }
      return ''
    },

    async getCardContent(cardType) {
      this.toggleRequestDetails();
      this.selectedCard = {};
      this.loading = true;
      await this._caseService.GetTicketMenuDescription(cardType)
      .then(res => {
        if (res.data.length > 0) {
          this.selectedCard = res.data[0];
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },

    downloadFile(attachment) {
      this.loading = true;
      this._contentVersionService
          .getDownloadFile(attachment.id)
          .then(attachmentData => {
            var file_name = attachment.name;
            var _correct_extension = attachment && attachment.mimetype ? extension(attachment.mimetype) : ''
            var extension_dot = "." + _correct_extension
            if(!file_name.endsWith(extension_dot)) {
              file_name = file_name + extension_dot
            }
            saveAs(
                new Blob([attachmentData.data], {type: lookup(attachment.mimeType)}),
                file_name
            );
          })
          .finally(() => {
            this.loading = false;
          });
    },

    async addComment() {
      this.loading = true;
      await this.createCaseEvent(this.selectedService.protocol)
      .then(response => {
      })
      .finally(() => {
        this.newComment.body = '';
        this.attachments = [];
        this.loadServiceComents();
        this.loading = false;
      });
    },

    async createCaseEvent(id) {
      let emptyFile = false;
      let emptyFileNames = "";
      const files = this.attachments.map(file => {

        let fileName = file.name;

        if (!(file.body.split(",")[1])) {
          emptyFile = true;
          emptyFileNames+= file.name + "<br/>"
        }

        let f = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };

        return f;
      });

      if(emptyFile){
        this.showmsg({ text: 'Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>' + emptyFileNames + '<b>', type: "error" });
        throw "Empty File"
      }

      this.newComment.email_from = this.getUserEmail();

      const caseCommentAux = Object.assign({}, this.newComment);
      if(caseCommentAux.body) {
        caseCommentAux.body = caseCommentAux.body.replaceAll('\n', '<br/>');
      }

      let attachments = {
        entityId: id,
        caseComment: caseCommentAux,
        files: files
      }

      await this._caseService.CreateCaseEvent(attachments).catch(someError => {
        throw someError;
      });
    },

    async loadItemupdate(data) {
      if (data) {
        data = data.trim();
        // parametros do requests
        var rq = [];

        //recuperar parametros a partir da query
        const filters = data.trim().split("andd");

        //percorrer query e montar os parametros
        filters.forEach(fil => {
          fil = fil.trim();

          // criar parametro
          let q = fil.split("in ");
          if (q[0] && q[0].length > 0) {
            // montar chave e valor
            rq.push({chave: q[0], valor: q[1]});
          }
        });

        // tratando os parametrosrequest
        rq.forEach(res => {
          res.chave = res.chave.trim();
          res.valor = res.valor.trim();
          res.valor = res.valor.replace("(", "");
          res.valor = res.valor.replace(")", "");

          // replace so tira um por vez
          for (let index = 0; index < 20; index++) {
            res.valor = res.valor.replace("'", "");
          }
        });

        let path = "";
        // passar parametros de filtros
        rq.forEach(parametro => {

          if(parametro.valor) {
            if (path && path.length > 0) {
              path += "&";
            } else {
              path = "?";
            }
            parametro.chave = parametro.chave.replace("CaseNumber", "caseNumber");
            parametro.chave = parametro.chave.replace("Status", "status");
            path += parametro.chave;
            path += "=" + parametro.valor;
            path += "&"
          }
        });
        this.params = path;

        this.getServiceHistoric(this.params);
      }
    },

    async loadFilters() {
      let caseTypes = [];
      caseTypes = this.user.menu
        .filter(menu => menu.id == this.$store.state.currentMenu.id)
        .map(menu => menu.subMenus)
        .reduce(Array)
        .map(menu => ({
          name: menu.title,
          value: menu.options,
          type: "select",
          active: true,
          selected: true
        }));

      if (caseTypes.length) {
        let caseTypesFilter = {
          name: "Solicitação",
          key: "typeCode",
          types: caseTypes
        };
        this.filters.push(caseTypesFilter);
      }

      var usersFinancialGroup = [{
        id: this.user.name,
        value: this.user.name,
        text: "Meus Atendimentos"
      }];
      this.loading = true;
      this._caseService
        .FindByParam('?clientViewPermission%5B0%5D.code=rh_protegido&fields=xipp_name_web')
        .then(res => {
          if (res.data.content) {
            let content = res.data.content.sort((p1, p2) => (p1.xipp_name_web < p2.xipp_name_web) ? -1 : (p1.xipp_name_web > p2.xipp_name_web) ? 1 : 0);
            content.forEach(c => {
              if(c.xipp_name_web && !usersFinancialGroup.includes(c.xipp_name_web)){
                usersFinancialGroup.push({
                  id: c.xipp_name_web,
                  value: c.xipp_name_web,
                  text: c.xipp_name_web
                });
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });

      let usersFinancialGroupFilter = usersFinancialGroup
      let usersFilter = {
        name: "Solicitante",
        key: "nameWeb",
        types: [{
            name: "Selecione um Solicitante",
            value: "",
            type: "combobox",
            items: usersFinancialGroupFilter,
            hasSubItem: false,
            active: true,
            clearable: true,
            multiple: true
          }]
      };

      this.filters.push(usersFilter);

      // if (this.resourcePermission()) {
      //   this.filters.push({
      //     name: "Usuários",
      //     key: "attendeeName",
      //     active: false,
      //     allowClose: false,
      //     types: [
      //       {
      //         name: "Nome do Usuário",
      //         value: "",
      //         type: "input",
      //         active: true
      //       }
      //     ]
      //   });
      // }

    },

    resourcePermission() {
      let caseTypes =
          this.user.menu
              .filter(menu => menu.id == this.$store.state.currentMenu.id)
              .map(menu => menu.subMenus)
              .reduce(Array)
              .map(menu => ({
                title: menu.title,
                icon: menu.icon,
                type: menu.options
              }));

      this.cards = Array.from(caseTypes);
      let index = this.cards.findIndex(menu => menu.title === 'ATENDIMENTOS - USUÁRIOS');
      if (index && index > -1) {
        return true;
      }
      return false;
    },

    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },

    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },

  },
  computed: {
    ...mapState(["window"]),
    ...mapGetters([
      "user", "currentMenu"
    ]),

    defaultQueryFields(){
      return '&fields=id,create_date,partner_id,partner_id.name,stage_id,xipp_helpdesk_display_stage,ticket_type_id,name,xipp_attendee_name,' +
          'xipp_external_protocol,xipp_contract_id,xipp_contract_id.policy,partner_name,__last_update,xipp_name_web';
    },

    computedSelectecCard() {
      return this.selectedCard;
    },

    computedFilters() {
      return this.filters;
    },

    computedLoading() {
      return this.loading;
    },

    imgUserPath(){
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/ticket_user_avatar.svg";
    },

    imgNewTicketPath(){
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/new_ticket_icon.svg";
    },

  },
  async beforeMount() {
    this._comentsService = new ComentsService();
    this._contentVersionService = new ContentVersionService();
    this._caseService = new CaseService();
    this._contractService = new ContractService();
    this._securityService = new SecurityService();
    await this.loadFilters();
    await this.getServiceHistoric();
    this.getTopicCards();
    this.getContracts();
    this.loadQueryParameters();
  },
};
</script>
<style scoped>
  .scrollable {
    max-height: calc(100% - 20px);
    overflow-y: auto;
  }
  .paragraphDetailsText {
    margin-bottom: 2%;
    color: black;
  }
</style>
