import HttpService from '../HttpService';

export default class ContractService {
    constructor() {
        this._httpService = new HttpService('/contract');
    }

    async findAllBillings(financialGroupId = null, contractId = null, subcontractId = null, billingNumber = null,
                          carrierBillingType = null, competenceDate= null, dueDateStart = null, dueDateEnd = null,
                          page = 0, size = 10) {
        let url = `/carrier_billing/?page=${page}&size=${size}&`;

        if(billingNumber)
            url += `billingNumber=${billingNumber}&`
        if(carrierBillingType)
            url += `carrierBillingType=${carrierBillingType}&`
        if(competenceDate)
            url += `competenceDate=${competenceDate}&`
        if(dueDateStart)
            url += `dueDateStart=${dueDateStart}&`
        if(dueDateEnd)
            url += `dueDateEnd=${dueDateEnd}&`
        if(financialGroupId)
            url += `financialGroupId=${financialGroupId}&`
        if(contractId)
            url += `contractId=${contractId}&`
        if(subcontractId)
            url += `subcontractId=${subcontractId}&`

        if(url.substring(url.length -1 ,url.length) === '&'){
            url = url.slice(0, -1)
        }
        return await this._httpService.get(url);
    }

    async getBillingById(id) {
        return await this._httpService.get(`/carrier_billing/${id}`);
    }

    async saveBilling(data) {
        return await this._httpService.post('/carrier_billing', data);
    }

    async updateBilling(id, data) {
        return await this._httpService.put(`/carrier_billing/${id}`, data);
    }

    async saveBillingAttachment(id, data) {
        return await this._httpService.post(`/carrier_billing/${id}/attachment`, data);
    }

    async getAllBillingAttachments(id) {
        return await this._httpService.get(`/carrier_billing/${id}/attachment`);
    }

    async putBillingAttachmentsbyId(id, data) {
        return await this._httpService.put(`/carrier_billing/${id}/attachment`, data);
    }

    async downloadBillingAttachment(id, blobId) {
        return await this._httpService.get(`/carrier_billing/${id}/attachment/${blobId}/download`);
    }

    async downloadBillingAttachmentHistory(id) {
        return await this._httpService.get(`/carrier_billing/${id}/download_attachment_history`);
    }

    async getAllBillingTypes() {
        return await this._httpService.get(`/carrier_billing_type/`);
    }

    async findAllBeneficiaryGroupsByFilters(params) {
        return await this._httpService.get('/beneficiary-group/findAllByFilters', params);
    }

    async findInsuredPositionsByFinancialGroupId(params) {
        return await this._httpService.get('/beneficiary/financialGroup/insured-position', params);
    }

    async findCostCentersByFinancialGroupId(params) {
        return await this._httpService.get('/beneficiary/financialGroup/cost-center', params );
    }

    async findAllKinship(params) {
        return await this._httpService.get('/kinship', params );
    }

}
