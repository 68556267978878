import { render, staticRenderFns } from "./usuario.vue?vue&type=template&id=5648dfea&scoped=true"
import script from "./usuario.vue?vue&type=script&lang=js"
export * from "./usuario.vue?vue&type=script&lang=js"
import style0 from "./usuario.vue?vue&type=style&index=0&id=5648dfea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5648dfea",
  null
  
)

export default component.exports