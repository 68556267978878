import axios from 'axios';
import store from '@/store/store'

export default class HttpService {
  constructor(serviceURL) {
    this.ws = this._loadAxios(serviceURL);
  }

  _loadAxios(serviceURL) {
    console.log(process.env.VUE_APP_API_URL+serviceURL);
    return axios.create({
      baseURL: process.env.VUE_APP_API_URL+serviceURL,
      headers: this._loadHeaders()
    });
  }

  _loadHeaders() {
    return {
      Authorization: `Bearer ${store.getters.user.tokenSecurityService.access_token}`,
      'Access-Control-Max-Age': 600,
      'Cache-Control': 'max-age=600'
    }
  }

  get(path, params = null) {
    return this.ws.get(path, {
      params: params,
    });
  }

  post(path, data) {
    return this.ws.post(path, data);
  }

  delete(path, params = null) {
    return this.ws.delete(path, {
      params: params,
    });
  }
}
