import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('Loading'),_c(VCard,[_c(VCardTitle,{staticStyle:{"color":"rebeccapurple","font-weight":"bold"}},[_vm._v(" Relatório de Acessos Eu Protegido Sintético ")]),_c(VCardText,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VMenu,{ref:"pickUntil",attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.formatDateInput(_vm.filter.until),"label":"Até","readonly":"","placeholder":"Dia/Mês/Ano","append-icon":"mdi-calendar","outlined":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.pickUntil),callback:function ($$v) {_vm.pickUntil=$$v},expression:"pickUntil"}},[_c(VDatePicker,{attrs:{"scrollable":"","no-title":""},on:{"close":function($event){return _vm.$refs.pickUntil.save(_vm.until)}},model:{value:(_vm.filter.until),callback:function ($$v) {_vm.$set(_vm.filter, "until", $$v)},expression:"filter.until"}})],1)],1)],1),_c(VRow,[_c(VCol,[_c(VBtn,{staticClass:"ma-2",attrs:{"large":"","color":"primary","loading":_vm.loadingDownload,"disabled":_vm.loadingDownload},on:{"click":_vm.generateReport}},[_vm._v(" Exportar ")])],1)],1)],1)],1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }