<template>
  <v-container style="overflow: auto !important;" fluid>
    <PreviousPage v-if="routeCreateInvoiceSalesforce !== '/criar-fatura-salesforce/0'" :route="routeInvoices" />
    <v-form ref="invoiceForm">
      <v-row dense>
        <v-col cols="12" md="6">
          <v-card flat class="mb-5">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      :rules="required"
                      v-model="form.financialGroupId"
                      label="Grupo Financeiro" class="pb-0"
                      outlined
                      placeholder="Procure pelo nome do grupo financeiro"
                      :items="financialGroups"
                      item-text="name"
                      item-value="id"
                      :loading="buzy"
                      @input="loadContracts()"
                  />
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-autocomplete
                      :disabled="!form.financialGroupId"
                      :rules="required"
                      v-model="form.contractId"
                      label="Contrato"
                      outlined
                      placeholder="Procure o código do contrato"
                      :items="contracts"
                      item-text="text"
                      item-value="contractId"
                      :loading="buzy"
                      @input="loadSubcontracts()"
                  />
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-autocomplete
                      :disabled="!hasBilling"
                      :rules="hasBilling ? required : []"
                      v-model="form.subcontractId"
                      label="Subcontrato"
                      outlined
                      placeholder="Procure o código do subcontrato"
                      :items="subcontracts"
                      item-text="text"
                      item-value="contractId"
                      :loading="buzy"
                  />
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          @click="menu = true"
                          :value="formatMonthInput"
                          label="Competência"
                          :rules="required"
                          readonly
                          placeholder="Mês/Ano"
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                      />
                    </template>
                    <v-date-picker
                        v-model="form.competenceDate"
                        type="month"
                        no-title
                        scrollable
                        @close="$refs.menu.save(form.competenceDate)"
                    />
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-select
                      :rules="required"
                      :items="invoiceTypes"
                      item-text="name"
                      item-value="id"
                      label="Tipo"
                      placeholder="Selecione o tipo"
                      outlined
                      v-model="form.carrierBillingType"
                  />
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                      :rules="[...numberOnly, ...required]"
                      v-model="form.billingNumber"
                      label="Número da Fatura"
                      placeholder="Apenas números"
                      outlined
                  />
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-menu
                      ref="menuDue"
                      v-model="menuDue"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          @click="menuDue = true"
                          :value="formatDateInput"
                          :rules="required"
                          label="Vencimento"
                          readonly
                          placeholder="Dia/Mês/Ano"
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                      />
                    </template>
                    <v-date-picker
                        v-model="form.dueDate"
                        no-title
                        scrollable
                        @close="$refs.menuDue.save(form.dueDate)"
                    />
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                      v-show="buzy"
                      value="R$ 0,00"
                      label="Valor"
                      outlined
                      readonly
                  />
                  <v-text-field v-show="!buzy"
                      ref="amount"
                      :rules="required"
                      v-model.lazy="form.amount"
                      label="Valor"
                      outlined
                      v-money="money"
                  />
                </v-col>
                <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="12">
                  <v-text-field
                      :rules="[...numberOnly, ...required]"
                      v-model="form.lifes"
                      label="Vidas"
                      placeholder="Apenas números"
                      outlined
                  />
                </v-col>
                <v-col cols="12">
                  <form-file-upload
                      ref="customFileUpload"
                      :maximum="10"
                      v-model="form.attachments"
                      :limit-file-size="12"
                      @update="setAttachments"
                  />-
                </v-col>
                <v-col cols="12">
                  <v-file-input
                      v-if="!isPublished"
                      label="Arquivos"
                      placeholder="Anexe aqui seus arquivos"
                      multiple
                      outlined
                      v-model="files"
                      @change="setAttachments"
                      :prepend-icon="null"
                      prepend-inner-icon="fas fa-paperclip"
                      :clearable="false"
                  >
                    <template v-slot:selection="{ index }">
                      <v-chip
                          small
                          label
                          color="primary"
                          close
                          @click:close="removeAttachment(index)"
                      >

                        <span>{{form.attachments[index].name}} ({{form.attachments[index].size | formatSize}})</span>

                      </v-chip>
                    </template>
                  </v-file-input>

                  <v-file-input
                      v-if="showDisplayOnlyAttachments"
                      label="Arquivos Anexados"
                      multiple
                      outlined
                      v-model="files"
                      :prepend-icon="null"
                      prepend-inner-icon="fas fa-paperclip"
                      :clearable="false"
                      disabled
                      >
                    <template v-slot:selection="{ index }">
                      <v-chip
                          small
                          label
                          color="primary"
                          close
                      >
                        <span>{{ form.attachments[index].name }}</span>
                      </v-chip>
                    </template>
                  </v-file-input>

                </v-col>
                <v-col class="pb-0 pt-9" cols="12">
                  <v-textarea
                      v-model="form.comments"
                      label="Comentários"
                      placeholder="Anote aqui as observações"
                      outlined
                  />
                </v-col>
              </v-row>
              <v-row v-if="$resize && $mq.above(window.mobileSize)" justify="end">
                <v-col cols="12" align="end">
                  <v-btn
                      v-if="$route.params.id > 0"
                      large
                      class="mr-2 mb-2"
                      color="primary"
                      :disabled="buzy || isPublished"
                      :loading="buzy"
                      @click="savePublish()"
                  >
                    Publicar
                  </v-btn>

                  <v-btn
                      large
                      class="mb-2"
                      color="outline-primary"
                      :disabled="buzy || isPublished"
                      :loading="buzy"
                      @click="saveDraft()"
                  >
                    Salvar como Rascunho
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-btn
                      large
                      block
                      class="center mb-2"
                      color="outline-primary"
                      :disabled="buzy || isPublished"
                      :loading="buzy"
                      @click="saveDraft()"
                  >
                    Salvar como Rascunho
                  </v-btn>
                </v-col>
                <v-col v-if="$route.params.id > 0" cols="12">
                  <v-btn
                      large
                      block
                      class="center mb-2"
                      color="primary"
                      :disabled="buzy || isPublished"
                      :loading="buzy"
                      @click="savePublish()"
                  >
                    Publicar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <ManuallyNotificationDialog />
  </v-container>
</template>

<script>
import moment from 'moment';
import { VMoney } from 'v-money';
import PreviousPage from '../../components/structure/previousPage';
import {mapState, mapActions, mapMutations} from 'vuex';
import { invoiceStatus } from '../../common/constants';
import ManuallyNotificationDialog from "../../components/notification/manuallyNotificationDialog";
import ContractService from "../../services/odoo/ContractService";
import AuthService from "../../services/auth/AuthService";

export default {
  name: 'newInvoice',
  directives: { money: VMoney },
  components: { PreviousPage, ManuallyNotificationDialog },
  data() {
    return {
      id: 0,
      menu: false,
      menuDue: false,
      buzy: false,
      form: {
        contractId: null,
        subcontractId: null,
        competenceDate: null,
        amount: null,
        carrierBillingType: null,
        billingNumber: null,
        financialGroupId: null,
        lifes: null,
        dueDate: null,
        comments: null,
        attachments: [],
        carrierBillingStatus: invoiceStatus.draft,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      numberOnly: [
        value => {
          const pattern = /^\d*$/;
          return value && (pattern.test(value) || 'Precisa ser um número');
        },
      ],
      required: [
        value => !!value || 'Campo obrigatório',
      ],
      sfUser: false,
      routeInvoices: 'faturas2',
      routeCreateInvoiceSalesforce: '',
      files: [],
      maxFiles: 10,
      limitFileSize: 12
    }
  },
  watch:{
    contracts(newValue) {
      if(this.$route.params.id > 0 && newValue.length){
        const formContractId = parseInt(this.form.contractId)
        const hasContract = newValue.some(contract => contract.contractId === formContractId);
        if(!hasContract) {
          this.loading(true);
          this._contractService.FindById(formContractId)
            .then(response => {
              if(response && response.data && response.data.records){
                this.form.subcontractId = formContractId;
                this.form.contractId = parseInt(response.data.parentContractId);
              }
            })
            .finally(() => {
              this.loadSubcontracts();
              this.loading(false);
            });
        }
      }
    }
  },
  methods: {
    ...mapMutations(['loading']),
    ...mapActions([
      'getInvoiceTypes', 'getInvoice', 'newInvoice',
      'getContracts', 'newAttachment', 'updateInvoice',
      'getActiveContractsByFinancialGroupId', 'getAttachments',
      'getFinancialGroups', 'getActiveSubContractsByParentId',
      'updateInvoiceAttachments'
    ]),
    loadContracts(){
      if(this.form.financialGroupId){
        this.buzy = true;
        this.getActiveContractsByFinancialGroupId(this.form.financialGroupId).finally(() => this.buzy = false);
      }
    },
    loadSubcontracts(){
      if(this.form.contractId){
        this.buzy = true;
        this.getActiveSubContractsByParentId(this.form.contractId).finally(() => this.buzy = false);
      }
    },
    convertFloatToMoney() {
      if(this.form && this.form.amount) {
        let amountString = this.form.amount.toFixed(2);
        amountString = amountString.replaceAll('.',',');
        const input = this.$refs.amount.$el.querySelector('input');
        input.value = amountString;
      }
    },
    clearAmount() {
      const input = this.$refs.amount.$el.querySelector('input');
      input.value = "0";
    },
    clearFields(){
      this.clearAmount();
      this.form.contractId = null;
      this.form.subcontractId = null;
      this.form.financialGroupId = null;
      this.form.competenceDate = null;
      this.form.amount = null;
      this.form.carrierBillingType = null;
      this.form.billingNumber = null;
      this.form.lifes = null;
      this.form.dueDate = null;
      this.form.comments = null;
      this.form.attachments = [];
      this.form.carrierBillingStatus = this.invoiceStatus.draft;
    },
    async loadAttachments() {
      await this.getAttachments(this.invoice.id)
          .then(() =>{
            this.setAttachments(this.attachments.map(attachment => {
              return {name: attachment, body: '', size: 0};
            }))
          });
    },
    setAttachments(attachments) {
      if (!this.form.attachments) {
        this.form.attachments = []
      }

      this.form.attachments = [
        ...attachments,
        ...this.form.attachments,
      ];

      /*TRATA DUPLICIDADE DE ARQUIVO*/
      const names = [...new Set(this.form.attachments.map(a => a.name))];
      this.form.attachments = names.map(name => this.form.attachments.find(attachment => {
        return attachment.name === name;
      }));

      /*REGRAS DE ANEXO (TAMANHO/QTD)*/
      if(this.form.attachments.length > this.maxFiles){
        this.showmsg({
          text: `Quantidade máxima de arquivos excedida, máximo de ${this.maxFiles} arquivos!`,
          type: "error",
          timeout: -1,
        });
        this.form.attachments.splice(this.maxFiles);
      }

      this.form.attachments = this.filterFilesByLimitSize(this.form.attachments);

      this.files = [
          ...this.form.attachments
      ];
    },
    removeAttachment(index) {
      this.form.attachments.splice(index, 1);
      this.files = [
          ...this.form.attachments
      ]
    },
    filterFilesByLimitSize(files) {
      if (this.limitFileSize > 0) {
        const filterFiles = files.filter(file => file.size <= this.limitFileSize * 1024 * 1024);

        if (filterFiles.length !== files.length) {
          this.showmsg({
            text: `Tamanho máximo de arquivo excedido, máximo ${this.limitFileSize}MB!`,
            type: "error",
            timeout: -1,
          });
        }

        return filterFiles;
      } else {
        return files;
      }
    },
    savePublish() {
      this.form.carrierBillingStatus = invoiceStatus.published;
      if(this.$route.params.id > 0 && this.invoice && this.invoice.id) {
        this.editInvoice();
      }else
        this.saveInvoice();
    },
    saveDraft() {
      this.form.carrierBillingStatus = invoiceStatus.draft;

      if(this.$route.params.id > 0 && this.invoice && this.invoice.id) {
        this.editInvoice();
      }else
        this.saveInvoice();
    },
    async saveAttachments() {
      let id = this.invoice.id;

      if(id && this.form.attachments.length) {
        const promises = []
        this.form.attachments.forEach((attachment) => {
            if(attachment.size > 0){
              promises.push(this.readFileContent(attachment))
            }
          });
        const finalFiles = await Promise.all(promises);
        if(this.$route.params.id > 0){
          this.updateInvoiceAttachments({
            invoiceId: id, attList: this.attachmentNameList
          });
        }
        finalFiles.forEach((media) => {
          if(media.notPersist === undefined || media.notPersist === null || media.notPersist === false) {
            let data = {
              arquivoBase64: media.body,
              nomeArquivo: media.name
            }
            this.newAttachment({id, data});
          }
        })
      }
    },
    saveInvoice() {
      if(this.$refs.invoiceForm.validate()) {
        this.buzy = true;

        let competenceDate = moment(`${this.form.competenceDate}-01`).format('YYYY-MM-DD');
        let amount = this.form.amount;

        if(isNaN(amount)) {
          amount = this.$util.formatMoneyToNumber(amount);
        }

        let data = {
          ...this.form,
          competenceDate,
          amount,
        };

        this.buzy = false;
        this.newInvoice(data)
            .then(() => {
              if(this.invoice && this.invoice.id)
                this.saveAttachments();
            })
            .finally(() => {
              this.buzy = false;
              this.$router.push({name: 'nova-fatura-salesforce', params: {id: this.invoice.id}});
            });
      }
    },
    editInvoice() {
      if(this.$refs.invoiceForm.validate()) {
        this.buzy = true;
        let amount = this.form.amount;

        if(isNaN(amount)) {
          amount = this.$util.formatMoneyToNumber(amount);
        }

        if(moment(`${this.form.competenceDate}-01`).isValid()) {
          this.form.competenceDate = moment(`${this.form.competenceDate}-01`).format('YYYY-MM-DD');
        }

        if(this.form.carrierBillingType.id) {
          this.form.carrierBillingType = this.form.carrierBillingType.id
        }

        let invoice = {
          ...this.form,
          amount,
        };

        let id = this.invoice.id;

        this.updateInvoice({ id, invoice })
            .then(() => {
              if(this.invoice && this.invoice.id)
                this.saveAttachments()
            })
            .finally(() => {
              this.buzy = false;
              this.$router.push({name: 'nova-fatura-salesforce', params: {id: this.invoice.id}});
            });
      }
    },
    async loadJWTToken() {
        this._xippAuthService = new AuthService();
        await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
                localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    },
    async verifyIsCreateRouteSalesforce() {
      this.routeCreateInvoiceSalesforce = this.$route.path;
      const {token, origin} = this.$route.query;
      if (token) {
          localStorage.setItem("LOCALIZED_TOKEN", token)
          localStorage.setItem("LOCALIZED_ORIGIN", origin)
          localStorage.removeItem("auth_user");
          localStorage.removeItem("token");
          await this.loadJWTToken();
      }
    },
    async revalidateParams() {
      const token = localStorage.getItem('LOCALIZED_TOKEN');
      if(!token){
        await this.verifyIsCreateRouteSalesforce();
      }else{
        return;
      }

      if(!token){
        this.$router.go(0);
      }
    },
    readFileContent(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () => resolve({
          name: file.name,
          body: fileReader.result,
          size: file.size,
        });

        fileReader.readAsDataURL(file);
      });
    },
  },
  computed: {
    ...mapState({
      invoiceTypes: state => state.invoiceTypes,
      invoice: state => state.invoice,
      window: state => state.window,
      contracts: state => state.contracts,
      attachments: state => state.attachments,
      financialGroups: state => state.financialGroups,
      subcontracts: state => state.subcontracts,
    }),
    hasBilling(){
      let existsSub = false
      if(this.contracts.length && this.form && this.form.contractId){
        existsSub = this.contracts.some(contract => contract.contractId && contract.contractId === this.form.contractId
          && contract.billing && contract.billing.includes('sub'))
      }
      return existsSub
    },
    formatDateInput() {
      return this.form.dueDate ? moment(this.form.dueDate).format('DD/MM/YYYY') : '';
    },
    formatMonthInput() {
      return this.form.competenceDate ? moment(this.form.competenceDate).format('MM/YYYY') : '';
    },
    isPublished() {
      return this.form.carrierBillingStatus === invoiceStatus.published;
    },
    showDisplayOnlyAttachments(){
      return (this.form.attachments.length > 0 && this.invoice.id > 0 && this.isPublished)
    },
    attachmentNameList(){
      return this.form.attachments.map((att) => att.name)
    },
  },
  async beforeMount() {
    this._contractService = new ContractService();
    this.buzy = true;
    await this.verifyIsCreateRouteSalesforce();
    const odooToken = localStorage.getItem('LOCALIZED_TOKEN');
    this.sfUser = odooToken ? true : false;

    if (this.sfUser) {
      this.routeInvoices = 'faturas2-salesforce'
    }

    this._contractService = new ContractService();
    await this.revalidateParams();
    this.getInvoiceTypes();

    if (this.$route.params.id > 0) {
      await this.getInvoice(this.$route.params.id).then(async () => {
        if (this.invoice && this.invoice.id) {
          this.form = {...this.invoice}
          this.convertFloatToMoney();
          await this.loadAttachments();
        }
      });

      if (this.invoice && this.invoice.id) {
        this.form.amount = this.invoice.amount
      }
    }

    if (this.sfUser) {
      await this.getFinancialGroups().then(async () => {
        if (this.$route.params.id > 0) {
          await this.getActiveContractsByFinancialGroupId(this.form.financialGroupId).then(async () => {
            await this.getActiveSubContractsByParentId(this.form.contractId);
          })
        }
      })
    } else {
      await this.getContracts();
    }
    this.buzy = false;
  }
}
</script>

<style scoped>
.center {
  margin-left: 50%;
  transform: translate(-50%, 0);
}
</style>
