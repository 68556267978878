<template>
  <v-container>
    <v-card elevation="0" color="transparent">
      <v-card-text class="px-8">
        <v-row>
          <v-col class="px-0">
            <v-row>
              <v-avatar size="80" style="border-radius: 20%">
                <v-img :src="appLogoPath"/>
              </v-avatar>
              <v-card-title class="black--text mb-10">{{appTitle}}</v-card-title>
              <v-card-text v-if="$util.isBrowserCompatible()">
                <p>Para utilizar esse aplicativo é necessário instalá-lo!</p>
                <div v-if="$util.isMobile()">
                  <p v-if="$util.isIosMobile()">Clique no botão de compartilhar e, na sequência, em "adicionar à tela de início"</p>
                  <p v-else>Para instalar o aplicativo clique no botão <b>INSTALAR</b> e depois em adicionar.</p>
                </div>
                <div v-else>
                  <p>Para instalar o aplicativo clique no botão <b>INSTALAR</b> e, na sequência, em Instalar.</p>
                </div>
              </v-card-text>
              <v-card-text v-else>
                <p>Esse navegador não é compatível.</p>
                <p v-if="$util.isIosMobile()">Utilize o navegador Safari para instalar esse aplicativo.</p>
                <p v-else>Utilize o navegador Chrome para instalar esse aplicativo.</p>
              </v-card-text>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-row v-if="!$util.isIosMobile()">
            <v-btn class="mt-6" color="primary" @click="install" :loading="installing" block :disabled="!$util.isBrowserCompatible()">
              Instalar
            </v-btn>
          </v-row>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="notInstalled" persistent>
      <v-card>
        <v-card-title primary-title dark class="primary white--text">Aplicativo não instalado</v-card-title>

        <v-card-text class="pt-6">
          Para instalar o aplicativo clique no botão <b>INSTALAR</b> e depois em adicionar.
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="notInstalled = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import $util from "../../common/util";

export default {
  name: 'Install',
  data: () => ({
    installationEvent: null,
    notInstalled: false,
    installing: false,
  }),
  computed: {
    appTitle(){
      return process.env.VUE_APP_TITLE;
    },
    appLogoPath(){
      return "/img/icons/" + process.env.VUE_APP_ACCOUNT_APP_CODE + "/tile150x150.png" ;
    }
  },
  methods: {
    configureEvents() {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.installationEvent = event;
      });
      window.addEventListener('appinstalled', (event) => {
        this.installing = false;

        if (!$util.isMobile()) {
          setTimeout(() => this.$router.push('/'), 600);
        } else {
          this.window.location.href = this.window.location.href.split('/install')[1];
        }
      });
    },
    install() {
      if (!this.installationEvent) {
        window.location.reload();
      }

      this.installing = true;
      this.installationEvent.prompt();
      this.installationEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          return;
        }
        this.installing = false;
        this.notInstalled = true;
      });
    }
  },
  created() {
    const isInstalled = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    if (isInstalled) {
      this.$router.push('/login');
      return;
    }
    this.configureEvents();
  },
};
</script>
