import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Comentário","placeholder":"Digite aqui seu comentário","rows":"5","auto-grow":"","outlined":""},model:{value:(_vm.caseComment.body),callback:function ($$v) {_vm.$set(_vm.caseComment, "body", $$v)},expression:"caseComment.body"}})],1)],1)],1),(_vm.$resize && _vm.$mq.above(_vm.window.mobileSize))?_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"black--text",attrs:{"large":"","color":"secondary","light":""},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"large":"","color":"primary","loading":_vm.busy,"disabled":_vm.busy || !_vm.caseComment.body},on:{"click":_vm.createComment}},[_vm._v("Inserir")])],1):_c(VFlex,[_c(VCardActions,[_c(VBtn,{attrs:{"block":"","large":"","color":"primary","loading":_vm.busy,"disabled":_vm.busy || !_vm.caseComment.body},on:{"click":_vm.createComment}},[_vm._v("Inserir")])],1),_c(VCardActions,[_c(VBtn,{staticClass:"black--text",attrs:{"block":"","large":"","color":"secondary","light":""},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }