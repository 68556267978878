<template>
  <v-container fluid grid-list-md>
    <v-tabs v-model="selectedTab" background-color="transparent" grow centered>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-active">Ativos</v-tab>
      <v-tab href="#tab-closed">Cancelados</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" class="pt-4">
      <v-tab-item :value="'tab-active'">
        <ContractsList whereClause="active" />
      </v-tab-item>

      <v-tab-item :value="'tab-closed'">
        <ContractsList whereClause="closed" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ContractsList from "@/components/contracts/list.vue";

export default {
  name: "Contrato",
  components: { ContractsList },
  data: () => ({
    selectedTab: "active"
  })
};
</script>
