import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('msg'),_c('Loading'),_c('router-view',{key:_vm.$route.fullPath,attrs:{"name":"container"}}),_c('keep-alive',[_c('router-view',{attrs:{"name":"containerkeepalive"}})],1),_c(VSnackbar,{attrs:{"content-class":"font-weight-bold","timeout":_vm.timeout,"bottom":"","color":"#c9aad1"},model:{value:(_vm.snackWithButtons),callback:function ($$v) {_vm.snackWithButtons=$$v},expression:"snackWithButtons"}},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"font-weight-bold",staticStyle:{"color":"#532E88"},attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.snackWithBtnText)+" ")])],1),_c(VSpacer),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"11"}},[_c(VBtn,{staticClass:"black--text mr-2 ml-5",staticStyle:{"width":"117px"},attrs:{"color":"secondary"},on:{"click":function($event){_vm.snackWithButtons = false}}},[_vm._v("Fechar ")]),_c(VBtn,{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.refreshApp.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.snackBtnText)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }