<template>
    <v-container fluid class="container-style">
          <PowerBIReportEmbed v-if="enbededLoaded"
              :embed-config="computedReportConfig"
              :eventHandlers="eventHandler"
              :phasedEmbedding=false
              :css-class-name="reportClass"
          >
          </PowerBIReportEmbed>
      
    </v-container>
</template>

<script>
import PowerBIService from "../../services/powerBi/PowerBi";
import { PowerBIReportEmbed } from 'powerbi-client-vue-js';
import { models } from 'powerbi-client';
import { mapMutations } from "vuex";
const reportClass = "report-style";

    export default {
        name: "bi",
        components: {
            PowerBIReportEmbed,
        },
        data: () => ({
            reportConfig: {
                type: "report",
                embedUrl: undefined,
                id: undefined,
                tokenType: models.TokenType.Embed,
                accessToken: undefined,
            },
            enbededLoaded: false,
            eventHandler: new Map([
                ['loaded', () => console.log('Report loaded')],
                ['rendered', () => console.log('Report rendered')],
                ['error', (event) => console.log(event.detail)]
            ]),
            reportClass,
        }),
        computed: {
            computedReportConfig() {
                return this.reportConfig
            }
        },
        methods: {
            ...mapMutations(["toggleBreadcrumb"]),
        },
        beforeCreate() {
            this._powerBIService = new PowerBIService();
        },
        async created() {
            this.toggleBreadcrumb();
            const params = new URLSearchParams();
            params.append("grant_type", process.env.VUE_APP_GRANT_TYPE);
            params.append("client_id", process.env.VUE_APP_CLIENT_ID);
            params.append("client_secret", process.env.VUE_APP_CLIENT_SECRET);
            params.append("scope", process.env.VUE_APP_SCOPE);
            await this._powerBIService.getReport().then(response => {
                this.reportConfig.embedUrl = response.data.embedUrl;
                this.reportConfig.accessToken = response.data.token;
                this.id = response.data.token.reportId;
                this.enbededLoaded = true;
            }).catch(() => {
              this.showmsg({text: "Erro na validação de acesso.", type: "error"})
            });
        },
        beforeDestroy() {
            this.toggleBreadcrumb();
        }
    }

</script>

<style scoped>
    .calc-h90 {
        height: calc(100% - 75px);
    }
    .report-style {
        height: 100%;
        width: 100%;
        padding: 5px
    }
    .container-style {
        height: 100%;
        padding: 0px 0px 1% 0px;
    }
</style>
