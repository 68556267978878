<template>
  <v-container fluid grid-list-md class="px-2">
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>

    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <v-flex xs12>
        <ProgressiveLoader :disabled="busy" @reachedEnd="loadItems">
          <v-card>
            <v-card-title class="primary--text">
            </v-card-title>
            <v-card-title>
              <v-spacer />
              <v-btn
                color="grey"
                text
                icon
                @click="$router.push(`${urlAssessmentEdit}/0`)"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
                style="cursor: pointer;"
                loading-text="Carregando..."
                disable-filtering
                disable-pagination
                disable-sort
                hide-default-footer
                :headers="headers"
                :items="items"
                :loading="loading"
                item-key="id"
                @click:row="(item) => handleItemSelection(item)"
            >
              <template v-slot:header> </template>
              <template v-slot:no-data>
                <v-alert :value="true" type="info" v-show="!loading" outlined
                  >Nenhum item foi encontrado</v-alert
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <v-menu
                    transition="slide-y-transition"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="managerFeedback(item)">
                      <v-list-item-icon>
                        <v-icon>fas fa-eye</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Gerenciar Feedback</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </ProgressiveLoader>
      </v-flex>
    </v-layout>
    <template v-else>
      <ProgressiveLoader :disabled="busy" @reachedEnd="loadItems">
        <v-data-iterator
            style="cursor: pointer;"
            hide-default-footer
            hide-default-header
            disable-pagination
            :items="items"
        >
          <template v-slot:no-data>
            <v-alert :value="false" type="info" v-show="!loading" outlined
              >Nenhum item foi encontrado</v-alert
            >
          </template>
          <template v-slot:default="props">
            <v-row v-for="item in props.items" :key="item.id">
              <v-col cols="12">
                <v-card
                  @click="handleItemSelection(item)"
                  class="elevation-5 pb-0"
                >
                  <v-card-text class="pb-0">
                    <v-row dense class="mb-4">
                      <template v-for="header in headers">
                        <v-col :cols="header.cols" :key="header.value">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="black--text font-weight-bold"
                              >{{ header.text }}</v-col
                            >
                            <v-col
                              cols="12"
                              :class="
                                (header.bold ? 'font-weight-bold' : '') +
                                ' silver--text pa-0'
                              "
                              >{{ item[header.value] }}</v-col
                            >
                          </v-row>
                        </v-col>
                      </template>
                      <v-col
                        cols="12"
                        class="d-flex primary--text justify-center text-sm-center mb-1"
                      >
                        <label class="primary--text">
                          Clique para detalhar
                        </label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </ProgressiveLoader>
    </template>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import ProgressiveLoader from "@/components/progressive-loader";
import SurveyService from "../../services/survey/SurveyService";
import moment from "moment-timezone";
import AuthService from "@/services/auth/AuthService";

export default {
  name: "assessmentListar",
  components: { ProgressiveLoader },
  data: () => ({
    sfUser: false,
    urlAssessmentEdit: '/assessment/management',
    urlAssessmentFeedback: '/assessment/feedback',
    items: [],
    filteredItems: [],
    loading: false,
    search: "",
    busy: false,
    paginations: 0,
    maximumItems: 0,
    loadedItems: 0,
    describe: null,
    pageNext: null,
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapState(["window"]),
    headers() {
      const baseHeaders = [
        { text: "Nome da Enquete", value: "name", width: '20%' },
        { text: "Grupo Financeiro", value: "financialGroup", width: '20%' },
        { text: "Contrato", value: "contract", width: '20%' },
        { text: "Data Inicial", value: "startDate", width: '10%' },
        { text: "Data Final", value: "endDate", width: '10%' },
        { text: "Status", value: "status", width: '10%' },
        { text: "Ações", value: "actions", width: '5%' },
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({ text: "", value: "", sortable: false, cols: "" });
      }
      return baseHeaders;
    }
  },
  methods: {
    ...mapMutations(["showmsg", 'loading']),
    handleItemSelection(item) {
      this.$router.push(`${this.urlAssessmentEdit}/${item.id}`);
    },
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    async loadItems() {
      this.busy = true
      this.loading = true

      await this._surveyService.FindAllAssessment()
        .then(response => {
          this.items = response.data
          this.items = this.items.map(item => {
            return {
              id: item.id,
              name: item.name,
              financialGroup: item.financialGroupName ? item.financialGroupName : 'Todos',
              contract: item.contractName ? item.contractName : 'Todos',
              startDate: item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : ' - ',
              endDate: item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : ' - ',
              status: item.status
            }
          })
        })
        .catch(() => {
          this.showmsg({ text: 'Ocorreu um erro ao listar os Assessments.', type: 'error' });
        })
        .finally(() => {
          this.busy = false
          this.loading = false
        })

    },
    loadUrlAssessment(){
      const odooToken = localStorage.getItem('LOCALIZED_TOKEN');
      this.sfUser = odooToken != null;
      if(this.sfUser) {
        this.urlAssessmentEdit = '/assessment/management-backoffice';
        this.urlAssessmentFeedback = '/assessment/feedback-backoffice';
      }
    },
    managerFeedback(item) {
      if(item){
        this.$router.push(`${this.urlAssessmentFeedback}?assessmentId=${item.id}`);
      }
    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    },
  },
  beforeCreate() {
  },
  async created() {
    const {token} = this.$route.query;
    if(token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.removeItem("auth_user");
      localStorage.removeItem("token");
      await this.loadJWTToken();
    }

    this._surveyService = new SurveyService();
    this.loadUrlAssessment();
    await this.loadItems();
  },
};
</script>
<style>
#assesments-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}
</style>
