<template>
  <v-container fluid>
    <Loading/>
    <v-card>
      <v-card-title style="color: rebeccapurple; font-weight: bold">
        Relatório de Acessos Eu Protegido Sintético
      </v-card-title>
      <v-card-text>

        <v-row dense>
<!--          <v-col>
            <v-menu
              ref="pickFrom"
              v-model="pickFrom"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDateInput(filter.from)"
                  label="De"
                  readonly
                  placeholder="Dia/Mês/Ano"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                />
              </template>
              <v-date-picker
                v-model="filter.from"
                scrollable
                no-title
              />
            </v-menu>
          </v-col>-->

          <v-col>
            <v-menu
              ref="pickUntil"
              v-model="pickUntil"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  
                  :value="formatDateInput(filter.until)"
                  label="Até"
                  readonly
                  placeholder="Dia/Mês/Ano"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                />
              </template>
              <v-date-picker
                v-model="filter.until"
                scrollable
                no-title
                @close="$refs.pickUntil.save(until)"
              />
            </v-menu>
          </v-col>
        </v-row>

      <v-row>
        <v-col>
              <v-btn
                large
                color="primary"
                class="ma-2"
                :loading="loadingDownload"
                :disabled="loadingDownload"
                @click="generateReport"
              >
                Exportar
              </v-btn>
          </v-col>
      </v-row>

      </v-card-text>
    </v-card>
    <br />
    
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import ContractService from "@/services/odoo/ContractService";
import {formrules} from "@/common/formrules";
import AuthService from "@/services/auth/AuthService";
import moment from 'moment';
import SecurityService from "@/services/security/SecurityService"
import Loading from "@/components/structure/Loading";

export default {
  name: "userAccessSyntheticReport",
  components: { Loading },
  data: () => ({
    filter: {
      from: null,
      until: null,
    },
    pickFrom: false,
    pickUntil: false,
    financialGroupIds: [],
    rules: formrules,
    loadingDownload: false,
  }),
  computed: {
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),

    formatDateInput(date) {
      return date ? moment(date).format('DD/MM/YYYY') : '';
    },

    generateReport(){
      this.loading(true);
      const params = new URLSearchParams();
      params.append("endDate", this.filter.until);
      this.loadingDownload = true;
      this._securityService.getUserAccessSyntheticReport(params)
      .then(response => {
        const blobParts = this.$util.base64ToBytesArray(response.data);
        const blob = new Blob(blobParts, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64" })
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'Relatório de Acesso dos Usuários Sintético.xlsx';
        link.click();
        this.loadingDownload = false;
        this.loading(false);
      })
      .catch((error) => {
          this.showmsg({text: 'Ocorreu um erro ao exportar o relatório.', type: 'error'})
          this.loadingDownload=false;
          this.loading(false);
      })
    },

    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    }
  },
  async created() {
    this._contractService = new ContractService();
    this._securityService = new SecurityService();
    const {token} = this.$route.query;
    if(token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.removeItem("auth_user");
      localStorage.removeItem("token");
      await this.loadJWTToken();
    }

    this._contractService.FindFinancialGroupPermiteds()
    .then(response => {
      this.financialGroupIds = response.data;
    })
    .catch(() => this.showmsg({text: 'Ocorreu um erro ao carregar os grupos financeiros.', type: 'error'}));
  }
};
</script>
