<style lang="scss">
  @import 'public/styles/app.scss';
</style>
<template>
  <v-app>
    <msg />
    <Loading/>
    <router-view name="container" :key="$route.fullPath"></router-view>
    <keep-alive>
      <router-view name="containerkeepalive"></router-view>
    </keep-alive>
    <v-snackbar
      content-class="font-weight-bold"
      v-model="snackWithButtons"
      :timeout="timeout"
      bottom
      color="#c9aad1"
    >
      <v-row justify="center">
        <v-col cols="7" class="font-weight-bold" style="color: #532E88;">
          {{ snackWithBtnText }}
        </v-col>
      </v-row>
      <v-spacer />
      <v-row justify="center">
        <v-col cols="11">
          <v-btn
            style="width: 117px"
            color="secondary"
            class="black--text mr-2 ml-5"
            @click="snackWithButtons = false"
          >Fechar
          </v-btn>
          <v-btn
            class="white--text"
            color="primary"
            @click.stop="refreshApp"
          >
            {{ snackBtnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-app>
</template>

<script>
import msg from "./components/structure/msg";
import $store from "./store/store";
import $router from "./router/router";
import Loading from "./components/structure/Loading";
import { Capacitor } from '@capacitor/core';
import { tratarMensagemErroLogin } from "./common/securityserver";

export default {
  name: 'App',
  components: {Loading, msg },
  data() {
    return {
      refreshing: false,
      registration: null,
      snackBtnText: 'Atualizar',
      snackWithBtnText: 'Nova versão disponível!',
      snackWithButtons: false,
      timeout: 0,
    };
  },
  methods: {
    async handleSessionAuthentication() {
      const logged = await this.$store.dispatch('checkAuthentication');

      if (!logged && this.$route.meta.auth) {
        this.$store.dispatch('logout');
        this.$store.commit('updateMenuState', {
          left: false,
          leftmini: false,
          right: false,
        });
        this.$router.push('/login');
      }
    },
    async checkAppInstalationStatus() {
      if (Capacitor.isNative){
          this.handleSessionAuthentication();
          return;
      }else{
        const isInstalled = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
        const installationNotRequired = this.$route.meta.installationNotRequired;
        const { skipInstall } = this.$route.meta;
        if (isInstalled || process.env.NODE_ENV === 'development' || installationNotRequired || skipInstall) {
          this.handleSessionAuthentication();
          return;
        }
        this.$router.push('/install');
      }
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
  watch: {
    $route() {
      this.checkAppInstalationStatus();
    },
  },
  beforeMount() {
    this.checkAppInstalationStatus();

    window.addEventListener('message', function (msg) {
      if (msg.data.event === 'callbackServiceLogin') {
        try{
          return $store.dispatch('createSession', {access_token: msg.data.tokenSecurityService, tinnyToken: msg.data.tinnyToken, noCache: false}).then((response) => {
              let return_message = response ? tratarMensagemErroLogin(response) : "";
              if(!response || (response && return_message !== 'O Termo de Uso não foi aceito.' && return_message !== 'ACCESS_VALIDATION_ERROR_MESSAGE')) {
                /* if(!response || (response && response !== 'O Termo de Uso não foi aceito.' && response !== 'ACCESS_VALIDATION_ERROR_MESSAGE')) { */
                  $router.push('/home');
              }
          })

        }catch (e) {
          return $router.push('/login');
        }
      }else if(msg.data.event === 'serviceDefaultReturn'){
        $router.push('/home');
      }

    })
  },
  mounted() {
    //mounted
    this.checkAppInstalationStatus();
  },
  beforeRouteEnter() {
    this.checkAppInstalationStatus();
  },
  created() {

    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if(registrations && registrations.length) {
        registrations.forEach(registration => {
          if(registration && registration.active && registration.active.scriptURL === 'https://tnk.rhprotegido.app/service-worker.js') {
            registration.unregister();
          }
        })
      }
    });
  },
};
</script>
