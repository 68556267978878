import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('PreviousPage',{attrs:{"route":'faturas'}}),_c(VCard,[_c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.id,"label":"Nº Fatura","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.contrato,"label":"Contrato","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.cnpj,"label":"CNPJ","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.competencia,"label":"Competencia","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.vencimento,"label":"Vencimento","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.valor,"label":"Valor (R$)","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.status,"label":"Status","readonly":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }