import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VFlex,{attrs:{"xs12":"","fill-height":""}},[_c(VCard,{attrs:{"height":"95%"}},[_c('iframe',{attrs:{"src":"https://firecodeit-apps.herokuapp.com/relatorioGerencial/","height":"100%","width":"100%","frameborder":"0"}})]),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }