<template>
  <v-container fluid>
    <PreviousPage :route="'beneficiarios'" />
    <v-card>
      <v-container fluid grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.ben" label="Nome" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.tp" label="Tipo" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.cpf" label="CPF" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.matr" label="Matricula" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.benef" label="Beneficio" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.ope" label="Operadora" readonly></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 lg4 xl3>
            <v-text-field :value="item.cart" label="Cartão" readonly></v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { getGoogleSheets } from "./../../common/gsxrest";
import { mapGetters, mapMutations } from "vuex";
import PreviousPage from "../../components/structure/previousPage";

const { VUE_APP_SHEETS_BENEFICIARIES, VUE_APP_SHEETS_API_KEY } = process.env;

export default {
  components: {PreviousPage},
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    ...mapMutations(["showmsg"]),
    loadItem(obj) {
      const load = obj.id == this.id;
      if (load) this.item = Object.assign({}, obj);
      return load;
    }
  },
  props: {
    id: null
  },
  data() {
    return {
      item: {}
    };
  },
  mounted() {
    getGoogleSheets(VUE_APP_SHEETS_BENEFICIARIES, VUE_APP_SHEETS_API_KEY, 'Sheet1!A2:H100')
      .then(data => {
        // eslint-disable-next-line no-unused-vars
        for (const [index, item] of data.entries()) {
          const gsxobj = {
            id: index,
            ben: item[0],
            cpf: item[1],
            matr: item[2],
            cart: item[3],
            ope: item[4],
            benef: item[5],
            tp: item[6]
          };

          if (this.loadItem(gsxobj)) return;
        }
      })
      .catch(() => {
        this.showmsg({
          text: "Erro ao carregar",
          type: "error"
        });
      });
  }
};
</script>

<style>
</style>
