export default {
  state: {
    app: {
      progress: false,
      reloadableKey: new Date().getTime(),
      loading: false,
    },
    showingTerms: false,
  },
  mutations: {
    progress(state, bool) {
      state.app.progress = bool
    },
    refreshReloadableKey(state) {
      state.app.reloadableKey = new Date().getTime()
    },
    setShowingTerms(state, newValue) {
      state.showingTerms = newValue;
    },
    loading(state, bool) {
      state.app.loading = bool
    },
  },
  getters:{
    loading: (state) => state.loading
  }
}
