<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-textarea label="Comentário" v-model="caseComment.body" placeholder="Digite aqui seu comentário" rows="5" auto-grow outlined/>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer/>
        <v-btn large color="secondary" light @click="cancelar()" class="black--text">Cancelar</v-btn>
        <v-btn large
               color="primary"
               :loading="busy"
               :disabled="busy || !caseComment.body"
               @click="createComment"
        >Inserir</v-btn>
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn block
                 large
                 color="primary"
                 :loading="busy"
                 :disabled="busy || !caseComment.body"
                 @click="createComment"
          >Inserir</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn block large color="secondary" light @click="cancelar()" class="black--text">Cancelar</v-btn>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import ComentsService from "../../services/odoo/ComentsService";
import {mapMutations, mapState} from "vuex";
import moment from "moment";

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    caseComment: {
      name: moment(new Date()).format("DD/MM/YYYY - HH:mm"),
      res_id: null,
      body: null,
      model: "helpdesk.ticket",
      message_type: "comment"
    },
    busy: false
  }),
  computed:{
    ...mapState(["window"]),
  },
  methods: {
    ...mapMutations(["showmsg"]),
    async createComment() {
      this.busy = true;
      await this._comentsService
        .Create(this.caseComment)
        .then(res => {
          this.$router.go(-1);
        })
        .finally(() => (this.busy = false));
    },
    cancelar() {
      this.$router.go(-1);
    }
  },
  beforeMount() {
    this._comentsService = new ComentsService();
    this.caseComment.res_id = this.id;
  }
};
</script>

