<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4">
        <v-btn
          @click="handleCampaignResults(caseData)"
          block
          elevation="3"
          color="primary"
          raised
          tile
          x-large
        >
          <v-icon class="mr-2">fas fa-paper-plane</v-icon
          >&nbsp;321&nbsp;&nbsp;<small>Enviados</small>
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="handleCampaignResults(caseData)"
          block
          color="primary"
          elevation="3"
          raised
          tile
          x-large
        >
          <v-icon class="mr-2">fas fa-eye</v-icon>&nbsp;23&nbsp;&nbsp;<small
            >Visto</small
          >
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="handleCampaignResults(caseData)"
          block
          color="primary"
          elevation="3"
          raised
          tile
          x-large
        >
          <v-icon class="mr-2">fas fa-eye-slash</v-icon
          >&nbsp;123&nbsp;&nbsp;<small>Não visto</small>
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-card-text>
        <v-form id="campanhaForm" ref="campanhaForm" @submit.prevent="create">
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                :value="caseData.subject"
                label="Titulo"
                readonly
                outlined
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="grupos"
                v-model="caseData.id"
                label="Grupo"
                placeholder="Selecione um grupo"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="status"
                v-model="caseData.status"
                label="Status"
                placeholder="Selecione um status"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                :value="caseData.datainicio"
                label="Inicio"
                type="date"
                readonly
                outlined
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                :value="caseData.datafim"
                label="Fim"
                type="date"
                readonly
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="caseData.description"
                label="Descrição"
                placeholder="Descreva sua Mensagem"
                :rules="[rules.required]"
                auto-grow
                outlined
                hide-details
                class="pb-5"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="caseData.campanharecorrente"
                :label="`Evento / Campanha recorrente?`"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                :value="caseData.enviarcada"
                label="Enviar a cada"
                type="number"
                outlined
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-select
                :items="periodicidade"
                v-model="caseData.periodo"
                label="Periodo"
                placeholder="Selecione periodo"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <form-file-upload
                label="Banner"
                ref="bannerFile"
                :maximum="1"
                :limit-file-size="10"
                @update="setAttachments"
              />
            </v-col>
            <v-col cols="6">
              <form-file-upload
                label="Anexos"
                ref="anexosFile"
                :maximum="1"
                :limit-file-size="10"
                @update="setAttachments"
              />
            </v-col>

            <v-col cols="12">
              <fieldset>
                <legend style="padding: 5px">Formulário Dinâmico</legend>

                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      style="margin-left: 10px"
                      label="Nome do campo"
                      outlined
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      :items="tipocampos"
                      v-model="caseData.tipocampo"
                      label="Tipo do campo"
                      placeholder="Selecione Tipo do campo"
                      outlined
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn style="margin-top: 8px" color="grey" text icon>
                      <v-icon>fas fa-save</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row style="margin-left: 10px">
                  <v-col style="margin-top: 15px" cols="6">Nome</v-col>
                  <v-col style="margin-top: 15px" cols="4">Texto</v-col>
                  <v-col cols="2">
                    <v-btn color="danger" text icon>
                      <v-icon>fas fa-trash</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col style="margin-top: 15px" cols="6">Idade</v-col>
                  <v-col style="margin-top: 15px" cols="4">Numero</v-col>
                  <v-col cols="2">
                    <v-btn text icon>
                      <v-icon>fas fa-trash</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </fieldset>
            </v-col>
            <v-col cols="3">
              <v-card-title>
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer />
        <v-btn
          large
          light
          @click="cancelar()"
          class="black--text"
          >Cancelar</v-btn
        >
        <v-btn
          large
          color="primary"
          type="submit"
          form="campanhaForm"
          :loading="busy"
          :disabled="busy"
          >Salvar</v-btn
        >
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn
            block
            large
            color="primary"
            type="submit"
            form="campanhaForm"
            :loading="busy"
            :disabled="busy"
            >Salvar</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            large
            light
            @click="cancelar()"
            class="black--text"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import { formrules } from "./../../common/formrules";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
export default {
  name: "campanhainserir",
  components: {
    FormFileUpload,
  },
  props: {
    campanha: null,
  },
  data: () => ({
    caseData: {
      subject: null,
      description: "",
      id: 0,
      status,
    },
    grupos: [],
    periodicidade: [
      { value: "0", text: "Dias" },
      { value: "1", text: "Meses" },
      { value: "2", text: "Anos" },
    ],
    tipocampos: [
      { value: "0", text: "Numero" },
      { value: "1", text: "texto" },
      { value: "2", text: "Select" },
      { value: "3", text: "Booleano" },
    ],
    status: [
      { value: "0", text: "Ativo" },
      { value: "1", text: "Cancelado" },
      { value: "2", text: "Disparado" },
    ],
    rules: formrules,
    busy: false,
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    setAttachments(attachments) {
      this.caseData.attachments = attachments;
    },
    handleCampaignResults(item) {
      this.$router.push({ name: "campanharesultado", params: { id: item.id } });
    },
    async createCampanha() {
      if (!this.$refs.campanhaForm.validate()) {
        setTimeout(this.$refs.campanhaForm.resetValidation, 3000);
        return;
      }

      this.showmsg("mensagem criada com sucesso.");
    },

    async getGrupos() {
      this.grupos.push({ text: "Grupo 001", value: "001" });
      this.grupos.push({ text: "Grupo 002", value: "002" });
      this.grupos.push({ text: "Grupo 003", value: "003" });
      this.grupos.push({ text: "Grupo 004", value: "004" });
      this.grupos.push({ text: "Grupo 005", value: "005" });
    },
    cancelar() {
      this.$router.go(-1);
    },
  },
  created() {
    this.getGrupos();
  },
};
</script>
