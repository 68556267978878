import SecurityService from '../../services/security/SecurityService';

const _securityService = new SecurityService();

export default {
    state: {
        userById: {},
    },
    mutations: {
        setUserById(state, newValue) {
            state.userById = newValue;
        },
    },
    actions: {
        async getUserById(store, id) {
            await _securityService.FindById(id).then(({ data }) => {
                store.commit('setUserById', data);
            });
        },
    }
};
