<template>
  <v-container fluid>
    <PreviousPage />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="primary--text">
            <span>Configuração das Notificações</span>
            <v-spacer />
            <v-btn
                color="primary"
                dark
                icon
                v-if="!isLoadingContent"
                @click="$router.push({ name: routeConfigureNotification, params: { id: 0 } })"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-data-table class="file-explorer-desktop"  :headers="headers" :items="notificationConfigs"
                        :loading="isLoadingContent"
                        item-key="id" hide-default-footer disable-pagination>
            <template v-slot:no-data>
              Não existem configurações cadastradas.
            </template>

            <template v-slot:item.insertable="{ item }">
              <v-icon small v-if="item.insertable" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon small v-else color="red">
                mdi-close-circle
              </v-icon>
            </template>

            <template v-slot:item.updatable="{ item }">
              <v-icon small v-if="item.updatable" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon small v-else color="red">
                mdi-close-circle
              </v-icon>
            </template>

            <template v-slot:item.deletable="{ item }">
              <v-icon small v-if="item.deletable" color="green">
                mdi-checkbox-marked-circle
              </v-icon>
              <v-icon small v-else color="red">
                mdi-close-circle
              </v-icon>
            </template>

            <template class="pl-2" v-slot:item.actions="{ item }">
              <v-btn icon @click="$router.push({name: routeConfigureNotification, params: {id: item.id}})">
                <v-icon color="grey">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteNotificationConfig(item.id)">
                <v-icon color="grey">mdi-delete-forever</v-icon>
              </v-btn>
            </template>

            <template class="pl-2"  v-if="notificationConfigs && notificationConfigs.length > 0" slot="body.append">
              <tr class="white--text controlscroll">
                <td :colspan="headers.length"></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PreviousPage from '../../components/structure/previousPage';
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import {mapMutations} from "vuex";

export default {
  name: "showNotification",
  components: { PreviousPage },
  data: () => ({
    hasMenu: false,
    headers: [
      {
        text: 'ID',
        value: 'id',
        name: 'id',
        width: '5%',
        sortable: false,
      },
      {
        text: 'Título',
        value: 'title',
        name: 'title',
        width: '30%',
        sortable: false,
      },
      {
        text: 'Notificar ao Inserir',
        value: 'insertable',
        sortable: false,
      },
      {
        text: 'Notificar ao Alterar',
        value: 'updatable',
        sortable: false,
      },
      {
        text: 'Notificar ao Deletar',
        value: 'deletable',
        sortable: false,
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right',
      },
    ],
    isLoadingContent: false,
    notificationConfigs: [],
    routeConfigureNotification: 'configureNotification'
  }),
  beforeCreate() {
    this._socialNetworkService = new SocialNetworkService();
  },
  async created() {
    this.hasMenu = this.$util.hasMenu();

    if(!this.hasMenu){
      this.routeConfigureNotification = 'configureNotification-salesforce'
    }

    await this.loadAllNotificationConfigs();
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    async deleteNotificationConfig(id) {
      this.isLoadingContent = true;
      this.loading(this.isLoadingContent);
      await this._socialNetworkService.DeleteNotificationConfig(id)
          .then(() => {
            this.showmsg({text: 'Configuração excluída com sucesso.'});
          })
          .catch(() => {
            this.showmsg({text: 'Não foi possível excluir a configuração de notificação.', type: 'error'});
          })
          .finally(() => {
            this.isLoadingContent = false;
            this.loading(this.isLoadingContent);
          });
      await this.loadAllNotificationConfigs();
    },
    async loadAllNotificationConfigs() {
      this.isLoadingContent = true;
      this.loading(this.isLoadingContent);
      await this._socialNetworkService.FindAllCarrierBillingNotificationConfig()
        .then((response) => {
          if (response.data) {
            this.notificationConfigs = response.data;
          }
        })
        .catch(() => {
          this.showmsg({text: 'Não foi possível carregar as configurações de notificação.', type: 'error'});
        })
        .finally(() => {
          this.isLoadingContent = false;
          this.loading(this.isLoadingContent);
        });
      },
    },
  }

</script>
