import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"avatar-upload-component mr-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs.upload && _vm.$refs.upload.dropActive),expression:"$refs.upload && $refs.upload.dropActive"}],staticClass:"drop-active"},[_c('h3',[_vm._v("Arraste aqui para atualizar!")])]),_c('div',[_c('label',{attrs:{"for":"avatar"}},[_c(VAvatar,{staticStyle:{"overflow":"visible"},attrs:{"size":"60"}},[_c('img',{attrs:{"src":_vm.userProfilePicture}}),_c('i',{staticClass:"edit-profile-picture fas fa-pen"})])],1),_c('file-upload',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"upload",attrs:{"extensions":"gif,jpg,jpeg,png,webp","accept":"image/png,image/gif,image/jpeg,image/webp","name":"avatar","drop":!_vm.edit,"custom-action":_vm.customAction},on:{"input-filter":_vm.inputFilter,"input-file":_vm.inputFile},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),(_vm.files.length)?_c(VDialog,{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('div',{attrs:{"justify-center":"","align-center":""}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c('img',{ref:"editImage",staticStyle:{"max-width":"400px"},attrs:{"src":_vm.files[0].url}})]),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.editSave.apply(null, arguments)}}},[_vm._v("Atualizar")])],1),_c(VCardActions,[_c(VBtn,{staticClass:"black--text",attrs:{"block":"","large":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.cancel()}}},[_vm._v("Cancelar")])],1)],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }