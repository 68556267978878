<template>
  <iframe
    class="contrastHard"
    src="https://apps.xipp.com.br/rhprotegido/imagens/rhprotegido_background.png"
    height="100%"
    width="100%"
    scrolling="auto"
    frameborder="0"
  />
</template>
<script>
import ContractService from "../../services/odoo/ContractService";
import { mapState, mapGetters } from "vuex";
export default {
  data: () => ({
    busy: false
  }),
  computed: {
    ...mapState(["window", "users"]),
    ...mapGetters(["user"])
  },
  async created() {
    this._contractService = new ContractService();
  }
};
</script>