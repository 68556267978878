<template>
  <div>
    <v-dialog fullscreen hide-overlay v-model="fabIsOpen" transition="dialog-bottom-transition" @open="toggleFab">
      <v-toolbar dark color="primary">
        <v-icon @click="cancel">fas fa-times</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="toggleFab">fas fa-check</v-icon>
      </v-toolbar>
      <v-card>
        <v-card-text class="pa-0 ma-0" style="height: 300px;">
          <v-list two-line subheader>
            <slot/>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-btn fab fixed right bottom color="primary" @click="toggleFab">
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "GenericFabBottomSheet",
  data: () => ({
    fabIsOpen: false,
  }),
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  methods: {
    cancel() {
      this.fabIsOpen = false;
      this.$emit('clearFilters');
    },
    toggleFab() {
      this.fabIsOpen = !this.fabIsOpen;
      if (this.fabIsOpen) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
  },
};
</script>
