import Vue from 'vue';

import VueRouter from 'vue-router';
import $store from '../store/store'
Vue.use(VueRouter)

import benefitiaries from "../view/beneficiario/benefitiaries";
import benefitiariesDetalhar from "../view/beneficiario/benefitiariesDetalhar"
import login from './../view/app/login';
import portal from './../view/app/portal';
import notfound from './../view/app/notfound';
import home from "./../view/home/home";
import contratos from './../view/contrato/contrato';
import faturas2 from './../view/fatura/fatura2';
import faturas from './../view/fatura/fatura';
import newInvoice from './../view/fatura/newInvoice';
import faturaDetalhar from './../view/fatura/faturaDetalhar';
import movCads from './../view/movCad/movCad';
import atendimentos from './../view/atendimento/atendimento';
import atendimentoInserir from './../view/atendimento/atendimentoInserir';
import atendimentoDetalhar from './../view/atendimento/atendimentoDetalhar';
import atendimentoLight from './../view/atendimento/atendimentoLight';
import assesments from './../view/assesment/assesment';
import assessmentManagement from './../view/assesment/assessmentManagement';
import feedback from './../view/assesment/feedback';
import beneficiarioDetalhar from './../view/beneficiario/beneficiarioDetalhar';
import usuarios from './../view/usuario/usuario';
import usuarioDetalhar from './../view/usuario/usuarioDetalhar';
import criarUsuario from './../view/usuario/criar';
import arquivos from './../view/arquivo/arquivo';
import dashboardAnaliseVidas from './../view/relatorio/DashboardAnaliseVidas';
import dashboardSinistralidade from './../view/relatorio/DashboardSinistralidade';
import relatorioGerencial from './../view/relatorio/relatorioGerencial';
import dashboardGestaoRisco from './../view/relatorio/DashboardGestaoRisco';
import AlterarSenha from '@/view/alterar-senha/index.vue';
import LeitorQRCode from '@/view/triagem/leitorQRCode.vue';
import contractDetail from '@/components/contracts/detail.vue';
import Install from '@/view/install/index.vue';
import createCaseEvent from '@/components/cases/createCaseEvent';
import createCaseComment from '@/components/cases/createComment.vue';
import createAttachament from "../components/cases/createAttachament";
import CarouselEx from "../view/relatorio/CarouselEx";
import ExternalUrl from "../components/menu/ExternalUrl";
import bi from "../components/menu/bi";
import BeneficiaryConnectCompany from "../view/beneficiaryConnectCompany/BeneficiaryConnectCompany.vue";
import CreateBeneficiaryConnectCompany from "../view/beneficiaryConnectCompany/CreateBeneficiaryConnectCompany.vue";
import DashboardGestaoRisco1 from "../view/relatorio/DashboardGestaoRisco1";
import DashboardGestaoRisco2 from "../view/relatorio/DashboardGestaoRisco2";
import DashboardGestaoRisco3 from "../view/relatorio/DashboardGestaoRisco3";
import Carousel from "../components/menu/Carousel";
import mensagerias from './../view/mensageria/mensageria';
import mensageriaInserir from './../view/mensageria/mensageriaInserir';
import campanhas from './../view/campanhas/campanha';
import campanhaInserir from './../view/campanhas/campanhaInserir';
import campanhaResultado from "@/view/campanhas/campanhaResultado";
import PostsApp from "../view/admin/post/PostsApp";
import PostsEdit from "../view/admin/post/PostsEdit";
import TargetEditApp from "../view/admin/target-public/target-public-inserir";
import ShowNotification from '../view/notification/showNotification'
import ConfigureNotification from '../view/notification/configureNotification'
import beneficiario from "../view/beneficiario/beneficiario";
import NotificationPreferences from '../view/notification/preferences';
import relatorioEvolucao from "../view/relatorio/relatorioEvolucao";
import userReport from "../view/relatorio/userReport.vue";
import userAccessSyntheticReport from "../view/relatorio/userAccessSyntheticReport.vue";
import userAccessAnalyticalReport from "../view/relatorio/userAccessAnalyticalReport.vue";
import dashEvolution from "@/view/relatorio/dashEvolution.vue";

const checkNotfound = (to, from, next) => {
    var redirectTo = 'notfound'

    // eslint-disable-next-line no-undef
    if (process.env.BASE_URL.includes(to.fullPath) || to.fullPath.includes('index'))
        redirectTo = 'root'

    next({
        name: redirectTo
    })
}

const routes = [
    {
        name: 'login',
        path: '/login',
        components: {
            containerkeepalive: login
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'logout',
        path: '/logout',
        redirect: {
            name: 'login'
        },
        meta: {
            auth: false,
        }
    },
    {
        name: 'install',
        path: '/install',
        components: {
            containerkeepalive: Install,
        },
        meta: {
            auth: false,
        },
    },
    {
        name: 'accountLoginCallback',
        path: '/accountLoginCallback',
        async beforeEnter(to, from, next) {
            try {
                await $store.dispatch('createSession', { access_token: to.query.tokenSecurityService });
                next({
                    name: 'home'
                })
            } catch (e) {
                next({
                    name: "login"
                })
            }
        },
        meta: {
            auth: false,
        },
    },
    {
        name: 'preBeneficiaryConnectCompany',
        path: '/preBeneficiaryConnectCompany',
        async beforeEnter(to, from, next) {
            try {
                await $store.dispatch('createSession', { access_token: to.query.token });
                next({
                    name: 'beneficiaryConnectCompanySales'
                })
            } catch (e) {
                next({
                    name: "login"
                })
            }
        },
        meta: {
            auth: false,
            installationNotRequired : true
        },
    },
    {
        name: 'root',
        path: '/',
        meta: {
            auth: true,
        },
        components: {
            container: portal
        },
        children: [
            {
                name: 'home',
                path: 'home',
                component: home,
                meta: {
                    title: 'Home',
                    root: true,
                    auth: true,
                },
            },
            {
                name: 'contratos',
                path: 'contratos',
                component: contratos,
                meta: {
                    title: 'Contratos',
                    root: true,
                    auth: true,
                },
            },
            {
                name: 'contractDetail',
                path: 'contratos/:id',
                component: contractDetail,
                props: (route) => ({
                    id: route.params.id,
                    status: route.params.status
                }),
                meta: {
                    title: 'Detalhar Contrato',
                    bch: ['contratos'],
                    auth: true,
                }
            },
            {
                name: 'faturas',
                path: 'faturas',
                component: faturas,
                meta: {
                    title: 'Faturamento',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'faturas2',
                path: 'faturas2',
                component: faturas2,
                meta: {
                    title: 'Faturamento',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'faturas2-salesforce',
                path: 'faturas2-salesforce',
                component: faturas2,
                meta: {
                    title: 'Faturamento',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType: 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'nova-fatura',
                path: 'nova-fatura/:id',
                component: newInvoice,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Nova Fatura',
                    bch: ['faturas'],
                    auth: true,
                }
            },
            {
                name: 'nova-fatura-salesforce',
                path: 'nova-fatura-salesforce/:id',
                component: newInvoice,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Nova Fatura',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType: 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'criar-fatura-salesforce',
                path: 'criar-fatura-salesforce/:id',
                component: newInvoice,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Nova Fatura',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType: 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'fatura',
                path: 'faturas/:id',
                component: faturaDetalhar,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Detalhar Fatura',
                    bch: ['faturas'],
                    auth: true,
                }
            },
            {
                name: 'showNotification',
                path: 'showNotification',
                component: ShowNotification,
                meta: {
                    title: 'Listagem de Configurações das Notificações',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'notification-preferences',
                path: '/notification/preferences',
                component: NotificationPreferences,
                meta: {
                    title: 'Notificações - Preferências',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'showNotification-salesforce',
                path: 'showNotification-salesforce',
                component: ShowNotification,
                meta: {
                    title: 'Listagem de Configurações das Notificações',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType: 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'configureNotification',
                path: 'configureNotification/:id',
                component: ConfigureNotification,
                meta: {
                    title: 'Configuração das Notificações',
                    root: true,
                    auth: true,
                },
                props: (route) => ({
                    id: route.params.id,
                }),
            },
            {
                name: 'configureNotification-salesforce',
                path: 'configureNotification-salesforce/:id',
                component: ConfigureNotification,
                meta: {
                    title: 'Configuração das Notificações',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType: 'APP',
                    skipInstall: true,
                    hideMenu: true
                },
                props: (route) => ({
                    id: route.params.id,
                }),
            },
            {
                name: 'mov-cads',
                path: 'mov-cads',
                component: movCads,
                meta: {
                    title: 'Movimentação Cadastral',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'atendimentos',
                path: 'atendimentos',
                component: atendimentos,
                meta: {
                    title: 'Atendimentos',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'atendimentoLight',
                path: 'atendimentoLight',
                component: atendimentoLight,
                meta: {
                    title: 'atendimentoLight',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'inseriratendimento',
                path: 'atendimentos/inserir/',
                component: atendimentoInserir,
                props: (route) => ({
                    solicitation: route.params.solicitacao,
                    type: route.params.type,
                    request: route.params.request,
                }),
                meta: {
                    title: 'Solicitar Atendimento',
                    bch: ['atendimentos'],
                    auth: true,
                }
            },
            {
                name: 'atendimento',
                path: 'atendimentos/:id',
                component: atendimentoDetalhar,
                props: (route) => ({
                    id: parseInt(route.params.id),
                    appCode: route.params.appCode,
                    externalId: route.params.externalId
                }),
                meta: {
                    title: 'Detalhar Atendimento',
                    bch: ['atendimentos'],
                    auth: true,
                }
            },
            {
                name: 'beneficiarios2',
                path: 'beneficiarios2',
                component: benefitiaries,
                meta: {
                    title: 'benefitiaries',
                    auth: true,
                    root: true
                }
            },
            {
                name: 'benefitiariesDetalhar',
                path: 'benefitiariesDetalhar/:id',
                component: benefitiariesDetalhar,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'benefitiariesDetalhar',
                    auth: true,
                    root: true
                }
            },
            {
                name: 'assessments',
                path: 'assessments',
                component: assesments,
                meta: {
                    title: 'Questionários',
                    auth: true,
                    root: true
                }
            },
            {
                name: 'assessments-backoffice',
                path: 'assessments-backoffice',
                component: assesments,
                meta: {
                    title: 'Assessments',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'assessment',
                path: 'assessment/management/:id',
                component: assessmentManagement,
                meta: {
                    title: 'Questionário',
                    bch: ['assessment'],
                    auth: true,
                }
            },
            {
                name: 'assessment-backoffice',
                path: 'assessment/management-backoffice/:id',
                component: assessmentManagement,
                meta: {
                    title: 'Questionário',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'assessment-feedback',
                path: 'assessment/feedback',
                component: feedback,
                meta: {
                    title: 'Questionário - Feedback',
                    bch: ['assessment-feedback'],
                    auth: true,
                }
            },
            {
                name: 'assessment-feedback-backoffice',
                path: 'assessment/feedback-backoffice',
                component: feedback,
                meta: {
                    title: 'Questionário - Feedback',
                    bch: ['assessment-feedback'],
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'inserirEvento',
                path: 'atendimentos/:id/evento/',
                component: createCaseEvent,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Criar Evento',
                    bch: ['atendimentos/atendimento:id'],
                    auth: true,
                }
            },
            {
                name: 'inserirComentarioAtendimento',
                path: 'atendimentos/:id/comentario/',
                component: createCaseComment,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Inserir Comentário',
                    bch: ['atendimentos/atendimento:id'],
                    auth: true,
                }
            },
            {
                name: 'createAttachament',
                path: 'atendimentos/:id/documento/',
                component: createAttachament,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Inserir Documento',
                    bch: ['atendimentos/atendimento:id'],
                    auth: true,
                }
            },
            {
                name: 'mensageria',
                path: 'mensageria',
                component: mensagerias,
                meta: {
                    title: 'Mensageria',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'mensageria-salesforce',
                path: 'mensageria-salesforce',
                component: mensagerias,
                meta: {
                    title: 'Mensageria',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'mensageriainserir',
                path: 'mensageria/inserir',
                component: mensageriaInserir,
                meta: {
                    title: 'Mensageria Inserir',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'mensageria-salesforce-inserir',
                path: 'mensageria-salesforce/inserir',
                component: mensageriaInserir,
                meta: {
                    title: 'Mensageria Inserir',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                }
            },
            {
                name: 'campanhas',
                path: 'campanhas',
                component: campanhas,
                meta: {
                    title: 'campanhas',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'campanharesultado',
                path: 'campanha/resultado',
                component: campanhaResultado,
                meta: {
                    title: 'Resultado de Campanha',
                    root: true,
                    auth: true
                }
            },
            {
                name: 'campanhainserir',
                path: 'campanha/inserir',
                component: campanhaInserir,
                meta: {
                    title: 'campanha Inserir',
                    root: true,
                    auth: true
                }
            },
            {
                name: 'dashboardAnaliseVidas',
                path: 'dashboardAnaliseVidas',
                component: dashboardAnaliseVidas,
                meta: {
                    title: 'Dashboard - Análise de Vidas',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'dashboardSinistralidade',
                path: 'dashboardSinistralidade',
                component: dashboardSinistralidade,
                meta: {
                    title: 'Dashboard - Sinistralidade',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'relatorioGerencial',
                path: 'relatorioGerencial',
                component: relatorioGerencial,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'dashboardGestaoRisco',
                path: 'dashboardGestaoRisco',
                component: dashboardGestaoRisco,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'dashboardGestaoRisco1',
                path: 'dashboardGestaoRisco1',
                component: DashboardGestaoRisco1,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'dashboardGestaoRisco2',
                path: 'dashboardGestaoRisco2',
                component: DashboardGestaoRisco2,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'dashboardGestaoRisco3',
                path: 'dashboardGestaoRisco3',
                component: DashboardGestaoRisco3,
                meta: {
                    title: 'Gestão de Riscos V3',
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'carouselEx',
                path: 'carouselEx',
                component: CarouselEx,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'beneficiarios',
                path: 'beneficiarios',
                component: beneficiario,
                meta: {
                    title: 'Beneficiarios',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'beneficiario',
                path: 'beneficiarios/:id',
                component: beneficiarioDetalhar,
                props: (route) => ({
                    id: route.params.id
                }),
                meta: {
                    title: 'Detalhar Beneficiario',
                    bch: ['beneficiarios'],
                    auth: true,
                }
            },
            {
                name: 'usuarios',
                path: 'usuarios',
                component: usuarios,
                meta: {
                    title: 'Usuarios',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'usuario',
                path: 'usuarios/criar',
                component: criarUsuario,
                meta: {
                    title: 'Criar Usuário',
                    bch: ['usuarios'],
                    auth: true,
                }
            },
            {
                name: 'usuario',
                path: 'usuarios/editar/:userId',
                component: criarUsuario,
                meta: {
                    title: 'Editar Usuário',
                    bch: ['usuarios'],
                    auth: true,
                }
            },
            {
                name: 'usuario',
                path: 'usuarios/usuario/:userId',
                component: usuarioDetalhar,
                meta: {
                    title: 'Detalhes do Usuário',
                    bch: ['usuarios'],
                    auth: true,
                }
            },
            {
                name: 'arquivos',
                path: 'arquivos',
                component: arquivos,
                meta: {
                    title: 'Drive',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'alterar-senha',
                path: 'alterar-senha',
                component: AlterarSenha,
                meta: {
                    title: 'Alterar Senha',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'triagem-qrcode',
                path: 'triagem-qrcode',
                component: LeitorQRCode,
                meta: {
                    title: 'Triagem - Leitor QRCode',
                    root: true,
                    auth: true,
                }
            },
            {
                name: 'notfound',
                path: 'notfound',
                component: notfound,
            },
            {
                name: 'externalUrl',
                path: 'externalUrl',
                component: ExternalUrl,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: false,
                },
            },
            {
                name: 'powerBI',
                path: 'powerBI',
                component: bi,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: false,
                },
            },
            {
                name: 'publicacoes',
                path: 'publicacoes',
                component: PostsApp,
                meta: {
                    root: true,
                    auth: true,
                    title: 'Publicações',
                    hideBreadCrumb: false,
                    entityType : 'APP',
                    skipInstall: true
                },
            },
            {
                name: 'publicacoes-salesforce',
                path: 'publicacoes-salesforce',
                component: PostsApp,
                meta: {
                    root: true,
                    auth: false,
                    title: 'Publicações',
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                },
            },
            {
                name: 'publicacao-editar',
                path: 'publicacao/:id',
                component: PostsEdit,
                meta: {
                    root: false,
                    auth: true,
                    title: 'Publicações',
                    hideBreadCrumb: false,
                    entityType : 'APP',
                    skipInstall: true
                },
            },
            {
                name: 'publicacao-salesforce-editar',
                path: 'publicacao-salesforce/:id',
                component: PostsEdit,
                meta: {
                    root: true,
                    auth: false,
                    title: 'Publicações',
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                },
            },
            {
                name: 'target-edit',
                path: '/target-upsert/:feedItemId/:id',
                component: TargetEditApp,
                meta: {
                    auth: true,
                    root: true,
                    title: 'Publicações',
                    hideBreadCrumb: false,
                    entityType : 'APP',
                    skipInstall: true
                },
            },
            {
                name: 'target-salesforce-edit',
                path: '/target-salesforce-upsert/:feedItemId/:id',
                component: TargetEditApp,
                meta: {
                    auth: false,
                    root: true,
                    title: 'Publicações',
                    hideBreadCrumb: true,
                    entityType : 'APP',
                    skipInstall: true,
                    hideMenu: true
                },
            },
            {
                name: 'carousel',
                path: 'carousel',
                component: Carousel,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: false,
                },
            },
            {
                name: 'beneficiaryConnectCompanySales',
                path: 'beneficiaryConnectCompanySales',
                component: BeneficiaryConnectCompany,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true
                },
            },
            {
                name: 'beneficiaryConnectCompany',
                path: 'beneficiaryConnectCompany',
                component: BeneficiaryConnectCompany,
                meta: {
                    root: true,
                    auth: true,
                    hideBreadCrumb: true,
                },
            },
            {
                name: 'createBeneficiaryConnectCompanySales',
                path: 'createBeneficiaryConnectCompanySales',
                component: CreateBeneficiaryConnectCompany,
                meta: {
                    title: 'Criar Conexão Beneficiário x Empresa',
                    bch: ['usuarios'],
                    auth: true,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true
                }
            },
            {
                name: 'createBeneficiaryConnectCompany',
                path: 'createBeneficiaryConnectCompany',
                component: CreateBeneficiaryConnectCompany,
                meta: {
                    title: 'Criar Conexão Beneficiário x Empresa',
                    bch: ['usuarios'],
                    auth: true,
                    hideBreadCrumb: true,
                }
            },
            {
                name: 'RelatorioEvolucao',
                path: 'relatorioEvolucao',
                component: relatorioEvolucao,
                meta: {
                    title: 'Relatório de Evolução',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true,
                    skipInstall: true,
                }
            },
            {
                name: 'userReport',
                path: 'user-report',
                component: userReport,
                meta: {
                    title: 'Visão Gerencial dos acessos',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true,
                    skipInstall: true,
                }
            },
            {
                name: 'dashEvolution',
                path: 'dash-evolution',
                component: dashEvolution,
                meta: {
                    title: 'Dash de Evoluções',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true,
                    skipInstall: true,
                }
            },
            {
                name: 'userAccessSyntheticReport',
                path: 'user-access-synthetic-report',
                component: userAccessSyntheticReport,
                meta: {
                    title: 'Relatório Sintético de acessos de usuarios',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true,
                    skipInstall: true,
                }
            },
            {
                name: 'userAccessAnalyticalReport',
                path: 'user-access-analytical-report',
                component: userAccessAnalyticalReport,
                meta: {
                    title: 'Relatório Analitico dos acessos de usuarios',
                    root: true,
                    auth: false,
                    hideBreadCrumb: true,
                    hideMenu: true,
                    installationNotRequired : true,
                    skipInstall: true,
                }
            },
        ]
    },
    {
        path: "*",
        beforeEnter: checkNotfound
    },
]



const router = new VueRouter({
    mode: 'history',
    // eslint-disable-next-line no-undef
    base: process.env.BASE_URL,
    routes: routes
})

router.beforeEach((to, from, next) => {
    next();
})

export default router;


