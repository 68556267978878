<template>
    <div>
        <div>
            <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                <h3>Arraste arquivos aqui!</h3>
            </div>

            <file-upload
                    v-model="files"
                    :multiple="multiple"
                    :drop="drop"
                    :drop-directory="true"
                    ref="upload"
                    @input="updateFiles"
                    v-show="false"
            />
        </div>

        <label v-show="false" id="uploadLabel" ref="uploadLabel" for="file" style="cursor:pointer"/>

        <fieldset class="fieldsetOutlined">
            <legend class="ml-2 text--secondary px-1 py-0" style="font-size: small">{{label}}</legend>
            <v-container fluid>

                <v-row v-if="showInfoMessage" justify="center" align="center" no-gutters
                       style="cursor: pointer" class="pb-2">
                    <v-col cols="12" class="hidden-sm-and-down">
                        <span style="color: rgba(0, 0, 0, 0.38);">Arraste os arquivos para anexá-los ou</span>
                        <v-menu open-on-hover offset-y>
                            <template v-slot:activator="{ on }">
                                <label v-on="on">
                                    procurar.
                                </label>
                            </template>

                            <v-list>
                                <v-list-item @click="onAddFile()">
                                    <v-list-item-title>Arquivo</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="onAddFolder()"
                                >
                                    <v-list-item-title>Pasta</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" class="hidden-md-and-up">
                        <span style="color: rgba(0, 0, 0, 0.38);" @click="onAddFile()">Clique aqui para <span class="text--primary">procurar.</span></span>
                    </v-col>
                </v-row>

                <v-row v-if="!files.length">
                    <v-col cols="12">
                        <p class="mb-0" style="color: var(--v-primary-base)">Máximo {{maximum}} arquivos.</p>
                        <p class="mb-0" style="color: var(--v-primary-base)">Tamanho máximo por arquivo: {{limitFileSize + 'MB'}}.</p>
                    </v-col>
                </v-row>

                <v-row v-if="files.length">
                    <v-col cols="12">
                        <v-chip v-for="(file) in files" :key="file.id"
                                class="mr-2 mt-2"
                                close
                                outlined
                                @click:close="$refs.upload.remove(file)">

                                <span v-if="$resize && $mq.below(window.mobileSize)" style="max-width: 180px;" class="text-truncate d-inline-block">{{file.name}}</span>
                                <span v-else>{{file.name}} ({{file.size | formatSize}})</span>

                        </v-chip>
                    </v-col>
                    <v-col cols="12">
                        <v-btn @click="$refs.upload.clear()">Limpar</v-btn>

                    </v-col>
                </v-row>
            </v-container>
        </fieldset>
    </div>
</template>

<script>
    import FileUpload from 'vue-upload-component'
    import {mapMutations, mapState} from "vuex";

    export default {
        name: "CustomFileUpload",
        components: { FileUpload },
        props:{
            maximum: {
                type: Number,
                required: false,
                default: 0,
            },
            limitFileSize: {
                type: Number,
                required: false,
                default: 10,
            },
            showInfoMessage: {
                type: Boolean,
                required: false,
                default: true,
            },
            multiple: {
                type: Boolean,
                required: false,
                default: true,
            },
            drop: {
                type: Boolean,
                required: false,
                default: true,
            },
            label: {
              type: String,
              required: false,
              default: "Arquivos",
            },
        },
        data: () => ({
            files: [],
            mouseOver: false,
        }),
        methods: {
            ...mapMutations(["showmsg"]),

            async updateFiles(files) {

                if(files.length > this.maximum){
                    this.showmsg({
                        text: `Quantidade máxima de arquivos excedida, máximo de ${this.maximum} arquivos!`,
                        type: "error",
                        timeout: -1,
                    });
                    files.splice(this.maximum);
                }

                files = this.filterFilesByLimitSize(files);
                this.files = files;

                const promises = files.map(
                    attachment => this.readFileContent(attachment.file),
                );

                const finalFiles = await Promise.all(promises);

                this.$emit( 'update', finalFiles);
            },
            readFileContent(file) {
                const fileReader = new FileReader();

                return new Promise((resolve, reject) => {
                    fileReader.onerror = () => {
                        fileReader.abort();
                        reject(new DOMException("Unable to parse the file."));
                    };

                    fileReader.onload = () => resolve({
                        name: file.name,
                        body: fileReader.result,
                        size: file.size,
                    });

                    fileReader.readAsDataURL(file);
                });
            },
            onAddFolder() {
                if (!this.$refs.upload.features.directory) {
                    this.alert('Your browser does not support')
                    return
                }
                let input = this.$refs.upload.$el.querySelector('input')
                input.directory = true
                input.webkitdirectory = true
                this.directory = true
                input.onclick = null
                input.click()
                input.onclick = (e) => {
                    this.directory = false
                    input.directory = false
                    input.webkitdirectory = false
                }
            },
            onAddFile() {
                this.$refs.uploadLabel.click();
            },
            filterFilesByLimitSize(files){
                if(this.limitFileSize > 0){
                    const filterFiles = files.filter(file => file.size <= this.limitFileSize * 1024 * 1024);

                    if(filterFiles.length != files.length){
                        this.showmsg({
                            text: `Tamanho máximo de arquivo excedido, máximo ${this.limitFileSize}MB!`,
                            type: "error",
                            timeout: -1,
                        });
                    }

                    return filterFiles;
                }else{
                    return files;
                }
            },
        },
        computed: {
            ...mapState(["window"]),
        }

    }
</script>

<style scoped>

    .fieldsetOutlined{
        border-radius: 4px;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.38);
    }

    .fieldsetOutlined:hover{
        border-color: rgba(0, 0, 0, 0.8);
    }

    .drop-active {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: fixed;
        z-index: 9999;
        opacity: .6;
        text-align: center;
        background: #000;
    }
    .drop-active h3 {
        margin: -.5em 0 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px;
        color: #ffffff;
        padding: 0;
    }

</style>
