<template>
  <v-container fluid fill-height>
    <v-layout justify-center align-center>
      <v-flex xs12 fill-height>
        <v-card height="95%">
          <jasperserver-visualize
            container="dashboardAnaliseVidas"
            resource="/dashboards/Analise_de_Vidas"
            type="dashboard"
            classParam="hidden-sm-and-down"
            styleParam="width: 100%; float: left; height: 100%"/>

          <jasperserver-visualize
            container="dashboardAnaliseVidasMobile"
            resource="/dashboards/Analise_de_Vidas_Mobile"
            type="dashboard"
            classParam="hidden-md-and-up"
            styleParam="width: 100%; float: left; height: 600px"/>

        </v-card>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import JasperserverVisualize from '../../components/reports/JasperserverVisualize.vue'

export default {
  components:{
    JasperserverVisualize
  }
}
</script>

<style>

/* Header */
#dashboardAnaliseVidas td.jrxtcolfloating.jrxtcolheader.jrxtinteractive,
#dashboardAnaliseVidas td.jrxtcolfloating.jrxtcolheader.jrxtinteractive > span,
#dashboardAnaliseVidas td.jrxtrowheader.jrxtinteractive.jrxtrowfloating,
#dashboardAnaliseVidas td.jrxtrowheader.jrxtinteractive.jrxtrowfloating > span,
#dashboardAnaliseVidas td.jrxtrowheader.jrxtinteractive.jrxtrowfloating.jrxtcrossheader
{
  background-color: white !important;
  color: rgba(0, 0, 0, 0.54) !important;
  border:0px !important;
  font-size: 12px !important;
  text-align: left !important;
  font-weight: bold !important;
}

/* Cell */
#dashboardAnaliseVidas td.jrxtdatacell,
#dashboardAnaliseVidas td.jrxtdatacell > span,
#dashboardAnaliseVidas td.jrxtrowfloating.jrxtrowheader,
#dashboardAnaliseVidas td.jrxtrowfloating.jrxtrowheader > span
{
  font-size: 12px !important;
  border:0px !important;
  text-align: left !important;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

/* border bottom */
#dashboardAnaliseVidas td.jrxtdatacell,
#dashboardAnaliseVidas td.jrxtrowfloating.jrxtrowheader{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

/* Mobile */

/* Header */
#dashboardAnaliseVidasMobile td.jrxtcolfloating.jrxtcolheader.jrxtinteractive,
#dashboardAnaliseVidasMobile td.jrxtcolfloating.jrxtcolheader.jrxtinteractive > span,
#dashboardAnaliseVidasMobile td.jrxtrowheader.jrxtinteractive.jrxtrowfloating,
#dashboardAnaliseVidasMobile td.jrxtrowheader.jrxtinteractive.jrxtrowfloating > span,
#dashboardAnaliseVidasMobile td.jrxtrowheader.jrxtinteractive.jrxtrowfloating.jrxtcrossheader
{
  background-color: white !important;
  color: rgba(0, 0, 0, 0.54) !important;
  border:0px !important;
  font-size: 14px !important;
  text-align: left !important;
  font-weight: bold !important;
}

/* Cell */
#dashboardAnaliseVidasMobile td.jrxtdatacell,
#dashboardAnaliseVidasMobile td.jrxtdatacell > span,
#dashboardAnaliseVidasMobile td.jrxtrowfloating.jrxtrowheader,
#dashboardAnaliseVidasMobile td.jrxtrowfloating.jrxtrowheader > span
{
  font-size: 14px !important;
  border:0px !important;
  text-align: left !important;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

/* border bottom */
#dashboardAnaliseVidasMobile td.jrxtdatacell,
#dashboardAnaliseVidasMobile td.jrxtrowfloating.jrxtrowheader{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

</style>
