// import util from './../common/util'

export default {
  state: {
    window: {
      width: 0,
      height: 0,
      mobileSize: 960,
      footer: true
    }
  },
  getters: {
    isMobileSize: (state) => state.window.width <= state.window.mobileSize,
    getWidth: (state) => state.window.width
  },
  mutations: {
    handleResize(state) {
      state.window.width = window.innerWidth;
      state.window.height = window.innerHeight;
    },
    handleScroll(state) {
      // if (util.isMobile()) {
        var bottomOfWindow = (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) >= (document.documentElement.offsetHeight-100)
        state.window.footer = bottomOfWindow
      // } else {
      //   state.window.footer = true
      // }
    },
  }
}
