<template>
  <v-container fluid grid-list-md>
    <v-card-title>
      <v-spacer/>
      <v-btn
        color="primary"
        v-if="sfUser"
        dark
        icon
        @click="$router.push({ name: routeNewInvoice, params: { id: 0 } })"
      >
        <v-icon>fas fa-plus</v-icon>
      </v-btn>

      <v-menu
        v-if="configItems && configItems.length > 0"
        transition="slide-y-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-if="sfUser"
              color="primary"
              dark
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>fas fa-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in configItems"
            :key="index"
            @click="$router.push(item.route)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <template v-if="$resize && $mq.above(window.mobileSize)">
      <DrawerFilterer
        v-if="(openedDrawer && sfUser) || (!sfUser)"
        title="Faturas"
        :filters="filters"
        :data="items"
        @filtersRemoved="loadAllItems"
        @clearFilters="loadAllItems"
        @queryUpdate="loadFilterItems"
        @loadFinancialGroupToInvoice="loadFinancialGroupToInvoice"
        @loadComboBoxSubItems="loadComboBoxSubItems"
      />
      <v-btn
          fab bottom right fixed color="primary"
          height="56"
          width="56"
          @click="toggleFilterDrawer()"
      >
        <v-icon dark>fas fa-filter</v-icon>
      </v-btn>
      <v-card>
        <v-data-table
            loading-text="Carregando..."
            :headers="headers"
            :items="items"
            :page.sync="filter.page"
            :loading="loading"
            item-key="id"
            class="elevation-1"
            :items-per-page="10"
            hide-default-footer
        >
          <template v-slot:item.dueDate="{ item }">
            {{ moment(item.dueDate).format('DD/MM/yyyy') }}
          </template>

          <template v-slot:item.competenceDate="{ item }">
            {{ moment(item.competenceDate).format('MM/yyyy') }}
          </template>

          <template v-slot:item.amount="{ item }">
            R$ {{ item.amount | brl }}
          </template>

          <template v-slot:item.carrierBillingType="{ item }">
            {{ item.carrierBillingType.name }}
          </template>

          <template v-slot:item.contract="{ item }">
            <v-menu
              open-on-hover
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  class="pr-2 pb-1"
                >
                  fas fa-info
                </v-icon>
              </template>

              <div class="text-center mt-4" style="min-width: 150px; min-height: 50px; background-color: white">
                <span class="ma-4">{{ item.comments || 'Sem comentários' }}</span>
              </div>

            </v-menu>
            {{ item.contract.text }}
          </template>

          <template v-slot:item.carrierBillingStatus="{ item }">
            {{ getStatus(item.carrierBillingStatus) }}
          </template>

          <template v-slot:item.history="{ item }">
            <v-tooltip v-if="item.attachments.length > 0" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                  @click="getAttachHistory(item.id)"
                >
                  fas fa-eye
                </v-icon>
              </template>
              <span>Clique para ver o histórico de download</span>
            </v-tooltip>
            <v-icon
              v-else
              color="primary"
              dark
              small
            >
              fas fa-eye-slash
            </v-icon>
          </template>

          <template v-if="configManuallyItems && configManuallyItems.length > 0" v-slot:item.actions="{ item }">
            <v-menu
              transition="slide-y-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(configItem, index) in configManuallyItems"
                  :key="index"
                  @click="onClickManuallyConfig(configItem, item.id, item.financialGroupId)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="configItem.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ configItem.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:item.attachments="{ item }">
            <v-menu
              v-if="item.attachments.length > 0"
              open-on-hover
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>fas fa-file-alt </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                    v-for="(file, index) in item.attachments"
                    :key="index"
                >
                  <v-list-item-title>{{ file }}</v-list-item-title>
                  <v-list-item-action>
                    <v-btn
                      color="primary"
                      small
                      dark
                      icon
                      @click="() => { downloadAttach(item.id, file) }"
                    >
                      <v-icon>fas fa-file-download</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
            <template v-else>
              Sem anexos
            </template>
          </template>

          <template v-slot:no-data>
            <v-alert :value="true" type="info" v-show="!loading" outlined>{{msgEmptyList}}</v-alert>
          </template>
          <template v-slot:loading>
            <v-alert :value="true" type="info" v-show="loading" outlined>Carregando...</v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" type="info" outlined>Nada foi encontrado para "{{ search }}"</v-alert>
          </template>
          <template v-slot:footer>
            <v-pagination
              v-model="filter.selectedPage"
              :length="invoices && invoices.totalPages"
              @input="getAllInvoices"
            />
          </template>
        </v-data-table>
      </v-card>
    </template>

    <template v-else>
      <FabFilterer
        :filters="filters"
        :data="items"
        @filtersRemoved="loadAllItems"
        @clearFilters="loadAllItems"
        @queryUpdate="loadFilterItems"
        @loadFinancialGroupToInvoice="loadFinancialGroupToInvoice"
        @loadComboBoxSubItems="loadComboBoxSubItems"
      />
      <v-data-iterator hide-default-footer hide-default-header disable-pagination :items="items">
        <template v-slot:default="props">
          <v-row v-for="item in props.items" :key="item.id">
            <v-col cols="12">
              <v-card>
                <v-list dense>
                  <v-list-item v-if="sfUser || item.attachments.length > 0  ">
                    <v-row justify="end">
                      <v-col :cols="item.attachments.length > 0 && sfUser ? 9 : 10"></v-col>
                      <v-col :cols="item.attachments.length > 0 && sfUser  ? 3 : 2">
                        <v-icon
                            v-if="item.attachments.length > 0"
                            class="pr-4"
                            color="primary"
                            small
                            @click="getAttachHistory(item.id)"
                        >
                          fas fa-eye
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Contrato/Subcontrato</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>{{ item.contract.text }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Tipo</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.carrierBillingType.name }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Competência</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ moment(item.competenceDate).format('MM/yyyy') }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Vencimento</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ moment(item.dueDate).format('DD/MM/yyyy') }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Fatura</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>
                        {{ item.billingNumber }}
                      </v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Valor (R$)</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>R$ {{ item.amount | brl }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Vidas</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.lifes}}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Status</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ getStatus(item.carrierBillingStatus) }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Comentários</v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>{{ item.comments || 'Sem comentários' }}</v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Anexos</v-list-item-content>
                  </v-list-item>

                  <v-list v-if="item.attachments.length > 0">
                    <v-list-item
                        v-for="(file, index) in item.attachments"
                        :key="index"
                    >
                      <v-list-item-content>{{ file }}</v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                            color="primary"
                            small
                            dark
                            icon
                            @click="() => { downloadAttach(item.id, file) }"
                        >
                          <v-icon>fas fa-file-download</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <v-list-item v-else>Sem anexos</v-list-item>

                  <v-list-item>
                    <v-list-item-content>Ações</v-list-item-content>
                    <v-list-item-action>
                      <v-menu
                          transition="slide-y-transition"
                          offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="grey"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                              v-for="(configItem, index) in configManuallyItems"
                              :key="index"
                              @click="onClickManuallyConfig(configItem, item.id, item.financialGroupId)"
                          >
                            <v-list-item-icon>
                              <v-icon v-text="configItem.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ configItem.title }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>

    <v-dialog v-model="downloadHistoryDialog" width="600">
      <v-card style="width: 600px; overflow: hidden" :loading="flagTotal !== flagCount">
        <v-card-title class="text-h5 pb-0">
          Histórico de download
        </v-card-title>

        <v-list v-if="historyItems.length" class="pl-2">
          <v-list-item>
            <v-row justify="space-between" class="text-center">
              <v-col cols="4" style="font-size: 20px">Arquivo</v-col>
              <v-col cols="4" style="font-size: 20px">Usuário</v-col>
              <v-col cols="4" style="font-size: 20px">Data</v-col>
            </v-row>
          </v-list-item>

          <v-list-item v-for="history in historyItems" :key="history.id">
            <v-row justify="space-between" class="text-center">
              <v-col cols="4">{{ history.carrierBillingAttatchment.azureBlobId }}</v-col>
              <v-col cols="4">{{ history.userById.name }}</v-col>
              <v-col cols="4">{{ moment(history.downloadedAt).format('DD/MM/yyyy HH:mm') }}</v-col>
            </v-row>
          </v-list-item>
        </v-list>

        <v-row v-if="!historyItems.length && flagTotal === -1 && flagCount === -1"  justify="center" class="text-center">
          <v-col cols="12" style="font-size: 20px">Sem histórico</v-col>
        </v-row>
        <v-row v-else-if="flagTotal !== flagCount" justify="center" class="text-center">
          <v-col cols="12" style="font-size: 20px">Carregando...</v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <ManuallyNotificationDialog />
  </v-container>
</template>

<script>
import { invoiceStatus } from '../../common/constants';
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import FabFilterer from "@/components/fab-filterer";
import DrawerFilterer from "@/components/drawer-filterer";
import ManuallyNotificationDialog from '../../components/notification/manuallyNotificationDialog'
import moment from "moment";
import AuthService from "../../services/auth/AuthService";

export default {
  name: "Fatura",
  components: { FabFilterer, ManuallyNotificationDialog, DrawerFilterer },
  data: () => ({
    moment,
    headers: [
      { text: "Contrato/Subcontrato", value: "contract", align: "left" },
      { text: "Tipo", value: "carrierBillingType", align: "center" },
      { text: "Competência", value: "competenceDate", align: "left" },
      { text: "Vencimento", value: "dueDate", align: "left" },
      { text: "Fatura", value: "billingNumber", align: "center" },
      { text: "Valor (R$)", value: "amount", align: "left" },
      { text: "Vidas", value: "lifes", align: "left" },
      { text: "Anexos", value: "attachments", align: "center" },
      /*{ text: "Status", value: "carrierBillingStatus", align: "center" },*/
      { text: "Histórico de download", value: "history", align: "center" },
    ],
    msgEmptyList: 'Carregando...',
    openedDrawer: false,
    itemsAux: [],
    items: [],
    auxContracts: [],
    loading: false,
    search: "",
    flagTotal: 0,
    flagCount: 0,
    filter: {
      financialGroupId: null,
      contractId: null,
      subcontractId: null,
      billingNumber: null,
      carrierBillingType: null,
      competenceDate: null,
      dueDateStart: null,
      dueDateEnd: null,
      selectedPage: 1,
      page: 0,
      size: 10,
    },
    downloadHistoryDialog: false,
    historyItems: [],
    sfUser: false,
    configItems: [],
    configManuallyItems: [],
    manuallyNotificationType: 'CARRIER_BILLING',
    routeNewInvoice: 'nova-fatura',
    canEdit: false,
    canAdd: false,
  }),
  computed: {
    ...mapGetters(['getIsOpenDialog', 'getCarrierBillingId', 'getContracts']),
    ...mapState({
      invoices: state => state.invoices,
      contract: state => state.contract,
      window: state => state.window,
      attachments: state => state.attachments,
      downloadHistory: state => state.downloadHistory,
      downloadAttachment: state => state.downloadAttachment,
      userById: state => state.userById,
      invoiceTypes: state => state.invoiceTypes,
      financialGroups: state => state.financialGroups,
      contracts: state => state.contracts,
      subcontracts: state => state.subcontracts,
    }),
    filters() {
      let fabFilters = [
        {
          name: "Tipo de Fatura",
          key: "carrierBillingType",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Nome do Tipo",
              value: "",
              type: "combobox",
              items: [],
              active: true,
            },
          ],
        },
        {
          name: "Competência",
          key: "competenceDate",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Data da Competência",
              value: "",
              type: "date",
              mask: '##/####',
              active: true,
            },
          ],
        },
        {
          name: "Vencimento Inicial",
          key: "dueDateStart",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Data Inicial do Vencimento",
              value: "",
              type: "date",
              mask: '##/##/####',
              active: true,
            },
          ],
        },
        {
          name: "Vencimento Final",
          key: "dueDateEnd",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Data Final do Vencimento",
              value: "",
              type: "date",
              mask: '##/##/####',
              active: true,
            },
          ],
        },
        {
          name: "Número da Fatura",
          key: "billingNumber",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Número da Fatura",
              value: "",
              type: "input",
              active: true
            }
          ]
        },
      ]

      if(this.sfUser){
        fabFilters.push({
          name: "Grupo Financeiro",
          key: "financialGroup",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Nome do Tipo",
              value: "",
              type: "combobox",
              items: [],
              active: true,
            },
          ],
        })

        fabFilters.push({
          name: "Contrato/Subcontrato",
          key: "subcontractId",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Selecione um contrato",
              value: "",
              type: "combobox",
              items: [],
              hasSubItem: true,
              subItemName: 'Selecione um subcontrato',
              subItems: [],
              active: true,
            },
          ],
        })
      }

      return fabFilters;
    }
  },
  methods: {
    ...mapMutations(['showmsg']),
    ...mapActions([
      'getInvoices', 'getAttachments', 'getContractById',
      'downloadBillingAttachment', 'getHistoryAttachment',
      'getUserById', 'getInvoiceTypes', 'setIsOpenDialog',
      'setCarrierBillingId', 'setManuallyNotificationType',
      'setFinancialGroupId', 'setCarrierBillingAction','getFinancialGroups',
      'getActiveContractsByFinancialGroupId', 'getActiveSubContractsByParentId'
    ]),
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer(){
      this.openedDrawer = true;
      this.$store.commit('setFilterDrawer', true);
      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    loadFinancialGroupToFilter(){
      this.filters.forEach((filter) => {
        if(filter.key === 'financialGroup'){
          this.financialGroups.forEach(({ Name, Id }) =>
              filter.types[0].items.push({ text: Name, value: Id }))
        }
      })
    },
    loadContractsToFilter(){
      this.filter.contractId = null;
      this.filter.subcontractId = null;
      this.filters[6].types[0].value = null;
      this.filters.forEach((filter) => {
        if(filter.key === 'subcontractId'){
          filter.types[0].items = [];
          this.contracts.forEach(({ text, contractId }) =>
              filter.types[0].items.push({ text: text, value: contractId }))
        }
      })
    },
    loadSubContractsToFilter(){
      this.filters.forEach((filter) => {
        if(filter.key === 'subcontractId'){
          filter.types[0].subItems = [];
          this.subcontracts.forEach(({ text, contractId }) =>
              filter.types[0].subItems.push({ text: text, value: contractId }))
        }
      })
    },
    loadFinancialGroupToInvoice(financialGroupId){
      if(financialGroupId){
        this.filter.financialGroupId = financialGroupId
        this.filters[6].types[0].items = [];
        this.filters[6].types[0].subItems = [];
        this.getActiveContractsByFinancialGroupId(financialGroupId).finally(() => {
          this.loadFinancialGroupToFilter();
          this.filters[5].types[0].value = financialGroupId;
          this.loadContractsToFilter();
        });
      }
    },
    loadComboBoxSubItems(contractId){
      this.filter.subcontractId = null;
      this.filters[6].types[0].value = null;
      if(contractId){
        this.filter.contractId = contractId
        this.filters[6].types[0].subItems = [];
        this.getActiveSubContractsByParentId(contractId).finally(() => {
          if(this.subcontracts.length){
            this.loadSubContractsToFilter();
          }else{
            this.filters[6].types[0].subItems = [];
          }
        });
      }
    },
    getStatus(status) {
      if(status === invoiceStatus.published)
        return 'Publicado'

      if(status === invoiceStatus.draft)
        return 'Rascunho'

      if(status === invoiceStatus.archived)
        return 'Arquivado'
    },
    addItem(obj) {
      this.items.push(obj);
    },
    downloadAttach(id, blobId) {
      this.downloadBillingAttachment({ id, blobId })
        .then(() => {
          if(this.downloadAttachment)
            window.open(this.downloadAttachment);
        });
    },
    async getAttachHistory(id) {
      this.flagTotal = 0;
      this.flagCount = 0;
      this.historyItems = [];
      await this.getHistoryAttachment(id)
        .then(() => {
          if(this.downloadHistory.content && this.downloadHistory.content.length) {
            this.flagTotal = this.downloadHistory.content.length;
            this.downloadHistory.content.forEach(async (history) => {
              await this.getUser(history);
              this.flagCount++;
            })
            this.downloadHistoryDialog = true
          } else {
            this.flagTotal = -1;
            this.flagCount = -1;
            this.downloadHistoryDialog = true
          }
        });
    },
    async getUser(history) {
      if(history.userId) {

        await this.getUserById(history.userId)
            .then(() => {
              this.historyItems.push({
                ...history,
                userById: this.userById
              })
            })

      } else {

        this.historyItems.push({
          ...history,
          userById: { name: 'Usuário do Salesforce' }
        })
      }
    },
    async getAttach(invoice) {
      await this.getAttachments(invoice.id)
        .then(() => {
          let attachs = [ ...this.attachments ];

          if(invoice.contractId !== 'ALL'){
            this.getContractById(invoice.contractId)
                .then(() => {
                  this.itemsAux.push({
                    ...invoice,
                    attachments: attachs,
                    contract: { ...this.contract }
                  })
                  this.itemsAux.sort((a, b) => (a.competenceDate > b.competenceDate) ? -1 : 1)
                })
          }else {
            this.itemsAux.push({
              ...invoice,
              attachments: attachs,
              contract: { text: 'Todos' }
            })
            this.itemsAux.sort((a, b) => (a.competenceDate > b.competenceDate) ? -1 : 1)
          }
        })
    },
    loadConfigItemsByAuthorities(){
      if(this.sfUser || this.$util.hasNotificationConfigCarrierBillingAuthority()) {
        this.headers.push({ text: "Ações", value: "actions", align: "center" });

        let routeShowNotification = 'showNotification';
        if(this.sfUser){
          this.routeNewInvoice = 'nova-fatura-salesforce'
          routeShowNotification = 'showNotification-salesforce';

          let editItem = { title: 'Editar', icon: 'fas fa-edit', route: this.routeNewInvoice}
          this.configManuallyItems.push(editItem)
        }

        let configItem = { title: 'Configurar Notificações de Faturamento', icon: 'fas fa-bell', route: routeShowNotification}
        this.configItems.push(configItem)

        let manuallyItem = { title: 'Emitir Notificação', icon: 'fas fa-bell', route: null, openDialog: true }
        this.configManuallyItems.push(manuallyItem)
      }
    },
    onClickManuallyConfig(configItem, carrierBillingId, financialGroupId) {
      if(configItem && configItem.route) {
        this.$router.push({name: configItem.route, params: {id: carrierBillingId}})
      }else if(configItem && configItem.openDialog) {
        this.setIsOpenDialog(configItem.openDialog)
        this.setCarrierBillingId(carrierBillingId)
        this.setManuallyNotificationType(this.manuallyNotificationType)
        this.setFinancialGroupId(financialGroupId)
        this.setCarrierBillingAction('updatable')
      }
    },
    async revalidateParams() {
      const token = localStorage.getItem('LOCALIZED_TOKEN');
      if(!token){
        await this.loadUserOriginInfo();
      }else{
        return;
      }

      if(!token){
        this.$router.go(0);
      }
    },
    async loadJWTToken() {
        this._xippAuthService = new AuthService();
        await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if(response && response.data) {
                localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    },
    async loadUserOriginInfo(){
      const {token, origin} = this.$route.query;
      if (token) {
        localStorage.setItem("LOCALIZED_TOKEN", token);
        localStorage.setItem("LOCALIZED_ORIGIN", origin);
        localStorage.removeItem("auth_user");
        localStorage.removeItem("token");
        await this.loadJWTToken();
      }
    },
    async filterItemsSplit(filters) {
      let newFilters = filters.trim().split('and');

      await newFilters.forEach((filter) => {
        if (filter.includes('dueDateStart')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          if(moment(value, "DD/MM/YYYY").isValid()) {
            this.filter.dueDateStart = moment(value, "DD/MM/YYYY").format('YYYY-MM-DD')
          }else {
            this.showmsg({ text: 'A data inicial de vencimento do filtro não é válida.', type: 'error' })
          }

        }

        if (filter.includes('dueDateEnd')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          if(moment(value, "DD/MM/YYYY").isValid()) {
            this.filter.dueDateEnd = moment(value, "DD/MM/YYYY").format('YYYY-MM-DD')
          }else {
            this.showmsg({ text: 'A data final de vencimento do filtro não é válida.', type: 'error' })
          }

        }

        if(filter.includes('carrierBillingType')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          this.filter.carrierBillingType = +value;
        }

        if(filter.includes('billingNumber')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          this.filter.billingNumber = value;
        }

        if(filter.includes('competenceDate')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = '01' + '/' + filter.slice(start + 2, end - 1);

          if(moment(value).isValid()) {
            this.filter.competenceDate = moment(value).format('YYYY-DD-MM')
          }else
            this.showmsg({ text: 'A data da competência do filtro não é válida.', type: 'error' })
        }

        if(filter.includes('subcontractId')) {
          let start = filter.indexOf('(');
          let end = filter.indexOf(')');
          let value = filter.slice(start + 2, end - 1);

          this.filter.subcontractId = value;
        }
      })
    },
    loadFilterItems(data) {
      if (data) {
        this.resetFiltersIfExists(data);
        this.filterItemsSplit(data)
          .then(() => this.getAllInvoices())
      }
    },

    resetFiltersIfExists(data){
      if(data && !data.includes('dueDateStart')){
        this.filter.dueDateStart = null;
      }

      if(data && !data.includes('dueDateEnd')){
        this.filter.dueDateEnd = null;
      }

      if(data && !data.includes('carrierBillingType')){
        this.filter.title = null;
      }

      if(data && !data.includes('billingNumber')){
        this.filter.billingNumber = null;
      }

      if(data && !data.includes('competenceDate')){
        this.filter.competenceDate = null;
      }

      if(data && !data.includes('subcontractId')){
        this.filter.subcontractId = null;
      }
    },

    loadAllItems() {
      this.clearFilter();
      this.getAllInvoices();
    },

    clearFilter() {
      this.filter = {
        financialGroupId: null,
        contractId: null,
        subcontractId: null,
        billingNumber: null,
        carrierBillingType: null,
        competenceDate: null,
        selectedPage: 1,
        page: 0,
        size: 10,
      }
    },

    async getAllInvoices() {
      this.loading = true;

      this.items = [];
      this.itemsAux = [];
      this.filter.page = this.filter.selectedPage - 1
      await this.getInvoices(this.filter)
        .then(() => {
          if(this.invoices && this.invoices.content.length) {
            this.invoices.content.forEach(async (invoice) => {
              await this.getAttach(invoice)
            })
          }
        })
        .finally(() => {
          this.loading = false;
          if(this.itemsAux.length) {
            this.itemsAux.sort((a, b) => (a.competenceDate > b.competenceDate) ? -1 : 1)
          }
          this.items = this.itemsAux;
        });
    }
  },
  watch: {
    invoices(newValue) {
      if(newValue && !newValue.content.length) {
        this.msgEmptyList = 'Nenhum item foi encontrado.';
      }
    },
    loading(newValue) {
      if (newValue) {
        this.msgEmptyList = 'Carregando...';
      }
    }
  },
  async beforeMount() {
    this.loading = true;
    await this.loadUserOriginInfo();

    let authorities = this.$util.getAuthorities();

    if(authorities){
      let add = authorities.some(item => item.authority === 'rh_protegido_faturamento_add');
      let edit = authorities.some(item => item.authority === 'rh_protegido_faturamento_edit');

      if(add) this.canAdd = true
      if(edit) this.canEdit = true
    }

    const odooToken = localStorage.getItem('LOCALIZED_TOKEN');
    this.sfUser = odooToken ? true : false;
    this.loadConfigItemsByAuthorities();

    if(this.sfUser){
      await this.revalidateParams();
    }

    this.getInvoiceTypes()
      .then(() => {
        this.filters.forEach((filter) => {
          if(filter.key === 'carrierBillingType')
            this.invoiceTypes.forEach(({ name, id }) =>
              filter.types[0].items.push({ text: name, value: id }))
        })
      });

    if(this.sfUser) {
      this.getFinancialGroups()
          .then(() => {
            this.loadFinancialGroupToFilter();
          });
    }

    this.getAllInvoices();

    this.loading = false;
  },
};

</script>
