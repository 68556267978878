<template>
  <v-container>
    <v-layout>
      <v-flex sm12>A página não existe.</v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>
