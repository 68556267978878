<template>
  <v-container fluid v-if="!busy">
    <PreviousPage :route="'beneficiarios2'"/>
    <v-card class="elevation-0">
      <v-card elevation="0">
        <v-card-title class="primary--text">
          Beneficiários
        </v-card-title>
      </v-card>
      <v-row>
        <v-col cols="12" class="pt-0" >
          <v-card>
            <v-card-title class="primary--text">
              Titular
            </v-card-title>
            <v-card-text>
              <v-row dense class="mb-4 py-0">
                <template v-for="header in headers">
                  <v-col :cols="header.cols" md="4" :key="header.value">
                    <v-text-field style="height: 43px" :label="header.text" :value="item[header.value]" readonly dense outlined></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="pt-0" >
          <v-card>
            <template>
              <v-col cols="12">
                <v-card-title class="primary--text">
                  Dependentes
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <template v-if="item.dependente.length">
                      <v-col v-for="(dependent, index) in item.dependente" :key="index"  cols="12">
                        <v-text-field style="height: 43px" :value="dependent.name" :label="dependent.kinship" readonly dense></v-text-field>
                        <v-row>
                          <v-col
                              v-for="(dependent, dIndex) in item.dependente[index]"
                              :key="dependent.name"
                              class="pa-1"
                              :cols="$resize && $mq.above(window.mobileSize) ? 4 : (dIndex === 'Cartão' ? 12 : 6)"
                          >
                            <v-text-field class="pa-0 ma-0" style="height: 43px" v-if="dIndex !== 'name'" :value="dependent" :label="getLabelDependent(dIndex)" readonly dense outlined></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </template>
                    <v-col v-else class="pt-0 mt-0">
                      <p>Não existem dependentes cadastrados.</p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import PreviousPage from "../../components/structure/previousPage";
import {mapGetters, mapActions, mapState, mapMutations} from "vuex";
import ContractService from "../../services/odoo/ContractService";

export default {
  name: "benefitiariesDetalhar",
  components: {PreviousPage },
  props: {
    id: {
      type: Number,
      required: true
    },
  },
  data: () => ({
    item: {},
    busy: false,
    selectedTab: "comments",
    describeContract: null,
    count:0,
    dependenteHeaders:[
      { text: "Nome", value: "name", cols: "6" },
      { text: "CPF", value: "cpf", cols: "6" },
      { text: "Nascimento", value: "birthdate", cols: "6" },
      { text: "Parentesco", value: "kinship", cols: "6" },
      { text: "Cartão", value: "card", cols: "6" },
      { text: "Status", value: "status", cols: "6" },
      ],
    headers: [
      { text: "Nome", value: "name", cols: "6" },
      { text: "CPF", value: "cpf", cols: "6" },
      { text: "Nascimento", value: "birthdate", cols: "6" },
      { text: "Matrícula", value: "registration", cols: "6" },
      { text: "Benefício", value: "benefit", cols: "6" },
      { text: "Operadora", value: "operator", cols: "6" },
      { text: "Apólice", value: "policy", cols: "6" },
      { text: "Subcontrato", value: "subcontract", cols: "6" },
      { text: "Plano", value: "plan", cols: "12" },
      { text: "Código da Família / Cartão", value: "card", cols: "12" },
      { text: "Vigência", value: "validity", cols: "6" },
      { text: "Status", value: "status", cols: "6" },
    ],
  }),
  computed:{
    ...mapState(["window"]),
    ...mapGetters(["getBeneficiariesDetails"]),
  },
  methods:{
    ...mapMutations(["showmsg", "loading"]),
    ...mapActions(["setBeneficiariesDetails"]),
    getLabelDependent(dIndex){
      if(dIndex === 'Cartão'){
        dIndex = 'Código da Família / Cartão'
      }
      return dIndex;
    },
    async loadAllBeneficiaryAndDependents() {
      await this._contractService.findBeneficiaryContractedPlanAndDependentsContractedPlansById(this.id)
        .then(response => {
          if(response.data && response.data.length > 0) {
            this.setBeneficiariesDetails(response.data)
            this.item = this.getBeneficiariesDetails
          }
        })
        .catch(() => {
          this.showmsg({ text: "Não foi possível carregar o detalhamento do beneficiário.", type: "error" })
        })
    },
    async loadAllBeneficiaryAndDependentsByDependentContractedPlan() {
      await this._contractService.findBeneficiaryAndDependentsByDependentContractedPlan(this.id)
          .then(response => {
            if(response.data && response.data.length > 0) {
              this.setBeneficiariesDetails(response.data)
              this.item = this.getBeneficiariesDetails
            }
          })
          .catch(() => {
            this.showmsg({ text: "Não foi possível carregar o detalhamento do dependente.", type: "error" })
          })
    }
  },
  beforeCreate() {
    this._contractService = new ContractService();
  },
  async created() {
    this.busy = true;
    this.loading(true);
    const { isBeneficiaryHolder } = this.$route.params
    if(isBeneficiaryHolder) {
      console.log('if')
      await this.loadAllBeneficiaryAndDependents();
    }else {
      console.log('else')
      await this.loadAllBeneficiaryAndDependentsByDependentContractedPlan();
    }
    this.busy = false;
    this.loading(false);
  }

}
</script>
