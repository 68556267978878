<template>
 <!-- :scroll-threshold="50" scroll-off-screen -->
  <v-app-bar flat :color="userLoggedBy() ? '#C9AAD1' : 'primary'" app class="white--text" v-if="!this.$route.meta.hideMenu">
    <v-app-bar-nav-icon @click.stop="toggleMenul">
      <v-icon class="white--text">fas fa-bars</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title class="text-truncate pl-0 ml-4">
      {{title}}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <span  v-if="userLoggedBy() && $resize && $mq.above(window.mobileSize)">
      {{user.loggedBy.name}} <b> Como: </b>{{user.nome ? user.nome : user.name ? user.name : ''}}
    </span>
    <span  v-else>
      {{user.nome ? user.nome : user.name ? user.name : ''}}
    </span>
    <notifications :key="user.email"/>
    <v-btn icon @click.stop="toggleMenur">
      <v-avatar size="40">
        <img :src="userProfilePicture" />
      </v-avatar>
    </v-btn>
  </v-app-bar>
</template>

<script>
  import {mapMutations, mapGetters, mapState} from "vuex";
  import Notifications from "./Notifications";

export default {
  components: {Notifications},
  computed: {
    ...mapGetters(["user", "userProfilePicture"]),
    ...mapState(["window"]),
    title() {
      if (this.user && this.user.financialGroup && this.user.financialGroup.name) {
        return this.user.financialGroup.name;
      }
      return null;
    },
  },
  methods: {
    ...mapMutations(["toggleMenul", "toggleMenur"]),
    userLoggedBy(){
      return this.user.loggedBy != null;
    },
  }
};
</script>
