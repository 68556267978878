import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},_vm._l((_vm.items),function(item,index){return _c(VFlex,{key:index,attrs:{"xs12":"","md6":"","lg3":""}},[_c(VCard,[_c(VCardTitle,{staticClass:"contrast white--text title"},[_vm._v(_vm._s(item.name))]),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[_vm._v("Cadastradas:")]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(_vm._s(item.cad))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Liberadas:")]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(_vm._s(item.lib))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Enviadas:")]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(_vm._s(item.env))])],1),_c(VListItem,[_c(VListItemContent,[_vm._v("Criticadas:")]),_c(VListItemContent,{staticClass:"align-end"},[_vm._v(_vm._s(item.crit))])],1)],1)],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }