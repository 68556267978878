<template>
  <GenericDrawer
    right
    :open="$store.state.drawerOpen"
  >
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{this.title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeSideDrawer">
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>

    <GenericFilterer
      ref="genericFilterer"
      :filters="filters"
      :data="data"
      :baseQuery="baseQuery"
      @queryUpdate="emitQuery"
      @filterChange="emitData"
      @filtersRemoved="(eventValue) => $emit('filtersRemoved', eventValue)"
      @loadComboBoxSubItems="loadComboBoxSubItems"
      @loadFinancialGroupToInvoice="loadFinancialGroupToInvoice"
    />

    <template v-slot:append>
      <div class="pa-2">
        <v-btn block large class="primary" @click="updateDrawer">Aplicar</v-btn>
      </div>
      <div class="pa-2">
        <v-btn block large class=" text--primary" @click="clearFilters">Limpar</v-btn>
      </div>
    </template>
  </GenericDrawer>
</template>

<script>
import GenericDrawer from "@/components/generic-drawer";
import GenericFilterer from "@/components/generic-filterer";
import {mapState} from "vuex";

export default {
  name: "DrawerFilterer",
  components: { GenericDrawer, GenericFilterer },
  data() {
    return {
      filterChanges: null,
      sfUser: false,
      dataToEmit: null
    }
  },
  computed: {
    ...mapState(['abledToSearch'])
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    baseQuery: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: 'Filtrar Resultados'
    },
  },
  methods: {
    emitData(dataToEmit) {
      this.$emit('filterChange', dataToEmit);
    },
    emitQuery(dataToEmit) {
      this.$emit('queryUpdate', dataToEmit)
    },
    closeSideDrawer() {
      this.$store.commit('setFilterDrawer', false);
      this.$store.commit('setAbledToSearch', true);
    },
    updateDrawer() {
      this.emitData();
      this.emitQuery();
      this.closeSideDrawer();
    },
    clearFilters() {
      this.$store.commit('setFilterDrawer', false);
      this.$refs.genericFilterer.clearFilters();
      this.$emit('clearFilters');
    },
    loadComboBoxSubItems(dataToEmit){
      this.$emit('loadComboBoxSubItems', dataToEmit)
    },
    loadFinancialGroupToInvoice(dataToEmit){
      this.$emit('loadFinancialGroupToInvoice', dataToEmit)
    }
  },
};

</script>
