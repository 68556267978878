import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VDialog,{attrs:{"max-width":"1024","persistent":""},model:{value:(_vm.getIsOpenDialog),callback:function ($$v) {_vm.getIsOpenDialog=$$v},expression:"getIsOpenDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 pb-0"},[_vm._v(" Emitir Notificação ")]),_c(VCardText,[(!_vm.isSendNotification)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('p',[_vm._v("Deseja emitir notificações aos usuários?")])])],1):_c('ConfigureNotificationForm',{attrs:{"isManuallyNotification":""}})],1),(!_vm.isSendNotification)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"outline-primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.enableNotificationConfig()}}},[_vm._v(" Confirmar ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }