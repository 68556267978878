const state = {
  assessment: {
    name: null,
    presentation: null,
    status: null,
    startDate: null,
    endDate: null,
    topics: null,
    beneficiaryGroups: [],
    insuredPositions: [],
    costCenters: [],
    financialGroups: [],
    contracts: [],
    subContracts: [],
  },
  assessmentTopics: [],
  assessmentTopicIndex: -1,
  conditions: [
    { value: "CONTAINS", text: "Contém" },
    { value: "EQUALS", text: "Igual" },
    { value: "BETWEEN", text: "Maior que e Menor que"},
    { value: "GREATER_THAN", text: "Maior que" },
    { value: "LESS_THAN", text: "Menor que" },
  ],
};

const mutations = {
  setAssessment(state, assessment){
    state.assessment = assessment;
  },
  setAssessmentName(state, name) {
    state.assessment.name = name;
  },
  setAssessmentPresentation(state, presentation) {
    state.assessment.presentation = presentation;
  },
  setAssessmentStatus(state, status) {
    state.assessment.status = status;
  },
  setAssessmentStartDate(state, startDate) {
    state.assessment.startDate = startDate;
  },
  setAssessmentEndDate(state, endDate) {
    state.assessment.endDate = endDate;
  },
  setAssessmentTopics(state, assessmentTopics){
    state.assessmentTopics = assessmentTopics;
  },
  setAsessmentTopicIndex(state, assessmentTopicIndex) {
    state.assessmentTopicIndex = assessmentTopicIndex;
  },
  setAssessmentQuestionByIndexOfTopic(state, payload) {
    state.assessmentTopics[payload.index].topic.questions.push(payload.question);
  },
  setAssessmentQuestionByIndexOfTopicAndIndexOfQuestion(state, payload) {
    state.assessmentTopics[payload.indexOfTopic].topic.questions[payload.indexOfQuestion] = Object.assign({}, payload.question);
  },
  setAssessmentAnswerByIndexOfTopicAndIndexOfQuestion(state, payload) {
    state.assessmentTopics[payload.indexOfTopic].topic.questions[payload.indexOfQuestion].triggers.push(payload.trigger);
  },
  setAssessmentAnswerByIndexOfTopicAndIndexOfQuestionAndIndexOfAnswer(state, payload) {
    state.assessmentTopics[payload.indexOfTopic].topic.questions[payload.indexOfQuestion].triggers.splice(payload.indexOfAnswer, 1);
    state.assessmentTopics[payload.indexOfTopic].topic.questions[payload.indexOfQuestion].triggers.splice(payload.indexOfAnswer, 0, payload.trigger);
  },
  setTargetFinancialGroups(state, payload) {
    state.assessment.financialGroups = Object.assign([], payload);
  },
  setTargetContracts(state, payload) {
    state.assessment.contracts = payload;
  },
  setTargetSubContracts(state, payload) {
    state.assessment.subContracts = payload;
  },
  setTargetBeneficiaryGroups(state, payload) {
    state.assessment.beneficiaryGroups = payload;
  },
  setTargetInsuredPositions(state, payload) {
    state.assessment.insuredPositions = payload;
  },
  setTargetCostCenters(state, payload) {
    state.assessment.costCenters = payload;
  },
};

const actions = {
  setAssessment(context, assessment) {
    context.commit('setAssessment', assessment);
  },
  setAssessmentName(context, name) {
    context.commit('setAssessmentName', name);
  },
  setAssessmentPresentation(context, presentation) {
    context.commit('setAssessmentPresentation', presentation);
  },
  setAssessmentStatus(context, status) {
    context.commit('setAssessmentStatus', status);
  },
  setAssessmentStartDate(context, startDate) {
    context.commit('setAssessmentStartDate', startDate);
  },
  setAssessmentEndDate(context, endDate) {
    context.commit('setAssessmentEndDate', endDate);
  },
  setAssessmentTopics(context, assessmentTopics) {
    context.commit('setAssessmentTopics', assessmentTopics);
  },
  setAsessmentTopicIndex(context, assessmentTopicIndex) {
    context.commit('setAsessmentTopicIndex', assessmentTopicIndex);
  },
  setAssessmentQuestionByIndexOfTopic(context, payload) {
    context.commit('setAssessmentQuestionByIndexOfTopic', payload);
  },
  setAssessmentQuestionByIndexOfTopicAndIndexOfQuestion(context, payload) {
    context.commit('setAssessmentQuestionByIndexOfTopicAndIndexOfQuestion', payload);
  },
  setAssessmentAnswerByIndexOfTopicAndIndexOfQuestion(context, payload) {
    context.commit('setAssessmentAnswerByIndexOfTopicAndIndexOfQuestion', payload);
  },
  setAssessmentAnswerByIndexOfTopicAndIndexOfQuestionAndIndexOfAnswer(context, payload) {
    context.commit('setAssessmentAnswerByIndexOfTopicAndIndexOfQuestionAndIndexOfAnswer', payload);
  },
  setTargetFinancialGroups(context, payload) {
    context.commit('setTargetFinancialGroups', payload);
  },
  setTargetContracts(context, payload) {
    context.commit('setTargetContracts', payload);
  },
  setTargetSubContracts(context, payload) {
    context.commit('setTargetSubContracts', payload);
  },
  setTargetBeneficiaryGroups(context, payload) {
    context.commit('setTargetBeneficiaryGroups', payload);
  },
  setTargetInsuredPositions(context, payload) {
    context.commit('setTargetInsuredPositions', payload);
  },
  setTargetCostCenters(context, payload) {
    context.commit('setTargetCostCenters', payload);
  }
};

const getters = {
  assessment: (state) => state.assessment,
  assessmentTopics: (state) => state.assessmentTopics,
  assessmentTopicIndex: (state) => state.assessmentTopicIndex,
  conditions: (state) => state.conditions,
  fullAssessment (state) {
    state.assessment.topics = state.assessmentTopics;
    return state.assessment;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
