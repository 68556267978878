<template>
  <v-toolbar elevation="1" class="ma-0 row align-center">
    <v-container fluid pb-3 pb-0 v-if="!this.$route.meta.hideBreadCrumb">
      <v-layout>
        <template v-for="(item, index) in navigation">
          <v-flex v-if="index != 0" :key="'icon' + index" shrink>
            &nbsp;
            <v-icon class="ml-2" small color="grey">fas fa-caret-right</v-icon
            >&nbsp;
          </v-flex>
          <v-flex shrink :key="index">
            <span @click.capture="bclink($event, item)">
              <router-link
                tag="span"
                :class="
                  !item.disabled
                    ? 'grey--text'
                    : 'primary--text font-weight-bold'
                "
                :to="{ name: item.name, params: item.params }"
                exact
              >
                <v-list-item class="pl-0">
                  <v-list-item-avatar :title="item.text" class="mr-1 ma-0">
                    <v-icon class="primary--text font-weight-bold">{{
                      item.icon
                    }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-bold primary--text"
                      :style="!item.disabled ? { cursor: 'pointer' } : {}"
                      >{{ item.text }}</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </span>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </v-toolbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["navigation"]),
  },
  methods: {
    ...mapMutations(["buildBreadCrumb"]),
    bclink: function (event, item) {
      if (item.disabled) event.preventDefault();
    },
  },
  watch: {
    $route() {
      if (!this.$route.meta.hideBreadCrumb) {
        this.buildBreadCrumb(this);
      }
    },
  },
  mounted() {
    this.buildBreadCrumb(this);
  },
};
</script>

<style>
</style>
