<template>
  <v-container fluid grid-list-md>
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn color="grey" text icon @click="createBeneficiaryConnectCompany()" v-if="false">
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table
              :headers="headers"
              :items="connectionsWithCompany"
              :loading="loading"
              :search="search"
              item-key="id"
      >
        <template v-slot:top>
          <v-text-field
                  v-model="search"
                  label="Pesquisa"
                  class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.changeStatus="{ item }">
          <span v-if="item.type == 'Request' ">
            <span v-if="item.status == 'Pendente'">
              <v-btn color="primary" class="mx-1" text--white @click="approveConnectionWithCompany(item.id)">
                Aprovar
              </v-btn>
              <v-btn color="primary" class="mx-1" text--white @click="refuseConnectionWithCompany(item.id)">
                Recusar
              </v-btn>
            </span>
            <span v-else>
            </span>
          </span>
          <span v-else>
            <v-btn v-if="item.status == 'Ativo'" color="primary" text--white @click="inactivateConnectionWithCompany(item.id)">
              Inativar
            </v-btn>
            <v-btn v-else color="primary" text--white @click="activateConnectionWithCompany(item.id)">
              Ativar
            </v-btn>
          </span>
        </template>
        <template v-slot:no-data>Nenhum registro cadastrado!</template>
        <template v-slot:loading>Carregando</template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations, mapState } from "vuex";
  import { findAllConnectCompanyByFinancialGroup, approveConnectionWithCompany, refuseConnectionWithCompany, activateConnectionWithCompany, disconnectFromCompany } from "@/common/euprotegidoservice";
  import ContractService from "../../services/odoo/ContractService";

  export default {
    name: "Usuario",
    data() {
      return {
        headers: [
          { text: "Nome do Usuário", value: "username", name: "username" },
          { text: "E-mail do Usuário", value: "useremail", name: "useremail" },
          { text: "Beneficiário", value: "name", name: "name" },
          { text: "CPF", value: "cpf", sortable: true },
          { text: "Status", value: "status", sortable: false },
          { text: "", value: "changeStatus", sortable: false, align: "right" }
        ],
        loading: true,
        busy: true,
        search: "",
        connectionsWithCompany: [],
        subMenuOptions: [{ title: "Editar", value: "edit" }]
      };
    },
    computed: {
      ...mapState(["window", "users"]),
      ...mapGetters(["user"])
    },
    async created() {
      this._contractservice = new ContractService();
    },
    methods: {
      ...mapMutations(["showmsg"]),
      createBeneficiaryConnectCompany(){
        if(this.$route.meta.hideMenu)
          this.$router.push('/createBeneficiaryConnectCompanySales')
        else
          this.$router.push('/createBeneficiaryConnectCompany')
      },
      isActive(status){
        return status == 'Ativo';
      },
      activateConnectionWithCompany(id, status){
        this.loading = true;
        activateConnectionWithCompany(this.user.tokenSecurityService.access_token, id)
                .then(res => {
                  this.atualizarRegistroArray(id, 'Ativo');
                })
                .catch(error => {
                  this.showmsg({ text: "Erro ao atualizar registro", type: "error" });
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      inactivateConnectionWithCompany(id){
        this.loading = true;
        disconnectFromCompany(this.user.tokenSecurityService.access_token, id)
                .then(res => {
                  this.atualizarRegistroArray(id, 'Inativo');
                })
                .catch(error => {
                  this.showmsg({ text: "Erro ao atualizar registro", type: "error" });
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      approveConnectionWithCompany(id, status){
        this.loading = true;
        approveConnectionWithCompany(this.user.tokenSecurityService.access_token, id)
                .then(res => {
                  this.atualizarRegistroArray(id, 'Aprovado');
                })
                .catch(error => {
                  this.showmsg({ text: "Erro ao atualizar registro", type: "error" });
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      refuseConnectionWithCompany(id){
        this.loading = true;
        refuseConnectionWithCompany(this.user.tokenSecurityService.access_token, id)
                .then(res => {
                  this.atualizarRegistroArray(id, 'Recusado');
                })
                .catch(error => {
                  this.showmsg({ text: "Erro ao atualizar registro", type: "error" });
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      atualizarRegistroArray(id, status){
        this.connectionsWithCompany.forEach(function(b) {
          if(b.id == id){
            b.status = status;
          }
        });
      },
      getStatusTraducao(status){
        let traducao = "";

        if(status == 'ACTIVE')
          traducao = 'Ativo';
        else if(status == 'ACCEPT')
          traducao = 'Aceito';
        else if(status == 'PENDING')
          traducao = 'Pendente';
        else if(status == 'DISCONNECT')
          traducao = 'Desconectado';
        else if(status == 'REFUSED')
          traducao = 'Recusado';

        return traducao;
      },
    },
    async beforeMount() {
      this.loading = true;
      findAllConnectCompanyByFinancialGroup(this.user.tokenSecurityService.access_token, this.user.financialGroup.id)
              .then(res => {
                console.log('findAllConnectCompanyByFinancialGroup', res.data);
                res.data.forEach((connectCompany, index) => {

                  if(connectCompany.connectionType == "Invitation")
                    return;

                  this.connectionsWithCompany.push({
                    index: index,
                    id: connectCompany.connectCompanyConnection ? connectCompany.connectCompanyConnection.id : connectCompany.id,
                    name: connectCompany.physicalPerson.name,
                    username: connectCompany.userName,
                    useremail: connectCompany.userEmail,
                    cpf: connectCompany.physicalPerson.cpf,
                    status: connectCompany.connectCompanyConnection ? this.getStatusTraducao(connectCompany.connectCompanyConnection.status) : this.getStatusTraducao(connectCompany.status),
                    type: connectCompany.connectCompanyConnection ? 'Connection' : 'Request'
                  });
                });
              })
              .catch(error => {
                console.error('>>>>', error)
                    this.showmsg({
                      text: "Erro ao carregar",
                      type: "error"
                    });
              })
              .finally(() => {
                this.loading = false;
              });
    }
  };
</script>

<style lang="scss" scoped>
  .users-mobile {
    padding-bottom: 100px;
  }
</style>
