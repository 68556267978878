import HttpService from '../HttpService'

export default class MetabaseService {
  constructor() {
    this._httpService = new HttpService("/reports/metabase");
  }

  async getMetabaseURLByServerCodeAndResourceId(serverCode, resourceId) {
    return await this._httpService.get('/getMetabaseURLByServerCodeAndResourceId', {serverCode, resourceId});
  }

}
