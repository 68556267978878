import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c('PreviousPage',{attrs:{"route":'beneficiarios'}}),_c(VCard,[_c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VLayout,{attrs:{"row":"","wrap":""}},[_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.ben,"label":"Nome","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.tp,"label":"Tipo","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.cpf,"label":"CPF","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.matr,"label":"Matricula","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.benef,"label":"Beneficio","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.ope,"label":"Operadora","readonly":""}})],1),_c(VFlex,{attrs:{"xs12":"","sm6":"","lg4":"","xl3":""}},[_c(VTextField,{attrs:{"value":_vm.item.cart,"label":"Cartão","readonly":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }