<template>
  <v-card flat tile height="100%">
    <v-card-actions class="justify-space-between">
      <v-btn
              text
              @click="prev"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-item-group
              v-model="onboarding"
              class="text-center"
              mandatory
      >
        <v-item
                v-for="n in dashboards"
                :key="`btn-${n}`"
                v-slot:default="{ active, toggle }"
        >
          <v-btn
                  :input-value="active"
                  icon
                  @click="toggle"
          >
            <v-icon>mdi-record</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn
              text
              @click="next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
    <v-window v-model="onboarding" vertical style="height: 100%">
      <v-window-item style="height: 100%"
                     v-for="dashboard in dashboards"
                     :key="`card-${dashboard}`"
      >
        <Metabase
                :resourceId="dashboard"
                path-params="#bordered=true&titled=false"
        />
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
  import Metabase from "../../components/reports/Metabase";

  export default {
    components:{ Metabase },
    data: () => ({
      onboarding: 0,
      dashboards: [15,17,18,19,20],
    }),
    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.dashboards.length
                ? 0
                : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
                ? this.dashboards.length - 1
                : this.onboarding - 1
      },
    },
  }
</script>
