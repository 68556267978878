<template>
    <v-container fluid >
        <v-row>
            <div class="text-center center">
                <h2 class=" title-qrcode" style="color: #2a244e;">Para validar o seu acesso,</h2>
            </div>
        </v-row>
        <v-row>
            <div class="text-center center">
                <h2 class="title-qrcode" style="color: #2a244e;">exiba o Qr Code da Triagem</h2>
            </div>
        </v-row>
        <v-row>
            <div class="text-center center">
                <h2 class="justify-center title-qrcode" style="color: #2a244e;">no leitor abaixo</h2>
            </div>
        </v-row>
        <v-row>
            <i class="fas fa-arrow-circle-down fa-5x center py-4" style="color: blue"></i>
        </v-row>
        <v-row>
            <qrcode-stream class="qrCode center"  @decode="validateQRCode"></qrcode-stream>
        </v-row>
        <v-dialog
                v-model="dialog"
                max-width="600"
        >
            <v-card>
                <v-card-title >
                    <v-col cols="12" class="mt-3 pt-0">
                        <p class="title-qrcode text-center" >
                            {{ titleResult }}
                        </p>
                    </v-col>
                </v-card-title>


                <v-col cols="12">
                    <p class="text-center">
                        <span class="fa-stack fa-8x" v-if="invalid">
                            <i class="fas fa-qrcode fa-stack-2x" style="color: lightgray"></i>
                            <i class="fas fa-exclamation-triangle fa-stack-1x " style="color: gray"></i>
                        </span>
                        <span class="fa-stack fa-8x" v-else-if="expired">
                            <i class="fas fa-qrcode fa-stack-2x" style="color: lightgray"></i>
                            <i class="fas fa-exclamation-triangle fa-stack-1x " style="color: gray"></i>
                        </span>
                        <span class="fa-stack fa-8x" v-else-if="release">
                            <i class="fas fa-qrcode fa-stack-2x"></i>
                            <i class="fas fa-check-circle fa-stack-1x " style="color: green"></i>
                        </span>
                        <span class="fa-stack fa-8x" v-else-if="!release">
                            <i class="fas fa-qrcode fa-stack-2x"></i>
                            <i class="far fa-times-circle fa-stack-1x " style="color: red"></i>
                        </span>
                    </p>
                </v-col>
                <v-card-text v-if="!invalid && !expired">
                    <v-col cols="12">
                        <p class="text-center generic-title mb-0 generated-date ">
                            {{ formateDateTime(generatedDate) }}
                        </p>
                    </v-col>
                    <v-col cols="12" class="mt-0 pt-0">
                        <p class="text-center main-text ma-0 user-name">
                            {{ result.userName }}
                        </p>
                    </v-col>
                </v-card-text>

                <v-card-text v-else-if="invalid">
                    <v-col cols="12" class="mt-0 pt-0">
                        <p class="text-center main-text ma-0 generated-date">
                            QRCode não foi encontrado
                        </p>
                    </v-col>
                </v-card-text>

                <v-card-text v-else-if="expired">
                    <v-col cols="12" class="mt-0 pt-0">
                        <p class="text-center main-text ma-0 generated-date">
                            Seu QRCode expirou
                        </p>
                    </v-col>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn
                            color="blue"
                            text
                            class="text-right"
                            @click="dialog = false"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapMutations } from "vuex";
import EuProtegidoService from "../../services/euprotegido/EuProtegidoService";
import { QrcodeStream } from 'vue-qrcode-reader'
import moment from 'moment'

export default {
    name: "AtendimentoDetalhar",
    components: { QrcodeStream },
    data: () => ({
        result: {},
        dialog: false,
        qrCode: '',
        titleResult : '',
        invalid: false,
        expired: false,
        release: false,
        generatedDate: '',
    }),
    methods: {
        ...mapMutations(["showmsg"]),
        validateQRCode(qrCode){
            const data = { value: qrCode }
            this._euprotegidoService.validateScreeningQRCode(data)
                .then((response) => {
                    this.result = response.data;
                    this.qrCode = qrCode;
                    this.release = this.result.release;
                    this.expired = this.result.expired;
                    this.generatedDate = this.result.medicalReleaseDate || this.result.generatedDate
                    this.titleResult = this.expired ? 'QrCode expirado!' : this.release ? 'Acesso liberado!' : 'Acesso negado!'
                })
                .catch((error) => {
                    this.invalid = true;
                    this.titleResult = 'QrCode inválido!'
                })
                .finally(() => {
                    this.dialog = true;
                })
        },
        formateDateTime(dateTime){
            return moment(dateTime).format('DD/MM/YYYY hh:mm');
        },
        //testeQrcode(){
            //const valid = 'UtebJQbyhHF/YolikdcC2mAQAxtWB/oQPNxV+tMTqvXmN3Ll6ika+hfMKApvlIcArV0KPT/5Rc3MrqlJuOCjNILkTpd51RzPfaX9S3fNaZCXwePS7C7XDl1/nkGxJ2p363DJBqPnh/QfBoM0CuLGUW0pKHp13ZksjvZZ9I0S1Ys1AYbJ2XnKzFF5yL1bIhswbiwVAVMK31LiatK2uwBF7mYxAmm7+hKlI8qD1uSkbsE0MwjMgHALF9OAd7ZRzZY3e8oxh3827FQxOye1X1tQM1dgaQahL6f7hAh+gN2tXBiiz0sLbHklGW9lpCeWthzVILbHFjZXlZx79+xRtPMb0t2Lt0F7kHpRqYsKJPhxKAu4GMnW+4lr21U3OI/PLqpjrl/aiWTG97ChuS9/U/iJbUtOoMVmqUV09mD10f+xa8m1JgA2np0ucPhanKs3lDm42NIrebNbZcLNk40BrgZo82R7wBaI+FaPYr9CKQVDXd5bhcZvuieTZZ09sODjgYQOVXD/HdhWLngu0nD/7Ccd42AIlSDgbw1JVYKNE7tOwoLm8HpSx4O3TgkKJe53UGklt/J3A1iHfM9qjzuD4mZE+z7dhfZ5fUDymGwNXYQBNbdmEPZXt2xCAnA4MKnd85oRMS3m+SUPgiJUwUfpPu0vfCL47yRJDgpCPCyLUMffqhLtHo2y8SDFRkk33uxaKgEPsKZ7WGmdD1W1UmxQEp7MJjOuIGqGKh0YgFpRQu+ofif08V7Cwxg/k/BZ509KCJ5XawQ5TFfCPya2FUnouxliX7qBaD8szPqTOFcKhpjDeOZibkonsC8GT2b3aDgaYJVVjk/aEDyM1hvJ458GAM/RQGfMK1jInptkEQhvfWmqc1WuObiI0jVrIAihHqQP5VJFRKodQ+8jIFqGk6jNqYiGFe07NhfWmTTxwoIv8fAKG8fBK/Mufy5O6FX/qN8188jBk5xHrGg/hzo2ytYVjQBlXfWFFeHNkAvvxodreJV9mQlDi/97gAJ/E/1TgBtgp2sueY8GGXAUCfjqq9FMezxFMZBw3fNa+Ur5OoCcMo0KRvRvYJij/4DyCKLSrrt/77nypkT5lMc6j4Mph44W9V40/jLUWslrzOv6GxeNG8gos3jy93Smsy/nQhh+kwb3JpQZNnIz6/NZLwj1qYoK3ThWHMrINH2KD7VXedSDDFZuwogGWYpqQfUpK3bVxAOZoz4iL/ivzYCDwUzNlJHCNBS0PMghAEOXBu7gwVT5sxGT97rUK8MFmOQco6Esw6446ia6SV4r2UrFyGpFwtuM85jpMslWZN2FxMc1gZwICN5K9co='
            //const invalid = '111';
            //const expirado = 'UtebJQbyhHF/YolikdcC2mAQAxtWB/oQPNxV+tMTqvXmN3Ll6ika+hfMKApvlIcArV0KPT/5Rc3MrqlJuOCjNILkTpd51RzPfaX9S3fNaZC8HrFmMQH03u7GcOIyMQmHbSrvwIK2jGi6kkgimFhVSNHw/BHytQijBq+gofGcqIpY0Vm1SH6ZnZ3vCPjMapoXPCbIwvyZy2BCiegcB55Rq/K00Em5cFUETQ7X1sjTp+gqJekRTz5TArd45ApVH4n+9moF0rSADqA8z0clzobDWc+wV41xCvFxX9ia5SfEkce0JdriWj02xIVXbmIZylNjb79QRIXYVi7rQhCYXoqkKUbG/GCAiJuaYBbyOUBaM8lZ9jJbgZUsamYhtKdVa0W4KicLC86UghakXVITi41ONStV7RzIoLvvYbK0G47tI1iUn37FwMxJzCObgklYOdeAKSIWrUOqirkHk3mXGse4BnclPXIG1XrVID6P/5G9BjQEkKMrl2p2jWspomYxcvXvibEYhPCMBPVH0rTqbHJZxX76WzEMDmQW9XrbSCr7Fimopfs5zxEILAC1nCpQibTye05IDtRSVINjJzKH647BgQcMvpiygvflPnbM0FkCJoQxvQI1mn4OU0M1bKji83dMsG+OId8iikFkTwPtmOuBJ+ro1rLo7n7jQ2YM8C2zuJ0lGaxinKqhsUMvVXSNxg3FNOFjHJxKlwNtpglSAH7epMC9A9YjntDCujLtqPggIuMrCIEga1qlhWY4S9zrX1omJTXdBY4S0Wgt4jR97yHtCDtGgJkkMjrh18gac2dI/h6z3bbORpB3qcxLW3d3hX1GGgqklN30H7sZvO/56p80/wUEwY2xdicPDcIZ/ArRgGUHeHXRPKU7wRi6NocbWWPzJy2iRXtnSHIWGKqI/BYEr5u40HvhdYiuWTIuiSKpyH1w72gmHo2HVgl3r+1aPSURtD7t4nNBzzP7VL94HIRWctLEtQkqoY5tKdEg+GyZA7dVc/BOOMOMLKMViQWPiVexMbN9eZYSNTnu8cQXiH1QrlfVXwWkAwpOsoPAUpR8D8gR6KlUfuu1dAbKUS+/U4qaG7pInJkbBnfcx81XoAMFeA5nIO60h5V6G9t+MEQ3nL1lvsbXA0ukVZBbSv+57QuzQ5vR612JW0E70GHxqpUwpEi2omjeQhqvlU2TA3fZjpvA+vAa5lrUrvq3wzXC3gMsH5epdv2N/Osh9JSWssxzVAjiGGzQKhK/vvldW6ap0epUIBwnREDAfYP8IXtYzlrjhyn+AFjuNU0hP6pg7WgpR++YG9ZXH1f/Bxge2wBZjD8='
            //const notRelease = 'UtebJQbyhHF/YolikdcC2mAQAxtWB/oQPNxV+tMTqvXmN3Ll6ika+hfMKApvlIcArV0KPT/5Rc3MrqlJuOCjNILkTpd51RzPfaX9S3fNaZDDy9BgGlnvAjPjQDnJVap4kQO6y5pPrBX0VG2+GL0wk1+bhnxiJzNCSaSjyLFwAaQX1nhvC+v2YFKhO8EfQRL53tTTV66I+FH9bZJVWD+ke2B5Gq3mkZSzg3xONWKlrr07rEgcdayPObfgig37h8BhhgPxd0Vqt0f9ZjphtNcu/3hDLJdL6pQkuWOICnE89dSLCKLrU6z1d4svvjqIEZVlyO5akRt0ayYzLKeOwEGcVibP/mGYsCzpsco8bnLG2jZSMOHxuz4S3IDaUceQ+w5ei9EF6N3b8DMM8mE6qvb8w8QR1fX9FibUjoit97LYeomhhv44gLRlzlHtba+Lqx/Ob4J6T+KrbhYoFl3tiATj1agUDmtt9xqpd/pg0Z72O/7pAKLDpofVzy8hVo3cMIujax7CCxZZB1IVQy2fDvNTfVIwLxUP7nyOs/PZyrpL8P3pzyaqI2QpEMyY9tu2E9tziZmNJke86c829RYxkiI/7yTX+GXkG5yI4M1ODjZtuLt6ZLkca9Alynfk84eZT85s10MsROkSQZxjW14LxB7ogBejCprLiaXPWIeeUjVx9AbKhOJbhCgwVZShjutuDWvn+vLEODEW/aBgm9vTGxnAXmBcEaZVR8dRfyn3ERdTkXNJrqs10qimF6yJsizqtuX+FU5MpOHwWoZfWePmZTTXVsjgS5yA7v/ZkREtuHhGe8lfuLTE71FWjxYrfaqIoRhKgLKYRFUt6q83y3cHfH0Yg5x4FWQBcu7dvXLjyvNQMATVYkVPv8kbdjQusBnpUIZi0+wEoZzHPUMaOBBWjmfaq9C8J+4/dgQ8ZwYEM/6o61D1oHoH7tWjtU4xy+6d7PHSoRjqyyNu83VKFDX6mnhpkgCIQFjxBzSjMDIMegDwFAh3H8eTTUJOW07UpdDxsAgQZl5IxcWZjHV9ga8fqtDWBuWufD6OCMJ5Ap7tNGbtl3HzJa30Pu61fxNg15Qj6t+pOnF/b+Pm32wQxPGRWE7H6MbvDY8bJtEZ7PK2JBZu3CuUc0BHYS7Fpg1bsZnY2da4ZoNhBKMm5lin6Waov1kyJyNlVU/cixE0/PEBKams3bnAWMJVadXQADlUL1zlV/CGsd9KEu/EWITr/R9HlRvfUfMu5mpvn+MkiQhf7gIQ5/5kg9jVuC4+ajRFkTd08GhZ6xtxC1LoTebLpi/42KW416OMqM13QUL07OVXR013WMQ='
            //this.validateQRCode(notRelease);
        //}
    },

    async created() {
        this._euprotegidoService = new EuProtegidoService();
        //this.testeQrcode();
    }
};
</script>
<style scoped>
.qrCode {
    max-height: 700px !important;
    max-width: 700px !important;
    padding: 10px !important;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.title-qrcode {
    font-family: "Open Sans" !important;
    font-weight: bold !important;
    font-size: 28px !important;
    line-height: 38px !important;
    line-height: 120% !important;
}

.generated-date {
    font-family: "Open Sans" !important;
    font-weight: bold !important;
    font-size: 22px !important;
}

.user-name {
    font-family: "Open Sans" !important;
    font-size: 20px !important;

}
</style>

