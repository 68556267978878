<template>
  <v-container fluid>
    <v-form id="searchForm" ref="searchForm" @submit.prevent="searchItems">
      <v-row dense>
        <v-col cols="3">
          <v-select
              :items="financialGroups"
              :loading="createdLoading"
              v-model="filter.financialGroupId"
              label="Grupo Financeiro"
              item-value="id"
              item-text="name"
              placeholder="Selecione o Grupo Financeiro"
              outlined
              clearable
              @change="changeFinancialGroup"
              :rules="[rules.required]" />
        </v-col>
        <v-col cols="3">
          <v-select
              :items="clients"
              :loading="clientLoading"
              v-model="filter.clientId"
              label="Cliente"
              item-value="id"
              item-text="name"
              clearable
              placeholder="Selecione o Cliente"
              outlined
          />
        </v-col>
        <v-col cols="3">
          <v-select
              :items="carriers"
              :loading="contractLoading"
              v-model="carrierIds"
              label="Operadora"
              item-value="id"
              item-text="name"
              clearable
              placeholder="Selecione a Operadora"
              outlined
              multiple
          />
        </v-col>
        <v-col cols="3">
          <v-select
              :items="contracts"
              :loading="contractLoading"
              v-model="contractIds"
              label="Contrato"
              item-value="id"
              item-text="name"
              placeholder="Selecione o Contrato"
              outlined
              clearable
              multiple
              @change="changeContract"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-select
              :items="subContracts"
              :loading="subContractLoading"
              v-model="subContractIds"
              label="SubContrato"
              item-value="id"
              item-text="name"
              clearable
              placeholder="Selecione o SubContrato"
              outlined
              multiple
          />
        </v-col>
        <v-col cols="3">
          <v-menu
              ref="menuInitialDate"
              v-model="menuInitialDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  @click="menuInitialDate = true"
                  :value="formatInitialDateInput"
                  :rules="[rules.required]"
                  label="Período Inicial"
                  readonly
                  placeholder="Data início"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
              />
            </template>
            <v-date-picker
                v-model="filter.initialDate"
                no-title
                scrollable
                @close="$refs.menuInitialDate.save(filter.initialDate)"
            />
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
              ref="menuFinalDate"
              v-model="menuFinalDate"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  @click="menuFinalDate = true"
                  :value="formatFinalDateInput"
                  :rules="[rules.required]"
                  label="Período Final"
                  readonly
                  placeholder="Data fim"
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  outlined
              />
            </template>
            <v-date-picker
                v-model="filter.finalDate"
                no-title
                scrollable
                :max="maxEndDate"
                :min="filter.initialDate"
                @close="$refs.menuFinalDate.save(filter.finalDate)"
            />
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-select
              :items="elegibilities"
              :loading="createdLoading"
              v-model="elegibilityIds"
              label="Elegibilidade"
              item-value="id"
              item-text="name"
              clearable
              placeholder="Selecione a Elegibilidade"
              outlined
              multiple
              :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          <v-select
              :items="kinships"
              :loading="createdLoading"
              v-model="kinshipIds"
              label="Grau de Parentesco"
              item-value="id"
              item-text="description"
              clearable
              placeholder="Selecione o Grau de Parentesco"
              outlined
              multiple
          />
        </v-col>
        <v-col cols="3">
          <v-select
              :items="ageGroups"
              :loading="createdLoading"
              v-model="ageGroupsIds"
              label="Faixa Etária"
              item-value="id"
              item-text="name"
              clearable
              placeholder="Selecione Faixa Etária"
              outlined
              multiple
          />
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
              large
              color="primary"
              :loading="loadingSearch"
              :disabled="loadingSearch"
              type="submit">Pesquisar</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row dense>
      <v-col cols="12" class="text-center font-weight-bold"><h2>Questionário de Saúde - Análise de Evolução</h2></v-col>
    </v-row>
    <v-row dense>
      <v-col cols="2">
        <v-card style="height: 100%" class="pt-3">
          <v-card-text class="green--text text-center">
            <span style="font-size: 40px">{{this.response.activeLives}}</span><h3>Vidas Ativas</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card style="height: 100%" class="pt-3">
          <v-card-text class="text-center">
            <span style="font-size: 40px">{{this.response.inactiveLives}}</span><h3>Vidas Inativas</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card style="height: 100%" class="pt-3">
          <v-card-text class="text-center">
            <span style="font-size: 40px">{{this.eligibleLives}}</span><h3>Vidas Elegíveis</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card style="height: 100%" class="pt-3">
          <v-card-text class="text-center">
            <span style="font-size: 40px">{{this.response.totalAnswers}}</span><h3>Respostas</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card style="height: 100%" class="pt-3">
          <v-card-text class="red--text text-center" v-if="this.goal < 70">
            <span style="font-size: 38px">{{this.goal}}%</span><h3>Meta (70%)</h3>
          </v-card-text>
          <v-card-text class="green--text text-center" v-if="this.goal >= 70">
            <span style="font-size: 38px">{{this.goal}}%</span><h3>Meta (70%)</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card style="height: 100%" class="pt-3">
          <v-card-text class="red--text text-center" v-if="this.goalLives > 0">
            <span style="font-size: 40px">{{this.goalLives}}</span><h3>Qtde de vidas para atingir a meta</h3>
          </v-card-text>
          <v-card-text class="green--text text-center" v-if="this.goalLives == 0">
            <span style="font-size: 40px">{{this.goalLives}}</span><h3>Qtde de vidas para atingir a meta</h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-spacer style="height: 20px"></v-spacer>
    <v-row dense>
      <v-col cols="12" class="text-center font-weight-bold"><h2>Distribuição de respondentes por período</h2></v-col>
    </v-row>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="6"><canvas id="answersDistribuction"></canvas></v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations} from "vuex";
import ContractService from "@/services/odoo/ContractService";
import Service from "@/services/contract/ContractService";
import {formrules} from "@/common/formrules";
import AuthService from "@/services/auth/AuthService";
import moment from "moment";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import SurveyService from "@/services/survey/SurveyService";

export default {
  name: "dashEvolution",
  data: () => ({
    filter: {
      financialGroupId: null,
      clientId: null,
      carrierIds: null,
      contractIds: null,
      subContractIds: null,
      initialDate: null,
      finalDate: null,
      elegibilityIds: null,
      kinshipIds: null,
      ageGroups: null
    },
    response: {
      activeLives: 0,
      inactiveLives: 0,
      totalAnswers: 0,
      answers: []
    },
    financialGroups: [],
    clients: [],
    carriers: [],
    contracts: [],
    subContracts: [],
    elegibilities: [{id:'T',name:'Titular'},{id:'D',name:'Dependente'}],
    kinships: [],
    ageGroups: [{id:'0-18',name:'0 - 18 anos'},{id:'19+',name:'Acima de 18 anos'}],
    carrierIds: [],
    contractIds: [],
    subContractIds: [],
    elegibilityIds: null,
    kinshipIds: null,
    ageGroupsIds: null,
    menuInitialDate: null,
    menuFinalDate: null,
    createdLoading: false,
    clientLoading: false,
    contractLoading: false,
    subContractLoading: false,
    loadingSearch: false,
    rules: formrules,
    maxEndDate: null,
    chart: null
  }),
  methods: {
    ...mapMutations(["showmsg"]),
    searchItems() {
      if(!this.searchValidate()){
        return;
      }

      this.loadingSearch = true;
      this.filter.carrierIds = this.carrierIds.length>0 ? this.carrierIds.toString() : null;
      this.filter.contractIds = this.contractIds.length>0 ? this.contractIds.toString() : null;
      this.filter.subContractIds = this.subContractIds.length>0 ? this.subContractIds.toString() : null;
      this.filter.elegibilityIds = this.elegibilityIds.length>0 ? this.elegibilityIds.toString() : null;
      this.filter.kinshipIds = this.kinshipIds && this.kinshipIds.length>0 ? this.kinshipIds.toString() : null;
      this.filter.ageGroups = this.ageGroupsIds && this.ageGroupsIds.length>0 ? this.ageGroupsIds.toString() : null;
      this._surveyService.findAllDashEvolutionByFilters(this.filter)
          .then(response => {

            this.response = response.data;
            this.chart.data = {
              labels: this.response.answers.map(row => row.label),
              datasets: [
                {
                  label: 'Vidas Ativas',
                  data: this.response.answers.map(row => row.activeLives),
                  backgroundColor: 'green',
                },
                {
                  label: 'Vidas Inativas',
                  data: this.response.answers.map(row => row.inactiveLives),
                  backgroundColor: 'red',
                }
              ]
            }

            this.chart.update();
          })
          .catch((e) => console.log(e))
          .finally(() => this.loadingSearch=false);

    },
    searchValidate(){
      if (!this.$refs.searchForm.validate()) {
        setTimeout(this.$refs.searchForm.resetValidation, 3000);
        return false;
      }

      if(moment(this.filter.initialDate, "YYYY-MM-DD").isSameOrAfter(moment(this.filter.finalDate, "YYYY-MM-DD"))){
        this.showmsg({ text: 'A data inicial maior ou igual a data final.', type: 'error' })
        return false;
      }

      if(this.elegibilityIds && this.elegibilityIds.includes("D")){
        if(!this.kinshipIds){
          this.showmsg({ text: 'O grau de parentesco é obrigatório para dependente.', type: 'error' })
          return false;
        }

        if(!this.ageGroupsIds){
          this.showmsg({ text: 'A faixa de etária é obrigatório para dependente.', type: 'error' })
          return false;
        }
      }

      return true;
    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
          .then((response) => {
            if (response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
            }
          });
    },
    changeFinancialGroup(){
      if(!this.filter.financialGroupId){
        this.filter.clientId=null;
        this.filter.contractId=null;
        this.filter.subContractId=null;
        return;
      }

      this.contractLoading = true;
      this._contractService.FindAllContractsByFinancialGroupId(this.filter.financialGroupId)
          .then(response => {
            this.contracts = response.data.records.filter(c => !c.subcontract);
            this.contractIds = this.contracts.filter(c => c.benefit_id == 'Saúde').map(c => c.id);
            this.subContracts = response.data.records.filter(c => c.subcontract && this.contractIds.includes(c.parent_contract));
            this.subContractIds = this.subContracts.filter(c => c.benefit_id == 'Saúde').map(c => c.id);
            this.contracts.forEach(c => {

              if(!this.existsIdOnArray(this.carriers,c.carrier_id.id)){
                this.carriers.push({id: c.carrier_id.id, name: c.carrier_id.xipp_commercial_name})
              }
            })
          })
          .catch(() => this.showmsg(this.defaultErrorObject))
          .finally(() => this.contractLoading=false);

      this.clientLoading=true;
      this._contractService.findClientsByFinancialGroupId(this.filter.financialGroupId)
          .then(response => {
            this.clients = response.data.records;
          })
          .catch(() => this.showmsg(this.defaultErrorObject))
          .finally(() => this.clientLoading=false);

    },
    existsIdOnArray(array,id){
      array.forEach(a => {
        if(a.id === id)
          return true;
      });
      return false;
    },
    changeContract(){

      if(!this.contractIds) {
        this.subContracts=[];
        this.subContractIds = [];
        return;
      }

      this.subContracts=[];
      this.subContractIds = [];
      this.contractIds.forEach(c => {
        this.subContractLoading=true;
        this._contractService.FindSubContracts(c)
            .then(response => {
              this.subContracts = this.subContracts.concat(response.data.records);
              this.subContractIds = this.subContracts.filter(c => c.benefit_id == 'Saúde').map(c => c.id);
            })
            .catch(() => this.showmsg(this.defaultErrorObject))
            .finally(() => this.subContractLoading=false);
      });

    },
  },
  async created() {
    this._contractService = new ContractService();
    this._service = new Service();
    this._surveyService = new SurveyService();
    const {token} = this.$route.query;
    if (token) {
      localStorage.setItem("LOCALIZED_TOKEN", token);
      localStorage.removeItem("auth_user");
      localStorage.removeItem("token");
      await this.loadJWTToken();
    }
    await this.loadJWTToken();

    this._contractService.FindFinancialGroupPermiteds()
        .then(response => {
          this.financialGroups = response.data;
        })
        .catch(() => this.showmsg({text: 'Ocorreu um erro ao carregar os grupos financeiros.', type: 'error'}));

    this._service.findAllKinship()
        .then(response => {
          this.kinships = response.data;
        })
        .catch(() => this.showmsg({text: 'Ocorreu um erro ao carregar os tipo de parentesco.', type: 'error'}));

    Chart.register(
        ArcElement,
        LineElement,
        BarElement,
        PointElement,
        BarController,
        BubbleController,
        DoughnutController,
        LineController,
        PieController,
        PolarAreaController,
        RadarController,
        ScatterController,
        CategoryScale,
        LinearScale,
        LogarithmicScale,
        RadialLinearScale,
        TimeScale,
        TimeSeriesScale,
        Decimation,
        Filler,
        Legend,
        Title,
        Tooltip
    );

    this.chart = new Chart(
        document.getElementById('answersDistribuction'),
        {
          type: 'bar',
          options:{
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true
              }
            }
          },
          data: {
            labels: this.response.answers.map(row => row.label),
            datasets: [
              {
                label: 'Vidas Ativas',
                data: this.response.answers.map(row => row.activeLives),
                backgroundColor: 'green',
              },
              {
                label: 'Vidas Inativas',
                data: this.response.answers.map(row => row.inactiveLives),
                backgroundColor: 'red',
              }
            ]
          }
        }
    );
  },
  computed: {
    formatInitialDateInput() {
      return this.filter.initialDate ? moment(this.filter.initialDate).format('DD/MM/YYYY') : '';
    },
    formatFinalDateInput() {
      return this.filter.finalDate ? moment(this.filter.finalDate).format('DD/MM/YYYY') : '';
    },
    eligibleLives() {
      return this.response.activeLives;
    },
    goal() {
      let eligibleLives = this.response.activeLives;
      if(eligibleLives==0)return 0;
      return (this.response.totalAnswers*70/((eligibleLives*70)/100)).toFixed(2);
    },
    goalLives(){
      let eligibleLives = this.response.activeLives;
      if(eligibleLives==0)return 0;
      if(this.response.totalAnswers==0) return ((eligibleLives*70)/100).toFixed(0);
      let result = (((eligibleLives*70)/100)-this.response.totalAnswers).toFixed(0);
      return result <= 0 ? 0 : result;
    }
  },
  watch: {
    'filter.initialDate'(newDate) {
      this.maxEndDate = new Date(newDate);
      this.maxEndDate.setMonth(this.maxEndDate.getMonth() + 6);
      this.maxEndDate = moment(this.maxEndDate).format("YYYY-MM-DD");

      if (this.filter.finalDate && this.filter.finalDate > this.maxEndDate) {
        this.filter.finalDate = null;
      }
    }
  }
};
</script>
