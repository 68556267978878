import ContractService from '../../services/odoo/ContractService';

let _contractService = new ContractService();

export default {
    state: {
        financialGroups: [],
        contracts: [],
        subcontracts: [],
        contract: {},
    },
    mutations: {
        setFinancialGroups(state, newValue) {
            state.financialGroups = newValue;
        },
        setContracts(state, newValue) {
            state.contracts = newValue;
        },
        setSubcontracts(state, newValue) {
            state.subcontracts = newValue;
        },
        setContract(state, newValue) {
            state.contract = newValue;
        },
    },
    actions: {
        async getContracts(store) {
            _contractService = new ContractService();
            await _contractService.FindByStatus().then((res) => {
                let contracts = [];
                res.data.records.forEach((contract) => {
                    var texto = '';
                    texto += contract.policy ? contract.policy : '';
                    texto += contract.benefit_id  ? ' - ' + contract.benefit_id : '';
                    texto += contract.carrier_id  ? '/' + contract.carrier_id.xipp_commercial_name + ' - ' : ' - ';
                    texto += contract.contract_owner_id ? (contract.contract_owner_id ? contract.contract_owner_id.legal_name : '') : ''

                    if (contract.contract_owner_id) {
                        contracts.push({
                            accountID: contract.contract_owner_id.id,
                            contractId: contract.id,
                            text: texto,
                        });
                    }
                });
                store.commit('setContracts', contracts);
            });
        },
        async getActiveContractsByFinancialGroupId(store, payload) {
            _contractService = new ContractService();

            const searchParams = new URLSearchParams();
            searchParams.append('account.financialGroup.id', payload);
            searchParams.append('subcontract', 'false');
            searchParams.append('status', 'active');

            store.commit('setContracts', []);
            await _contractService.FindAll(searchParams).then((res) => {
                let contracts = [];
                res.data.records.forEach((contract) => {
                    var texto = '';
                    texto += contract.policy ? contract.policy : '';
                    texto += contract.benefit_id ? ' - ' + contract.benefit_id : '';
                    texto += contract.carrier_id ? '/' + contract.carrier_id.xipp_commercial_name + ' - ' : ' - ';
                    texto += contract.contract_owner_id && contract.contract_owner_id.legal_name ?
                        contract.contract_owner_id.legal_name : ''

                    if (contract.contract_owner_id) {
                        contracts.push({
                            accountID: contract.contract_owner_id.id,
                            contractId: contract.id,
                            billing: contract.billing,
                            text: texto,
                        });
                    }
                });
                store.commit('setContracts', contracts);
            });
        },
        async getActiveSubContractsByParentId(store, payload) {
            _contractService = new ContractService();
            const searchParams = new URLSearchParams()
            searchParams.append('subcontract', 'true');
            searchParams.append('status', 'active');
            searchParams.append('parentContractId', payload);

            store.commit('setSubcontracts', []);
            await _contractService.FindAll(searchParams).then((res) => {
                let subcontracts = [];
                res.data.records.forEach((subcontract) => {
                    var texto = '';
                    texto += subcontract.subcontract_number ? subcontract.subcontract_number : '';
                    texto += subcontract.benefit_id ? ' - ' + subcontract.benefit_id: '';
                    texto += subcontract.carrier_id ? '/' + subcontract.carrier_id.xipp_commercial_name + ' - ' : ' - ';
                    texto += subcontract.contract_owner_id ? (subcontract.contract_owner_id ? subcontract.contract_owner_id.legal_name : '') : ''

                    if (subcontract.contract_owner_id) {
                        subcontracts.push({
                            accountID: subcontract.contract_owner_id.id,
                            contractId: subcontract.id,
                            text: texto,
                        });
                    }
                });
                store.commit('setSubcontracts', subcontracts);
            });
        },
        async getFinancialGroups(store) {
            _contractService = new ContractService();
            await _contractService.FindAllFinancialGroup().then((res) => {
                let financialGroups = res.data.records
                if(financialGroups.length){
                    financialGroups.forEach((item) => {
                        if(!item.name) {
                            item.name = '-';
                        }
                    });
                    financialGroups.sort((financialGroupA, financialGroupB) => {
                        return financialGroupA.name.localeCompare(financialGroupB.name);
                    });

                    store.commit('setFinancialGroups', financialGroups);
                }
            });
        },
        async getContractById(store, id) {
            _contractService = new ContractService();
            await _contractService.FindById(id).then((res) => {
                if(res.data.records && res.data.records.length) {
                    let contract = res.data.records[0];
                    var texto = '';
                    texto += contract.policy ? contract.policy : '';
                    texto += contract.benefit_id ? ' - ' + contract.benefit_id : '';
                    texto += contract.carrier_id
                        ? '/' + contract.carrier_id.xipp_commercial_name + ' - ' : ' - ';
                    texto += contract.contract_owner_id ? (contract.contract_owner_id ? contract.contract_owner_id.legal_name : '') : ''

                    store.commit('setContract', {
                        accountID: contract.contract_owner_id.id,
                        contractId: contract.id,
                        text: texto,
                    });
                }
            });
        }
    }
};
