<template>
    <div v-infinite-scroll="reachedEnd" :infinite-scroll-disabled="disabled" :infinite-scroll-distance="minimumDistance" :infinite-scroll-immediate-check="false">
        <slot />
    </div>
</template>

<script>

export default {
    name: 'ProgressiveLoader',
    props: {
        disabled: {
            type: Boolean,
            required: true,
        },
        minimumDistance: {
            type: Number,
            required: false,
            default: 350,
        },
    },
    methods: {
        reachedEnd() {
            if (!this.disabled) {
                this.$emit('reachedEnd');
            }
        },
    },
};

</script>
