import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 font-weight-bold",staticStyle:{"color":"var(--v-primary-base)"}},[_c(VIcon,{staticClass:"pr-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VCardText,[_vm._v(_vm._s(_vm.text))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticStyle:{"color":"var(--v-primary-base)"},attrs:{"color":"white"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmDialog()}}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }