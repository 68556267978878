/* eslint-disable */

import HttpService from '../HttpService'

export default class BIService {
  constructor() {

    this._httpService = new HttpService('/security');
  }

  async getReport() {
    return await this._httpService.get(`/power-bi`);
  }

}