<template>
  <v-container fluid>
    <PreviousPage />
    <ConfigureNotificationForm :id="id" />
  </v-container>
</template>

<script>
import PreviousPage from '../../components/structure/previousPage';
import ConfigureNotificationForm from "../../components/notification/configureNotificationForm"

export default {
  name: "configureNotification",
  components: { PreviousPage, ConfigureNotificationForm },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  }
};

</script>
