<template>
  <v-navigation-drawer
    v-model="menul"
    :mini-variant="menu.leftmini"
    :mini-variant-width="80"
    class="scrollbarMenu customShadow"
    floating
    app
    hide-overlay
    dark
    color="primary"
    :mobile-break-point="window.mobileSize"
    v-if="!this.$route.meta.hideMenu"
  >
    <v-list class="layout column">
      <v-col cols="12" class="text-start pt-1">
        <img
          src="/img/icons/logos/xipp-rh-protegido-logo-redondo.png"
          :style="menu.leftmini ? 'width: 50px' : 'width: 70px'"
        />
        <img
            src="/img/icons/logos/xipp_logo_horizontal_branco_fundo_transparente.png"
            :style="menu.leftmini ? 'margin-left: -10px; width: 70px' : 'margin-left: -25px; width: 170px;'"/>
      </v-col>

      <div
        class="spaceLeftDrawer"
        v-for="(item, index) in items"
        :key="item.title + '_' + index"
      >
        <v-list-group
          v-if="item.type === 'EXPAND'"
          :key="item.id"
          no-action
          color="secundary"
        >
          <template v-slot:activator>
            <v-list-item-avatar :title="item.title">
              <v-icon id="icon_menu" class="font-weight-bold">{{
                item.icon
              }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-title class="font-weight-bold">{{
              item.title
            }}</v-list-item-title>
          </template>

          <v-list-item
            :class="
              currentMenu() && subMenu.id === currentMenu().id
                ? 'selectedItem'
                : ''
            "
            @click="link(subMenu)"
            v-for="subMenu in item.subMenus"
            :key="subMenu.id"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="
                  currentMenu() && subMenu.id === currentMenu().id
                    ? 'menuLeft primary--text'
                    : 'menuLeft'
                "
                >{{ subMenu.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          :class="
            currentMenu() && item.id === currentMenu().id ? 'selectedItem' : ''
          "
          @click="link(item)"
          v-else
        >
          <v-list-item-avatar :title="item.title">
            <v-icon
              id="icon_menu"
              :class="
                currentMenu() && item.id === currentMenu().id
                  ? 'primary--text'
                  : ''
              "
              >{{ item.icon }}</v-icon
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="menuLeft"
              :class="
                currentMenu() && item.id === currentMenu().id
                  ? 'menuLeft primary--text'
                  : 'menuLeft'
              "
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </div>

      <!-- <div class="ma-auto" /> -->
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <label class="white--text" style="font-size: small"
          >Versão: {{ appBuildVersion }}</label
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

export default {
  name: "MenuLeft",
  data: () => ({}),
  methods: {
    ...mapMutations(["updateMenuState", "setKeyValue", "setCurrentMenu"]),
    ...mapGetters(["isMobileSize", "getKeyValue"]),
    ...mapState(["currentMenu"]),
    link(item) {
      if (
        item &&
        (item.title === "Gestão de Riscos" ||
          item.title === "Gestão de Riscos PRT")
      ) {
        this.createLogSentryFromMetabase(item);
      }

      if (
        item &&
        (item.title === "Drive" ||
          item.title === "Qualidade de Vida" ||
          item.title === "Drive Config" ||
          item.title === "Regras Drive")
      ) {
        this.createLogSentryFromDrive(item);
      }

      if (this.$store.state.showingTerms) {
        this.$store.commit("showmsg", {
          text: "Aceite o termo para acessar",
          type: "error",
        });
        return;
      }

      this.setCurrentMenu(item);

      switch (item.type) {
        case "EXTERNAL_URL":
          this.menuTypeExternalURL(item);
          break;
        case "CAROUSEL":
          this.menuTypeCarousel(item);
          break;
        default:
          this.menuTypeRoute(item);
          break;
      }

      if (this.isMobileSize() && this.menu.left)
        this.updateMenuState({ left: false });
    },
    menuTypeRoute(item) {
      if (this.$route.name == item.link) {
        this.$router.go();
      } else {
        this.$router.push({
          name: item.link,
          params: {
            menuIdParam: item.id,
          },
        });
      }
    },
    menuTypeExternalURL(item) {
      if (!item.target) item.target = "SELF";

      const titleParam = item.title;
      const urlParam = item.link;
      const queryParam = item.options ? eval(item.options) : "";
      const classParam = item.clazz;
      const styleParam = item.style;
      const menuIdParam = item.id;
      const iconParam = item.icon;

      switch (item.target) {
        case "SELF":
          this.setKeyValue(this.computedGetKeyValue + 1);
          this.$router.push({
            name: "externalUrl",
            params: {
              menuIdParam: menuIdParam,
              titleParam: titleParam,
              urlParam: urlParam,
              queryParam: queryParam,
              classParam: classParam,
              styleParam: styleParam,
              iconParam: iconParam,
            },
            query: {
              id: menuIdParam,
            },
          });
          break;
        case "BLANK":
          parent.window.open(`${urlParam}${queryParam}`);
          break;
        case "PARENT":
          parent.window.location = `${urlParam}${queryParam}`;
          break;
      }
    },
    menuTypeCarousel(item) {
      const menuIdParam = item.id;

      this.$router.push({
        name: "carousel",
        params: {
          menuIdParam: menuIdParam,
          items: item.subMenus,
          parentItem: item,
        },
        query: {
          id: menuIdParam,
        },
      });
    },
    createLogSentryFromMetabase(item) {
      const session = this.$util.getSession();

      if (session) {
        let menuTitle = null;
        if (item && item.title) {
          menuTitle = item.title.replaceAll(" ", "_").toLowerCase();
        }

        let firstTabTitle = null;
        if (item && item.subMenus.length && item.subMenus[0].title) {
          firstTabTitle = item.subMenus[0].title
            .replaceAll(" ", "_")
            .toLowerCase();
        }

        let initialMenuTab = null;
        if (menuTitle && firstTabTitle) {
          initialMenuTab = menuTitle + "_" + firstTabTitle;
        }

        if (initialMenuTab) {
          Sentry.setTag("usuario", session.email);
          Sentry.captureMessage(initialMenuTab);
        }
      }
    },
    createLogSentryFromDrive(item) {
      const session = this.$util.getSession();

      if (session) {
        let menuTitle = null;
        if (item && item.title) {
          menuTitle = item.title.replaceAll(" ", "_").toLowerCase();
        }

        if (menuTitle) {
          Sentry.setTag("usuario", session.email);
          Sentry.captureMessage(menuTitle);
        }
      }
    },
  },
  computed: {
    ...mapState(["menu", "window", "session"]),
    items() {
      return this.session.menu;
    },
    computedGetKeyValue() {
      return this.getKeyValue()
    },
    menul: {
      get: function () {
        return this.menu.left;
      },
      set: function (value) {
        this.updateMenuState({ left: value });
      },
    },
    appBuildVersion() {
      return process.env.VUE_APP_BUILD_VERSION;
    },
  },
  mounted() {
    this.updateMenuState(this.isMobileSize() ? {} : { left: true });
  },
};
</script>

<style lang="scss" scoped>
.selectedItem {
  background-color: #5fd3c7;
  border-radius: 5px 0px 0px 5px;
}

.spaceLeftDrawer {
  margin-left: 15px;
}

.customShadow {
  box-shadow: 0.5px 0px 2.6px rgba(0, 0, 0, 0.037),
    1.3px 0px 6.7px rgba(0, 0, 0, 0.053), 2.7px 0px 13.6px rgba(0, 0, 0, 0.067),
    5.5px 0px 28.1px rgba(0, 0, 0, 0.083), 15px 0px 77px rgba(0, 0, 0, 0.12);
}
</style>
