<template>
  <v-container style="overflow: auto !important;" fluid grid-list-md class="px-2">
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"/>
    </v-overlay>
    <v-card-title>
      <v-spacer/>
      <v-btn color="primary" dark icon @click="$router.push(`${urlPublish}/0`)">
        <v-icon>fas fa-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <DrawerFilterer
        open
        title="Publicações"
        :filters="filters"
        :data="items"
        @queryUpdate="loadItemupdate"
        @filtersRemoved="loadInitialItens"
        @clearFilters="loadInitialItens"
      />
      <v-btn
          fab
          bottom
          right
          fixed
          dark
          small
          color="primary"
          height="56"
          width="56"
          @click="toggleFilterDrawer"
      >
        <v-icon dark>fas fa-filter</v-icon>
      </v-btn>
      <v-flex xs12>
        <ProgressiveLoader :disabled="busy" @reachedEnd="getTimeline">
          <v-card>
            <v-card-title class="text--">
              Publicações
            </v-card-title>
            <v-data-table
                id="timeline-listar"
                loading-text="Carregando publicações..."
                disable-sort
                :items-per-page="25"
                hide-default-footer
                :headers="headers"
                :items="filteredItems"
                :loading="loading"
                item-key="id"
                @click:row="(item) => handleItemSelection(item)"
            >
              <template v-slot:header></template>
              <template v-slot:no-data>
                <v-alert :value="true" type="info" v-show="!loading" outlined
                >Nenhum item foi encontrado
                </v-alert
                >
              </template>
            </v-data-table>
          </v-card>
        </ProgressiveLoader>
      </v-flex>
    </v-layout>
    <template v-else>
      <FabFilterer
        title="Publicações"
        :filters="filters"
        :data="items"
        @queryUpdate="loadItemupdate"
        @filtersRemoved="loadInitialItens"
        @clearFilters="loadInitialItens"
      />
      <ProgressiveLoader :disabled="busy" @reachedEnd="getTimeline">
        <v-data-iterator
            hide-default-footer
            hide-default-header
            disable-pagination
            :items="filteredItems"
        >
          <template v-slot:no-data>
            <v-alert :value="false" type="info" v-show="!loading" outlined
            >Nenhum item foi encontrado
            </v-alert
            >
          </template>
          <template v-slot:default="props">
            <v-row v-for="item in props.items" :key="item.id">
              <v-col cols="12">
                <v-card
                    @click="handleItemSelection(item)"
                    class="elevation-5 pb-0"
                >
                  <v-card-text class="pb-0">
                    <v-row dense class="mb-4">
                      <template v-for="header in headers">
                        <v-col :cols="header.cols" :key="header.value">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="black--text font-weight-bold"
                            >{{ header.text }}
                            </v-col
                            >
                            <v-col
                                cols="12"
                                :class="
                                (header.bold ? 'font-weight-bold' : '') +
                                ' silver--text pa-0'
                              "

                            >
                              {{ item[header.value] }}
                            </v-col
                            >
                          </v-row>
                        </v-col>
                      </template>
                      <v-col
                          cols="12"
                          class="d-flex primary--text justify-center text-sm-center mb-1"
                      >
                        <label class="primary--text">
                          Clique para detalhar
                        </label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </ProgressiveLoader>
    </template>
    <v-pagination
        :disabled="busy"
        v-model="page"
        :length="totalPages"
        :total-visible="10"
        @input="loadItemsWithCount(search)"
    ></v-pagination>
  </v-container>
</template>

<script>
/* eslint-disable */
import {mapMutations, mapGetters, mapState} from "vuex";
import FabFilterer from "../../../components/fab-filterer";
import DrawerFilterer from "../../../components/drawer-filterer";
import ProgressiveLoader from "../../../components/progressive-loader";
import SocialNetworkService from "../../../services/socialnetwork/SocialNetworkService";
import SecurityService from "../../../services/security/SecurityService";
import moment from "moment";
import AuthService from "../../../services/auth/AuthService";

export default {
  name: "PostsApp",
  components: {FabFilterer, DrawerFilterer, ProgressiveLoader},
  data: () => ({
    items: [],
    filters: [],
    filteredItems: [],
    loading: true,
    search: "",
    busy: true,
    totalPages: 1,
    page: 1,
    sfUser: null,
    entities: [],
    urlPublish: '/publicacao'
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapState(["window"]),
    headers() {
      const baseHeaders = [
        {text: "Código", value: "id", cols: "6"},
        {text: "Tipo", value: "feedItemType", cols: "6"},
        {text: "Titulo", value: "internalDescription", cols: "12"},
        {text: "Status", value: "feedItemStatus", cols: "6"},
        {text: "Publicação", value: "dataPublicacao", cols: "6"},
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({text: "", value: "", sortable: false, cols: ""});
      }
      return baseHeaders;
    },
  },
  methods: {
    ...mapMutations(["showmsg"]),
    loadFilters() {
      this.filters.push({
        name: "Título",
        key: "title",
        active: false,
        allowClose: false,
        types: [
          {
            name: "Título",
            value: "",
            type: "input",
            active: true
          }
        ]
      });

      if(this.sfUser) {
        this.filters.push({
          name: "Entidade",
          key: "entityId",
          active: false,
          allowClose: false,
          types: [
            {
              name: "Nome da Entidade",
              value: "",
              type: "combobox",
              items: [],
              active: true,
            },
          ],
        });
      }

      this.filters.push({
            name: "Data de Criação",
            key: "creationDate",
            active: false,
            allowClose: false,
            types: [
              {
                name: "Data de Criação",
                value: "",
                type: "date",
                mask: '##/##/####',
                active: true,
              },
            ],
          },
          {
            name: "Data de Publicação",
            key: "publishDate",
            active: false,
            allowClose: false,
            types: [
              {
                name: "Data de Publicação",
                value: "",
                type: "date",
                mask: '##/##/####',
                active: true,
              },
            ],
          },
          {
            name: "Tipo",
            key: "feedItemType",
            types: [
              {
                name: "Banner",
                value: "BANNER",
                type: "select",
                active: false
              },
              {
                name: "Dica",
                value: "TIP",
                type: "select",
                active: false
              },
              { name: "Post",
                value: "POST",
                type: "select",
                active: false
              }
            ]
          },
          {
            name: "Status",
            key: "status",
            types: [
              {
                name: "Arquivado",
                value: "ARCHIVED",
                type: "select",
                active: false
              },
              {
                name: "Publicado",
                value: "PUBLISHED",
                type: "select",
                active: false
              },
              { name: "Rascunho",
                value: "DRAFT",
                type: "select",
                active: false
              }
            ]
          });
    },
    addItem(obj) {
      this.items.push(obj);
      this.filteredItems.push(obj);
    },
    setFilteredItems(newItems) {
      this.clearListItems();
      for (const item of newItems) {
        this.addItem(item);
      }
    },
    formatDate(data) {
      if (!data) return ''
      let datehoracompleta = data.split("T");
      let datehora = datehoracompleta[0].split("-");
      if (datehora.length == 3) {
        return `${datehora[2]}/${datehora[1]}/${datehora[0]}`;
      }
    },
    handleItemSelection(item) {
      this.$router.push(`${this.urlPublish}/${item.id}`);
    },
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    loadItemupdate(data) {
      if (data) {
        data = data.trim();

        let getUser = this.$util.getSession();
        // parametros do requests
        var rq = [];

        rq.push({chave: "idUser", valor: getUser.id.toString()});

        //recuperar parametros a partir da query
        const filters = data.trim().split("andd");

        //percorrer query e montar os parametros
        filters.forEach((fil) => {
          fil = fil.trim();

          // criar parametro
          let q = fil.split("in");
          if (q[0] && q[0].length > 0) {
            // montar chave e valor
            rq.push({chave: q[0], valor: q[1]});
          }
        });

        // tratando os parametrosrequest
        rq.forEach((res) => {
          res.chave = res.chave.trim();
          res.valor = res.valor.trim();
          res.valor = res.valor.replace("(", "");
          res.valor = res.valor.replace(")", "");

          // replace so tira um por vez
          for (let index = 0; index < 20; index++) {
            res.valor = res.valor.replace("'", "");
          }
        });

        // passar parametros de filtros
        let path = "";
        rq.forEach((parametro) => {
          if (path && path.length > 0) {
            path += "&";
          } else {
            path = "?";
          }

          if(parametro.chave == 'creationDate'){
            if(!moment(parametro.valor, "DD/MM/YYYY", true).isValid()){
              this.showmsg({text: "A Data de Criação do filtro não é válida.", type: "error"})
              return;
            }else{
              parametro.valor = moment(parametro.valor, "DD/MM/YYYY").format("YYYY-MM-DD");
            }
          }

          if(parametro.chave == 'publishDate'){
            if(!moment(parametro.valor, "DD/MM/YYYY", true).isValid()){
              this.showmsg({text: "A Data de Publicação do filtro não é válida.", type: "error"})
              return;
            }else{
              parametro.valor = moment(parametro.valor, "DD/MM/YYYY").format("YYYY-MM-DD");
            }
          }

          path += parametro.chave;
          path += "=" + parametro.valor;
        });

        this.page = 1;
        this.search = path;
        this.loadItemsWithCount(path);
      }
    },

    async loadItemsWithCount(params = "") {
      let comp = this;
      this.busy = true;
      this.loading = true;
      //Object.assign(this.$data, this.$options.data());
      if (params && params.length > 0) {
        let stories = [];
        params = params + `&page=${this.page - 1}`;
        this._socialNetworkService
          .findFeedItemUserAdminFilter(params)
          .then((_res) => {
            this.totalPages = _res.data.totalPages;
            if (_res.data && _res.data.content.length > 0) {
              const feedItens = _res.data.content;

              feedItens.forEach(elem => {
                let store = {
                  id: elem.id,
                  entity: elem.entity_name,
                  entityId: elem.entity_id,
                  contents: elem.contents ? elem.contents.replaceAll('\n', '<br>') : '',
                  alwaysShow: elem.always_show,
                  internalDescription: elem.internal_description ? elem.internal_description : '',
                  feedItemType: comp.feedItemTypeLabel(elem.feed_item_type),
                  feedItemStatus: comp.feedItemStatusLabel(elem.feed_item_status),
                  applicationFilter: comp.appFilterLabel(elem.application_filter),
                  dataCriacao: comp.formatDate(elem.creation_date),
                  dataExpiracao: comp.formatDate(elem.expiration_date),
                  dataPublicacao: comp.formatDate(elem.publish_date),
                  contentsSize:
                    elem.contents && elem.contents.length > 150
                      ? elem.contents.substring(0, 149)
                      : elem.contents,
                };

                stories.push(store);
              })
            }
          })
          .catch(() => {
            this.showmsg({
              text: "Erro ao carregar as publicações filtradas.",
              type: "error",
            });
            this.totalPages = 0;
          })
          .finally(() => {
            if (stories && stories.length > 0) {
              this.setFilteredItems(stories);
            } else {
              this.clearListItems();
            }
            this.busy = false;
            this.loading = false;
          });
      } else {
        this.clearListItems();
        this.getTimeline();
      }
    },

    clearListItems() {
      this.items = [];
      this.filteredItems = [];
    },

    async loadInitialItens() {
      this.search = "";
      this.page = 1;
      this.totalPages = 1;
      await this.loadItemsWithCount();
    },
    feedItemTypeLabel(enumVal) {
      if (enumVal === 'POST') {
        return 'Post'
      } else if (enumVal === 'TIP') {
        return 'Dica'
      } else if (enumVal === 'MESSAGE') {
        return 'Mensagem'
      } else if (enumVal === 'BANNER') {
        return 'Banner'
      }
      return ''
    },
    feedItemStatusLabel(enumVal) {
      if (enumVal === 'PUBLISHED') {
        return 'Publicado'
      } else if (enumVal === 'DRAFT') {
        return 'Rascunho'
      } else if (enumVal === 'ARCHIVED') {
        return 'Arquivado'
      }
      return ''
    },
    appFilterLabel(enumVal) {
      if (enumVal === 'MUNDO_A_DENTRO') {
        return 'Mundo a Dentro'
      } else if (enumVal === 'MUNDO_A_FORA') {
        return 'Mundo a Fora'
      } else if (enumVal === 'TODO_MUNDO') {
        return 'Todo Mundo'
      }
      return ''
    },
    getTimeline() {
      let comp = this;
      if (this.page <= this.totalPages) {
        let getUser = this.$util.getSession();
        let stories = [];
        this.busy = true;
        this.loading = true;
        this._socialNetworkService
            .findFeedItemUserAdmin(getUser.id, 0, this.page - 1)
            .then((_res) => {
              if (_res.data && _res.data.totalElements > 0) {
                this.totalPages = _res.data.totalPages;
                _res.data.content.forEach(function (elem) {
                  let store = {
                    id: elem.id,
                    entity: elem.entity_name,
                    entityId: elem.entity_id,
                    contents: elem.contents,
                    alwaysShow: elem.always_show,
                    internalDescription: elem.internal_description,
                    dataCriacao: comp.formatDate(elem.creation_date),
                    dataExpiracao: comp.formatDate(elem.expiration_date),
                    dataPublicacao: comp.formatDate(elem.publish_date),
                    feedItemType: comp.feedItemTypeLabel(elem.feed_item_type),
                    feedItemStatus: comp.feedItemStatusLabel(elem.feed_item_status),
                    applicationFilter: comp.appFilterLabel(elem.application_filter),
                    contentsSize:
                        elem.contents && elem.contents.length > 150
                            ? elem.contents.substring(0, 149)
                            : elem.contents,
                  };

                  stories.push(store);
                });
              } else {
                this.busy = true;
                this.loading = false;
              }
            })
            .catch((ex) => {
              this.showmsg({
                text: "Erro ao carregar as publicações!",
                type: "error",
              })
            })
            .finally(() => {
              if (stories && stories.length > 0) {
                for (const item of stories) {
                  this.addItem(item);
                }
                this.busy = false;
                this.loading = false;
              }
            });
      } else {
        this.busy = true;
      }
    },
    async loadJWTToken() {
      this._xippAuthService = new AuthService();
      await this._xippAuthService.GetTokenFull()
        .then((response) => {
          if(response && response.data) {
              localStorage.setItem("LOCALIZED_TOKEN", response.data.token);
          }
        });
    },
    async loadStorageSalesforce() {
      const {token} = this.$route.query;
      if (token) {
        localStorage.setItem("LOCALIZED_TOKEN", token)
        localStorage.setItem("LOCALIZED_ORIGIN", 'salesforce')
        localStorage.removeItem("auth_user");
        localStorage.removeItem("token");
        await this.loadJWTToken();
        this.sfUser = true;
      }
    },
    loadUrlCases(){
      const {hideMenu} = this.$route.meta
      if(hideMenu){
        this.urlPublish = '/publicacao-salesforce'
      }
    },
    async loadUserData(){
      this._securityService.getCurrent().then(response => {
        let session = this.$util.getSession();
        session.id = response.data.user.id;
        this.$util.setSession(session);
      })
    },
    async loadEntities(){
      await this._socialNetworkService.GetAllEntities()
        .then(response => {
          if(response.data){
            const allEntities = response.data
            if(allEntities.length){
              allEntities.forEach(entity => {
                this.entities.push({text: entity.name, value: entity.id})
              })

              if(this.filters.length) {
                this.filters[1].types[0].items = this.entities;
              }
            }
          }
        })
        .catch(() => {
          this.showmsg({
            text: "Erro ao carregar as entidades do filtro.",
            type: "error",
          })
      })
    }
  },
  async created() {
    this.loading = true;
    await this.loadStorageSalesforce();
    this.loading = true;

    this._securityService = new SecurityService();
    this._socialNetworkService = new SocialNetworkService();
    this.loadFilters();
    await this.loadEntities();
    await this.loadUserData();
    await this.loadInitialItens();
    this.loadUrlCases();
  },
};
</script>

<style>
#atendimento-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}
</style>
