/* eslint-disable */
import HttpService from './HttpService'

export default class ContentVersionService {
  constructor() {
    this._httpService = new HttpService();
  }

  async GetAllFilesFromStorage(params) {
    return await this._httpService.get('/api/odoo/dms_file/getAllFilesFromStorage', params);
  }

  async GetDownloadLinkByFileId(fileId) {
    return await this._httpService.get('/api/odoo/dms_file/getDownloadLinkByFileId', { fileId });
  }
}
