<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-autocomplete
                v-model="assessmentTopicsLoadedSelected"
                :items="assessmentTopicsLoaded"
                return-object
                item-text="topic.name"
                label="Tópicos Cadastrados"
                placeholder="Selecione um Tópico"
                outlined
                :loading="loadingTopics"
                :disabled="loadingTopics"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-btn
                class="mt-1"
                large
                :disabled="!assessmentTopicsLoadedSelected"
                :dark="!!assessmentTopicsLoadedSelected"
                color="primary" width="120" height="46"
                @click="addToAssessmentTopicsSelecteds()"
            >
              <span>Adicionar</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            grow
            centered
            show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-form-list" @click="clearAssessmentTopicFields(true)">Tópicos</v-tab>
          <v-tab href="#tab-form-new">Cadastrar Novo Tópico</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item :value="'tab-form-list'">
            <v-row class="pt-2">
              <v-col cols="12">
                <v-data-table
                    :loading="busy"
                    :height="assessmentTopicsModel && assessmentTopicsModel.length >= 5 ? '300px': ''"
                    loading-text="Carregando..."
                    class="elevation-1 mx-1 mb-1"
                    style="overflow: auto !important;"
                    :items="assessmentTopicsModel"
                    :headers="topicHeaders"
                    hide-default-footer
                    disable-pagination
                    item-key="ordination"
                >

                  <template v-slot:item.topic.questions="{ item }">
                    <span>
                      {{ item.topic.questions ? item.topic.questions.length : 0 }}
                    </span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-menu
                        transition="slide-y-transition"
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="grey"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="managerAssessmentTopicIndex(item)">
                          <v-list-item-icon>
                            <v-icon>fas fa-list-ol</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Gerenciar Questões</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="editAssessmentTopic(item)">
                          <v-list-item-icon>
                            <v-icon>fas fa-edit</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Editar Tópico</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item @click="openDialog(item)">
                          <v-list-item-icon>
                            <v-icon>fas fa-trash</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Excluir Tópico</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item :value="'tab-form-new'">
            <v-row class="pt-2">
              <v-col cols="12">
                <fieldset class="px-5">
                  <legend class="px-1">
                    Cadastro de Novo Tópico
                  </legend>

                  <v-row class="mt-4">
                    <v-col cols="12" md="2">
                      <v-text-field
                          v-model="assessmentTopic.ordination"
                          label="Ordem"
                          outlined
                          type="number"
                          :rules="[formrules.required, formrules.valueGreaterThanZero]"
                      />
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-text-field
                          v-model="assessmentTopic.topic.name"
                          label="Nome do Tópico"
                          maxlength="255"
                          :rules="[formrules.required]"
                          outlined
                      />
                    </v-col>
                  </v-row>

                  <v-row justify="end" class="pt-0 mt-0">
                    <v-col class="text-right" cols="6" md="2">
                      <v-btn
                          class="mt-1"
                          large
                          :disabled="isEmptyAssessmentTopic"
                          :dark="!isEmptyAssessmentTopic"
                          color="primary" width="120" height="46"
                          @click="managerAssessmentTopic()"
                      >
                        <span>{{indexOfTopicEditing === - 1 ? 'Adicionar': 'Alterar'}}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </fieldset>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <dialogDeleteConfirmation :show="showDialog" @confirmDialog="deleteAssessmentTopic"></dialogDeleteConfirmation>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {formrules} from "../../common/formrules";
import DialogDeleteConfirmation from "./dialogDeleteConfirmation.vue";
import SurveyService from "../../services/survey/SurveyService";
import loading from "../structure/Loading.vue";

export default {
  name: "managementTopics",
  components: {
    DialogDeleteConfirmation,
  },
  data: () => ({
    formrules,
    selectedTab: '',
    topicHeaders: [
      { text: "Ordem", value: "ordination", width: '10%' },
      { text: "Nome", value: "topic.name", width: '55%' },
      { text: "Total de Questões", value: "topic.questions", width: '30%' },
      { text: "Ações", value: "actions", sortable: false, align: "right", width: '5%', }
    ],
    indexOfTopicEditing: -1,
    showTooltipTopic: false,
    assessmentTopicsTemp: [],
    assessmentTopicsLoadedSelected: null,
    loadingTopics: false,
    assessmentTopicsLoaded: [],
    assessmentTopicsModel: [],
    assessmentTopic: {
      ordination: 1,
      topic: {
        id: 0,
        name: null,
        description: null,
        questions: [],
      },
    },
    showDialog: false,
    messageExistsWithName: 'Já existe um tópico cadastrado com este nome.',
    messageExistsWithOrder: 'Já existe um tópico cadastrado com esta ordem.',
    messageExistsLoaded: 'Já existe um tópico cadastrado com os dados a partir desta lista.',
    messageRequiredFields: 'Preencha os dados do tópico corretamente.',
    typeMessage: 'error',
  }),
  props: {
    assessmentParam: {
      type: Object,
    },
    busy: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    assessmentParam(newValue) {
      if(newValue) {
        this.assessmentTopicsModel = newValue.topics;
      }
    }
  },
  methods: {
    loading() {
      return loading
    },
    ...mapMutations(['showmsg']),
    ...mapActions(['setAssessmentTopics', 'setAsessmentTopicIndex']),
    resetFormValidation() {
      this.$emit('resetFormValidation');
    },
    managerAssessmentTopic() {
      if(!this.assessmentTopic || !this.assessmentTopic.ordination || !this.assessmentTopic.topic.name) {
        this.showmsg({ text: this.messageRequiredFields, type: this.typeMessage });
        return;
      }

      const assessmentTopicItem = {
        ordination: parseInt(this.assessmentTopic.ordination),
        topic: {
          id: this.assessmentTopic.topic.id,
          name: this.assessmentTopic.topic.name,
          description: this.assessmentTopic.topic.description,
          questions: this.assessmentTopic.topic.questions
        }
      }

      if(this.indexOfTopicEditing === -1) {
        if(this.assessmentTopicsModel.some((item) => item.topic.name === assessmentTopicItem.topic.name)){
          this.showmsg({ text: this.messageExistsWithName, type: this.typeMessage });
          return;
        } else if(this.assessmentTopicsModel.some((item) => item.ordination === assessmentTopicItem.ordination)) {
          this.showmsg({ text: this.messageExistsWithOrder, type: this.typeMessage });
          return;
        }

        this.assessmentTopicsModel.push(assessmentTopicItem);
      } else {
        if(this.assessmentTopicsTemp.some((item) => item.topic.name === assessmentTopicItem.topic.name)){
          this.showmsg({ text: this.messageExistsWithName, type: this.typeMessage });
          return;
        } else if(this.assessmentTopicsTemp.some((item) => item.ordination === assessmentTopicItem.ordination)) {
          this.showmsg({ text: this.messageExistsWithOrder, type: this.typeMessage });
          return;
        }

        this.assessmentTopicsModel[this.indexOfTopicEditing] = assessmentTopicItem;
      }

      this.orderAssessmentTopics();
      this.clearAssessmentTopicFields();
      this.setAssessmentTopics(this.assessmentTopicsModel);
    },
    clearAssessmentTopicFields(isTabClick = false) {
      this.indexOfTopicEditing = -1;
      if(!isTabClick){
        this.assessmentTopic.ordination = parseInt(this.assessmentTopic.ordination) + 1;
      }

      this.assessmentTopic.topic.id = 0;
      this.assessmentTopic.topic.name = null;
      this.assessmentTopic.topic.description = null;
      this.assessmentTopic.topic.questions = [];
      this.assessmentTopicsTemp = [];
      this.resetFormValidation();
    },
    orderAssessmentTopics() {
      if(this.assessmentTopicsModel && this.assessmentTopicsModel.length) {
        this.assessmentTopicsModel.sort((a, b) => (a.ordination > b.ordination) ? 1 : -1);
      }
    },
    editAssessmentTopic(item) {
      if(item && this.assessmentTopicsModel && this.assessmentTopicsModel.length) {
        this.indexOfTopicEditing = this.assessmentTopicsModel.indexOf(item);
        this.selectedTab = 'tab-form-new';

        this.assessmentTopic.ordination = item.ordination;
        this.assessmentTopic.topic.id = item.topic.id;
        this.assessmentTopic.topic.name = item.topic.name;
        this.assessmentTopic.topic.description = item.topic.description;
        this.assessmentTopic.topic.questions = item.topic.questions;

        this.assessmentTopicsTemp = Object.assign([], this.assessmentTopicsModel);
        this.assessmentTopicsTemp.splice(this.indexOfTopicEditing, 1);
      }
    },
    openDialog(item) {
      if(item && this.assessmentTopicsModel && this.assessmentTopicsModel.length) {
        this.indexOfTopicEditing = this.assessmentTopicsModel.indexOf(item);
        this.showDialog = !this.showDialog;
      }
    },
    deleteAssessmentTopic() {
      if(this.indexOfTopicEditing !== -1) {
        this.assessmentTopicsModel.splice(this.indexOfTopicEditing, 1);

        if(this.assessmentTopicIndex == this.indexOfTopicEditing) {
          this.setAsessmentTopicIndex(-1);
        }

        this.setAssessmentTopics(this.assessmentTopicsModel);
        this.indexOfTopicEditing = -1;
        this.showDialog = !this.showDialog;

        if(this.assessmentTopic.ordination > 0 && this.assessmentTopicsModel.length) {
          const maxValue = Math.max(...this.assessmentTopicsModel.map(item => item.ordination));
          if(maxValue) {
            this.assessmentTopic.ordination = maxValue + 1;
          }
        } else {
          this.assessmentTopic.ordination = 1;
        }
      }
    },
    managerAssessmentTopicIndex(item) {
      if(item && this.assessmentTopicsModel && this.assessmentTopicsModel.length) {
        this.setAsessmentTopicIndex(this.assessmentTopicsModel.indexOf(item));
        this.$emit('scrollTo');
      }
    },
    addToAssessmentTopicsSelecteds() {
      if(!this.assessmentTopicsLoadedSelected) {
        this.showmsg({ text: 'Nenhum tópico foi selecionado.', type: 'error' });
        return;

      } else {
        let maxOrder = 1;

        if(this.assessmentTopicsModel && this.assessmentTopicsModel.length) {
          const topicOrders = this.assessmentTopicsModel.map((item) => { return item.ordination; });
          maxOrder = Math.max(...topicOrders);
          maxOrder++;
        }

        const questions = Object.assign([], this.assessmentTopicsLoadedSelected.topic.questions);

        const assessmentTopicItem = Object.assign({}, {
          ordination: maxOrder,
          topic: {
            id: this.assessmentTopicsLoadedSelected.topic.id,
            name: this.assessmentTopicsLoadedSelected.topic.name,
            description: this.assessmentTopicsLoadedSelected.topic.description,
            questions: questions,
          }
        });

        if(this.assessmentTopicsModel.some((item) => item.topic.name === assessmentTopicItem.topic.name)){
          this.showmsg({ text: this.messageExistsWithName, type: this.typeMessage });
          return;
        } else if(this.assessmentTopicsModel.some((item) => item.ordination === assessmentTopicItem.ordination)) {
          this.showmsg({ text: this.messageExistsWithOrder, type: this.typeMessage });
          return;
        } else if(this.assessmentTopicsModel.some((item) => item.topic.id === assessmentTopicItem.topic.id)) {
          this.showmsg({ text: this.messageExistsLoaded, type: this.typeMessage });
          return;
        }

        this.assessmentTopicsModel.push(assessmentTopicItem);
        this.assessmentTopicsLoadedSelected = null;
        this.orderAssessmentTopics();
        this.clearAssessmentTopicFields();
        this.setAssessmentTopics(this.assessmentTopicsModel);
      }
    },
    async loadAlLTopics() {
      await this._surveyService.FindAllTopics()
          .then((response) => {
            if(response && response.data) {
              this.assessmentTopicsLoaded = response.data.map((item) => ({
                ordination: 0,
                topic: {
                  id: item.id,
                  name: item.name,
                  description: item.description,
                  questions: item.questions,
                }
              }));
            }
          }).catch(() => {
            this.showmsg({text: "Erro ao tentar carregar os tópicos cadastrados.", type: "error"})
          });
    }
  },
  computed: {
    ...mapGetters(['assessmentTopicIndex']),
    isEmptyAssessmentTopic() {
      return !this.assessmentTopic.ordination || this.assessmentTopic.ordination <= 0
          || !this.assessmentTopic.topic.name || this.assessmentTopic.topic.name.trim().length === 0
    },
  },
  beforeCreate() {
    this._surveyService = new SurveyService();
  },
  async created() {
    this.loadingTopics = true;
    await this.loadAlLTopics();
    this.loadingTopics = false;
  },
};
</script>
