import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VOverlay,{attrs:{"value":_vm.loading,"opacity":0}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c(VCard,[_c(VCardTitle,[_c(VSpacer),(false)?_c(VBtn,{attrs:{"color":"grey","text":"","icon":""},on:{"click":function($event){return _vm.createBeneficiaryConnectCompany()}}},[_c(VIcon,[_vm._v("fas fa-plus")])],1):_vm._e()],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.connectionsWithCompany,"loading":_vm.loading,"search":_vm.search,"item-key":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VTextField,{staticClass:"mx-4",attrs:{"label":"Pesquisa"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.changeStatus",fn:function({ item }){return [(item.type == 'Request' )?_c('span',[(item.status == 'Pendente')?_c('span',[_c(VBtn,{staticClass:"mx-1",attrs:{"color":"primary","text--white":""},on:{"click":function($event){return _vm.approveConnectionWithCompany(item.id)}}},[_vm._v(" Aprovar ")]),_c(VBtn,{staticClass:"mx-1",attrs:{"color":"primary","text--white":""},on:{"click":function($event){return _vm.refuseConnectionWithCompany(item.id)}}},[_vm._v(" Recusar ")])],1):_c('span')]):_c('span',[(item.status == 'Ativo')?_c(VBtn,{attrs:{"color":"primary","text--white":""},on:{"click":function($event){return _vm.inactivateConnectionWithCompany(item.id)}}},[_vm._v(" Inativar ")]):_c(VBtn,{attrs:{"color":"primary","text--white":""},on:{"click":function($event){return _vm.activateConnectionWithCompany(item.id)}}},[_vm._v(" Ativar ")])],1)]}},{key:"no-data",fn:function(){return [_vm._v("Nenhum registro cadastrado!")]},proxy:true},{key:"loading",fn:function(){return [_vm._v("Carregando")]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }