<template>
  <v-container fluid fill-height>
    <v-layout justify-center align-center>
      <v-flex xs12 fill-height>
        <v-card height="95%">
            <Metabase
              :resourceId=4
              :path-params=pathParams
            />
        </v-card>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import Metabase from "../../components/reports/Metabase";

export default {
  components:{ Metabase },
  computed:{
    pathParams(){
      return '#bordered=true&titled=false';
    }
  }
};
</script>

<style>
</style>
