<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="grey lighten-3 font-weight-bold" style="color: var(--v-primary-base)">
        Feedback - {{ assessment && assessment.name ? assessment.name : '' }}
      </v-card-title>

      <v-card-text>
        <v-tabs
            class="mt-2"
            v-model="selectedTab"
            background-color="transparent"
            grow
            centered
            show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-form-feedback">Cadastro do Feedback</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item :value="'tab-form-feedback'">

            <v-form ref="formFeedback">
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-autocomplete
                      :disabled="indexOfFeedbackGroupEditing >= 0 || indexOfFeedbackEditing >= 0"
                      v-model="feedbackGroup.topicSelected"
                      :items="assessment.topics"
                      label="Tópico"
                      item-value="topic.id"
                      item-text="topic.name"
                      placeholder="Selecione um tópico"
                      :rules="[formrules.required]"
                      outlined
                      return-object
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                      :disabled="indexOfFeedbackGroupEditing >= 0 || indexOfFeedbackEditing >= 0 || !feedbackGroup.topicSelected"
                      v-model="feedbackGroup.questions"
                      :items="getQuestionsFromTopicSelected"
                      :height="feedbackGroup.questions.length <= 1 ? '30' : ''"
                      label="Questões"
                      placeholder="Selecione uma questão"
                      :rules="[formrules.required]"
                      outlined
                      return-object
                      multiple
                      chips
                      deletable-chips
                      item-value="id"
                      item-text="description"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      outlined
                      v-model="feedback.startScore"
                      label="Pontuação Inicial"
                      type="number"
                      :rules="[formrules.required, formrules.valueGreaterEqualsThanZero]"
                      @blur="isStartScoreGreaterThan()"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      outlined
                      v-model="feedback.endScore"
                      label="Pontuação Final"
                      type="number"
                      :rules="[formrules.required, formrules.valueGreaterEqualsThanZero]"
                      @blur="isStartScoreGreaterThan()"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="feedback.title"
                      label="Título"
                      maxlength="30"
                      outlined
                      :rules="[formrules.required]"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-file-input
                      label="Imagem"
                      v-model="imageToUpload"
                      @change="uploadDocument(imageToUpload)"
                      outlined
                      prepend-icon="mdi-file-image-outline"
                      extensions="gif,jpg,jpeg,png,webp"
                      accept="image/png,image/gif,image/jpeg,image/webp"
                      clearable
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                          small
                          label
                          color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      outlined
                      v-model="feedback.description"
                      label="Descrição"
                      :rules="[formrules.required]">
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>

          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn :width="indexOfFeedbackGroupEditing === -1 && indexOfFeedbackEditing === -1 ? 120 : 180" large light @click="cancel()">
          {{indexOfFeedbackGroupEditing === -1 && indexOfFeedbackEditing === -1 ? 'Limpar' : 'Cancelar Edição'}}
        </v-btn>
        <v-btn :width="indexOfFeedbackGroupEditing === -1 && indexOfFeedbackEditing === -1 ? 120 : 180" large color="primary" :disabled="isEmptyFields" @click="addFeedback()">
          {{indexOfFeedbackGroupEditing === -1 && indexOfFeedbackEditing === -1 ? 'Adicionar' : 'Alterar'}}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-5">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              class="elevation-1 mx-1 grey lighten-1"
              item-key="name"
              :items="questionsData"
              :headers="questionsHeader"
              hide-default-footer
              show-expand
              single-expand
            >

              <template v-slot:item.actions="{ item }">
                <v-menu
                    transition="slide-y-transition"
                    offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="deleteFeedbackGroup(item)">
                      <v-list-item-icon>
                        <v-icon>fas fa-trash</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="px-0 py-0">
                  <v-row class="pa-0 ma-0 white">
                    <v-col class="px-0 py-0" cols="12">

                      <v-data-table
                        class="elevation-1 mx-1 grey lighten-2"
                        item-key="randomName"
                        :items="item.feedbackGroupItems"
                        :headers="feedbackGroupHeaders"
                        hide-default-footer
                        show-expand
                        single-expand
                      >

                        <template v-slot:item.actions="{ item }">
                          <v-menu
                              transition="slide-y-transition"
                              offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  color="grey"
                                  dark
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                <v-icon>mdi-dots-vertical</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="viewQuestions(item)">
                                <v-list-item-icon>
                                  <v-icon>fas fa-eye</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Ver questões</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <v-list-item @click="deleteFeedbackGroup(item)">
                                <v-list-item-icon>
                                  <v-icon>fas fa-trash</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Excluir</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                          <td :colspan="headers.length" class="mx-0 px-0">
                            <v-row class="pa-0 ma-0 white">
                              <v-col class="px-0 py-0" cols="12">
                                <v-data-table
                                    class="grey lighten-3"
                                    loading-text="Carregando..."
                                    :items="item.feedbacks"
                                    :headers="feedbackHeaders"
                                    hide-default-footer
                                    item-key="title"
                                    itemid="itemFeedback"
                                >

                                  <template v-slot:item.description="{ item: childItem }">
                                    {{ getFeedbackDescription(childItem) }}
                                  </template>

                                  <template v-slot:item.actions="{ item: childItem }">
                                    <v-menu
                                        transition="slide-y-transition"
                                        offset-y
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="grey"
                                            dark
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                          <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                      </template>
                                      <v-list>
                                        <v-list-item @click="editFeedbackItem(item, childItem)">
                                          <v-list-item-icon>
                                            <v-icon>fas fa-edit</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Editar</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item @click="deleteFeedbackItem(item, childItem)">
                                          <v-list-item-icon>
                                            <v-icon>fas fa-trash</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>Excluir</v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-menu>
                                  </template>
                                </v-data-table>
                                
                              </v-col>
                            </v-row>
                          </td>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-5">
      <v-card-actions>
        <v-spacer />
        <v-btn width="250" large light @click="ignore()" style="color: var(--v-primary-base)" :loading="busy" :disabled="busy">
          Continuar Sem Feedback
        </v-btn>
        <v-btn width="250" large color="primary" @click="register()" :loading="busy" :disabled="busy || !feedbackGroupItems.length">
          {{ !isEditingFeedback ? 'Cadastrar Feedback' : 'Alterar Feedback'}}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
        v-model="showDialog"
        max-width="650"
        scrollable
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-bold" style="color: var(--v-primary-base); box-shadow: 2px 2px 10px 3px #ccc;">
          <v-icon class="pr-1" color="primary">fas fa-question-circle</v-icon> Questões
        </v-card-title>
        <v-card-text style="max-height: 300px;" class="mt-3">
          <v-list three-line>
            <template v-for="(item, index) in detailQuestions">
              <v-divider
                  v-if="index > 0 && index < detailQuestions.length"
                  :key="index"
              ></v-divider>

              <v-list-item :key="item.id">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ item.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="closeDialog()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="showDialogFinal"
        persistent
        max-width="650"
        scrollable
    >
      <v-card>
        <v-card-title class="text-h5 font-weight-bold" style="color: var(--v-primary-base); box-shadow: 2px 2px 10px 3px #ccc;">
          <v-icon class="pr-1" color="primary">fas fa-check</v-icon> Feedback cadastrado com sucesso
        </v-card-title>
        <v-card-text style="max-height: 300px;" class="mt-3">
          O questionário ainda está como rascunho. Deseja enviar o questionário agora mesmo?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              style="color: var(--v-primary-base)"
              color="white"
              width="120"
              :loading="loadingDialogFinal"
              :disabled="loadingDialogFinal"
              @click="cancelUpdateStatusOfAssessment()">
            Agora Não
          </v-btn>
          <v-btn
              color="primary"
              width="120"
              :loading="loadingDialogFinal"
              :disabled="loadingDialogFinal"
              @click="registerUpdateStatusOfAssessment()">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations} from "vuex";
import { formrules } from "./../../common/formrules";
import SurveyService from "../../services/survey/SurveyService";

export default {
  name: "feedback",
  data: () => ({
    sfUser: false,
    indexOfFeedbackGroupEditing: -1,
    indexOfFeedbackEditing: -1,
    imageToUpload: null,
    loadingDialogFinal: false,
    showDialogFinal: false,
    showDialog: false,
    isEditingFeedback: false,
    detailQuestions: [],
    feedbackGroupItemsTemp: [],
    feedbackGroupItems: [],
    feedbackGroup: {
      questions: [],
      feedbacks: [],
      topicSelected: null,
      randomName: null,
      index: 0,
      id: null
    },
    feedback: {
      id: null,
      title: '',
      description: '',
      image: null,
      startScore: null,
      endScore: null,
    },
    formrules,
    selectedTab: null,
    busy: false,
    assessmentId: null,
    assessment: null,
    showRangeSlider: true,
    questionsHeader: [
      { text: "Título", width: '20%', value: 'name'},
      { text: "Questões", width: '80%', value: 'question'},        
    ],
    questionsData: [],
    feedbackGroupHeaders: [
      { text: "Tópico", value: "topicSelected.topic.name", width: '40%' },
      { text: "Total de Questões", value: "questions.length", width: '40%' },
      { text: "Total de Feedbacks", value: "feedbacks.length", width: '15%'  },
      { text: "Ações", value: "actions", sortable: false, align: "right", width: '5%' }
    ],
    feedbackHeaders: [
      { text: "Título", value: "title", width: '30%' },
      { text: "Descrição", value: "description", width: '35%' },
      { text: "Pontuação Inicial", value: "startScore", width: '15%' },
      { text: "Pontuação Final", value: "endScore", width: '15%'  },
      { text: "Ações", value: "actions", sortable: false, align: "right", width: '5%' }
    ],
  }),
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    getFeedbackDescription(item) {
      if(item && item.description && item.description.trim().length > 0) {
        return item.description.trim().length <= 55 ? item.description.trim() : item.description.trim().substring(0, 55) + '...';
      }
      return '-';
    },
    isStartScoreGreaterThan() {
      if(this.feedback.startScore && this.feedback.endScore
          && parseInt(this.feedback.startScore) > parseInt(this.feedback.endScore)){
        this.showmsg({text: "A pontuação inicial não pode ser maior que a pontuação final.", type: "error"});
        this.feedback.startScore = null;
      }
    },
    async loadAssessmentById() {
      await this._surveyService.FindAssessmentById(this.assessmentId)
          .then((response) => {
            if(response && response.data) {
              this.assessment = response.data;
            }
          }).catch(() => {
            this.showmsg({text: "Ocorreu um erro ao tentar carregar o questionário.", type: "error"});
          });
    },
    async loadFeedbackByTopicId() {
      if(this.assessment && this.assessment.topics.length) {
        for (const item of this.assessment.topics) {
          await this._surveyService.FindAllFeedbackByTopicId(item.topic.id)
              .then(response => {
                if(response && response.data) {
                  this.isEditingFeedback = true;
                  const feedbackGroups = response.data;

                  feedbackGroups.forEach((feedbackGroup, index) => {
                    const randomName = `T[${feedbackGroup.topicId}]-Q[${feedbackGroup.questions.map(item => item.id).toString()}]`;

                    const itemGroupFound = this.feedbackGroupItems.find(item => item.randomName === randomName);
                    let indexOf = -1;
                    if(itemGroupFound) {
                      indexOf = this.feedbackGroupItems.indexOf(itemGroupFound);
                    }

                    if(indexOf >= 0) {
                      this.feedbackGroupItems[indexOf].feedbacks = Object.assign([], this.feedbackGroupItems[indexOf].feedbacks.concat(feedbackGroup.feedbacks));
                    } else {
                      const feedbackGroupLoaded = {
                        feedbacks: Object.assign([], feedbackGroup.feedbacks),
                        questions: Object.assign([], feedbackGroup.questions),
                        randomName,
                        index,
                        topicSelected: this.assessment.topics.find(element => element.topic.id === feedbackGroup.topicId),
                        id: feedbackGroup.id
                      }
                      this.feedbackGroupItems.push(Object.assign({}, feedbackGroupLoaded));
                    }
                  })
                }
              })
              .catch(() => {
                this.showmsg({text: "Ocorreu um erro ao tentar carregar os feedbacks.", type: "error"});
              })
        }
      }
    },
    uploadDocument(file) {
      if (file !== null) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          this.feedback.image = event.target.result;
        };
      }
    },
    cancel() {
      this.feedbackGroup.topicSelected = null;
      this.feedbackGroup.questions = [];
      this.clearFields();
    },
    clearFields() {
      if(this.indexOfFeedbackGroupEditing >= 0 && this.indexOfFeedbackEditing >= 0) {
        this.feedbackGroup.topicSelected = null;
        this.feedbackGroup.questions = [];
      }

      this.imageToUpload = null;
      this.indexOfFeedbackGroupEditing = -1;
      this.indexOfFeedbackEditing = -1;

      this.feedback = {
        id: null,
        title: '',
        description: '',
        image: null,
        startScore: null,
        endScore: null,
      };

      this.feedbackGroupItemsTemp = [];
      this.feedbackGroup.feedbacks = [];
      this.$refs.formFeedback.resetValidation();
    },
    isInvalidInterval(indexOf) {
      let hasIntervalAdd = false;
      if(this.indexOfFeedbackGroupEditing === -1 && this.indexOfFeedbackEditing === -1) {
        if(indexOf >= 0) {
          hasIntervalAdd = this.feedbackGroupItems[indexOf].feedbacks.some(item => (parseInt(this.feedback.startScore) >= parseInt(item.startScore) && parseInt(this.feedback.startScore) <= parseInt(item.endScore))
              || (parseInt(this.feedback.endScore) >= parseInt(item.startScore) && parseInt(this.feedback.endScore) <= parseInt(item.endScore)));
        }
      } else {
        if(indexOf >= 0) {
          hasIntervalAdd = this.feedbackGroupItemsTemp[indexOf].feedbacks.some(item => (parseInt(this.feedback.startScore) >= parseInt(item.startScore) && parseInt(this.feedback.startScore) <= parseInt(item.endScore))
              || (parseInt(this.feedback.endScore) >= parseInt(item.startScore) && parseInt(this.feedback.endScore) <= parseInt(item.endScore)));
        }
      }
      return hasIntervalAdd;
    },
    existsByTitle(indexOf) {
      let hasItem = false;
      if(this.indexOfFeedbackGroupEditing === -1 && this.indexOfFeedbackEditing === -1) {
        if(indexOf >= 0) {
          hasItem = this.feedbackGroupItems[indexOf].feedbacks.some(item => item.title === this.feedback.title);
        }
      } else {
        if(indexOf >= 0) {
          hasItem = this.feedbackGroupItemsTemp[indexOf].feedbacks.some(item => item.title === this.feedback.title);
        }
      }
      return hasItem;
    },
    existsQuestionAdd() {
      let existsItem = false;
      if(this.indexOfFeedbackGroupEditing === -1 && this.indexOfFeedbackEditing === -1) {
        this.feedbackGroup.questions.forEach(item => {
          if(existsItem) {
            return;
          }

          this.feedbackGroupItems.forEach(element => {
            existsItem = element.questions.some(questionItem => questionItem.id === item.id);
            if(existsItem) {
              return;
            }
          });
        });
      }
      return existsItem;
    },
    addFeedback() {
      if(!this.$refs.formFeedback.validate()) {
        return;
      }

      const randomName = `T[${this.feedbackGroup.topicSelected.topic.id}]-Q[${this.feedbackGroup.questions.map(item => item.id).toString()}]`;
      const itemGroupFound = this.feedbackGroupItems.find(item => item.randomName === randomName);
      let indexOf = -1;
      if(itemGroupFound) {
        indexOf = this.feedbackGroupItems.indexOf(itemGroupFound);
      }

      if(this.isInvalidInterval(indexOf)) {
        this.showmsg({text: "O intervalo de pontuação informado não pode ser cadastrado.<br/>Os valores estão contidos em outro intervalo de pontuação.", type: "error"});
        return;
      }

      if(this.existsByTitle(indexOf)) {
        this.showmsg({text: "Já existe um feedback cadastrado com este título.", type: "error"});
        return;
      }

      if(this.existsQuestionAdd() && indexOf == -1) {
        this.showmsg({text: "Alguma das questões selecionadas já estão contidas na listagem abaixo.<br/>Por favor, escolha outras questões.", type: "error"});
        return;
      }

      if(this.indexOfFeedbackGroupEditing === -1 && this.indexOfFeedbackEditing === -1) {
        if(indexOf == -1) {
          this.feedbackGroup.feedbacks.push(Object.assign({}, this.feedback));
          this.feedbackGroup.randomName = randomName;
          this.feedbackGroup.index = this.feedbackGroupItems.length + 1;

          this.feedbackGroupItems.push(Object.assign({}, this.feedbackGroup));
        } else {
          this.feedbackGroupItems[indexOf].feedbacks.push(Object.assign({}, this.feedback));
        }
      } else {
        this.feedbackGroupItems[this.indexOfFeedbackGroupEditing].feedbacks.splice(this.indexOfFeedbackEditing, 1, this.feedback);
      }

      this.clearFields();
      this.loadQuestionsData();
    },
    ignore() {
      if(this.assessment.status && this.assessment.status.toUpperCase() === 'RASCUNHO') {
        this.showDialogFinal = true;
      } else {
        this.redirectToInitialPage();
      }
    },
    async register() {
      this.busy = true;
      this.loading(this.busy);

      let feedbackGroupFinal = Object.assign([], this.feedbackGroupItems);
      feedbackGroupFinal = feedbackGroupFinal.map(item => ({
        feedbacks: item.feedbacks,
        questions: item.questions,
        topicId: item.topicSelected.topic.id,
        id: item.id,
      }));

      if(feedbackGroupFinal) {
        await this._surveyService.CreateFeedback(feedbackGroupFinal)
            .then(() => {
              if(this.assessment.status && this.assessment.status.toUpperCase() === 'RASCUNHO') {
                this.showDialogFinal = true;
              } else {
                this.showmsg({text: "Feedback cadastrado com sucesso.", type: "success"});
                this.redirectToInitialPage();
              }
            })
            .catch(() => {
              this.showmsg({text: "Ocorreu um erro ao tentar cadastrar o feedback.", type: "error"});
            });
      }

      this.busy = false;
      this.loading(this.busy);
    },
    redirectToInitialPage() {
      if(!this.sfUser) {
        this.$router.push({name: 'assessments'});
      } else {
        this.$router.push({name: 'assessments-backoffice'});
      }
    },
    cancelUpdateStatusOfAssessment() {
      this.showDialogFinal = !this.showDialogFinal;
      this.redirectToInitialPage();
    },
    async registerUpdateStatusOfAssessment() {
      this.assessment.status = 'ENVIADO';
      this.loadingDialogFinal = true;

      await this._surveyService.UpdateAssessment(this.assessment)
          .then(() => {
            this.showmsg({text: "Questionário enviado com sucesso.", type: "success"});
            this.showDialogFinal = !this.showDialogFinal;
            this.redirectToInitialPage();
          })
          .catch(() => {
            this.showmsg({text: "Ocorreu um erro ao tentar enviar o questionário.", type: "error"});
          })
          .finally(() => {
            this.loadingDialogFinal = false;
          });
    },
    deleteFeedbackItem(item, childItem) {
      if(item && childItem) {
        const indexOfParent = this.feedbackGroupItems.indexOf(item);
        if(indexOfParent >= 0) {
          const indexOfChild = this.feedbackGroupItems[indexOfParent].feedbacks.indexOf(childItem);
          if(indexOfChild >= 0) {
            this.feedbackGroupItems[indexOfParent].feedbacks.splice(indexOfChild, 1);
            if(!this.feedbackGroupItems[indexOfParent].feedbacks.length) {
              this.feedbackGroupItems.splice(indexOfParent, 1);
            }
          }
        }
      }
    },
    editFeedbackItem(item, childItem) {
      if(item && childItem) {
        this.indexOfFeedbackGroupEditing = this.feedbackGroupItems.indexOf(item);
        if (this.indexOfFeedbackGroupEditing >= 0) {
          this.indexOfFeedbackEditing = this.feedbackGroupItems[this.indexOfFeedbackGroupEditing].feedbacks.indexOf(childItem);

          this.feedbackGroup.id = item.id;
          this.feedbackGroup.index = item.index;
          this.feedbackGroup.topicSelected = item.topicSelected;
          this.feedbackGroup.questions = item.questions;
          this.feedbackGroup.randomName = item.randomName;

          this.feedback.id = childItem.id;
          this.feedback.title = childItem.title;
          this.feedback.startScore = childItem.startScore;
          this.feedback.endScore = childItem.endScore;
          this.feedback.description = childItem.description;
          this.feedback.image = childItem.image;

          this.feedbackGroupItemsTemp = Object.assign([], JSON.parse(JSON.stringify(this.feedbackGroupItems)));
          this.feedbackGroupItemsTemp[this.indexOfFeedbackEditing].feedbacks.splice(this.indexOfFeedbackEditing, 1);
        }
      }
    },
    deleteFeedbackGroup(item) {
      const indexOf = this.feedbackGroupItems.indexOf(item);
      if(indexOf >= 0) {
        this.feedbackGroupItems.splice(indexOf, 1);
        this.feedbackGroup.topicSelected = null;
        this.feedbackGroup.questions = [];
        this.clearFields();
      }
    },
    viewQuestions(item) {
      if(item && item.questions && item.questions.length) {
        this.detailQuestions = Object.assign([], item.questions);
        this.showDialog = true;
      }
    },
    closeDialog() {
      this.showDialog = !this.showDialog;
      this.detailQuestions = [];
    },
    loadQuestionsData() {
      this.feedbackGroupItems.forEach((group, index) => {
        let questionInfo = {
          name: 'Grupo ' + (index + 1),
          question: group.questions.map((x)=> x.description).join('/ '),
          questionIds: group.questions.map((x)=> x.id),
          feedbackGroupItems: [group]
        }
        this.questionsData.push(questionInfo);
      });
    }
  },
  computed: {
    getQuestionsFromTopicSelected() {
      if(this.feedbackGroup.topicSelected) {
        const topicFound = this.assessment.topics.find(item => item.topic.id === this.feedbackGroup.topicSelected.topic.id);
        return topicFound && topicFound.topic ? topicFound.topic.questions : [];
      } else {
        return [];
      }
    },
    isEmptyFields() {
      return !this.feedbackGroup.topicSelected || !this.feedbackGroup.questions.length
          || !this.feedback || !this.feedback.title || this.feedback.title.trim().length === 0
          || !this.feedback.description || this.feedback.description.trim().length === 0
          || !this.feedback.startScore || this.feedback.startScore < 0
          || !this.feedback.endScore || this.feedback.endScore < 0;
    },
  },
  beforeCreate() {
    this._surveyService = new SurveyService();
  },
  async created() {
    const odooToken = localStorage.getItem('LOCALIZED_TOKEN');
    this.sfUser = odooToken ? true : false;


    if(this.$route.params && this.$route.params.assessment && parseInt(this.$route.params.assessment.id)) {
      this.assessmentId = this.$route.params.assessment.id;
    } else if(this.$route.query && this.$route.query.assessmentId && parseInt(this.$route.query.assessmentId) > 0) {
      this.assessmentId = this.$route.query.assessmentId;
    } else {
      this.showmsg({text: "O ID do questionário não foi informado.", type: "error"});
    }

    this.busy = true;
    this.loading(this.busy);
    await this.loadAssessmentById();
    await this.loadFeedbackByTopicId();
    this.loadQuestionsData();
    this.busy = false;
    this.loading(this.busy);
  },
};
</script>
