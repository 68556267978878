import HttpService from '../HttpService'

export default class SurveyService {
  constructor() {
    this._httpService = new HttpService("/survey");
  }

  async FindAssessmentTargetById(id){
    return await this._httpService.get(`/assessment_target/${id}`);
  }

  async UpdateAssessmentTarget(data, id){
    return await this._httpService.put(`/assessment_target/${id}`, data);
  }

  async FindAllAssessmentTargetByAssessmentId(assessmentId){
    return await this._httpService.get('/assessment_target/findAllByAssessmentId', {assessmentId})
  }

  async FindAssessmentById(id){
    return await this._httpService.get(`/assessment/${id}`);
  }

  async FindAllAssessmentQuestionByAssessmentId(assessmentId){
    return await this._httpService.get(`/assessment_question/findAllByAssessmentId/${assessmentId}`);
  }

  async FindAllAssessment(){
    return await this._httpService.get('/assessment');
  }

  async CreateAssessment(data){
    return await this._httpService.post('/assessment', data);
  }

  async UpdateAssessment(data){
    return await this._httpService.put('/assessment' , data);
  }

  async CreateResearchGroupType(data){
    return await this._httpService.post('/research_group_type', data);
  }

  async UpdateResearchGroupType(data, id) {
    return await this._httpService.put(`/research_group_type/${id}`, data);
  }

  async DeleteResearchGroupType(id){
    return await this._httpService.delete(`/research_group_type/${id}`);
  }

  async FindAllResearchGroupTypes(){
    return await this._httpService.get('/research_group_type');
  }

  async FindAllResearchGroupTypesActive(){
    return await this._httpService.get('/research_group_type/active');
  }

  async FetchFeedbacks(id){
    return await this._httpService.get(`/assessment/${id}/feedback`);
  }

  async findAllByFinancialGroupId(id){
    return await this._httpService.get(`/assessment/financial_group/${id}`);
  }

  async findAllByFilters(data){
    return await this._httpService.get(`/assessment-answer/findEvolutionReport`,data);
  }

  async findAllDashEvolutionByFilters(data){
    return await this._httpService.get(`/assessment-answer/findDashEvolution`,data);
  }

  async generateReport(data){
    return await this._httpService.get(`/assessment-answer/generateEvolutionReport`,data);
  }

  async FindAllTopics(){
    return await this._httpService.get('/topic');
  }

  async CreateFeedback(data) {
    return await this._httpService.post('/feedback-group', data);
  }

  async FindAllFeedbackByTopicId(topicId) {
    return await this._httpService.get(`/feedback-group?topicId=${topicId}`);
  }
}
