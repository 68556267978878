<template>
  <div :id="container" :class="classParam" :style="styleParam"></div>
</template>

<script>
export default {
  props: {
    resource: null,
    type: null,
    classParam: null,
    styleParam: null,
    container: null,
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data: function() {
    return {
      name: "jasperadmin",
      password: "@BCP14im2018",
      organization: "organization_1"
    };
  },
  mounted() {
    const thiz = this;
    // eslint-disable-next-line
    visualize(
      {
        auth: {
          name: this.name,
          password: this.password,
          organization: this.organization
        }
      },

      function(v) {
        switch (thiz.type) {
          case "dashboard":
            v.dashboard({
              resource: thiz.resource,
              container: "#" + thiz.container,
              error: handleError,
              params: thiz.params
            });
            break;
          case "adhocView":
            v.adhocView({
              resource: thiz.resource,
              container: "#" + thiz.container,
              error: handleError,
              params: thiz.params
            });
            break;
          case "report":
            v.report({
              resource: thiz.resource,
              container: "#" + thiz.container,
              error: handleError,
              params: thiz.params
            });
            break;
          default:
            console.log("JasperserverVisualize invalid type");
        }

        //show error
        function handleError(err) {
          alert(err.message);
        }
      }
    );
  }
};
</script>
