/* eslint-disable */
import HttpService from './HttpService'

export default class ContractReajustService {
  constructor() {
    this._httpService = new HttpService();
  }

  async FindAll(data) {
    return await this._httpService.get('/api/odoo/contract_reajust', data);
  }
}
