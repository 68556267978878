import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GenericDrawer',{attrs:{"right":"","open":_vm.$store.state.drawerOpen},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c(VBtn,{staticClass:"primary",attrs:{"block":"","large":""},on:{"click":_vm.updateDrawer}},[_vm._v("Aplicar")])],1),_c('div',{staticClass:"pa-2"},[_c(VBtn,{staticClass:"text--primary",attrs:{"block":"","large":""},on:{"click":_vm.clearFilters}},[_vm._v("Limpar")])],1)]},proxy:true}])},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(this.title))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeSideDrawer}},[_c(VIcon,[_vm._v("fas fa-times")])],1)],1),_c('GenericFilterer',{ref:"genericFilterer",attrs:{"filters":_vm.filters,"data":_vm.data,"baseQuery":_vm.baseQuery},on:{"queryUpdate":_vm.emitQuery,"filterChange":_vm.emitData,"filtersRemoved":(eventValue) => _vm.$emit('filtersRemoved', eventValue),"loadComboBoxSubItems":_vm.loadComboBoxSubItems,"loadFinancialGroupToInvoice":_vm.loadFinancialGroupToInvoice}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }