import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.busy)?_c(VContainer,{attrs:{"fluid":""}},[_c('PreviousPage',{attrs:{"route":'beneficiarios2'}}),_c(VCard,{staticClass:"elevation-0"},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"primary--text"},[_vm._v(" Beneficiários ")])],1),_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,{staticClass:"primary--text"},[_vm._v(" Titular ")]),_c(VCardText,[_c(VRow,{staticClass:"mb-4 py-0",attrs:{"dense":""}},[_vm._l((_vm.headers),function(header){return [_c(VCol,{key:header.value,attrs:{"cols":header.cols,"md":"4"}},[_c(VTextField,{staticStyle:{"height":"43px"},attrs:{"label":header.text,"value":_vm.item[header.value],"readonly":"","dense":"","outlined":""}})],1)]})],2)],1)],1)],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c(VCard,[[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardTitle,{staticClass:"primary--text"},[_vm._v(" Dependentes ")]),_c(VCardText,[_c(VRow,[(_vm.item.dependente.length)?_vm._l((_vm.item.dependente),function(dependent,index){return _c(VCol,{key:index,attrs:{"cols":"12"}},[_c(VTextField,{staticStyle:{"height":"43px"},attrs:{"value":dependent.name,"label":dependent.kinship,"readonly":"","dense":""}}),_c(VRow,_vm._l((_vm.item.dependente[index]),function(dependent,dIndex){return _c(VCol,{key:dependent.name,staticClass:"pa-1",attrs:{"cols":_vm.$resize && _vm.$mq.above(_vm.window.mobileSize) ? 4 : (dIndex === 'Cartão' ? 12 : 6)}},[(dIndex !== 'name')?_c(VTextField,{staticClass:"pa-0 ma-0",staticStyle:{"height":"43px"},attrs:{"value":dependent,"label":_vm.getLabelDependent(dIndex),"readonly":"","dense":"","outlined":""}}):_vm._e()],1)}),1)],1)}):_c(VCol,{staticClass:"pt-0 mt-0"},[_c('p',[_vm._v("Não existem dependentes cadastrados.")])])],2)],1)],1)]],2)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }