import Vue from 'vue'
import moment from 'moment'

moment.locale('pt-BR')
Vue.filter('formatDatetime', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
  return null
})

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
  return null
})

Vue.filter('formatSmallDate', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/YY')
  }
  return null
})

Vue.filter('daysMonth', value => {
  if (value) {
    return moment(String(value))
      .format('DD/MMM')
      .toUpperCase()
  }
  return null
})

Vue.filter('monthOnly', value => {
  if (value) {
    return moment(String(value))
      .format('MMM')
      .toUpperCase()
  }
  return null
})

Vue.filter('dayOnly', value => {
  if (value) {
    return moment(String(value))
      .format('DD')
      .toUpperCase()
  }
  return null
})

Vue.filter('daysPast', value => {
  if (value) {
    return moment.duration(moment().diff(moment(String(value)))).humanize()
  }
  return null
})
