<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 lg3 v-for="(item, index) in items" :key="index">
        <v-card>
          <v-card-title class="contrast white--text title  ">{{ item.name }}</v-card-title>
          <v-divider></v-divider>

          <v-list dense>
            <v-list-item>
              <v-list-item-content>Cadastradas:</v-list-item-content>
              <v-list-item-content class="align-end">{{ item.cad }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Liberadas:</v-list-item-content>
              <v-list-item-content class="align-end">{{ item.lib }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Enviadas:</v-list-item-content>
              <v-list-item-content class="align-end">{{ item.env }}</v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Criticadas:</v-list-item-content>
              <v-list-item-content class="align-end">{{ item.crit }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>

import { mapMutations } from 'vuex';
import { getGoogleSheets } from '@/common/gsxrest';

const { VUE_APP_SHEETS_MOVES, VUE_APP_SHEETS_API_KEY } = process.env;


export default {
  data: () => ({
    items: []
  }),
  methods: {
    ...mapMutations(["showmsg"]),
  },
  beforeMount() {
    getGoogleSheets(VUE_APP_SHEETS_MOVES, VUE_APP_SHEETS_API_KEY, 'Sheet1!A2:E100')
      .then(data => {
        const outarr = [];
        for (const item of data) {
          outarr.push({
            name: item[0],
            cad: item[1],
            lib: item[2],
            env: item[3],
            crit: item[4]
          })
        }
        this.items = outarr;
      })
      .catch(() => {
        this.showmsg({
          text: "Erro ao carregar as movimentações.",
          type: "error"
        })
      })
  },
};

</script>
