<template>
  <v-row class="px-4 pt-4">

    <v-col cols="12" class="px-5 py-0">
      <label class="v-label theme--light">Período de Apuração - {{readjustment.periodCalculation}}</label>
    </v-col>

    <v-col cols="12" class="px-5 py-0">
      <v-divider class="py-4" :inset="false"></v-divider>
    </v-col>

    <v-col cols="12" md="4" class="px-5 py-0 ma-0">
      <v-text-field label="Reajuste Financeiro Apurado" :value="readjustment.calculatedFinancialReadjustment" readonly dense outlined></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="px-5 py-0 ma-0">
      <v-text-field label="Reajuste Técnico Apurado" :value="readjustment.calculatedClaimReadjustment" readonly dense outlined></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="px-5 py-0 ma-0">
      <v-text-field label="Reajuste Composto Apurado" :value="readjustment.calculatedCompositeReadjustment" readonly dense outlined></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="px-5 py-0 ma-0">
      <v-text-field label="Mês de Aplicação" :value="readjustment.appliedMonth" readonly dense outlined></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="px-5 py-0 ma-0">
      <v-text-field label="Reajuste Aplicado" :value="readjustment.readjustmentApplied" readonly dense outlined></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="px-5 py-0 ma-0">
      <v-text-field label="Aporte Aplicado" :value="readjustment.appliedFinancialSupport | currency " readonly dense outlined></v-text-field>
    </v-col>

  </v-row>
</template>

<script>



import moment from "moment";

export default {
  props: {
    readjustment: {
      type: Object,
      required: true
    }
  },

  beforeMount() {
    this.readjustment.periodCalculation = this.getPeriodoApuracao(this.readjustment);
    this.readjustment.calculatedFinancialReadjustment =
            this.readjustment.calculated_financial_readjustment_index
            ? this.readjustment.calculated_financial_readjustment_index + "%"
            : " ";
    this.readjustment.calculatedClaimReadjustment =
            this.readjustment.calculated_claim_readjustment_index
            ? this.readjustment.calculated_claim_readjustment_index + "%"
            : " ";
    this.readjustment.calculatedCompositeReadjustment =
            this.readjustment.calculated_composite_readjustment_Index
            ? this.readjustment.calculated_composite_readjustment_Index + "%"
            : " ";
    this.readjustment.appliedMonth =
            this.readjustment.applied_month
            ? this.capitalizeFirstLetter(this.formatMonth(this.readjustment.applied_month))
            : " ";
    this.readjustment.readjustmentApplied =
            this.readjustment.readjustment_applied
            ? this.readjustment.readjustment_applied + " " + this.readjustment.applied_readjustment_index + "%"
            : " ";
    this.readjustment.appliedFinancialSupport =
            this.readjustment.applied_financial_support_value
            ? this.readjustment.applied_financial_support_value
            : " ";

  },
  methods: {
    getPeriodoApuracao(readjustment) {
      var periodoApuracao = " ";

        if (readjustment.caculation_start) {
          periodoApuracao = this.formatDate(readjustment.caculation_start);

          readjustment.calculation_end ? (periodoApuracao += " - " + this.formatDate(readjustment.calculation_end)) : " ";
        }
      return periodoApuracao;

    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDate(date){
      return date ? Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date)) : " ";
    },
    formatMonth(date){
      return date ? moment(date, 'yyyy-MM-DD').format('MMM/YYYY') : "";
    }
  }
}
</script>

