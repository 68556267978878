import axios from 'axios';

const {
    VUE_APP_API_URL
// eslint-disable-next-line no-undef
} = process.env;

const VUE_APP_EU_PROTEGIDO_SERVICE_URL = VUE_APP_API_URL + "/euprotegido/"


const findAllConnectCompanyByFinancialGroup = (token, financialGroupExternalID) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'connect_company_request/findAllByFinancialGroupExternalId/' + financialGroupExternalID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const approveConnectionWithCompany = (token, id) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'connect_company_request/approveConnectionWithCompany/' + id;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const refuseConnectionWithCompany = (token, id) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'connect_company_request/refuseConnectionWithCompany/' + id;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const activateConnectionWithCompany = (token, id) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'connect_company_connection/activateConnectionWithCompany/' + id;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const disconnectFromCompany = (token, id) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'connect_company_connection/disconnectFromCompany/' + id;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const findAllBySubContractWithoutConnectionWithCompany = (token, subContractID) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'beneficiary/findAllBySubContractWithoutConnectionWithCompany/' + subContractID;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
};

const connectBeneficiaryWithCompany = (token, data) => {
    const url = `${VUE_APP_EU_PROTEGIDO_SERVICE_URL}` + 'beneficiary_connect_company/connectBeneficiaryWithCompany';

    return axios({
        url: url,
        method: 'post',
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        data,
    });
};

export {
    findAllConnectCompanyByFinancialGroup,
    approveConnectionWithCompany,
    refuseConnectionWithCompany,
    activateConnectionWithCompany,
    disconnectFromCompany,
    findAllBySubContractWithoutConnectionWithCompany,
    connectBeneficiaryWithCompany
};
