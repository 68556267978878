<template>
  <v-container fluid grid-list-md class="px-2">

    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <DrawerFilterer title="Beneficiários" :filters="filters" :data="items" @queryUpdate="loadItemUpdate" @filtersRemoved="loadInitialItens" @clearFilters="loadInitialItens"
                      @loadComboBoxSubItems="loadComboBoxSubItems"/>
      <v-flex xs12>
          <v-card>
            <v-card-title class="primary--text">
              <span>Histórico</span>
              <v-spacer />
              <v-btn fab bottom right fixed color="primary" dark @click="toggleFilterDrawer">
                <v-icon>fas fa-filter</v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
                :loading="busy"
                :headers="headers"
                :items="getBeneficiaries"
                item-key="index"
                :items-per-page="20"
                hide-default-footer
                class="elevation-1"
                @click:row="(item) => handleItemSelection(item)">
              <template v-slot:no-data>
                <span>Nenhum item foi encontrado. Utilize o filtro para buscar.</span>
              </template>

              <template v-slot:header.name="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.name.header }}</span>
              </template>
              <template v-slot:item.name="{ item }">
                <span>
                  {{ item.name }}
                </span>
                <br/> {{ item[groupData.name.value] }}
              </template>

              <template v-slot:header.birthdate="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.birthdate.header }}</span>
              </template>
              <template v-slot:item.birthdate="{ item }">
                <span>
                  {{ item.birthdate }}
                </span>
                <br/> {{ item[groupData.birthdate.value] }}
              </template>

              <template v-slot:header.type="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.type.header }}</span>
              </template>
              <template v-slot:item.type="{ item }">
                <span>
                  {{ item.type }}
                </span>
                <br/> {{ item[groupData.type.value] }}
              </template>

              <template v-slot:header.policy="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.policy.header }}</span>
              </template>
              <template v-slot:item.policy="{ item }">
                <span>
                  {{ item.policy }}
                </span>
                <br/> {{ item[groupData.policy.value] }}
              </template>

              <template v-slot:header.benefit="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.benefit.header }}</span>
              </template>
              <template v-slot:item.benefit="{ item }">
                <span>
                  {{ item.benefit }}
                </span>
                <br/> {{ item[groupData.benefit.value] }}
              </template>

              <template v-slot:header.card="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.card.header }}</span>
              </template>
              <template v-slot:item.card="{ item }">
                <span>
                  {{ item.card }}
                </span>
                <br/> {{ item[groupData.card.value] }}
              </template>

              <template v-slot:header.validity="{ header }">
                <span class="black--text">{{ header.text }}
                  <br/>{{ groupData.validity.header }}</span>
              </template>
              <template v-slot:item.validity="{ item }">
                <span>
                  {{ item.validity }}
                </span>
                <br/> {{ item[groupData.validity.value] }}
              </template>
            </v-data-table>
          </v-card>
      </v-flex>
    </v-layout>
    <template v-else>
      <FabFilterer title="Beneficiários" :filters="filters" :data="items" @queryUpdate="loadItemUpdate" @filtersRemoved="loadInitialItens" @clearFilters="loadInitialItens"
                   @loadComboBoxSubItems="loadComboBoxSubItems"/>
      <v-data-iterator
            hide-default-footer
            hide-default-header
            disable-pagination
            :items="getBeneficiariesCards"
        >
          <template v-slot:no-data>
            <v-alert
                :value="getBeneficiariesCards"
                type="info"
                outlined
            >Nenhum item foi encontrado. Utilize o filtro para buscar.</v-alert>
          </template>
          <template v-slot:default="props">
            <v-row
                v-for="item in props.items"
                :key="item.index"
            >
              <v-col cols="12">
                <v-card
                    hover
                    class="mb-4 elevation-5"
                    @click="handleItemSelection(item)">
                  <v-card-title
                      pa-0
                      ma-0
                      class="contrast white--text title elevation-5 mb-5"
                  >
                    <div class="pa-0 ma-0 row">
                      <div class="pa-0 ma-0 col text-left text-truncate">
                        {{ item.name }}<br/>{{ item.operator }} - {{ item.benefit }}
                      </div>
                    </div>
                  </v-card-title>
                  <v-row class="px-4">
                    <template v-for="(header, dIndex) in baseHeaders">
                      <v-col class="py-0 my-0" :cols="header.cols" :key="header.value">
                        <v-text-field
                            style="height: 68px"
                            readonly
                            outlined
                            v-if="!([11, 12, 13].includes(dIndex) || (dIndex == 6 && item.kinship == null) || (dIndex == 0 && item.kinship == null))"
                            :value="item[header.value]"
                            :label="header.text"
                        ></v-text-field>
                      </v-col>
                    </template>
                    <v-col cols="12" class="px-5 py-2 ma-0 primary--text text-center">
                      <v-flex> Clique para detalhar </v-flex>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

            </v-row>
          </template>
        </v-data-iterator>
    </template>
    <v-pagination
        :disabled="busy || maxPage === 1"
        v-model="countPage"
        :length="maxPage"
        :total-visible="10"
        @input="loadWithPagination()"
    ></v-pagination>
  </v-container>
</template>

<script>
import ContractService from "../../services/odoo/ContractService";
import FabFilterer from "@/components/fab-filterer";
import DrawerFilterer from "@/components/drawer-filterer";
import {mapGetters, mapMutations, mapActions, mapState} from "vuex";

export default {
  name: "Benefitiaries",
  components: { FabFilterer, DrawerFilterer },
  data: () => ({
    queryParameter: '',
    lastClickedPage: 1,
    countPage: 1,
    maxPage: 1,
    contracts: [],
    subContracts: [],
    beneficiaries: [],
    lastBeneficiariesLoaded: [],
    items: [],
    filteredItems: [],
    filters: [],
    expand:[],
    baseHeaders: [
      { text: "Titular", value: "parentName", cols: "12" },
      { text: "CPF", value: "cpf", cols: "6" },
      { text: "Nascimento", value: "birthdate", cols: "6" },
      { text: "Matricula", value: "registration", cols: "6" },
      { text: "Vigencia", value: "validity", cols: "6" },
      { text: "Tipo", value: "type", cols: "6" },
      { text: "Parentesco", value: "kinship", cols: "6" },
      { text: "Apólice - Subcontrato", value: "policy", cols: "12" },
      { text: "Plano", value: "plan", cols: "12" },
      { text: "Cartão", value: "card", cols: "12" },
      { text: "Status", value: "status", cols: "6" },
      { text: "Nome", value: "name", cols: "6" },
      { text: "Beneficio", value: "benefit", cols: "6" },
      { text: "Operadora", value: "operator", cols: "6" },
    ],
    groupData: {
      name: {
        header: 'Cpf',
        value: 'cpf',
        class: 'bolder'
      },
      birthdate: {
        header: 'Matricula',
        value: 'registration'
      },
      type: {
        header: 'Parentesco',
        value: 'kinship'
      },
      policy: {
        header: 'Subcontrato',
        value: 'subcontract'
      },
      benefit: {
        header: 'Operadora',
        value: 'operator'
      },
      card: {
        header: 'Plano',
        value: 'plan'
      },
      validity: {
        header: 'Status',
        value: 'status'
      },
    },
    busy: false,
    isClickedPagination: false,
  }),
  beforeMount() {
    this.loadFilters();
  },
  async mounted() {
    this.busy = true
    this.loading(true)
    this._contractService = new ContractService()
    await this.getContracts()
    this.clearData()
    this.loading(false)
    this.busy = false
  },
  computed: {
      ...mapGetters(["user","getBeneficiaries","getBeneficiariesCards"]),
      ...mapState(["window"]),
      headers() {
        let baseHeaders = []
        if(this.$resize && !this.$mq.above(window.mobileSize)){
          baseHeaders = [
            { text: "Nome", value: "name", cols: "6" },
            { text: "Nascimento", value: "birthdate", cols: "6" },
            { text: "Tipo", value: "type", cols: "6" },
            { text: "Apólice", value: "policy", cols: "6" },
            { text: "Beneficio", value: "benefit", cols: "6" },
            { text: "Cartão", value: "card", cols: "6" },
            { text: "Vigencia", value: "validity", cols: "6" },
          ];
        }
        if (this.$resize && this.$mq.above(this.window.mobileSize)) {
          baseHeaders.unshift({ text: "", value: "", sortable: false, cols: "" });
        }
        return baseHeaders;
      },
    },
    methods: {
      ...mapMutations(["showmsg", "loading"]),
      ...mapActions(["setBeneficiaries", "setBeneficiariesCards", "getContracts"]),
      async loadBeneficiariesAndDependents(params = ""){
        await this._contractService.findAllBeneficiariesAndDependentsByFilters(params)
            .then((response) => {
              if(response.data && response.data.length > 0) {
                this.beneficiaries = response.data
                this.setBeneficiaries(response.data)
                this.setBeneficiariesCards(response.data)

                if(this.queryParameter.length === 0){
                  this.maxPage = this.countPage + 1
                } else {
                  if(JSON.stringify(this.beneficiaries) !== JSON.stringify(this.lastBeneficiariesLoaded) || !this.isClickedPagination) {
                    this.maxPage = this.countPage + 1
                    this.lastBeneficiariesLoaded = this.beneficiaries
                  }else if(this.isClickedPagination){
                    this.clearData()
                  }
                }

                if(this.beneficiaries.length < 20) {
                  if(this.queryParameter.length === 0){
                    this.maxPage = this.countPage;
                  }else {
                    if(!this.isClickedPagination){
                      this.maxPage = 1;
                    } else {
                      this.maxPage = this.countPage;
                    }
                  }
                }

              }else {
                this.setBeneficiaries([])
                this.maxPage = this.countPage
              }
            })
            .catch((error) => {
              if(error && error.response && error.response.data && error.response.status === 500
                && error.response.data.message && error.response.data.message.includes('Unable to find')
                && error.response.data.message.includes('Contract')) {

                const msg = error.response.data.message;
                const indexOfStart = msg.indexOf('with id') + 8;
                const indexOfEnd = msg.indexOf(';');
                if(indexOfStart >= 0) {
                  const contractId = msg.substring(indexOfStart, indexOfEnd);
                  this.showmsg({ text: `Um contrato/subcontrato não foi encontrado no banco de dados. ID: ${contractId}.`, type: "error" });
                } else {
                  this.showmsg({ text: `Um contrato/subcontrato não foi encontrado.`, type: "error" });
                }
              } else {
                this.showmsg({ text: "Não foi possível carregar os beneficiários.", type: "error" });
              }
            })
            .finally(()=>{
              this.isClickedPagination = false;
            })
      },
      clearData() {
        this.beneficiaries = []
        this.lastBeneficiariesLoaded = []
        this.setBeneficiaries([])
        this.setBeneficiariesCards([])
      },
      async loadWithPagination() {
        this.busy = true
        this.loading(true)
        this.isClickedPagination = true;

        if(this.lastClickedPage === this.countPage){
          this.loading(false)
          this.busy = false
          return;
        }

        if(this.queryParameter.length === 0) {
          const query = "?page=" + (this.countPage - 1)
          await this.loadBeneficiariesAndDependents(query)
        } else {
          const indexPage = this.queryParameter.lastIndexOf('page=')
          if(indexPage >= 0) {
            let query = this.queryParameter.substring(0, indexPage)
            query = query + "page=" + (this.countPage - 1)
            await this.loadBeneficiariesAndDependents(query)
          }
        }

        this.lastClickedPage = this.countPage;
        this.loading(false)
        this.busy = false
      },
      async loadInitialItens() {
        this.queryParameter = ''
        this.countPage = 1
        this.maxPage = 1
        this.busy = true
        this.loading(true)
        this._contractService = new ContractService()
        this.clearData()
        this.loading(false)
        this.busy = false
      },
      async loadItemUpdate(data) {
        if (data) {
          data = data.trim();

          // parametros do requests
          var rq = [];

          //recuperar parametros a partir da query
          const filters = data.trim().split("andd");

          //percorrer query e montar os parametros
          filters.forEach(fil => {
            fil = fil.trim();

            // criar parametro
            let q = fil.split("in");
            if (q[0] && q[0].length > 0) {
              // montar chave e valor
              rq.push({chave: q[0], valor: q[1]});
            }
          });

          // tratando os parametrosrequest
          rq.forEach(res => {
            res.chave = res.chave.trim();
            res.valor = res.valor.trim();
            res.valor = res.valor.replace("(", "");
            res.valor = res.valor.replace(")", "");

            // replace so tira um por vez
            for (let index = 0; index < 20; index++) {
              res.valor = res.valor.replace("'", "");
            }
          });

          // passar parametros de filtros
          let path = "";
          rq.forEach(parametro => {
            if (path && path.length > 0) {
              path += "&";
            } else {
              path = "?";
            }
            parametro.chave = parametro.chave.replace("Status", "isActive");
            path += parametro.chave;
            path += "=" + parametro.valor;
          });

          this.isIndependentCase = false
          if(path.includes('?isActive=true,false')){
            this.isIndependentCase = true
            path = path.replace('?isActive=true,false', '')
          } else if(path.includes('&isActive=true,false')){
            this.isIndependentCase = true
            path = path.replace('&isActive=true,false', '')
          }

          if(path.includes('?beneficiarySituation=ATIVOS')){
            path = path.replace('?beneficiarySituation=ATIVOS', '?beneficiarySituation=ATIVO')
          } else if(path.includes('&beneficiarySituation=ATIVOS')){
            path = path.replace('&beneficiarySituation=ATIVOS', '&beneficiarySituation=ATIVO')
            // eslint-disable-next-line no-dupe-else-if
          } else if(path.includes('?beneficiarySituation=INATIVOS')){
            path = path.replace('?beneficiarySituation=INATIVOS', '?beneficiarySituation=INATIVO')
            // eslint-disable-next-line no-dupe-else-if
          } else if(path.includes('&beneficiarySituation=INATIVOS')){
            path = path.replace('&beneficiarySituation=INATIVOS', '&beneficiarySituation=INATIVO')
            // eslint-disable-next-line no-dupe-else-if
          } else if(path.includes('?beneficiarySituation=TODOS')){
            path = path.replace('?beneficiarySituation=TODOS', '')
            // eslint-disable-next-line no-dupe-else-if
          } else if(path.includes('&beneficiarySituation=TODOS')){
            path = path.replace('&beneficiarySituation=TODOS', '')
          }

          this.countPage = 1
          this.maxPage = 1
          if(path.length === 0){
            path = path + ("?page=" + (this.countPage - 1))
          } else {
            path = path + ("&page=" + (this.countPage - 1))
          }

          this.busy = true
          this.loading(true)
          this.queryParameter = path
          await this.loadBeneficiariesAndDependents(path);
          this.busy = false
          this.loading(false)
        }
      },
      handleItemSelection(item) {
        const contractPlanId = this.beneficiaries[item.index].contractPlanId
        this.$router.push({ name: "benefitiariesDetalhar", params: { id: contractPlanId, isBeneficiaryHolder: item.type === 'Titular' } });
      },
      toggleHabilityToSearch(value) {
        this.$store.commit("setAbledToSearch", value);
      },
      toggleFilterDrawer() {
        this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

        if (this.$store.state.drawerOpen) {
          this.toggleHabilityToSearch(false);
        }
      },

      loadFilters(){

        this.filters.push({
              name: "Nome",
              key: "physicalPersonName",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Nome do Beneficiário",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
            {
              name: "Documento",
              key: "physicalPersonCPF",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "CPF (somente números)",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
            {
              name: "Aniversário",
              key: "birthDate",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Data de Nascimento",
                  value: "",
                  type: "date",
                  mask: '##/##/####',
                  active: true
                }
              ]
            },
            {
              name: "Cartão",
              key: "cardCode",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Número do Cartão",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },{
              name: "Matrícula",
              key: "registration",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Número da Matrícula",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
            {
              name: "Benefício",
              key: "benefitName",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Nome do Benefício",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
            {
              name: "Operadora",
              key: "carrierName",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Nome da Operadora",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
            {
              name: "Plano",
              key: "planName",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Nome do Plano",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },
            {
              name: "Contrato/Subcontrato",
              key: "subcontractId",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Selecione um contrato",
                  value: "",
                  type: "combobox",
                  items: this.contracts,
                  hasSubItem: true,
                  subItemName: 'Selecione um subcontrato',
                  subItems: this.subContracts,
                  active: true,
                },
              ],
            },
            /*{
              name: "Subcontrato",
              key: "subcontractName",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Nome do Subcontrato",
                  value: "",
                  type: "input",
                  active: true
                }
              ]
            },*/
            {
              name: "Vigência",
              key: "planStartDate",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Data de Vigência",
                  value: "",
                  type: "date",
                  mask: '##/##/####',
                  active: true
                }
              ]
            },
            {
              name: "Status",
              types: [
                {
                  name: "Ativos",
                  value: "true",
                  type: "select",
                  active: false
                },
                {
                  name: "Inativos",
                  value: "false",
                  type: "select",
                  active: false
                }
              ]
            },
            {
              name: "Situação do Beneficiário",
              key: "beneficiarySituation",
              active: false,
              allowClose: false,
              types: [
                {
                  name: "Selecione a situação",
                  value: "",
                  type: "combobox",
                  items: [
                    "ATIVOS",
                    "INATIVOS",
                    "TODOS"
                  ],
                  active: true,
                },
              ],
            },
        );

        let beneficiaryTypes =
            this.user.menu
                .filter(menu => menu.id == this.$store.state.currentMenu.id)
                .map(menu => menu.subMenus)
                .reduce(Array)
                .map(menu => ({
                  name: menu.title,
                  value: menu.options,
                  type: "select",
                  active: true,
                  selected: true
                }));

        if(beneficiaryTypes.length){
          let beneficiaryTypesFilter = {
            name: "Solicitação",
            key: "type",
            types: beneficiaryTypes
          };

          this.filters.push(beneficiaryTypesFilter);
        }
      },
      async getContracts() {
        this._contractService.FindByStatus()
            .then((response) => {
              if(response && response.data && response.data.records) {
                response.data.records.forEach((contract) => {
                  let text = contract.contract_owner_id ? contract.contract_owner_id.name : "";
                  text += contract.policy ? " - " + contract.policy : "";
                  text += contract.benefit_id ? " - " + contract.benefit_id : "";
                  text += contract.carrier_id  ? "/" + contract.carrier_id.xipp_commercial_name : "";

                  if (contract.contract_owner_id) {
                    this.contracts.push({
                      accountID: contract.contract_owner_id.id,
                      value: contract.id,
                      text: text,
                    });
                  }
                });
              }
        })
        .catch(() => {
          this.showmsg({ text: "Não foi possível carregar os contratos do filtro.", type: "error" })
        })
      },
      async loadComboBoxSubItems(contractId){
        this.loading(true);
        await this.loadSubcontractsFilteredByContractId(contractId)
        this.loading(false);
      },
      async loadSubcontractsFilteredByContractId(contractId){
        this.subContracts = [];

        await this._contractService.FindSubContracts(contractId)
          .then((response) => {
            if(response && response.data && response.data.records && response.data.records.length > 0) {
              response.data.records.forEach((contract) => {

                let text = contract.contract_owner_id ? contract.contract_owner_id.name : "";
                text += contract.subcontract_number ? " - " + contract.subcontract_number : "";
                text += contract.benefit_id
                    ? " - " + contract.benefit_id : "";
                text += contract.carrier_id ? "/" + contract.carrier_id.xipp_commercial_name : "";

                if (contract.contract_owner_id) {
                  this.subContracts.push(Object.assign({}, {
                    accountID: contract.contract_owner_id.id,
                    value: contract.id,
                    text: text,
                  }));
                }
              })
            }
            this.filters[8].types[0].value = ""
            this.filters[8].types[0].subItems = this.subContracts
          })
          .catch(() => {
            this.showmsg({ text: "Não foi possível carregar os subcontratos do filtro.", type: "error" })
          })
      }
    },
  };
</script>

<style scoped>

</style>
