export default {
  state: {
    carrierBillingAction: null,
    carrierBillingId: 0,
    isOpenDialog: false,
    manuallyNotificationType: null,
    financialGroupId: 0,
  },
  getters: {
    getIsOpenDialog: (state) => state.isOpenDialog,
    getCarrierBillingId: (state) => state.carrierBillingId,
    getManuallyNotificationType: (state) => state.manuallyNotificationType,
    getCarrierBillingAction: (state) => state.carrierBillingAction,
    getFinancialGroupId: (state) => state.financialGroupId,
  },
  mutations: {
    setCarrierBillingAction(state, action) {
      state.carrierBillingAction = action;
    },
    setCarrierBillingId(state, id){
      state.carrierBillingId = id;
    },
    setIsOpenDialog(state, status){
      state.isOpenDialog = status;
    },
    setManuallyNotificationType(state, type){
      state.manuallyNotificationType = type
    },
    setFinancialGroupId(state, financialGroupId) {
      state.financialGroupId = financialGroupId;
    }
  },
  actions: {
    setCarrierBillingAction(context, action) {
      context.commit('setCarrierBillingAction', action);
    },
    setCarrierBillingId(context, id){
      context.commit('setCarrierBillingId', id);
    },
    setIsOpenDialog(context, status){
      context.commit('setIsOpenDialog', status);
    },
    setManuallyNotificationType(context, type){
      context.commit('setManuallyNotificationType', type);
    },
    setFinancialGroupId(context, id) {
      context.commit('setFinancialGroupId', id);
    }
  },
}
