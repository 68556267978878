const state = {
  users: [],
};

const getters = {
users: (state) => state.users,
};

const mutations = {
setUsers(state, data) {
  state.users = data;
},
};

export default {
state,
getters,
mutations,
};