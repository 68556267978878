<template>
  <v-content app>
    <toolbar />
    <v-progress-linear
      color="primary"
      :indeterminate="app.progress"
      :active="app.progress"
      height="3"
      class="ma-0"
    ></v-progress-linear>
    <menuLeft />
    <menuRight />
    <breadcrumbs v-if="navigation" class="scrollbarMenu"/>
    <keep-alive>
      <router-view name="keepalive"></router-view>
    </keep-alive>
    <router-view></router-view>
    <footerx />
  </v-content>
</template>

<script>
import toolbar from "./../../components/structure/toolbar";
import menuLeft from "./../../components/structure/menuLeft";
import menuRight from "./../../components/structure/menuRight";
import breadcrumbs from "./../../components/structure/breadcrumbs";
import footerx from "./../../components/structure/footerx";
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  components: {
    menuLeft,
    menuRight,
    toolbar,
    breadcrumbs,
    footerx
  },
  methods: {
    ...mapGetters(["isMobileSize"]),
    ...mapMutations(["progress"]),
    initiateProgressBar() {
      this.progress(true);
      setTimeout(() => {
        this.progress(false);
      }, 1000);
    }
  },
  computed: {
    ...mapState(["navigation", "app"])
  },
  watch: {
    $route() {
      this.initiateProgressBar();
    }
  },
  beforeMount() {
    this.initiateProgressBar();
  }
};
</script>

<style>
.v-input .v-icon {
  font-size: 15px !important;
}
</style>