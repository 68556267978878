<template>
    <div v-if="hasPermissionToViewComponent()" >
        <v-menu
                v-model="menu"
                :close-on-content-click="false"
                offset-y
        >
            <template v-slot:activator="{ on, attrs }">

                <v-btn
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                >
                    <v-badge
                            :content="countUnreadNotifications"
                            :value="countUnreadNotifications"
                            color="red"
                            overlap
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon dark>fas fa-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <v-card class="pa-2">
                <v-card-title class="font-weight-bold">
                        Notificações
                </v-card-title>
                <v-card-subtitle class="pb-0">
                    <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
                        <v-switch
                                hide-details
                                light
                                class="pa-0 ma-0"
                                label="filtrar não lidas"
                                v-model="toggleUnread"
                                :disabled="busy"
                        />
                        <v-spacer/>
                        <v-btn right text style="text-transform: none" @click="markAllNotificationsAsRead">Marcar tudo como lido</v-btn>
                    </v-card-actions>
                    <v-card-actions class="px-0" v-if="$resize && $mq.below(window.mobileSize)">
                        <v-switch
                                hide-details
                                light
                                class="pa-0 ma-0"
                                label="filtrar não lidas"
                                v-model="toggleUnread"
                                :disabled="busy"
                        />
                    </v-card-actions>
                    <v-card-actions class="px-0 pt-0" v-if="$resize && $mq.below(window.mobileSize)">
                        <span style="text-transform: none; text-decoration: underline;" @click="markAllNotificationsAsRead">Marcar tudo como lido</span>
                    </v-card-actions>

                </v-card-subtitle>

                <v-divider/>

                <v-list
                        :width="$resize && $mq.above(window.mobileSize) ? '500px' : '260px'"
                        :max-height="$resize && $mq.above(window.mobileSize) ? '400px' : '300px'"
                        class="overflow-y-auto py-0"
                        v-scroll.self="onScroll"
                        three-line
                >

                    <v-progress-linear
                            :active="busy"
                            :indeterminate="busy"
                            absolute
                            color="deep-purple accent-4"
                    ></v-progress-linear>

                    <div v-for="(notification, index) in notifications" :key="notification.id">
                        <v-divider v-if="index != 0" />
                        <v-list-item class="v-list-item--link">
                            <v-list-item-icon v-if="$resize && $mq.above(window.mobileSize)" style="align-self: normal;">
                                <v-icon>{{notification.message.icon ? notification.message.icon : 'far fa-comment-dots'}}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content @click="clickOnNotification(notification)">
                                <v-list-item-title :class="!notification.read ? 'font-weight-bold' : ''" v-html="$util.sanitize(notification.message.title)"/>
                                <truncate :class="!notification.read ? 'text--primary' : 'text--secondary' " action-class="text-truncate-custom-class" clamp="mostrar mais" :length="100" less="mostrar menos" type="html" :text="notification.message.body"></truncate>
                                <v-list-item-subtitle class="font-weight-bold">{{notification.notificationOpenTime}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn
                                        icon
                                        @click="changeNotificationStatus(notification)"
                                        :disabled="busy"
                                        :title="notification.read ? 'Marcar como não lido' : 'Marcar como lido'"
                                >
                                    <v-icon small>{{notification.read ? 'far fa-envelope-open' : 'far fa-envelope'}}</v-icon>
                                </v-btn>
                                <v-btn
                                        icon
                                        @click="removeNotification(notification)"
                                        :disabled="busy"
                                        title="Excluir"
                                >
                                    <v-icon small>far fa-trash-alt</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </div>
                    <div v-if="!notifications.length && !busy">
                        <v-divider  />
                        <v-list-item >
                            <v-list-item-content>
                                <v-list-item-title>Nenhum notificação!</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </v-list>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            text
                            @click="menu = false"
                    >
                        Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-menu>
    </div>
</template>

<script>

import UserNotificationService from "../../services/notification/UserNotificationService";
import {mapGetters, mapMutations, mapState} from "vuex";
import truncate from 'vue-truncate-collapsed';

export default {
    name: "Notification",
    components: {
        truncate
    },
    data: () => ({
        notifications: [],
        menu: false,
        busy: false,
        size: 10,
        nextPage: 0,
        lastPage: false,
        countUnreadNotifications: null,
        toggleUnread: false,
    }),
    computed:{
        ...mapState(["window"]),
        ...mapGetters(["user"]),
    },
    methods:{
        ...mapMutations(["showmsg"]),
        hasPermissionToViewComponent(){
            if(this.user.authorities){
                let authority = this.user.authorities.filter(a => a.authority === "notifications_component");
                return authority.length > 0;
            }else
                return false;
        },
        getNotifications(){
            if(this.lastPage)
                return;

            this.busy = true;
            this._userNotificationService.getNotifications(this.nextPage,this.size)
                .then(res => {
                    Array.prototype.push.apply(this.notifications, res.content);
                    this.lastPage = res.last;
                    if(!this.lastPage)
                        this.nextPage++;
                })
                .catch(() => this.showmsg({ text: "Não foi possível carregar notificações!", type: "error" }))
                .finally(() => this.busy = false);
        },
        toggleUnreadNotifications(){
            this.clearNotifications();
            if(this.toggleUnread){
                this.getUnreadNotifications();
            }else{
                this.getCountUnreadNotifications();
                this.getNotifications();
            }
        },
        getUnreadNotifications(){
            if(this.lastPage)
                return;

            this.busy = true;
            this._userNotificationService.getUnreadNotifications(this.nextPage,this.size)
                .then(res => {
                    Array.prototype.push.apply(this.notifications, res.content);
                    this.lastPage = res.last;
                    if(!this.lastPage)
                        this.nextPage++;
                })
                .catch(() => this.showmsg({ text: "Não foi possível carregar notificações!", type: "error" }))
                .finally(() => this.busy = false);
        },
        clickOnNotification(notification){

            if(this.busy)
                return;

            if(notification.message.link){
                this.$router.push(notification.message.link);
                this.menu = false;
            }

            if(!notification.read){
                this.busy = true;
                this._userNotificationService.markNotificationAsRead(notification.id)
                    .then(() => {
                        notification.read = true;
                        return this.getCountUnreadNotifications();
                    })
                    .catch(() => this.showmsg({ text: "Não foi possível atualizar notificação!", type: "error" }))
                    .finally(() => this.busy = false);
            }

        },
        async changeNotificationStatus(notification){
            try{
                this.busy = true;

                if(notification.read){
                    await this._userNotificationService.markNotificationAsUnread(notification.id).then(res => notification.read = false);
                }else{
                    await this._userNotificationService.markNotificationAsRead(notification.id).then(res => notification.read = true);
                }

                await this.getCountUnreadNotifications();

            } catch (e) {
                this.showmsg({ text: "Não foi possível atualizar notificação!", type: "error" });
            }
            finally {
                this.busy = false;
            }
        },
        removeNotification(notification){
            this.busy = true;
            this._userNotificationService.removeNotification(notification.id)
                .then(() => {
                    this.notifications.splice(this.notifications.indexOf(notification), 1);
                    return this.getCountUnreadNotifications();
                })
                .catch(() => this.showmsg({ text: "Não foi possível remover notificação!", type: "error" }))
                .finally(() => this.busy = false);
        },
        markAllNotificationsAsRead(){

            if(this.busy || !this.countUnreadNotifications)
                return;

            this.busy = true;
            this._userNotificationService.markAllNotificationsAsRead()
                .then(() => {
                    this.notifications.forEach(notification => notification.read = true);
                    return this.getCountUnreadNotifications();
                })
                .catch(() => this.showmsg({ text: "Não foi possível atualizar notificação!", type: "error" }))
                .finally(() => this.busy = false);
        },
        async getCountUnreadNotifications(){
            await this._userNotificationService.countUnreadNotifications()
                .then(res => this.countUnreadNotifications = res.data);
        },
        onScroll (e) {
            //scroll bottom
            if(!this.busy && e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 100){
                if(this.toggleUnread){
                    this.getUnreadNotifications();
                }else{
                    this.getNotifications();
                }
            }
        },
        clearNotifications(){
            this.nextPage = 0;
            this.lastPage = false;
            this.notifications = [];
        }
    },
    watch:{
      menu(value){
          if(value){
              this.toggleUnread = false;
              this.clearNotifications();
              this.getNotifications();
              this.getCountUnreadNotifications();
          }
      },
        toggleUnread(){
            this.toggleUnreadNotifications();
        }
    },
    created() {
        this._userNotificationService = new UserNotificationService();
        this.getCountUnreadNotifications();
        setInterval(() => {
            this.getCountUnreadNotifications();
        }, 1000 * 60);
    }
}
</script>

<style>
.text-truncate-custom-class{
    color: var(--v-primary-base);
}
</style>
