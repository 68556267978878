import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"color":"grey","icon":""}},on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-ellipsis-v")])],1)]}}])},[_c(VList,_vm._l((_vm.options),function(option,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.$emit('select', option.value)}}},[_c(VListItemTitle,[_vm._v(_vm._s(option.title))])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }