<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title class="primary--text">
        Eventos
      </v-card-title>

      <v-card-text v-if="!loading && !timeLine.length">Nenhum registro neste atendimento!</v-card-text>

      <v-card-text class="pl-0" v-else>
        <v-timeline
            dense
        >
          <v-timeline-item
              v-for="(item, index) in timeLine"
              :key="item.id"
              color="primary"
              :left="isClientItem(item) ? false : true"
              :right="isClientItem(item) ? true : false"
              fill-dot
              :hide-dot="!showDate(item, index)"
              large
          >

            <template v-slot:icon>
              <span v-if="showDate(item, index)" class="white--text">{{ item.formatDate }}</span>
            </template>

            <v-row>
              <v-col class="pa-0">
                <v-card :color="isClientItem(item) ? clientCardColor : brokerCardColor "
                        @click="item.hasMoreThanOneLine ? item.showMore = !item.showMore : ' ' ">
                  <v-card-text v-if="item.type === 'comment'" v-show="item.text !== ''" :class="'pb-0'">
                    <v-expand-transition v-bind="item.commentFirstLine">
                      <v-col>
                        <v-row align-content="stretch" v-show="!item.showMore"
                               v-html="item.hasMoreThanOneLine ? $util.sanitize(getFirstLineComment(item.text)) : $util.sanitize(item.text)"
                               class="showOnlyFirstLine overflow-auto"></v-row>
                      </v-col>

                    </v-expand-transition>
                    <v-expand-transition v-bind="item.commentFull">
                      <v-col>
                        <v-row align-content="stretch" v-show="item.showMore" v-html="$util.sanitize(item.text)"
                               class="showFullContent overflow-auto"></v-row>
                      </v-col>
                    </v-expand-transition>
                    <v-row>
                      <v-col :class="'pa-0' + (item.hasMoreThanOneLine  ? ' ' : ' d-none ')" align="right">
                        <v-btn small icon>
                          <div style="text-transform: initial">
                            {{ item.showMore ? 'Exibir menos' : 'Exibir mais' }}
                          </div>
                          <v-icon>
                            {{ item.showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                          </v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="pa-0 mb-1" align="right">
                        {{ item.formatHour }}
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text v-else :class="'pb-0' + (isClientItem(item) ? ' white--text ' : ' black-text ')"
                               @click="downloadFile(item)" style="cursor: pointer">
                    <v-row>
                      <v-col class="pt-0" cols="12">
                        <v-icon :class="'mr-1' + (isClientItem(item) ? ' white--text ' : ' black-text ')">{{
                            item.icon
                          }}
                        </v-icon>
                        {{ item.text }}
                      </v-col>
                      <v-col class="pa-0 mb-1" cols="12" align="right">
                        {{ item.formatHour }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-speed-dial
        v-model="fab"
        bottom
        right
        fixed
    >
      <template v-slot:activator>
        <v-btn
            v-model="fab"
            color="primary"
            dark
            fab
            @click="$router.push(`/atendimentos/${caseId}/evento/`)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>


    </v-speed-dial>

  </v-container>
</template>

<script>
import moment from "moment";
import ComentsService from "../../services/odoo/ComentsService";
import ContentVersionService from "../../services/odoo/ContentVersionService";
import {extension, lookup} from "mime-types";
import {saveAs} from "file-saver";
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
  props: {
    caseId: {
      type: Number,
      required: true
    },
    externalId: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState(["window"]),
  },
  data: () => ({
    regexRGB: /\brgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/g,
    headers: [
      {text: "Comentário", value: "text"},
      {text: "Autor", value: "author"},
      {text: "Data", value: "date"}
    ],
    comments: [],
    item: {
      arquivos: []
    },
    contentDocumentIds: [],
    timeLine: [],
    fab: false,
    clientCardColor: '#595fc0',
    brokerCardColor: '#e9e9e9',
  }),
  methods: {
    ...mapMutations(["loading"]),
    ...mapGetters(["isMobileSize", "getWidth"]),
    async requestComments() {
      await this._comentsService
          .FindById(this.caseId, 'helpdesk.ticket', 'comment', 'Discussões')
          .then(async res => {
            await this.configureComments(res.data.records);
          })
    },
    async configureComments(commentData) {
      if (commentData.length) {
        commentData.forEach((comment) => {
          //change color rgb from comment body (html)

          this.comments.push({
            id: comment && comment.id ? comment.id : '',
            text: comment && comment.body ? comment.body : '',
            formatDate: comment && comment.create_date ? moment(comment.create_date.replace(" ", "T") + '.000Z').format("DD/MM") : '',
            formatHour: comment && comment.create_date ? moment(comment.create_date.replace(" ", "T") + '.000Z').format("HH:mm") : '',
            showMore: false,
            hasMoreThanOneLine: comment && comment.body ? this.commentHasMoreThanOneLine(comment.body) : '',
            date: comment && comment.create_date ? comment.create_date : '',
            author: comment && comment.author_id ? comment.author_id : '',
            type: 'comment',
            icon: 'mdi-text',
            email: comment ? comment.email_from : null,
          });
          this.configureAttachments(comment.attachment_ids, comment.email_from);
        });
      }
    },
    getAttachmentAuthor(attach) {
      if (attach.Description && attach.Description == "CLIENT")
        return "CLIENT";
      else return "BROKER";
    },
    configureAttachments(attachmentData, email) {
      attachmentData.forEach(attach => {
            if (this.item.arquivos.length) {
              if (this.item.arquivos.some(arquivo => arquivo.id === attach.id)) {
                return;
              }
            }
            var _correct_extension = attach && attach.mimetype ? extension(attach.mimetype) : ''
            this.item.arquivos.push({
              id: attach && attach.id ? attach.id : '',
              name: attach && attach.name ? attach.name : '',
              extension: _correct_extension,
              mimeType: attach.mimetype,
              icon: this.convertToIcon(_correct_extension),
              downloading: false,
              formatDate: attach && attach.create_date ? moment(attach.create_date.replace(" ", "T") + '.000Z').format("DD/MM") : '',
              formatHour: attach && attach.create_date ? moment(attach.create_date.replace(" ", "T") + '.000Z').format("HH:mm") : '',
              date: attach && attach.create_date ? attach.create_date : '',
              author: '',
              email: email
            });
          }
      );
    },
    convertToIcon(fileExtension) {
      let configuredIcon = "file";
      const types = {
        word: ["doc", "dot", "webk", "docx", "docm", "dotx", "dotm", "docb"],
        excel: [
          "xls",
          "xlt",
          "xlm",
          "xlsx",
          "xlsm",
          "xltx",
          "xltm",
          "xlsb",
          "xla",
          "xlam",
          "xll",
          "xlw"
        ],
        powerpoint: [
          "ppt",
          "pot",
          "pps",
          "pptx",
          "pptm",
          "potx",
          "potm",
          "ppam",
          "ppsx",
          "ppsm",
          "sldx",
          "sldm"
        ],
        pdf: ["pdf"],
        csv: ["csv"],
        alt: ["txt"],
        image: ["png", "jpg", "jpeg", "svg"]
      };

      for (const typeName in types) {
        if (types[typeName].includes(fileExtension)) {
          configuredIcon = typeName;
        }
      }
      return `fas fa-file-${configuredIcon}`;
    },
    downloadFile(file) {
      this.loading(true);
      this._contentVersionService
          .getDownloadFile(file.id)
          .then(attachmentData => {
            var file_name = file.name;
            var extension_dot = "."+file.extension
            if(!file_name.endsWith(extension_dot)) {
              file_name = file_name + extension_dot
            }
            saveAs(
                new Blob([attachmentData.data], {type: lookup(file.mimeType)}),
                file_name
            );
          })
          .finally(() => (this.loading(false)));
    },
    async requestAttachments() {
      this.loading(true);
      await this._contentVersionService
          .FindAll(this.caseId, "helpdesk.ticket")
          .then(response => {
            if (
                response == null ||
                response.data == null ||
                response.data.records == null
            ) {
              return true;
            }
            return this.configureAttachments(response.data.records);
          })
    },
    generateTimeLineItens() {
      if (this.comments.length) {
        Array.prototype.push.apply(this.timeLine, this.comments);
      }

      if (this.item.arquivos.length) {
        let files = this.item.arquivos.map(file => ({
          id: file.id,
          text: file.name,
          date: file.date,
          author: file.author,
          type: 'file',
          icon: file.icon,
          downloading: false,
          formatDate: file.formatDate,
          formatHour: file.formatHour,
          extension: file.extension,
          name: file.name,
          email: file.email
        }));

        Array.prototype.push.apply(this.timeLine, files);
      }

      this.timeLine.sort(function (a, b) {
        return (b.date > a.date) ? 1 : (b.date < a.date) ? -1 : 0;
      });

    },
    isClientItem(item) {
      return !item.author || item.author == '' || item.email == this.getUserEmail();
    },
    getUserEmail() {
      return this.$util.getSession() && this.$util.getSession().email ? this.$util.getSession().email : null;
    },
    showDate(item, index) {
      if (this.timeLine[index - 1] != null && this.timeLine[index - 1].formatDate === item.formatDate)
        return false;
      else
        return true;
    },
    commentHasMoreThanOneLine(comment) {
      return comment.indexOf('<p>') > -1 ||
          (comment.length > 75 && this.getWidth() > 1650 && !this.isMobileSize()) ||
          (comment.length > 60 && this.getWidth() <= 1650 && !this.isMobileSize()) ||
          (comment.length > 30 && this.isMobileSize());
    },
    getFirstLineComment(comment) {
      let size;
      if (comment.indexOf('<p>') > -1) {
        size = comment.indexOf('</p>') + 4;
      } else
        size = this.isMobileSize() ? 30 : this.getWidth() > 1650 ? 75 : 60;

      return comment.substring(0, size) + ' ...'
    },
    async loadCommentsAndAttachments() {
      this.loading(true);
      this._comentsService = new ComentsService();
      this._contentVersionService = new ContentVersionService();

      await this.requestComments()
          .finally(() => {
        this.loading(false);
      });
      if (this.externalId)
        await this.requestAttachments()
            .finally(() => {
          this.loading(false);
        });

    }
  },
  async created() {
    await this.loadCommentsAndAttachments();
    this.generateTimeLineItens();
  }
};
</script>
<style>

.v-application p {
  margin-bottom: 0;
}

</style>

