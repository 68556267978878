<template>
  <v-container fluid grid-list-md class="px-2">
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>

    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <DrawerFilterer title="Campanha" :filters="filters" :data="items" />
      <v-flex xs12>
        <ProgressiveLoader :disabled="busy" @reachedEnd="loadItems">
          <v-card>
            <v-card-title class="primary--text">
              <v-spacer />
              <v-btn
                fab
                bottom
                right
                fixed
                color="primary"
                dark
                @click="toggleFilterDrawer"
              >
                <v-icon>fas fa-filter</v-icon>
              </v-btn>
            </v-card-title>

            <v-data-table
              loading-text="Carregando itens..."
              disable-filtering
              disable-pagination
              disable-sort
              hide-default-footer
              :headers="headers"
              :items="items"
              :loading="loading"
              item-key="id"
              @click:row="(item) => handleItemSelection(item)"
            >
              <template v-slot:header> </template>
              <template v-slot:no-data>
                <v-alert :value="true" type="info" v-show="!loading" outlined
                  >Nenhum item foi encontrado</v-alert
                >
              </template>
            </v-data-table>
          </v-card>
        </ProgressiveLoader>
      </v-flex>
    </v-layout>
    <template v-else>
      <FabFilterer
        title="Campanha"
        :filters="filters"
        :data="items"
        @queryUpdate="items"
        @filtersRemoved="items"
        @clearFilters="items"
      />
      <ProgressiveLoader :disabled="busy" @reachedEnd="loadItems">
        <v-data-iterator
          hide-default-footer
          hide-default-header
          disable-pagination
          :items="items"
        >
          <template v-slot:no-data>
            <v-alert :value="false" type="info" v-show="!loading" outlined
              >Nenhum item foi encontrado</v-alert
            >
          </template>
          <template v-slot:default="props">
            <v-row v-for="item in props.items" :key="item.id">
              <v-col cols="12">
                <v-card
                  class="elevation-5 pb-0"
                >
                  <v-card-text class="pb-0">
                    <v-row dense class="mb-4">
                      <template v-for="header in headers">
                        <v-col :cols="header.cols" :key="header.value">
                          <v-row no-gutters>
                            <v-col
                              cols="12"
                              class="black--text font-weight-bold"
                              >{{ header.text }}</v-col
                            >
                            <v-col
                              cols="12"
                              :class="
                                (header.bold ? 'font-weight-bold' : '') +
                                ' silver--text pa-0'
                              "
                              >{{ item[header.value] }}</v-col
                            >
                          </v-row>
                        </v-col>
                      </template>
                      <v-col
                        cols="12"
                        class="d-flex primary--text justify-center text-sm-center mb-1"
                      >
                        <label class="primary--text">
                          Clique para detalhar
                        </label>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </ProgressiveLoader>
    </template>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import FabFilterer from "@/components/fab-filterer";
import DrawerFilterer from "@/components/drawer-filterer";
import ProgressiveLoader from "@/components/progressive-loader";
// import CaseService from "../../services/salesforce/CaseService";

export default {
  name: "CampanhaResultado",
  components: { FabFilterer, DrawerFilterer, ProgressiveLoader },
  data: () => ({
    items: [],
    filteredItems: [],
    loading: false,
    search: "",
    busy: false,
    paginations: 0,
    maximumItems: 0,
    loadedItems: 0,
    describe: null,
    pageNext: null,
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapState(["window"]),
    filters() {
      return [
        {
          name: "Email",
          key: "email",
          types: [
            {
              name: "Email",
              value: "",
              type: "input",
              active: true,
            },
          ],
        },
        {
          name: "Evento",
          key: "evento",
          types: [
            {
              name: "evento",
              value: "",
              type: "input",
              active: true,
            },
          ],
        },
      ];
    },
    headers() {
      const baseHeaders = [
        { text: "Evento", value: "evento", cols: "6" },
        { text: "Pessoa", value: "pessoa", cols: "12" },
        { text: "Email", value: "email", cols: "6" },
        { text: "Visto em", value: "vistoEm", cols: "6" },
      ];
      if (this.$resize && this.$mq.above(this.window.mobileSize)) {
        baseHeaders.unshift({ text: "", value: "", sortable: false, cols: "" });
      }
      return baseHeaders;
    },
    defaultQuery() {
      let query = "?IsClosed=false";

      //Types
      query += this.queryTypes;

      return query;
    },
    queryTypes() {
      let types = this.filters
        .filter((filter) => filter.key === "type")
        .map((filter) => filter.types)
        .reduce(Array)
        .map((types) => types.value)
        .join(",");

      return "&type=" + types;
    },
  },
  methods: {
    ...mapMutations(["showmsg"]),
    addItem(obj) {},
    setFilteredItems(newItems) {},
    concatenFilteredItems(newItems) {},
    toggleHabilityToSearch(value) {
      this.$store.commit("setAbledToSearch", value);
    },
    toggleFilterDrawer() {
      this.$store.commit("setFilterDrawer", !this.$store.state.drawerOpen);

      if (this.$store.state.drawerOpen) {
        this.toggleHabilityToSearch(false);
      }
    },
    loadItems() {
      this.items.push({
        id: this.items.length + 1,
        evento: "Enviado",
        pessoa: "Pessoa " + (this.items.length + 1),
        email: "pessoa" + (this.items.length + 1) + "@gmail.com",
        vistoEm: "10/10/2020",
      });
      this.items.push({
        id: this.items.length + 1,
        evento: "Visto",
        pessoa: "Pessoa " + (this.items.length + 1),
        email: "pessoa" + (this.items.length + 1) + "@gmail.com",
        vistoEm: "11/10/2020",
      });
    },
  },
  created() {
    this.loadItems();
  },
};
</script>

<style>
#atendimento-listar th {
  font-weight: bold;
  color: #28253f !important;
  font-size: 14px;
}
</style>
