<template>
  <iframe v-if="!isLoading && encryptedUserId" :src="`${getUrlNotificationPreferences}`" height="100%" width="100%" scrolling="auto" frameborder="0"/>
</template>

<script>
import SecurityService from "../../services/security/SecurityService";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "notification-preferences",
  data: () => ({
    encryptedUserId: null,
    isLoading: false,
    userId: 0,
    userName: null,
    lastMenu: null,
  }),
  computed: {
    ...mapGetters(["currentMenu"]),
    getUrlNotificationPreferences() {
        const session = this.$util.getSession();
        const tinnyToken = session.tokenSecurityService ? session.tokenSecurityService.tinnyToken : null;
        return `${process.env.VUE_APP_ACCOUNT_URL}${process.env.VUE_APP_ACCOUNT_NOTIFICATION_PREFERENCES_PATH}?tinnyToken=${tinnyToken}&data=${this.getEncryptedUserIdBase64}.${this.getUserNameBase64}.${this.getAppCodeBase64}`;
    },
    getAppCodeBase64() {
      return btoa(`${process.env.VUE_APP_ACCOUNT_APP_CODE}`);
    },
    getEncryptedUserIdBase64(){
      return btoa(`${this.encryptedUserId}`);
    },
    getUserNameBase64() {
      return btoa(`${this.userName}`);
    }
  },
  methods: {
    ...mapMutations(["showmsg", "loading", "setCurrentMenu"]),
    async getCurrentSessionData(){
      await this._securityService.getCurrent()
        .then(response => {
          if(response && response.data && response.data && response.data.user){
            this.userId =  response.data.user.id;
            this.userName = response.data.user.name;
          }
        })
        .catch(() => {
          this.showmsg({text: "Não foi possível carregar as informações do usuário.", type: "error"});
        })
    },
    async encryptUserId(){
      const cryptography = {
        value: this.userId
      }

      await this._securityService.EncryptData(cryptography)
          .then(response => {
            if(response.data) {
              this.encryptedUserId = response.data
            }
          })
          .catch(() => {
            this.showmsg({text: "Não foi possível criptografar a informação.", type: "error"});
          })
    },
    changeBreadcrumbs(){
      this.lastMenu = this.currentMenu;

      const menu = {
        icon: 'fas fa-bell',
        title: 'Preferências de Notificações'
      }

      this.setCurrentMenu(menu);
    }
  },
  destroyed() {
    this.setCurrentMenu(this.lastMenu);
  },
  beforeCreate() {
    this._securityService = new SecurityService();
  },
  async created() {
    this.isLoading = true;
    this.loading(this.isLoading);
    this.changeBreadcrumbs()
    await this.getCurrentSessionData();
    await this.encryptUserId();
    setTimeout(()=>{
      this.isLoading = false;
      this.loading(this.isLoading);
    },3000)
  }
};
</script>

<style scoped>
</style>
