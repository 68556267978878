import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VFlex,{attrs:{"xs12":"","fill-height":""}},[_c(VCard,{attrs:{"height":"95%"}},[_c(VCarousel,{attrs:{"height":"100%"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCarouselItem,[_c('Metabase',{attrs:{"resourceId":4,"path-params":"#bordered=true&titled=false"}})],1),_c(VCarouselItem,[_c('Metabase',{attrs:{"resourceId":4,"path-params":"#bordered=true&titled=false"}})],1),_c(VCarouselItem,[_c('Metabase',{attrs:{"resourceId":4,"path-params":"#bordered=true&titled=false"}})],1)],1)],1),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }