<template>
  <v-container fluid fill-height>
    <!-- <v-layout justify-center align-center> -->
      <!-- <v-flex xs12 fill-height> -->
        <!-- <div height="100%"> -->
        <iframe :src="`http://172.18.0.1:8081/?token=${this.user.tokenSecurityService.access_token}`" height="100%" width="100%" frameborder="0"/>
        <!--<iframe :src="`https://app.stg.drive.firecodeit.net?token=${this.user.tokenSecurityService.access_token}`" height="100%" width="100%" frameborder="0"/>-->
        <!-- </div> -->
      <!-- </v-flex> -->
    <!-- </v-layout> -->
  </v-container>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: 'Arquivo',
  computed: {
    ...mapGetters(['user']),
  },
  data: function() {
      return {
          iframeUrl: null,
      };
  },
  mounted(){
      this.iframeUrl = `https://app.stg.drive.firecodeit.net?token=${this.user.tokenSecurityService.access_token}`;
  }
};

</script>
