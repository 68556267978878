import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!this.$route.meta.hideMenu)?_c(VNavigationDrawer,{staticClass:"scrollbarMenu customShadow",attrs:{"mini-variant":_vm.menu.leftmini,"mini-variant-width":80,"floating":"","app":"","hide-overlay":"","dark":"","color":"primary","mobile-break-point":_vm.window.mobileSize},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('label',{staticClass:"white--text",staticStyle:{"font-size":"small"}},[_vm._v("Versão: "+_vm._s(_vm.appBuildVersion))])])]},proxy:true}],null,false,452664500),model:{value:(_vm.menul),callback:function ($$v) {_vm.menul=$$v},expression:"menul"}},[_c(VList,{staticClass:"layout column"},[_c(VCol,{staticClass:"text-start pt-1",attrs:{"cols":"12"}},[_c('img',{style:(_vm.menu.leftmini ? 'width: 50px' : 'width: 70px'),attrs:{"src":"/img/icons/logos/xipp-rh-protegido-logo-redondo.png"}}),_c('img',{style:(_vm.menu.leftmini ? 'margin-left: -10px; width: 70px' : 'margin-left: -25px; width: 170px;'),attrs:{"src":"/img/icons/logos/xipp_logo_horizontal_branco_fundo_transparente.png"}})]),_vm._l((_vm.items),function(item,index){return _c('div',{key:item.title + '_' + index,staticClass:"spaceLeftDrawer"},[(item.type === 'EXPAND')?_c(VListGroup,{key:item.id,attrs:{"no-action":"","color":"secundary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemAvatar,{attrs:{"title":item.title}},[_c(VIcon,{staticClass:"font-weight-bold",attrs:{"id":"icon_menu"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},_vm._l((item.subMenus),function(subMenu){return _c(VListItem,{key:subMenu.id,class:_vm.currentMenu() && subMenu.id === _vm.currentMenu().id
              ? 'selectedItem'
              : '',on:{"click":function($event){return _vm.link(subMenu)}}},[_c(VListItemContent,[_c(VListItemTitle,{class:_vm.currentMenu() && subMenu.id === _vm.currentMenu().id
                  ? 'menuLeft primary--text'
                  : 'menuLeft'},[_vm._v(_vm._s(subMenu.title)+" ")])],1)],1)}),1):_c(VListItem,{class:_vm.currentMenu() && item.id === _vm.currentMenu().id ? 'selectedItem' : '',on:{"click":function($event){return _vm.link(item)}}},[_c(VListItemAvatar,{attrs:{"title":item.title}},[_c(VIcon,{class:_vm.currentMenu() && item.id === _vm.currentMenu().id
                ? 'primary--text'
                : '',attrs:{"id":"icon_menu"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"menuLeft",class:_vm.currentMenu() && item.id === _vm.currentMenu().id
                ? 'menuLeft primary--text'
                : 'menuLeft'},[_vm._v(_vm._s(item.title))])],1)],1)],1)})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }