/* eslint-disable */
import HttpService from './HttpService'

export default class CarrierService {
  constructor() {
    this._httpService = new HttpService();
  }

  async findAllCarriersByFilters(params = "") {
    return await this._httpService.get(`/api/odoo/carrier${params}`);
  }

  async findAllCarriersByFiltersV2(params = "") {
    return await this._httpService.get('/api/odoo/carrier/v2', params);
  }

  async findById(id) {
    return await this._httpService.get('/api/odoo/carrier/' + id );
  }

}
