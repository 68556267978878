<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <v-flex xs12 sm12>
        <v-form>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6 md3>
                <v-text-field label="Nome" v-model="filters.name" @input="filterBeneficiaries"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-text-field label="CPF" v-model="filters.cpf" @input="filterBeneficiaries"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md3>
                <v-text-field label="Cartão" v-model="filters.cardNumber" @input="filterBeneficiaries"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>

      <v-flex xs12>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="search"
            item-key="id"
            class="elevation-1"
            @click:row="(item) => $router.push({name:'beneficiario', params:{id: item.id}})"
          >
            <template v-slot:header>
            </template>
            <template v-slot:expand="props">
              <v-card flat>
                <v-card-text>{{props.item.detalhado}}</v-card-text>
              </v-card>
            </template>
            <template v-slot:no-data>
              <v-alert
                :value="true"
                type="info"
                v-show="!loading"
                outline
              >Nenhum item foi encontrado</v-alert>
            </template>
            <template v-slot:no-results>
              <v-alert :value="true" type="info" outline>Nada foi encontrado para "{{ search }}"</v-alert>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <template v-else>
      <v-data-iterator
        hide-default-footer
        hide-default-header
        :items="items"
      >
        <template v-slot:default="props">
          <v-row v-for="item in props.items" :key="item.id">
            <v-col cols="12">
              <v-card>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Nome</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.beneficiario }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Tipo</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.tipo }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>CPF</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.cpf }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Matricula</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.matricula }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Beneficio</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.beneficio }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Operadora</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.operadora }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Cartão</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.cartao }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Status</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.status }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import {getGoogleSheets} from './../../common/gsxrest'

const { VUE_APP_SHEETS_BENEFICIARIES, VUE_APP_SHEETS_API_KEY } = process.env;

export default {
  name: 'Beneficiario',
  data() {
    return {
      headers: [
        { text: "Nome", value: "beneficiario" },
        { text: "Tipo", value: "tipo" },
        { text: "CPF", value: "cpf" },
        { text: "Matrícula", value: "matricula" },
        { text: "Beneficio", value: "beneficio" },
        { text: "Operadora", value: "operadora" },
        { text: "Cartão", value: "cartao" },
        { text: "Status", value: "status" },
      ],
      items: [],
      backupItems: [],
      filters: {
        name: '',
        cpf: '',
        cardNumber: ''
      },
      loading: true,
      search: ""
    }
  },
  computed: {
    ...mapState(['window']),
  },
  methods: {
    ...mapMutations(["showmsg"]),
    filterBeneficiaries(){
      if((this.filters.name && this.filters.name.length > 0) || (this.filters.cpf && this.filters.cpf.length > 0)
          || (this.filters.cardNumber && this.filters.cardNumber.length > 0)) {

        let searchBeneficiaries = this.backupItems;
        this.items = searchBeneficiaries.filter(item => {
          return (this.filters.name && this.filters.name.length > 0 && item.beneficiario.trim().toLowerCase().includes(this.filters.name.trim().toLowerCase()))
              || (this.filters.cpf && this.filters.cpf.length > 0 && item.cpf.replace(/\D/g, "").includes(this.filters.cpf.replace(/\D/g, "")))
              || (this.filters.cardNumber && this.filters.cardNumber.length > 0 && item.cartao.replace(/\D/g, "").includes(this.filters.cardNumber.replace(/\D/g, "")))
        })

      }else{
        this.items = this.backupItems;
      }
    },
  },
  beforeMount() {
    getGoogleSheets(VUE_APP_SHEETS_BENEFICIARIES, VUE_APP_SHEETS_API_KEY, 'Sheet1!A2:H100')
      .then(data => {
        for (const [index, item] of data.entries()) {
          this.items.push({
            id: index,
            beneficiario: item[0],
            cpf: item[1],
            matricula: item[2],
            cartao: item[3],
            operadora: item[4],
            beneficio: item[5],
            tipo: item[6],
            status: item[7],
          })
        }

        this.backupItems = this.items;
      }).catch(() => this.showmsg({
        text: "Erro ao carregar os beneficiários.",
        type: "error"
      })).finally(() => this.loading = false)
  },
}
</script>
