export default {
  state: {
    msg: {
      timeout: 10000,
      show: false,
      data: {
        text: '',
        type: 'success',
      }
    },
  },
  mutations: {
    hidemsg(state) {
      state.msg.show = false
      state.msg.data.text = ''
    },
    showmsg(state, msg) {
      state.msg.data = Object.assign({}, state.msg.data, msg);
      state.msg.show = true
      state.msg.timeout = !msg.timeout ? 10000 : msg.timeout;
    },
    tooglemsg(state, show) {
      state.msg.show = show
    },
  },
}
