import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VFlex,{attrs:{"xs12":"","fill-height":""}},[_c(VCard,{attrs:{"height":"95%"}},[_c('jasperserver-visualize',{attrs:{"container":"dashboardAnaliseVidas","resource":"/dashboards/Analise_de_Vidas","type":"dashboard","classParam":"hidden-sm-and-down","styleParam":"width: 100%; float: left; height: 100%"}}),_c('jasperserver-visualize',{attrs:{"container":"dashboardAnaliseVidasMobile","resource":"/dashboards/Analise_de_Vidas_Mobile","type":"dashboard","classParam":"hidden-md-and-up","styleParam":"width: 100%; float: left; height: 600px"}})],1),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }