<template>
  <v-container fluid fill-height>
    <v-layout justify-center align-center>
      <v-flex xs12 fill-height>
        <v-card height="95%">
            <Metabase
                    :resourceId=4
                    path-params="#bordered=true&titled=false"
            />
        </v-card>
        <v-spacer></v-spacer>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import Metabase from "../../components/reports/Metabase";

  export default {
    components:{Metabase}
  };
</script>

<style>
</style>
