<template>
  <v-container fluid>
    <v-row v-if="!isLoadingContent">
      <v-col cols="12" class="px-5">
        <v-card elevation="2">
          <v-card-title v-if="!isManuallyNotification" class="primary--text">
            <span>Nova Configuração de Notificações</span>
            <v-spacer />
          </v-card-title>

          <v-card-text v-if="!sfUser">
            <v-row class="px-5">
              <v-col cols="12" class="py-0 my-0">
                <span v-if="notificationConfig.selectedUsers.length == usersWithAccessPermission.length">Todos os usuários com permissão receberão notificação.</span>
                <span v-else-if="notificationConfig.selectedUsers.length == 0">Nenhum usuário foi selecionado.</span>
                <span v-else>Um total de {{ notificationConfig.selectedUsers.length }} {{ notificationConfig.selectedUsers.length == 1 ? 'usuário receberá': 'usuários receberão'}} notificações.</span>
              </v-col>

              <v-col cols="12" md="6">
                <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
                  <v-tab href="#tab-peoples">Pessoas</v-tab>
                  <v-tab href="#tab-perfils">Perfis</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item :value="'tab-peoples'">
                    <v-list max-height="300" class="overflow-y-auto">
                      <v-subheader>Selecione os usuários</v-subheader>

                      <v-list-item-group v-model="notificationConfig.selectedUsers" multiple active-class="">

                        <v-list-item v-for="(users, index) in usersWithAccessPermission" :key="index">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{ users.email }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>

                      </v-list-item-group>
                    </v-list>

                    <v-row v-if="usersWithAccessPermission && usersWithAccessPermission.length > 0">
                      <v-col>
                        <v-checkbox v-model="isCheckedAllUsers" dense label="Selecionar todos os usuários" @change="selectAllUsers()"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item :value="'tab-perfils'">
                    <v-list max-height="300" class="px-2 overflow-y-auto">
                      <v-subheader>Selecione os perfis</v-subheader>

                      <v-list-item-group v-model="notificationConfig.selectedProfiles" multiple active-class="">

                        <v-list-item v-for="(profile, index) in profiles" :key="index">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>{{ profile.name }}</v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>

                      </v-list-item-group>
                    </v-list>

                    <v-row v-if="profiles && profiles.length > 0">
                      <v-col>
                        <v-checkbox v-model="isCheckedAllProfiles" dense label="Selecionar todos os perfis" @change="selectAllProfiles()"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>

              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Descrição da Configuração" v-model="notificationConfig.title" v-if="!isManuallyNotification"
                                  maxlength="255" outlined autofocus></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="pb-0 mb-0">
                    <header class="py-0 my-0">Template Padrão</header>
                    <v-select outlined placeholder="Selecione um template" :items="carrierBillingDefaultTemplates"
                              item-text="description" item-value="id" v-model="notificationConfig.defaultTemplateId"></v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="pb-0 mb-0">
                    <header class="py-0 my-0">Template de E-mail</header>
                    <v-select outlined placeholder="Selecione um template" :items="carrierBillingEmailTemplates"
                              item-text="description" item-value="id" v-model="notificationConfig.emailTemplateId"></v-select>
                  </v-col>

                  <v-col cols="12" sm="4" class="pb-0 mb-0" v-if="!isManuallyNotification">
                    <header class="py-0 my-0">Notificar ao Inserir</header>
                    <v-switch v-model="notificationConfig.insertable"></v-switch>
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0 mb-0" v-if="!isManuallyNotification">
                    <header class="py-0 my-0">Notificar ao Alterar</header>
                    <v-switch v-model="notificationConfig.updatable"></v-switch>
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0 mb-0" v-if="!isManuallyNotification">
                    <header class="py-0 my-0">Notificar ao Deletar</header>
                    <v-switch v-model="notificationConfig.deletable"></v-switch>
                  </v-col>

                  <v-col cols="12" class="pb-0 mb-0">
                    <header class="py-0 my-0">Tipos de Notificações</header>
                    <v-select outlined v-model="notificationConfig.deliveryChannels" :items="deliveryChannels"
                              attach chips placeholder="Selecione os tipos de notificações" multiple></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-text v-else>
            <v-row class="px-5">
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="E-mails dos Usuários" v-model="notificationConfig.customEmails" autofocus></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field label="Descrição da Configuração" v-model="notificationConfig.title" v-if="!isManuallyNotification"
                                  maxlength="255"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="4" class="pb-0 mb-0" v-if="!isManuallyNotification">
                    <header class="py-0 my-0">Notificar ao Inserir</header>
                    <v-switch v-model="notificationConfig.insertable"></v-switch>
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0 mb-0" v-if="!isManuallyNotification">
                    <header class="py-0 my-0">Notificar ao Alterar</header>
                    <v-switch v-model="notificationConfig.updatable"></v-switch>
                  </v-col>
                  <v-col cols="12" sm="4" class="pb-0 mb-0" v-if="!isManuallyNotification">
                    <header class="py-0 my-0">Notificar ao Deletar</header>
                    <v-switch v-model="notificationConfig.deletable"></v-switch>
                  </v-col>

                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" class="pb-0 mb-0">
                    <header class="py-0 my-0">Tipos de Notificações</header>
                    <v-select v-model="notificationConfig.deliveryChannels" :items="deliveryChannelsSalesforce"
                              attach chips placeholder="Selecione os tipos de notificações" multiple></v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="pb-0 mb-0">
                    <header class="py-0 my-0">Template Padrão</header>
                    <v-select placeholder="Selecione um template" :items="carrierBillingDefaultTemplates"
                              item-text="description" item-value="id" v-model="notificationConfig.defaultTemplateId"></v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="pb-0 mb-0">
                    <header class="py-0 my-0">Template de E-mail</header>
                    <v-select placeholder="Selecione um template" :items="carrierBillingEmailTemplates"
                              item-text="description" item-value="id" v-model="notificationConfig.emailTemplateId"></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="mr-2" color="outline-primary" @click="goBack()">
              Cancelar
            </v-btn>
            <v-btn color="primary" @click="configureNotification()">
              {{isManuallyNotification ? 'Emitir' : 'Salvar'}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="isSendingManuallyNotification">Emitindo notificações...</v-row>
  </v-container>
</template>

<script>
import SecurityService from "../../services/security/SecurityService";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";

export default {
  name: "configureNotification",
  data: () => ({
    carrierBillingDefaultTemplates: [],
    carrierBillingEmailTemplates: [],
    isCheckedAllProfiles: false,
    isCheckedAllUsers: true,
    isLoadingContent: false,
    isSendingManuallyNotification: false,
    sfUser: false,
    notificationConfig: {
      actualVersion: null,
      customEmails: '',
      deliveryChannels: null,
      defaultTemplateId: null,
      emailTemplateId: null,
      isSalesforceConfig: false,
      insertable: true,
      updatable: true,
      deletable: true,
      title: '',
      userIds: null,
      profileIds: null,
      selectedProfiles: [],
      selectedUsers: [],
      domainOrigin: "CARRIER_BILLING",
      parentId: null,
    },
    profiles: [],
    deliveryChannels: [
      { text: 'Notificação Padrão', value: 'DEFAULT_NOTIFICATION' },
      { text: 'Notificação via E-mail', value: 'EMAIL_NOTIFICATION' },
      { text: 'Notificação Push', value: 'PUSH_NOTIFICATION' },
    ],
    deliveryChannelsSalesforce: [
      { text: 'Notificação Padrão', value: 'DEFAULT_NOTIFICATION' },
      { text: 'Notificação via E-mail', value: 'EMAIL_NOTIFICATION' },
    ],
    usersWithAccessPermission: [],
    tab: '',
  }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
    isManuallyNotification: {
      type: Boolean,
      default: false
    }
  },
  beforeCreate() {
    this._securityService = new SecurityService();
    this._socialNetworkService = new SocialNetworkService();
  },
  async created() {
    this.isLoadingContent = true;
    this.loading(this.isLoadingContent);
    this.sfUser = localStorage.getItem('LOCALIZED_ORIGIN') === 'salesforce';

    await this.loadCarrierBillingDefaultTemplates();
    await this.loadCarrierBillingEmailTemplates();

    if (!this.sfUser) {
      await this.loadAllProfiles();
      await this.loadUsersWithAccessPermissionByFinancialGroup();
    }

    if (this.id > 0) {
      this.isCheckedAllUsers = false;
      await this.findNotificationConfigById(this.id);
    } else if (!this.sfUser) {
      this.enableAllUsersToNotification();
    }

    if (this.id === 0) {
      this.notificationConfig.isSalesforceConfig = this.sfUser;
    }

    this.isLoadingContent = false;
    this.loading(this.isLoadingContent);
  },
  computed: {
    ...mapGetters(['getIsOpenDialog', 'getCarrierBillingId', 'getManuallyNotificationType', 'getCarrierBillingAction', 'getFinancialGroupId']),
  },
  methods: {
    ...mapMutations(['showmsg', 'loading']),
    ...mapActions(['setIsOpenDialog']),
    goBack() {
      if(!this.isManuallyNotification){
        this.$router.go(-1);
      } else {
        this.setIsOpenDialog(false);
      }
    },
    async configureNotification() {
      if (this.isEmptyField()) {
        return;
      }

      if(this.isManuallyNotification) {
        await this.createManuallyNotification();
      } else {

        if (this.id === 0) {
          await this.createNotificationConfig();
        } else {
          await this.updateNotificationConfig();
        }

      }
    },
    isEmptyField() {
      const emptyFields = [];
      if (this.notificationConfig.title.trim().length === 0 && !this.isManuallyNotification) {
        emptyFields.push('Descrição da Configuração');
      }
      if (!this.sfUser && this.notificationConfig.defaultTemplateId === null) {
        emptyFields.push('Template Padrão');
      }
      if (this.notificationConfig.emailTemplateId === null) {
        emptyFields.push('Template de E-mail');
      }
      if (!this.sfUser && (!this.notificationConfig.selectedUsers || (this.notificationConfig.selectedUsers && this.notificationConfig.selectedUsers.length === 0
          && (!this.notificationConfig.selectedProfiles || (this.notificationConfig.selectedProfiles && this.notificationConfig.selectedProfiles.length === 0))))) {
        emptyFields.push('Usuários');
      }
      if (this.sfUser && this.notificationConfig.customEmails.trim().length === 0) {
        emptyFields.push('E-mails dos Usuários');
      }
      if (!this.notificationConfig.deliveryChannels || (this.notificationConfig.deliveryChannels && this.notificationConfig.deliveryChannels.length === 0)) {
        emptyFields.push('Tipos de Notificações');
      }

      if (emptyFields.length > 0) {
        this.showmsg({text: `Preencha os campos corretamente: ${emptyFields}.`, type: 'error'});
        return true;
      }
      return false;
    },
    async createNotificationConfig() {
      this.isLoadingContent = true;
      this.loading(this.isLoadingContent);
      this.loadUserIdsSelectedsToSave();
      this.loadProfileIdsSelectedsToSave();

      await this._socialNetworkService.SaveNotificationConfig(this.notificationConfig)
          .then(() => {
            this.goBack();
          })
          .catch(() => {
            this.showmsg({text: 'Não foi possível cadastrar a configuração de notificação.', type: 'error'});
          });
    },
    async updateNotificationConfig() {
      this.isLoadingContent = true;
      this.loading(this.isLoadingContent);
      this.loadUserIdsSelectedsToSave();
      this.loadProfileIdsSelectedsToSave();

      await this._socialNetworkService.UpdateNotificationConfig(this.notificationConfig, this.id)
          .then(() => {
            this.goBack();
          })
          .catch(() => {
            this.showmsg({text: 'Não foi possível alterar a configuração de notificação.', type: 'error'});
          });
    },
    async createManuallyNotification(){
      this.isLoadingContent = true;
      this.isSendingManuallyNotification = this.isLoadingContent;
      this.loading(this.isLoadingContent);
      this.loadUserIdsSelectedsToSave();
      this.loadProfileIdsSelectedsToSave();

      switch (this.getManuallyNotificationType){
        case 'CARRIER_BILLING':
          await this.createCarrierBillingNotification();
          break;
        default:
      }

      this.isLoadingContent = false;
      this.isSendingManuallyNotification = this.isLoadingContent;
      this.loading(this.isLoadingContent);
      this.clearNotificationConfig();
      this.setIsOpenDialog(this.isLoadingContent);
    },
    async createCarrierBillingNotification() {
      await this._socialNetworkService.CreateCarrierBillingManuallyNotification(this.notificationConfig, this.getCarrierBillingId, this.getCarrierBillingAction)
        .then(response => {
          if(response && response.data) {
            this.showmsg({text: 'As notificações foram emitidas com sucesso.'})
          }
        })
        .catch(() => {
          this.showmsg({text: 'Ocorreu um erro ao emitir a notificação.', type: 'error'})
        })
        .finally(() => {
          this.loading(false);
        })
    },
    async loadCarrierBillingDefaultTemplates() {
      await this._securityService.FindAllCarrierBillingDefaultTemplates()
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.carrierBillingDefaultTemplates = response.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao carregar os templates default do faturamento.', type: 'error'})
          });
    },
    async loadCarrierBillingEmailTemplates() {
      await this._securityService.FindAllCarrierBillingEmailTemplates()
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.carrierBillingEmailTemplates = response.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao carregar os templates email do faturamento.', type: 'error'})
          });
    },
    async loadUsersWithAccessPermissionByFinancialGroup() {
      let params = {
        financialGroupId: this.getFinancialGroupId,
        appCode: 'rh_protegido',
      };
      await this._securityService.FindAllUsersToNotifyByAppCodeAndFinancialGroup(params)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.usersWithAccessPermission = response.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao carregar os usuários a serem notificados.', type: 'error'})
          });
    },
    async loadAllProfiles() {
      let params = {
        appCode: 'rh_protegido'
      }
      await this._securityService.FindAllProfilesByAppCode(params)
          .then((response) => {
            if (response.data && response.data.length) {
              this.profiles = response.data;
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao carregar os perfis de usuário.', type: 'error'})
          });
    },
    async findNotificationConfigById(id) {
      await this._socialNetworkService.FindNotificationConfigById(id)
          .then((response) => {
            if (response.data) {
              this.notificationConfig.title = response.data.title;
              this.notificationConfig.insertable = response.data.insertable;
              this.notificationConfig.updatable = response.data.updatable;
              this.notificationConfig.deletable = response.data.deletable;
              this.notificationConfig.emailTemplateId = response.data.emailTemplateId;
              this.notificationConfig.defaultTemplateId = response.data.defaultTemplateId;
              this.notificationConfig.userIds = response.data.userIds;
              this.notificationConfig.profileIds = response.data.profileIds;
              this.notificationConfig.deliveryChannels = response.data.deliveryChannel ? response.data.deliveryChannel.split(',') : [];
              this.notificationConfig.actualVersion = response.data.actualVersion;
              this.notificationConfig.parentId = response.data.parentId;
              this.notificationConfig.customEmails = response.data.customEmails;
              this.notificationConfig.isSalesforceConfig = response.data.isSalesforceConfig;

              this.loadUserIdsSelecteds();
              this.loadProfileIdsSelecteds();
            }
          })
          .catch(() => {
            this.showmsg({text: 'Ocorreu um erro ao carregar a configuração da notificação.', type: 'error'});
          });
    },
    loadUserIdsSelectedsToSave() {
      const usersFiltered = [];
      this.notificationConfig.selectedUsers.forEach((value) => {
        usersFiltered.push(this.usersWithAccessPermission[value].userId);
      });
      this.notificationConfig.selectedUsers = usersFiltered;
    },
    loadProfileIdsSelectedsToSave() {
      const profilesFiltered = [];
      this.notificationConfig.selectedProfiles.forEach((value) => {
        profilesFiltered.push(this.profiles[value].id);
      });
      this.notificationConfig.selectedProfiles = profilesFiltered;
    },
    loadUserIdsSelecteds() {
      const userIdsSaveds = this.notificationConfig.userIds.split(',');

      userIdsSaveds.forEach((value) => {
        this.usersWithAccessPermission.forEach((user, index) => {
          if (user.userId === parseInt(value, 10)) {
            this.notificationConfig.selectedUsers.push(index);
          }
        });
      });
    },
    loadProfileIdsSelecteds() {
      const userIdsSaveds = this.notificationConfig.profileIds.split(',');

      userIdsSaveds.forEach((value) => {
        this.profiles.forEach((profile, index) => {
          if (profile.id === parseInt(value, 10)) {
            this.notificationConfig.selectedProfiles.push(index);
          }
        });
      });
    },
    selectAllProfiles() {
      if (this.isCheckedAllProfiles) {
        this.notificationConfig.selectedProfiles = [];
        this.notificationConfig.selectedProfiles = Array.from(Array(this.profiles.length).keys());
      } else {
        this.notificationConfig.selectedProfiles = [];
      }
    },
    enableAllUsersToNotification() {
      const usersLength = this.usersWithAccessPermission.length;
      for (let count = 0; count < usersLength; count += 1) {
        this.notificationConfig.selectedUsers.push(count);
      }
    },
    selectAllUsers() {
      if (this.isCheckedAllUsers) {
        this.notificationConfig.selectedUsers = [];
        this.notificationConfig.selectedUsers = Array.from(Array(this.usersWithAccessPermission.length).keys());
      } else {
        this.notificationConfig.selectedUsers = [];
      }
    },
    clearNotificationConfig(){
      this.notificationConfig = {
        actualVersion: null,
        customEmails: '',
        deliveryChannels: null,
        defaultTemplateId: null,
        emailTemplateId: null,
        isSalesforceConfig: false,
        insertable: true,
        updatable: true,
        deletable: true,
        title: '',
        userIds: null,
        profileIds: null,
        selectedProfiles: [],
        selectedUsers: [],
        domainOrigin: "CARRIER_BILLING",
        parentId: null,
      }
    }
  }
};

</script>
