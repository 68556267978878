<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-form
          id="mensageriaForm"
          ref="mensageriaForm"
          @submit.prevent="createMensageria()"
        >
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                  v-if="session.financialGroup"
                  item-text="text"
                  item-value="id"
                  return-object
                  :items="users"
                  v-model="selectRecipient"
                  label="Usuários"
                  placeholder="Selecione uma opção"
                  outlined
                  :rules="[rules.required]"
              />
              <v-select
                  v-if="!session.financialGroup"
                  item-text="text"
                  item-value="id"
                  return-object
                  :items="entities"
                  v-model="selectEntitySender"
                  label="Entidades"
                  placeholder="Selecione uma opção"
                  outlined
                  :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-if="!session.financialGroup"
                  item-text="text"
                  item-value="id"
                  return-object
                  :items="usersEuProtegido"
                  v-model="selectRecipientEuProtegido"
                  label="Usuários"
                  placeholder="Selecione uma opção"
                  outlined
                  :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="messageData.title"
                  label="Título"
                  :rules="[rules.required]"
                  outlined
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="messageData.body"
                label="Descrição"
                placeholder="Descreva sua Mensagem"
                :rules="[rules.required]"
                auto-grow
                outlined
                hide-details
                class="pb-5"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer />
        <v-btn
          large
          light
          @click="cancel()"
          class="black--text"
          >Cancelar</v-btn
        >
        <v-btn
          large
          color="primary"
          type="submit"
          form="mensageriaForm"
          :loading="busy"
          :disabled="busy"
          >Enviar</v-btn
        >
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn
            block
            large
            color="primary"
            type="submit"
            form="mensageriaForm"
            :loading="busy"
            :disabled="busy"
            >Criar</v-btn
          >
        </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            large
            light
            @click="cancel()"
            class="black--text"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import { formrules } from "./../../common/formrules";
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
import SecurityService from "../../services/security/SecurityService";

export default {
  name: "mensageriainserir",
  props: {
    mensageria: null,
  },
  data: () => ({
    euProtegidoAppCode: 'eu_protegido',
    busy: false,
    messageData: {
      title: "",
      body: "",
      recipientId: "",
      recipientType: ""
    },
    messageEntityData: {
      title: "",
      body: "",
      recipientId: "",
      recipientType: "",
      senderEntityId: "",
      sent: true
    },
    selectRecipient: null,
    selectRecipientEuProtegido: null,
    selectEntitySender: null,
    session: null,
    rules: formrules,
    users: [],
    usersEuProtegido: [],
    entities: [],
    sfUser: null
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    },
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    async createMensageria() {
      if (!this.$refs.mensageriaForm.validate()) {
        setTimeout(this.$refs.mensageriaForm.resetValidation, 3000);
        return;
      }

      this.loading(true);
      if(this.session && this.session.financialGroup && this.session.financialGroup.id){

        this.messageData.recipientId = this.selectRecipient.id
        this.messageData.recipientType = this.selectRecipient.value

        await this._socialnetworkService.SendMessageByFinancialGroup(this.messageData)
            .then(() => {
              this.showmsg({ text: "A mensagem foi enviada com sucesso."});
              setTimeout(this.$router.go(-1), 2000);
            })
            .catch(() => {
              this.showmsg({ text: "Ocorreu um erro ao tentar enviar a mensagem.", type: "error" });
            })
            .finally(() => {
              this.loading(false);
            })

      }else{

        this.messageEntityData.body = this.messageData.body
        this.messageEntityData.title = this.messageData.title
        this.messageEntityData.recipientId = this.selectRecipientEuProtegido.id
        this.messageEntityData.recipientType = this.selectRecipientEuProtegido.value
        this.messageEntityData.senderEntityId = this.selectEntitySender.id

        await this._socialnetworkService.SendMessage(this.messageEntityData)
            .then(() => {
              this.showmsg({ text: "A mensagem foi enviada com sucesso."});
              setTimeout(this.$router.go(-1), 2000);
            })
            .catch((error) => {
              this.showmsg({ text: "Ocorreu um erro ao tentar enviar a mensagem.", type: "error" });
            })
            .finally(() => {
              this.loading(false);
            })

      }
    },
    async loadUsers() {
      let usersIds = []

      if(this.session && this.session.financialGroup && this.session.financialGroup.id) {

        await this._securityService.FindAllEuProtegidoUsersIdByFinancialGroupExternalID(this.session.financialGroup.id)
            .then(response => {
              usersIds = response.data
            })
            .catch(() => {
              this.showmsg({text: "Ocorreu um erro ao tentar listar os usuários do Grupo Financeiro.", type: "error"});
              this.loading(false);
            })

        let usersDetails = []
        const amountUsers = usersIds.length;
        let countUsers = 0;

        if (usersIds && amountUsers > 0) {

          usersIds.forEach(id => {

            this._securityService.FindById(id)
                .then(response => {
                  usersDetails.push(response.data)
                  countUsers = countUsers + 1;
                })
                .catch(() => {
                  this.showmsg({text: "Ocorreu um erro ao tentar listar as informações do usuário.", type: "error"});
                  this.loading(false);
                }).finally(() => {

              if (countUsers == amountUsers) {
                this.loadUsersSelectOptions(usersDetails);
              }
            })
          })
        }
      } else if (this.sfUser) {

        await this._securityService.FindAllUsersConnectedByAppCode({appCode: this.euProtegidoAppCode})
            .then(response => {
              this.loadUsersEuProtegidoSelectOptions(response.data);
            })
            .catch(() => {
              this.showmsg({text: "Ocorreu um erro ao tentar listar os usuários conectados ao app.", type: "error"});
              this.loading(false);
            })

        await this._socialnetworkService.FindEntityByType("ENTITY").then(response => {
          if(response.data){
            this.loadEntitiesSelectOptions(response.data);
          }
        }).catch(() => {
          this.showmsg({text: "Ocorreu um erro ao tentar listar as entidades.", type: "error"});
          this.loading(false);
        })
      }
    },
    loadUsersSelectOptions(usersDetails){
      this.users .push({ text: "Todos Usuários", value: "FINANCIAL_GROUP", id: this.session.financialGroup.id})
      usersDetails.forEach(user => {
        this.users .push({ text: user.email, value: "USER", id: "" + user.id});
      })
    },
    loadUsersEuProtegidoSelectOptions(users){
      users.forEach(user => {
        this.usersEuProtegido .push({ text: user.email, value: "USER", id: "" + user.id});
      })
    },
    loadEntitiesSelectOptions(entities){
      entities.forEach(entity => {
        this.entities .push({ text: entity.entity_name, id: "" + entity.entity_id});
      })
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  async created() {
    this.loading(true);
    this.session = this.$util.getSession();
    this.sfUser = localStorage.getItem('LOCALIZED_ORIGIN') === 'salesforce';
    this._socialnetworkService = new SocialNetworkService();
    this._securityService = new SecurityService();
    await this.loadUsers();
    this.loading(false);
  },
};
</script>
