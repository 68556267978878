<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <fieldset class="px-5">
          <legend class="px-1">
            Cadastro das Respostas e Pontuações
          </legend>

          <v-row>
            <v-col cols="12">
              <v-autocomplete
                  v-model="questionSelected"
                  :items="assessmentTopics[assessmentTopicIndex].topic.questions"
                  item-text="description"
                  return-object
                  label="Questões"
                  placeholder="Selecione uma Questão"
                  :rules="[formrules.required]"
                  outlined
                  @change="findIndexOfAssessmentQuestion()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                  v-model="assessmentAnswerTrigger.condition"
                  :items="getConditions"
                  item-text="text"
                  item-value="value"
                  label="Condição"
                  placeholder="Selecione a Condição"
                  :rules="[formrules.required]"
                  outlined
              />
            </v-col>
            <v-col cols="12" :md="getPaddingByTriggerSelected">
              <v-text-field
                  v-model="assessmentAnswerTrigger.value"
                  :label="getTitleByTriggerSelected"
                  :maxlength="getMaxLengthByTriggerSelected"
                  :rules="[
                      formrules.required,
                      (isVisibleByTriggerSelected ? formrules.valueGreaterEqualsThanZero : true),
                      (getMaxLengthByTriggerSelected === '10' ? formrules.maxLength10 : true)
                  ]"
                  :type="getFieldTypeByTriggerSelected"
                  outlined
                  @blur="isValueGreaterEqualsThanValueComplementary()"
                  @click:append="isValueGreaterEqualsThanValueComplementary()"
              />
            </v-col>
            <v-col cols="12" md="3" v-if="isVisibleByTriggerSelected">
              <v-text-field
                  label="Menor que"
                  v-model="assessmentAnswerTrigger.valueComplementary"
                  :maxlength="getMaxLengthByTriggerSelected"
                  :rules="[formrules.required, formrules.valueGreaterEqualsThanZero, formrules.maxLength10]"
                  type="number"
                  outlined
                  @blur="isValueGreaterEqualsThanValueComplementary()"
                  @click:append="isValueGreaterEqualsThanValueComplementary()"
              />
            </v-col>
            <v-col cols="6" md="3">
              <v-text-field
                  label="Pontuação"
                  v-model="assessmentAnswerTrigger.score"
                  type="number"
                  :maxlength="10"
                  :rules="[isScoreGreaterThanZero(), formrules.valueGreaterEqualsThanZero]"
                  outlined
              />
            </v-col>
            <v-col cols="6" md="3" class="pt-2">
              <v-text-field
                  label="Ordenação"
                  type="number"
                  v-model="assessmentAnswerTrigger.ordination"
                  outlined
              />
            </v-col>
            <v-col cols="12" md="6" class="pt-2">
              <v-checkbox
                  v-model="assessmentAnswerTrigger.openAnswer"
                  label="Campo aberto?"
              />
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col cols="12" md="4" align="end">
              <v-btn
                  class="mr-2"
                  large
                  style="color: var(--v-primary-base)"
                  light width="120"
                  @click="clearFields()"
              >
                <span>Cancelar</span>
              </v-btn>
              <v-btn
                  large
                  :disabled="isAssessmentAnswerEmpty"
                  :dark="!isAssessmentAnswerEmpty"
                  color="primary" width="120"
                  @click="managerAssessmentAnswerToQuestion()"
              >
                <span>{{ indexOfAnswerEditing === -1 ? 'Adicionar' : 'Alterar' }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </fieldset>
      </v-col>

      <v-col cols="12" class="mb-0 pb-0">
        <v-data-table
            class="elevation-1 mx-1"
            item-key="index"
            :items="getAnswerTriggers"
            :headers="answerHeaders"
            hide-default-footer
        >

          <template v-slot:item.question="{  }">
            {{ assessmentTopics[assessmentTopicIndex].topic.questions[assessmentQuestionIndex] ?
              assessmentTopics[assessmentTopicIndex].topic.questions[assessmentQuestionIndex].description : '-' }}
          </template>

          <template v-slot:item.condition="{ item }">
            {{ convertAnswerCondition(item.condition) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu
                transition="slide-y-transition"
                offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="grey"
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editAssessmentAnswerTrigger(item)">
                  <v-list-item-icon>
                    <v-icon>fas fa-edit</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="openDialog(item)">
                  <v-list-item-icon>
                    <v-icon>fas fa-trash</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Excluir</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <dialogDeleteConfirmation :show="showDialog" @confirmDialog="deleteAssessmentTrigger"></dialogDeleteConfirmation>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { formrules } from "./../../common/formrules";
import DialogDeleteConfirmation from "./dialogDeleteConfirmation.vue";

export default {
  name: "managementAnswers",
  components: {
    DialogDeleteConfirmation,
  },
  data: () => ({
    formrules,
    showDialog: false,
    assessmentQuestionIndex: -1,
    indexOfAnswerEditing: -1,
    questionSelected: {
      id: 0,
      ordination: null,
      description: null,
      questionType: null,
      mandatory: false,
      triggers: [],
      active: true,
      visibility: []
    },
    answerHeaders: [
      { text: "Ordenação", value: "index", sortable: false, width: '20%' },
      { text: "Questão", value: "question", sortable: false, width: '35%' },
      { text: "Condição", value: "condition", width: '20%' },
      { text: "Valor", value: "value", sortable: false, width: '20%'  },
      { text: "Pontuação", value: "score", sortable: false, width: '20%' },
      { text: "Ações", value: "actions", sortable: false, align: "right", width: '5%' }
    ],
    assessmentAnswerTriggers: [],
    assessmentAnswerTrigger: {
      index: 0,
      id: 0,
      condition: null,
      value: null,
      valueComplementary: null,
      score: null,
      openAnswer: false,
      childQuestionDescription: null,
      childQuestionsDescription: [],
      assessmentQuestionId: null,
      ordination: null,
    },
  }),
  methods: {
    ...mapMutations(['showmsg']),
    ...mapActions(['setAssessmentAnswerByIndexOfTopicAndIndexOfQuestion', 'setAssessmentAnswerByIndexOfTopicAndIndexOfQuestionAndIndexOfAnswer']),
    resetTriggers(){
      this.assessmentQuestionIndex = -1;
    },
    sortAnswers(){
      this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.sort((a, b) => (a.index > b.index) ? 1 : -1);
    },
    findIndexOfAssessmentQuestion(){
      this.assessmentQuestionIndex = this.assessmentTopics[this.assessmentTopicIndex].topic.questions.indexOf(this.questionSelected);
      const triggers = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers;
      if(triggers && triggers.length){
        this.assessmentAnswerTrigger.index =  parseInt(this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.reduce((a,b) => Number(a.index) > Number(b.index) ? a : b).index) + 1
      } else {
        this.assessmentAnswerTrigger.index = 1;
      }
    },
    managerAssessmentAnswerToQuestion() {
      const messageExists = 'Já existe uma resposta cadastrada com estas informações.';
      const messageMaxBooleanTriggers = 'Você só pode cadastrar 2 respostas para questões do tipo Booleano.'
      const messageDuplicateIndex = 'Já existe outra resposta com essa ordem.'
      const errorType = 'error';

      const index = parseInt(this.assessmentAnswerTrigger.ordination)
      if(this.assessmentAnswerTriggers.some(item => parseInt(item.ordination) === index)){
        this.showmsg({ text: messageDuplicateIndex, type: errorType });
        return;
      }

      if(this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].questionType
          && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].questionType === 'BOOLEAN'
          && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.length === 2) {
        this.showmsg({ text: messageMaxBooleanTriggers, type: errorType });
        return;
      }

      if(this.assessmentAnswerTrigger.condition === 'GREATER_THAN' || this.assessmentAnswerTrigger.condition === 'LESS_THAN') {
        this.assessmentAnswerTrigger.value = this.assessmentAnswerTrigger.value.toString().replace('.', ',');
      }

      if(this.assessmentAnswerTrigger.condition === 'BETWEEN') {
        this.assessmentAnswerTrigger.value = `${this.assessmentAnswerTrigger.value.toString().replace('.', ',')};${this.assessmentAnswerTrigger.valueComplementary.toString().replace('.', ',')}`;
      }

      this.assessmentAnswerTrigger.index = this.assessmentAnswerTrigger.ordination
      const trigger = Object.assign({}, this.assessmentAnswerTrigger);
      if(this.indexOfAnswerEditing === -1){
        trigger.index = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.length;

        if(this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers &&
            this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.length) {
          const existsItem = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers
              .some(item => item.value === trigger.value && item.condition == trigger.condition && item.score === trigger.score && item.openAnswer == trigger.openAnswer);
          if(existsItem) {
            this.showmsg({ text: messageExists, type: errorType });
            return;
          }
        }

        if(this.assessmentTopicIndex > -1 && this.assessmentQuestionIndex > -1) {
          this.setAssessmentAnswerByIndexOfTopicAndIndexOfQuestion({
            indexOfTopic: this.assessmentTopicIndex,
            indexOfQuestion: this.assessmentQuestionIndex,
            trigger: trigger,
          });
        }

      } else {
        if(this.assessmentAnswerTriggers && this.assessmentAnswerTriggers.length) {
          const existsItem = this.assessmentAnswerTriggers.some(item => item.value === trigger.value && item.condition == trigger.condition && item.score === trigger.score && item.openAnswer == trigger.openAnswer);
          if(existsItem) {
            this.showmsg({ text: messageExists, type: errorType });
            return;
          }
        }

        this.setAssessmentAnswerByIndexOfTopicAndIndexOfQuestionAndIndexOfAnswer({
          indexOfTopic: this.assessmentTopicIndex,
          indexOfQuestion: this.assessmentQuestionIndex,
          indexOfAnswer: this.indexOfAnswerEditing,
          trigger: trigger,
        });
      }
      this.sortAnswers();

      this.clearFields();
    },
    convertAnswerCondition(fieldType) {
      if(fieldType) {
        return this.conditions.find(item => item.value === fieldType).text;
      }
      return '-';
    },
    clearFields() {
      this.assessmentAnswerTrigger = {
        index: parseInt(this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.reduce((a,b) => Number(a.index) > Number(b.index) ? a : b).index) + 1,
        id: 0,
        condition: null,
        value: null,
        valueComplementary: null,
        score: null,
        openAnswer: false,
        childQuestionDescription: null,
        childQuestionsDescription: [],
        assessmentQuestionId: null,
        ordination: null,
      };
      this.questionSelected = null;
      this.assessmentQuestionIndex = -1;
      this.indexOfAnswerEditing = -1;
      this.resetFormValidation();
    },
    resetFormValidation() {
      this.$emit('resetFormValidation');
    },
    openDialog(item) {
      if(item && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers
          && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.length) {
        this.indexOfAnswerEditing = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.indexOf(item);
        this.showDialog = !this.showDialog;
      }
    },
    editAssessmentAnswerTrigger(item){
      if(item && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers
          && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.length) {
        this.indexOfAnswerEditing = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.indexOf(item);

        let itemValue = item.value;
        let itemValueComplementary = item.valueComplementary;

        if(item.value && item.value.includes(';')) {
          const valueParts = item.value.split(';');
          if(valueParts && valueParts.length) {
            itemValue = valueParts[0];
          }
          if(valueParts && valueParts.length && valueParts.length > 1) {
            itemValueComplementary = valueParts[1];
          }
        }

        if(itemValue) {
          itemValue = itemValue.replaceAll(',', '.');
        }

        if(itemValueComplementary) {
          itemValueComplementary = itemValueComplementary.replaceAll(',', '.');
        }

        this.assessmentAnswerTrigger = Object.assign({}, {
          index: item.index,
          id: item.id,
          condition: item.condition,
          value: itemValue,
          valueComplementary: itemValueComplementary,
          score: item.score,
          openAnswer: item.openAnswer,
          childQuestionDescription: item.childQuestionDescription,
          childQuestionsDescription: item.childQuestionsDescription,
          assessmentQuestionId: item.assessmentQuestionId,
          ordination: item.index,
        });

        this.assessmentAnswerTriggers = Object.assign([], this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers);
        this.assessmentAnswerTriggers.splice(this.indexOfAnswerEditing, 1);
      }
    },
    deleteAssessmentTrigger() {
      if(this.indexOfAnswerEditing > -1) {
        this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.splice(this.indexOfAnswerEditing, 1);
        this.indexOfAnswerEditing = -1;
        this.showDialog = !this.showDialog;
        this.clearFields();
      }
    },
    isValueGreaterEqualsThanValueComplementary() {
      if(this.assessmentAnswerTrigger.value && this.assessmentAnswerTrigger.valueComplementary &&
        this.assessmentAnswerTrigger.value >= this.assessmentAnswerTrigger.valueComplementary) {
        setTimeout(() => {
          this.assessmentAnswerTrigger.valueComplementary = null;
        }, 100);
        this.showmsg({ text: 'A valor do campo "Maior que" não pode ser maior ou igual ao campo "Menor que".', type: 'error' });
      }
    },
    isScoreGreaterThanZero(){
      return this.assessmentAnswerTrigger.score !== null && (this.assessmentAnswerTrigger.score.length === undefined
          || this.assessmentAnswerTrigger.score.length) && this.assessmentAnswerTrigger.score >= 0 || 'Preenchimento Obrigatório';
    },
    filterConditionsByValues(conditionValues = null){
      return this.conditions.filter(condition => conditionValues.some(value => value === condition.value))
    }
  },
  computed: {
    ...mapGetters(['assessmentTopics', 'assessmentTopicIndex', 'conditions']),
    getAnswerTriggers() {
      if(this.assessmentTopicIndex > -1 && this.assessmentQuestionIndex > -1
        && this.assessmentTopics[this.assessmentTopicIndex].topic.questions.length
        && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers) {
        let index = 0;
        this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers.forEach((trigger) => {
          trigger.index = trigger.ordination ? parseInt(trigger.ordination) : index + 1;
          index = trigger.index;
        });
        this.sortAnswers();
        return this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.assessmentQuestionIndex].triggers;
      }
      return [];
    },
    isAssessmentAnswerEmpty() {
      return !this.assessmentAnswerTrigger || !this.questionSelected || !this.assessmentAnswerTrigger.condition
          || !this.assessmentAnswerTrigger.value || this.assessmentAnswerTrigger.value.toString().trim().length === 0
          || (this.assessmentAnswerTrigger.condition === 'BETWEEN' && !this.assessmentAnswerTrigger.valueComplementary)
          || (this.assessmentAnswerTrigger.score == null || false || undefined) || this.assessmentAnswerTrigger.score.toString().trim().length === 0;
    },
    isVisibleByTriggerSelected() {
      return this.assessmentAnswerTrigger.condition && this.assessmentAnswerTrigger.condition === 'BETWEEN';
    },
    getPaddingByTriggerSelected() {
      return !this.assessmentAnswerTrigger.condition || this.assessmentAnswerTrigger.condition !== 'BETWEEN' ? '6' : '3';
    },
    getTitleByTriggerSelected() {
      return !this.assessmentAnswerTrigger.condition || this.assessmentAnswerTrigger.condition !== 'BETWEEN' ? 'Valor' : 'Maior que';
    },
    getMaxLengthByTriggerSelected() {
      return !this.assessmentAnswerTrigger.condition || (this.assessmentAnswerTrigger.condition !== 'BETWEEN'
        && this.assessmentAnswerTrigger.condition !== 'GREATER_THAN' && this.assessmentAnswerTrigger.condition !== 'LESS_THAN')
        ? '255' : '10';
    },
    getFieldTypeByTriggerSelected() {
      return !this.assessmentAnswerTrigger.condition || (this.assessmentAnswerTrigger.condition !== 'BETWEEN'
          && this.assessmentAnswerTrigger.condition !== 'GREATER_THAN' && this.assessmentAnswerTrigger.condition !== 'LESS_THAN')
          ? 'text' : 'number';
    },
    getConditions(){
      let filteredConditions = [];
      if(this.questionSelected){
        switch (this.questionSelected.questionType){
          case 'SELECT':
            filteredConditions = this.filterConditionsByValues(['EQUALS']);
            break;
          case 'MULTIPLE':
            filteredConditions = this.filterConditionsByValues(['EQUALS']);
            break;
          case 'COIN':
            filteredConditions = this.filterConditionsByValues(['EQUALS', 'BETWEEN', 'GREATER_THAN', 'LESS_THAN']);
            break;
          case 'NUMBER':
            filteredConditions = this.filterConditionsByValues(['EQUALS', 'BETWEEN', 'GREATER_THAN', 'LESS_THAN']);
            break;
          case 'TEXT':
            filteredConditions = this.filterConditionsByValues(['EQUALS', 'CONTAINS']);
            break;
          case 'IMC':
            filteredConditions = this.filterConditionsByValues(['BETWEEN', 'GREATER_THAN', 'LESS_THAN']);
            break;
          case 'BOOLEAN':
            filteredConditions = this.filterConditionsByValues(['EQUALS']);
            break;
        }
        return filteredConditions;
      } else {
        return [];
      }
    }
  },
  beforeCreate() {
  },
  created() {
  },
};
</script>
