import moment from 'moment-timezone';
import util from '../common/util';

const state = {
    beneficiaries: [],
    beneficiariesCards: [],
    beneficiariesDetails: []
};

const mutations = {
    setBeneficiaries(state, beneficiaries){
        state.beneficiaries = beneficiaries
    },
    setBeneficiariesCards(state, beneficiaries){
        state.beneficiariesCards = beneficiaries
    },
    setBeneficiariesDetails(state, beneficiaries){
        state.beneficiariesDetails = beneficiaries
    }
};

const actions = {
    setBeneficiaries(context, beneficiaries) {
        const charToUndefinedCase = '-'
        let customBeneficiaries = []

        if(beneficiaries && beneficiaries.length > 0){
            for(let [index, beneficiary] of beneficiaries.entries()){
                let beneficiaryContent = {
                    index: index,
                    id: beneficiary.memberId ? beneficiary.memberId : charToUndefinedCase,
                    name: beneficiary.physicalPersonName ? beneficiary.physicalPersonName : charToUndefinedCase,
                    type: beneficiary.isBeneficiary ? 'Titular': (beneficiary.isDependent ? 'Dependente' : charToUndefinedCase),
                    kinship: beneficiary.kinship ? beneficiary.kinship : charToUndefinedCase,
                    cpf: beneficiary.physicalPersonCPF ? util.formatCPF(beneficiary.physicalPersonCPF) : charToUndefinedCase,
                    birthdate: beneficiary.physicalPersonBirthDate ? moment(beneficiary.physicalPersonBirthDate).format('DD/MM/YYYY') : charToUndefinedCase,
                    policy: beneficiary.contractPolicy ? beneficiary.contractPolicy : charToUndefinedCase,
                    subcontract: beneficiary.subcontractName ? beneficiary.subcontractName : charToUndefinedCase,
                    benefit: beneficiary.benefitName ? beneficiary.benefitName : charToUndefinedCase,
                    operator: beneficiary.carrierName ? beneficiary.carrierName : charToUndefinedCase,
                    registration: beneficiary.memberRegistration ? beneficiary.memberRegistration : charToUndefinedCase,
                    card: beneficiary.memberCard ? beneficiary.memberCard : charToUndefinedCase,
                    plan: beneficiary.planName ? beneficiary.planName : charToUndefinedCase,
                    validity: beneficiary.startDate ? moment(beneficiary.startDate).format('DD/MM/YYYY') : charToUndefinedCase,
                    status: beneficiary.isActive ? 'Ativo' : 'Inativo'
                }
                customBeneficiaries.push(beneficiaryContent)
            }
        }
        context.commit('setBeneficiaries', customBeneficiaries)
    },
    setBeneficiariesCards(context, beneficiaries) {
        const charToUndefinedCase = ''
        let customBeneficiaries = []

        if(beneficiaries && beneficiaries.length > 0){
            for(let [index, beneficiary] of beneficiaries.entries()){
                let beneficiaryContent = {
                    index: index,
                    id: beneficiary.memberId ? beneficiary.memberId : charToUndefinedCase,
                    name: beneficiary.physicalPersonName ? beneficiary.physicalPersonName : charToUndefinedCase,
                    type: beneficiary.isBeneficiary ? 'Titular': (beneficiary.isDependent ? 'Dependente' : charToUndefinedCase),
                    kinship: beneficiary.kinship && !beneficiary.isBeneficiary ? beneficiary.kinship : (beneficiary.isBeneficiary ? null : charToUndefinedCase),
                    cpf: beneficiary.physicalPersonCPF ? util.formatCPF(beneficiary.physicalPersonCPF)  : charToUndefinedCase,
                    birthdate: beneficiary.physicalPersonBirthDate ? moment(beneficiary.physicalPersonBirthDate).format('DD/MM/YYYY') : charToUndefinedCase,
                    policy: beneficiary.contractPolicy ? (beneficiary.contractPolicy + ' / ' + (beneficiary.subcontractName ? beneficiary.subcontractName : '')) : charToUndefinedCase,
                    benefit: beneficiary.benefitName ? beneficiary.benefitName : charToUndefinedCase,
                    operator: beneficiary.carrierName ? beneficiary.carrierName : charToUndefinedCase,
                    registration: beneficiary.memberRegistration ? beneficiary.memberRegistration : charToUndefinedCase,
                    card: beneficiary.memberCard ? beneficiary.memberCard : charToUndefinedCase,
                    plan: beneficiary.planName ? beneficiary.planName : charToUndefinedCase,
                    validity: beneficiary.startDate ? moment(beneficiary.startDate).format('DD/MM/YYYY') : charToUndefinedCase,
                    status: beneficiary.isActive ? 'Ativo' : 'Inativo',
                    parentName: beneficiary.parentName ? beneficiary.parentName : null
                }
                customBeneficiaries.push(beneficiaryContent)
            }
        }
        context.commit('setBeneficiariesCards', customBeneficiaries)
    },
    setBeneficiariesDetails(context, beneficiaries){
        const charToUndefinedCase = ''
        let beneficiaryContent = null

        if(beneficiaries && beneficiaries.length > 0){
            let dependents = []

            for(let [index, member] of beneficiaries.entries()) {
                let dependentContent = null;
                let familyCode = (member.memberFamilyCode ? member.memberFamilyCode : '') + ' ' +  (member.memberFamilyCodeSeq ? member.memberFamilyCodeSeq: '') ;
                if (member && member.isBeneficiary){
                    beneficiaryContent = {
                        index: index,
                        id: member.memberId ? member.memberId : charToUndefinedCase,
                        name: member.physicalPersonName ? member.physicalPersonName : charToUndefinedCase,
                        cpf: member.physicalPersonCPF ? util.formatCPF(member.physicalPersonCPF) : charToUndefinedCase,
                        birthdate: member.physicalPersonBirthDate ? moment(member.physicalPersonBirthDate).format('DD/MM/YYYY') : charToUndefinedCase,
                        policy: member.contractPolicy ? member.contractPolicy : charToUndefinedCase,
                        subcontract: member.subcontractName ? member.subcontractName : charToUndefinedCase,
                        benefit: member.benefitName ? member.benefitName : charToUndefinedCase,
                        operator: member.carrierName ? member.carrierName : charToUndefinedCase,
                        registration: member.memberRegistration ? member.memberRegistration : charToUndefinedCase,
                        card: member.memberCard ? (familyCode + ' / ' + member.memberCard) : (familyCode + ' / ' + charToUndefinedCase),
                        plan: member.planName ? member.planName : charToUndefinedCase,
                        validity: member.startDate ? moment(member.startDate).format('DD/MM/YYYY') : charToUndefinedCase,
                        status: member.isActive ? 'Ativo' : 'Inativo'
                    }
                } else {
                    dependentContent = {
                        CPF: member.physicalPersonCPF ? util.formatCPF(member.physicalPersonCPF) : charToUndefinedCase,
                        Nascimento: member.physicalPersonBirthDate ? moment(member.physicalPersonBirthDate).format('DD/MM/YYYY') : charToUndefinedCase,
                        Parentesco: member.kinship ? member.kinship : charToUndefinedCase,
                        Vigência: member.startDate ? moment(member.startDate).format('DD/MM/YYYY') : charToUndefinedCase,
                        Cartão: member.memberCard ? (familyCode + ' / ' +  member.memberCard) : (familyCode + ' / ' + charToUndefinedCase),
                        Status: member.isActive ? 'Ativo' : 'Inativo',
                        name: member.physicalPersonName ? member.physicalPersonName : charToUndefinedCase,
                    }
                    dependents.push(dependentContent);
                }
            }

            dependents.sort((a, b) => (a.name > b.name) ? 1 : -1)
            beneficiaryContent.dependente = dependents
        }
        context.commit('setBeneficiariesDetails', beneficiaryContent)
    }
};

const getters = {
    getBeneficiaries: (state) => state.beneficiaries,
    getBeneficiariesCards: (state) => state.beneficiariesCards,
    getBeneficiariesDetails: (state) => state.beneficiariesDetails
};

export default {
    state,
    actions,
    mutations,
    getters,
};
