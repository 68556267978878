<template>
  <div></div>
  <!-- <v-footer app v-if="window.footer" >
    <v-spacer></v-spacer>
    <span class="caption">&copy; XIPP - {{ new Date().getFullYear() }}</span>
    <v-spacer></v-spacer>
  </v-footer>-->
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["window"])
  }
};
</script>

<style>
</style>
