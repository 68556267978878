<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <campanhaListar />
    </v-layout>
    <v-row v-else dense class="fitContainer pa-1">
      <v-tabs
        v-model="selectedTab"
        background-color="transparent"
        grow
        centered
      >
        <v-tab href="#tab-list">Mensagens</v-tab>
      </v-tabs>

      <v-tabs-items v-model="selectedTab" style="width: 100%">
        <v-tab-item :value="'tab-list'">
          <campanhaListar />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import campanhaListar from "./campanhaListar";

export default {
  name: "Campanha",
  components: { campanhaListar },
  data: () => ({
    cards: [],
    selectedTab: "active",
  }),
  methods: {
    ...mapMutations(["showmsg"]),
  },
  computed: {
    ...mapState(["window"]),
  },
  beforeMount() {
    if (
      this.$route.params.parameters &&
      this.$route.params.parameters.selectedTab
    )
      this.selectedTab = this.$route.params.parameters.selectedTab;
  },
};
</script>
