const state = {
    abledToSearch: false,
    drawerOpen: false,
    query: '',
};

const actions = {
    normalizeFilteredItems(context, items) {
        const seemIds = [];
        return items.filter((item) => {
            const idKey = item['Id'] ? 'Id' : 'id';
            const itemId = item[idKey];

            if (!seemIds.includes(itemId)) {
                seemIds.push(itemId);
                return item;
            }
        });
    },
};

const mutations = {
    setAbledToSearch(state, newValue) {
        state.abledToSearch = newValue;
    },
    setFilterDrawer(state, newValue) {
        state.drawerOpen = newValue;
    },
    setQuery(state, newValue) {
        state.query = newValue;
    },
};

export default {
    state,
    actions,
    mutations,
};
