import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabsSlider),_c(VTab,{attrs:{"href":"#tab-active"}},[_vm._v("Ativos")]),_c(VTab,{attrs:{"href":"#tab-closed"}},[_vm._v("Cancelados")])],1),_c(VTabsItems,{staticClass:"pt-4",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabItem,{attrs:{"value":'tab-active'}},[_c('ContractsList',{attrs:{"whereClause":"active"}})],1),_c(VTabItem,{attrs:{"value":'tab-closed'}},[_c('ContractsList',{attrs:{"whereClause":"closed"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }