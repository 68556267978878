<template>
  <v-container fluid grid-list-md>
    <template v-if="$resize && $mq.above(window.mobileSize)">
      <v-layout row wrap>
        <v-flex sm12 md6 lg4 v-for="(filtro, findex) in filters" :key="findex">
          {{filtro.nome}}<br />
          <v-btn-toggle dense :key="findex" v-model="filtro.selected">
            <v-btn raised outlined  v-for="(item, itindex) in filtro.items" :key="itindex">{{item}}</v-btn>
          </v-btn-toggle>
          <v-spacer></v-spacer>
        </v-flex>
      </v-layout>
      <br/>

      <v-card>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="search"
            item-key="id"
            class="elevation-1"
            @click:row="(item) => $router.push({name:'fatura', params:{id: item.id}})"
        >
          <template v-slot:item.boleto>
            <v-icon>fas fa-print</v-icon>
          </template>
          <template v-slot:item.fatura>
            <v-icon>fas fa-file-pdf</v-icon>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" type="info" v-show="!loading" outlined>Nenhum item foi encontrado</v-alert>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" type="info" outlined>Nada foi encontrado para "{{ search }}"</v-alert>
          </template>
        </v-data-table>
      </v-card>
    </template>

    <template v-else>
      <FabFilterer :filters="filters" :data="items" @filterChange="setFilteredItems"/>

      <v-data-iterator hide-default-footer hide-default-header disable-pagination :items="filteredItems">
        <template v-slot:default="props">
          <v-row v-for="item in props.items" :key="item.id">
            <v-col cols="12">
              <v-card>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Contrato</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.contrato }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Competência</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.competencia }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Vencimento</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.vencimento }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Valor (R$)</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>{{ item.valor }}</v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Boleto</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>
                        <v-icon color="grey lighten-1">fas fa-print</v-icon>
                      </v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Fatura</v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-content>
                        <v-icon>fas fa-file-pdf</v-icon>
                      </v-list-item-content>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </template>
  </v-container>
</template>

<script>

import { mapMutations, mapState } from "vuex";
import {getGoogleSheets} from "@/common/gsxrest";
import FabFilterer from "@/components/fab-filterer";

const { VUE_APP_SHEETS_BILLS, VUE_APP_SHEETS_API_KEY } = process.env;

export default {
  name: "Fatura",
  components: { FabFilterer },
  data: () => ({
    headers: [
      { text: "Contrato", value: "contrato", align: "left" },
      { text: "Competencia", value: "competencia", align: "left" },
      { text: "Vencimento", value: "vencimento", align: "left" },
      { text: "Valor (R$)", value: "valor", align: "left" },
      { text: "Boleto", value: "boleto", align: "center", name: "boleto" },
      { text: "Fatura", value: "fatura", align: "center", name: "fatura" },
    ],
    filters: [
      {
        name: "Vencimento",
        types: [
          { name: "Este mês", method: 'thisMonth', key: 'vencimento', type: 'chip' },
          { name: "Mês passado", method: 'lastMonth', key: 'vencimento', type: 'chip' },
          { name: "Este trimestre", method: 'lastThreeMonths', key: 'vencimento', type: 'chip' },
        ],
      },
      {
        name: "Status",
        types: [
          { name: "Pago", method: 'exactKey', key: 'status', value: 'Pago', type: 'chip' },
        ],
      },
      {
        name: "Pesquisar",
        types: [
          { name: "Número do Contrato", method: 'exactKey', key: 'contrato', value: '', type: 'input' },
        ],
      },
    ],
    filteredItems: [],
    items: [],
    loading: false,
    search: "",
  }),
  computed: {
    ...mapState(["window"]),
  },
  methods: {
    ...mapMutations(["showmsg"]),
    addItem(obj) {
      this.items.push(obj);
      this.filteredItems.push(obj);
    },
    setFilteredItems(newItems) {
      this.filteredItems = newItems;
    },
  },
  beforeMount() {
    getGoogleSheets(VUE_APP_SHEETS_BILLS, VUE_APP_SHEETS_API_KEY, 'Sheet1!A2:G100')
        .then(data => {
          for (const item of data) {
            this.addItem({
              id: item[0],
              contrato: item[1],
              cnpj: item[2],
              competencia: item[3],
              vencimento: item[4],
              valor: item[5],
              status: item[6],
              boleto: '',
              fatura: '',
            });
          }
        }).catch(() => this.showmsg({
      text: "Erro ao carregar as faturas.",
      type: "error",
    })).finally(() => this.loading = false);
  },
};

</script>
