<template>
    <iframe id="accountIframe" :src="`${accountLoginURL}`" height="100%" width="100%" scrolling="auto" frameborder="0"/>
</template>

<script>
import store from "@/store/store";
import { mapMutations } from "vuex";
export default {
  name: "Login",
  data: () => ({}),
  computed: {
    accountLoginURL() {
      return `${process.env.VUE_APP_ACCOUNT_URL}${process.env.VUE_APP_ACCOUNT_LOGIN_PATH}?callback=${window.location.origin}${process.env.VUE_APP_ACCOUNT_LOGIN_CALLBACK_PATH}&userRole=${process.env.VUE_APP_ACCOUNT_USER_ROLE}&appCode=${process.env.VUE_APP_ACCOUNT_APP_CODE}&appVersion=${process.env.VUE_APP_BUILD_VERSION}`;
    }
  },
  methods: {
    ...mapMutations(["showmsg"])
  },
  async beforeRouteEnter(to, from, next) {
    const logged = await store.dispatch("checkAuthentication");
    if (logged) {
      next("/home");
    } else {
      next();
    }
  },
  beforeMount() {}
};
</script>

<style>
body {
  overflow: hidden; /* Hide scrollbars */
}

.sobre {
  background-image: url("https://www.thinkcare.com.br/landing-tnk/assets/images/bg-sobre.png");
  background-size: cover;
  background-position: top center
}

</style>
