<template>
  <GenericSubMenu :options="options" @select="(selection) => $emit('select', selection)"/>
</template>

<script>
import GenericSubMenu from '@/components/generic-sub-menu/index.vue';

export default {
  name: 'SubMenu',
  components: { GenericSubMenu },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>
