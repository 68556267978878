<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="grey lighten-3 font-weight-bold" style="color: var(--v-primary-base)">
        {{ assessmentTopics[assessmentTopicIndex].topic.name ? assessmentTopics[assessmentTopicIndex].topic.name : '-' }}
      </v-card-title>

      <v-card-text>
        <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            grow
            centered
            show-arrows
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-form-questions">Questões</v-tab>
          <v-tab href="#tab-form-answers" :disabled="indexOfQuestionEditing > -1">Respostas e Pontuações</v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item :value="'tab-form-questions'">
            <v-row>
              <v-col cols="12">
                <fieldset class="px-5">
                  <legend class="px-1">
                    Cadastro das Questões
                  </legend>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                              v-model="assessmentQuestion.ordination"
                              label="Ordem"
                              outlined
                              type="number"
                              :rules="[formrules.required, formrules.valueGreaterThanZero]"
                          />
                        </v-col>
                        <v-col cols="12" md="8">
                          <v-autocomplete
                              v-model="assessmentQuestion.questionType"
                              :items="fieldTypes"
                              label="Tipo de Questão"
                              placeholder="Selecione o Tipo de Questão"
                              :rules="[formrules.required]"
                              outlined
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                              v-model="assessmentQuestion.description"
                              label="Descrição da Questão"
                              maxlength="255"
                              outlined
                              :rules="[formrules.required]"
                          />
                        </v-col>
                        <v-col cols="11">
                          <v-autocomplete
                              :disabled="!assessmentQuestion.description || assessmentQuestion.description.trim().length === 0"
                              v-model="assessmentQuestionDependent"
                              :items="getQuestionsFiltered"
                              class="py-0 my-0"
                              height="30"
                              item-text="description"
                              return-object
                              label="Questão Gatilho"
                              placeholder="Selecione a questão gatilho"
                              outlined
                              @change="managerAssessmentQuestionDependent()"
                          />

                        </v-col>
                        <v-col cols="1" class="px-0 pt-7 mx-0">
                          <v-tooltip color="primary" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">fas fa-question-circle</v-icon>
                            </template>
                            <span>Preencha a descrição para habilitar este campo.</span>
                          </v-tooltip>

                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                              v-if="indexOfQuestionDependent > -1"
                              v-model="answersTriggersSelecteds"
                              :items="getTriggersFiltered"
                              :height="answersTriggersSelecteds.length <= 1 ? '30' : ''"
                              multiple
                              chips
                              deletable-chips
                              class="py-0 my-0"
                              item-text="text"
                              item-value="index"
                              return-object
                              label="Respostas Gatilhos"
                              placeholder="Selecione as respostas"
                              outlined
                          />
                        </v-col>
                        <v-col cols="6" md="3">
                          <v-checkbox
                              v-model="assessmentQuestion.mandatory"
                              label="Obrigatória?"
                          />
                        </v-col>

                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12">
                          <v-expansion-panels class="pb-0 mb-0" readonly accordion multiple v-model="panels">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="pb-0 mb-0" expand-icon="">
                                <v-row>
                                  <v-col cols="12">
                                    <v-autocomplete
                                        class="py-0 my-0"
                                        height="30"
                                        multiple
                                        chips
                                        deletable-chips
                                        item-text="text"
                                        item-value="value"
                                        :items="visibilityGenders"
                                        v-model="visibilityGendersSelecteds"
                                        label="Filtro por Sexo"
                                        placeholder="Selecione o Filtro por Sexo"
                                        outlined
                                    />
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content color="#eee">
                                  <v-row class="pb-0 mb-0 pt-6" v-for="item in this.assessmentQuestion.visibilities" :key="item.value">
                                      <v-col class="pb-0 mb-0 mt-2 text-right" cols="4">
                                        {{item.gender}}
                                      </v-col>

                                      <v-col class="pb-0 mb-0" cols="4">
                                        <v-text-field
                                            dense
                                            v-model="item.minAge"
                                            label="Idade Inicial"
                                            outlined
                                            type="number"
                                            :rules="[formrules.valueGreaterEqualsThanZero]"
                                        />
                                      </v-col>

                                      <v-col class="pb-0 mb-0" cols="4">
                                        <v-text-field
                                            dense
                                            v-model="item.maxAge"
                                            label="Idade Final"
                                            outlined
                                            type="number"
                                            :rules="[formrules.valueGreaterEqualsThanZero]"
                                        />
                                      </v-col>
                                  </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>

                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row justify="end">
                    <v-col cols="12" md="4" align="end">
                      <v-btn
                          class="mr-2"
                          large
                          style="color: var(--v-primary-base)"
                          light width="120"
                          @click="clearFields()"
                      >
                        <span>Cancelar</span>
                      </v-btn>
                      <v-btn
                          large
                          :disabled="isAssessmentQuestionEmpty"
                          :dark="!isAssessmentQuestionEmpty"
                          color="primary" width="120"
                          @click="managerAssessmentQuestionsToTopic()"
                      >
                        <span>{{ indexOfQuestionEditing === -1 ? 'Adicionar' : 'Alterar' }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </fieldset>
              </v-col>

              <v-col cols="12">
                <v-data-table
                    class="elevation-1 mx-1"
                    :items="assessmentTopics[assessmentTopicIndex].topic.questions"
                    :headers="questionHeaders"
                    item-key="ordination"
                    hide-default-footer
                    show-expand
                    single-expand
                >

                  <template v-slot:item.topic="{ }">
                    {{ assessmentTopics[assessmentTopicIndex].topic.name ? assessmentTopics[assessmentTopicIndex].topic.name : '-' }}
                  </template>

                  <template v-slot:item.questionType="{ item }">
                    {{ convertQuestionType(item.questionType) }}
                  </template>

                  <template v-slot:item.active="{ item }">
                    <v-switch v-model="item.active" @change="verifyIsEditingAndChangeActive(item.active)"></v-switch>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-menu
                        transition="slide-y-transition"
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="grey"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="editAssessmentQuestion(item)">
                          <v-list-item-icon>
                            <v-icon>fas fa-edit</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>Editar</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="mx-0 px-0">
                      <v-row class="pa-0 ma-0 white">
                        <v-col class="px-0 py-0" cols="12">
                          <v-data-table
                              class="grey lighten-3"
                              loading-text="Carregando..."
                              :items="item.triggers"
                              :headers="answerHeaders"
                              hide-default-footer
                              item-key="ordination"
                          >
                            <template v-slot:item.condition="{ item }">
                              {{ convertAnswerCondition(item.condition) }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item :value="'tab-form-answers'">
            <managerAnswers class="mb-0 pb-0" ref="manageTriggers" @resetFormValidation="resetFormValidation"></managerAnswers>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <dialogDeleteConfirmation :show="showDialog" @confirmDialog="deleteAssessmentQuestion"></dialogDeleteConfirmation>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import { formrules } from "./../../common/formrules";
import DialogDeleteConfirmation from "./dialogDeleteConfirmation.vue";
import ManagerAnswers from "./managementAnswers.vue";

export default {
  name: "managementQuestions",
  components: {
    DialogDeleteConfirmation,
    ManagerAnswers,
  },
  data: () => ({
    formrules,
    questionDescriptionTemp: null,
    isDisabledTabTriggers: true,
    selectedTab: '',
    panels: [],
    indexOfQuestionEditing: -1,
    indexOfQuestionDependent: -1,
    answersTriggersSelecteds: [],
    visibilityTemp: [],
    assessmentQuestionsTemp: [],
    assessmentQuestionDependent: {
      id: 0,
      ordination: 1,
      description: null,
      questionType: null,
      mandatory: false,
      triggers: [],
      active: true,
      visibilities: []
    },
    assessmentQuestion: {
      id: 0,
      ordination: 1,
      description: null,
      questionType: null,
      mandatory: false,
      triggers: [],
      active: true,
      visibilities: []
    },
    questionHeaders: [
      { text: "Ordem", value: "ordination", width: '10%' },
      { text: "Descrição", value: "description", width: '35%' },
      { text: "Tópico", value: "topic", width: '30%' },
      { text: "Tipo", value: "questionType", width: '10%'  },
      { text: "Ativo", value: "active", width: '10%' },
      { text: "Ações", value: "actions", sortable: false, align: "right", width: '5%' }
    ],
    answerHeaders: [
      { text: "Condição da Resposta", value: "condition", width: '33%' },
      { text: "Valor da Resposta", value: "value", sortable: false, width: '33%'  },
      { text: "Pontuação", value: "score", sortable: false, width: '33%' },
    ],
    fieldTypes: [
      { value: "BOOLEAN", text: "Booleano" },
      { value: "IMC", text: "Cálculo de IMC" },
      { value: "NUMBER", text: "Numérico" },
      { value: "COIN", text: "Monetário" },
      { value: "MULTIPLE", text: "Múltipla Seleção" },
      { value: "SELECT", text: "Seleção Única" },
      { value: "TEXT", text: "Texto" },
    ],
    visibilityGendersSelecteds: [],
    visibilityGenders: [
      { value: 'ALL', text: 'Todos', disabled: false },
      { value: 'MASCULINO', text: 'Masculino', disabled: false },
      { value: 'FEMININO', text: 'Feminino', disabled: false }
    ],
    showDialog: false,
  }),
  watch: {
    visibilityGendersSelecteds: {
      handler (newValue, oldValue) {
        if(newValue && newValue.some(item => item === 'TODOS')) {
          if(this.visibilityGenders.length > 1) {
            this.visibilityGenders.forEach(item => {
              item.disabled = true;
            })
            this.visibilityGenders[0].disabled = false;
          }

          if(newValue.length > 1) {
            this.visibilityGendersSelecteds = ['TODOS'];
          }
        } else {
          if(this.visibilityGenders.length > 1) {
            this.visibilityGenders.forEach(item => {
              item.disabled = false;
            })
          }
        }

        this.managerItemsOfVisibility();

        if(this.panels.length == 0 && this.assessmentQuestion && this.assessmentQuestion.visibilities && this.assessmentQuestion.visibilities.length) {
          this.panels.push(0);
        } else if (this.assessmentQuestion && this.assessmentQuestion.visibilities && this.assessmentQuestion.visibilities.length == 0) {
          this.panels = [];
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(['showmsg']),
    ...mapActions(['setAssessmentQuestionByIndexOfTopic', 'setAssessmentQuestionByIndexOfTopicAndIndexOfQuestion']),
    resetTriggers(){
      this.$refs.manageTriggers.resetTriggers();
    },
    managerAssessmentQuestionsToTopic() {
      const messageExistsWithName = 'Já existe uma questão cadastrada com esta descrição.';
      const messageExistsWithOrder = 'Já existe uma questão cadastrada com esta ordem.';
      const typeMessage = 'error';

      if(this.assessmentTopicIndex > -1) {
        this.assessmentQuestion.ordination = parseInt(this.assessmentQuestion.ordination);

        if(this.indexOfQuestionEditing === -1) {
          if(this.assessmentTopics[this.assessmentTopicIndex].topic.questions.some((item) => item.description === this.assessmentQuestion.description)){
            this.showmsg({ text: messageExistsWithName, type: typeMessage });
            return;
          } else if(this.assessmentTopics[this.assessmentTopicIndex].topic.questions.some((item) => item.ordination === this.assessmentQuestion.ordination)) {
            this.showmsg({ text: messageExistsWithOrder, type: typeMessage });
            return;
          }
          this.updateTriggersWithChildQuestionDependent();
          const question = Object.assign({}, this.assessmentQuestion);

          this.setAssessmentQuestionByIndexOfTopic({
            index: this.assessmentTopicIndex,
            question: question,
          });
        } else {
          if(this.assessmentQuestionsTemp.some((item) => item.description === this.assessmentQuestion.description)){
            this.showmsg({ text: messageExistsWithName, type: typeMessage });
            return;
          } else if(this.assessmentQuestionsTemp.some((item) => item.ordination === this.assessmentQuestion.ordination)) {
            this.showmsg({ text: messageExistsWithOrder, type: typeMessage });
            return;
          }
          this.updateTriggersWithChildQuestionDependent();
          const question = Object.assign({}, this.assessmentQuestion);

          this.setAssessmentQuestionByIndexOfTopicAndIndexOfQuestion({
            indexOfTopic: this.assessmentTopicIndex,
            indexOfQuestion: this.indexOfQuestionEditing,
            question: question,
          });
        }

        this.orderAssessmentQuestions(this.assessmentTopicIndex);
        this.clearFields();
      }
    },
    orderAssessmentQuestions(index) {
      if(index > -1 && this.assessmentTopics[index].topic.questions && this.assessmentTopics[index].topic.questions.length) {
        this.assessmentTopics[index].topic.questions.sort((a, b) => (a.ordination > b.ordination) ? 1 : -1);
      }
    },
    clearFields() {
      let ordinationValue = 1;
      if(this.assessmentQuestion.ordination > 0 && this.assessmentTopics[this.assessmentTopicIndex].topic.questions.length) {
         ordinationValue = Math.max(...this.assessmentTopics[this.assessmentTopicIndex].topic.questions.map(item => item.ordination));
        if(ordinationValue) {
          ordinationValue = ordinationValue + 1;
        }
      }

      this.assessmentQuestion = {
        id: 0,
        ordination: ordinationValue,
        description: null,
        questionType: null,
        mandatory: false,
        triggers: [],
        active: true,
        visibilities: []
      };
      this.assessmentQuestionDependent = Object.assign({}, this.assessmentQuestion);
      this.answersTriggersSelecteds = [];
      this.visibilityTemp = [];
      this.visibilityGendersSelecteds = [];
      this.indexOfQuestionEditing = -1;
      this.indexOfQuestionDependent = -1;
      this.questionDescriptionTemp = null;
      this.resetFormValidation();
    },
    resetFormValidation() {
      this.$emit('resetFormValidation');
    },
    convertQuestionType(fieldType) {
      if(fieldType) {
        return this.fieldTypes.find(item => item.value === fieldType).text;
      }
      return '-';
    },
    convertAnswerCondition(fieldType) {
      if(fieldType) {
        return this.conditions.find(item => item.value === fieldType).text;
      }
      return '-';
    },
    openDialog(item) {
      if(item && this.assessmentTopics[this.assessmentTopicIndex].topic.questions && this.assessmentTopics[this.assessmentTopicIndex].topic.questions.length) {
        this.indexOfQuestionEditing = this.assessmentTopics[this.assessmentTopicIndex].topic.questions.indexOf(item);
        this.showDialog = !this.showDialog;
      }
    },
    deleteAssessmentQuestion() {
      if(this.indexOfQuestionEditing > -1) {
        this.assessmentTopics[this.assessmentTopicIndex].topic.questions.splice(this.indexOfQuestionEditing, 1);
        this.indexOfQuestionEditing = -1;
        this.showDialog = !this.showDialog;
        this.clearFields();
      }
    },
    editAssessmentQuestion(item) {
      this.answersTriggersSelecteds = [];
      if(item && this.assessmentTopics[this.assessmentTopicIndex].topic.questions && this.assessmentTopics[this.assessmentTopicIndex].topic.questions.length) {
        this.indexOfQuestionEditing = this.assessmentTopics[this.assessmentTopicIndex].topic.questions.indexOf(item);

        //controll childQuestionDescription when description updated
        this.questionDescriptionTemp = item.description;

        this.assessmentQuestion = Object.assign({}, {
          id: item.id,
          ordination: item.ordination,
          description: item.description,
          questionType: item.questionType,
          mandatory: item.mandatory,
          triggers: Object.assign([], item.triggers),
          active: item.active,
          visibilities: Object.assign([], item.visibilities),
        });

        this.assessmentTopics[this.assessmentTopicIndex].topic.questions.forEach((item, index) => {
          if(item && item.triggers && item.triggers.length) {
            //this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers
            const triggersFound = item.triggers.filter(element => element.childQuestionsDescription && element.childQuestionsDescription.some(item => item === this.assessmentQuestion.description));

            if(triggersFound && triggersFound.length) {
              this.assessmentQuestionDependent = item;
              this.indexOfQuestionDependent = index;
              this.answersTriggersSelecteds = triggersFound;
            }
          }
        });
        this.answersTriggersSelecteds.forEach(trigger => {
          trigger.text = `Condição: ${this.convertAnswerCondition(trigger.condition)} - Valor: ${trigger.value} - Pontuação: ${trigger.score}${trigger.openAnswer ? ' - Campo aberto' : '.'}`;
        })

        this.visibilityTemp = this.assessmentQuestion.visibilities;
        this.visibilityGendersSelecteds = this.assessmentQuestion.visibilities.map(item => {return item.gender});

        this.assessmentQuestionsTemp = Object.assign([], this.assessmentTopics[this.assessmentTopicIndex].topic.questions);
        this.assessmentQuestionsTemp.splice(this.indexOfQuestionEditing, 1);
      }
    },
    managerItemsOfVisibility(){
      if(!this.visibilityGendersSelecteds.length) {
        this.assessmentQuestion.visibilities = [];
      }

      this.visibilityGendersSelecteds.forEach(item => {
        const found = this.assessmentQuestion.visibilities.some((element) => element.gender === item);
        if(!found) {
          this.assessmentQuestion.visibilities.push({gender: item, minAge: 0, maxAge: 200})
        }
      });

      const indexToRemove = [];
      this.assessmentQuestion.visibilities.forEach((item, index) => {
        const found = this.visibilityGendersSelecteds.some((element) => element === item.gender);
        if(!found) {
          indexToRemove.push(index);
        }
      });

      indexToRemove.forEach(item => {
        this.assessmentQuestion.visibilities.splice(item, 1);
      });
    },
    verifyIsEditingAndChangeActive(status) {
      if(this.indexOfQuestionEditing > -1) {
        this.assessmentQuestion.active = status;
      }
    },
    managerAssessmentQuestionDependent() {
       this.indexOfQuestionDependent = this.assessmentTopics[this.assessmentTopicIndex].topic.questions.indexOf(this.assessmentQuestionDependent);
    },
    updateTriggersWithChildQuestionDependent() {
      if(this.assessmentTopicIndex > -1 && this.indexOfQuestionDependent > -1
          && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers
          && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers.length) {

        if(this.answersTriggersSelecteds && this.answersTriggersSelecteds.length) {
          const answersSelected = JSON.parse(JSON.stringify(this.answersTriggersSelecteds));
          const answersFound = [];
          answersSelected.forEach(item => {
            delete item.text;
            const answerFound = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers
                .find(element => element.value === item.value);
            answersFound.push(answerFound);

            if(answerFound) {
              const indexOf = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers.indexOf(answerFound);
              if(indexOf > -1) {
                this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionDescription = this.assessmentQuestion.description;
                if(this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription
                  && !this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription.includes(this.assessmentQuestion.description)){
                  this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription.push(this.assessmentQuestion.description);
                } else if(!this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription) {
                  this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription = [this.assessmentQuestion.description];
                }
              }
            }
          });
          const answersNotFound = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers
              .filter(element => !answersFound.includes(element));
          if(answersNotFound){
            answersNotFound.forEach(answer => {
              const indexOf = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers.indexOf(answer);
              if(this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription
                  && this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription.includes(this.assessmentQuestion.description)){
                const triggerIndex = this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription.indexOf(this.assessmentQuestion.description);
                this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers[indexOf].childQuestionsDescription.splice(triggerIndex,1);
              }
            })
          }
        } else {
          this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers.forEach(trigger => {

            if (trigger.childQuestionsDescription)
              trigger.childQuestionsDescription = trigger.childQuestionsDescription.filter((item) => item !== this.assessmentQuestion.description)
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['assessmentTopics', 'assessmentTopicIndex', 'conditions']),
    isAssessmentQuestionEmpty() {
      return !this.assessmentQuestion || !this.assessmentQuestion.description || this.assessmentQuestion.description.trim().length === 0
          || !this.assessmentQuestion.questionType || !this.assessmentQuestion.ordination || this.assessmentQuestion.ordination <= 0;
    },
    getQuestionsFiltered(){
      return this.assessmentTopics[this.assessmentTopicIndex].topic.questions.filter((item) => item.description !== this.assessmentQuestion.description);
    },
    getTriggersFiltered() {
      if(this.assessmentTopicIndex > -1 && this.indexOfQuestionDependent > -1) {
        return this.assessmentTopics[this.assessmentTopicIndex].topic.questions[this.indexOfQuestionDependent].triggers.map((item) => ({
          ...item,
          text: `Condição: ${this.convertAnswerCondition(item.condition)} - Valor: ${item.value} - Pontuação: ${item.score}${item.openAnswer ? ' - Campo aberto' : '.'}`,
        }));
      }
      return [];
    }
  },
  beforeCreate() {
  },
  created() {
  },
};
</script>
