import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c(VCard,{staticClass:"elevation-0",attrs:{"height":"100%"}},[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","show-arrows":"","center-active":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:'tab'+item.id,on:{"click":function($event){return _vm.createLogSentry(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1),_c(VTabsItems,{staticStyle:{"height":"100%"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTabItem,{key:'tab-item'+item.id,staticStyle:{"height":"100%"}},[_c('Metabase',{attrs:{"serverCode":_vm.parentItem.options,"resourceId":Number(item.link),"url-param":_vm.parentItem.link,"path-params":"#bordered=true&titled=false"}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }