<template>
  <v-container fluid>
    <v-overlay :value="loading" :opacity="0">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
    <v-card>
      <v-card-text>
      <v-form
        id="createBeneficaryConnectCompany"
        ref="createBeneficaryConnectCompany"
        @submit.prevent="salvar()"
      >
          <v-row dense>
            <v-col cols="12" md="6">
              <v-select
                      :items="contracts"
                      v-model="beneficiaryCompany.contractExternalID"
                      label="Contrato"
                      placeholder="Selecione um contrato"
                      outlined
                      :rules="[rules.required]"
                      @change="loadSubContracts()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                      :items="subContracts"
                      v-model="beneficiaryCompany.subContractExternalID"
                      label="SubContrato"
                      placeholder="Selecione um sub contrato"
                      outlined
                      :rules="[rules.required]"
                      @change="loadBeneficiaries()"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                      :items="beneficiaries"
                      v-model="beneficiaryCompany.beneficiaryID"
                      label="Beneficiário"
                      placeholder="Selecione um beneficiário"
                      outlined
                      :rules="[rules.required]"
              />
            </v-col>
          </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions v-if="$resize && $mq.above(window.mobileSize)">
        <v-spacer />
        <v-btn color="sedundary" @click="cancelar()" :disabled="busy || loading">Cancelar</v-btn>
        <v-btn
                color="primary"
                type="submit"
                form="createBeneficaryConnectCompany"
                :loading="busy"
                :disabled="busy || loading"
        >Salvar</v-btn>
      </v-card-actions>
      <v-flex v-else>
        <v-card-actions>
          <v-btn block
                 large
                 color="primary"
                 type="submit"
                 form="createBeneficaryConnectCompany"
                 :loading="busy"
                 :disabled="busy || loading"
          >Salvar</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn block large color="secondary" light @click="cancelar()" :disabled="busy || loading" class="black--text">Cancelar</v-btn>
        </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { formrules } from "./../../common/formrules";
import ContractService from "../../services/odoo/ContractService";
import { findAllBySubContractWithoutConnectionWithCompany, connectBeneficiaryWithCompany } from "@/common/euprotegidoservice";

export default {
  name: "CriarUsuario",
  data: () => ({
    beneficiaryCompany: {
      contractExternalID: "",
      subContractExternalID: "",
      beneficiaryID: "",
    },
    busy: false,
    rules: formrules,
    contracts: [],
    subContracts: [],
    beneficiaries: [],
    required: value => !!value || "Preenchimento obrigatório.",
    loading: false
  }),
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["user"]),
    isMobileSize() {
      return this.$resize && this.$mq.below(this.window.mobileSize);
    }
  },
  methods: {
    ...mapMutations(["showmsg"]),
    cancelar() {
      this.$router.go(-1);
    },
    salvar(){
      console.log('salvando');
      if (!this.$refs.createBeneficaryConnectCompany.validate()) {
        setTimeout(this.$refs.createBeneficaryConnectCompany.resetValidation, 3000);
        return;
      }

      this.loading = true;
      this.beneficiaryCompany.status = 'ACTIVE';
      connectBeneficiaryWithCompany(this.user.tokenSecurityService.access_token, this.beneficiaryCompany).then( res => {
        this.showmsg({ text: "Salvo com sucesso!", type: "success" });
        this.loading = false ;
        this.cancelar();
      }).catch( err => {
        this.showmsg({ text: "Erro ao salvar registro!", type: "error" });
        this.loading = false ;
      })
    },
    async getContracts() {
      this.loading = true;
      this._contractservice.FindByStatus().then((res) => {
        res.data.records.forEach((contract) => {
          var texto = contract.contract_owner_id ? contract.contract_owner_id.name : "";
          texto += contract.policy ? " - " + contract.policy : "";
          texto += contract.benefit_id ? " - " + contract.benefit_id : "";
          texto += contract.carrier_id ? "/" + contract.carrier_id.xipp_commercial_name : "";

          if (contract.contract_owner_id) {
            this.contracts.push({
              accountID: contract.contract_owner_id.id,
              value: contract.id,
              text: texto,
            });
          }
        });
      }).catch( err => {
        this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
      }).finally(() => ( this.loading = false ));
    },
    loadSubContracts(){
      this.loading = true;
      this.subContracts = [];
      if(this.beneficiaryCompany.contractExternalID){
        this._contractservice.FindSubContracts(this.beneficiaryCompany.contractExternalID).then((res) => {
          res.data.records.forEach((contract) => {
            var texto = contract.contract_owner_id ? contract.contract_owner_id.name : "";
            texto += contract.policy ? " - " + contract.policy : "";
            texto += contract.benefit_id ? " - " + contract.benefit_id : "";
            texto += contract.carrier_id ? "/" + contract.carrier_id.xipp_commercial_name : "";

            if (contract.contract_owner_id) {
              this.subContracts.push({
                accountID: contract.contract_owner_id.id,
                value: contract.id,
                text: texto,
              });
            }
          });
        }).catch( err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
        }).finally(() => ( this.loading = false ));
      }
    },
    loadBeneficiaries() {
      this.loading = true;
      this.beneficiaries = [];
      if (this.beneficiaryCompany.subContractExternalID) {
        findAllBySubContractWithoutConnectionWithCompany(this.user.tokenSecurityService.access_token, this.beneficiaryCompany.subContractExternalID).then((res) => {
          res.data.forEach((beneficiary) => {
            const texto = beneficiary.physicalPerson ? beneficiary.physicalPerson.name + " - " + beneficiary.physicalPerson.cpf : "";
            if (texto != "")
              this.beneficiaries.push({
                value: beneficiary.id,
                text: texto,
              });
          });
          console.log('beneficiaries', this.beneficiaries);
        }).catch( err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
        }).finally(() => ( this.loading = false ));
      }
    }
  },
  async created() {
    this._contractservice = new ContractService();
    await this.getContracts();
  },
};
</script>
