<template>
  <v-file-input chips multiple show-size :error="error" :error-messages="errorMsg" label="Arquivos" placeholder="Anexe aqui seus arquivos" @change="updateData" prepend-icon="fas fa-paperclip" >
    <template v-slot:selection="{ index, text }">
      <v-chip>{{ text }}</v-chip>
    </template>
  </v-file-input>
</template>

<script>

export default {
  name: 'GenericFileInput',
  props: {
    chips: {
      type: Boolean,
      required: false,
      default: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: true,
    },
    limitFileSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data: () => ({
    error: false,
    errorMsg: null,
  }),
  methods: {
    processFile(file) {
      const fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(new DOMException("Unable to parse the file."));
        };

        fileReader.onload = () => resolve({
          name: file.name,
          body: fileReader.result,
          size: file.size,
        });

        fileReader.readAsDataURL(file);
      });
    },
    async updateData(newData) {
      const promises = newData.map(
        file => this.processFile(file),
      );
      const finalFiles = await Promise.all(promises);

      if(this.validateFileSize(finalFiles))
        this.$emit( 'update', finalFiles);
      else
        this.$emit( 'validationError');
    },
    validateFileSize(finalFiles){
      if(this.limitFileSize > 0 && finalFiles.some(file => file.size > this.limitFileSize * 1024 * 1024)){
        this.error = true;
        this.errorMsg = `Tamanho máximo por arquivo ${this.limitFileSize}MB`;
        return false;
      }else{
        this.error = false;
        this.errorMsg = null;
        return true;
      }
    },
  },
};

</script>
