import axios from 'axios';
import $util from "./../common/util";

export default class HttpService {
  constructor(serviceURL) {
    this.ws = this._loadAxios(serviceURL);
  }

  _loadAxios(serviceURL) {
    return axios.create({
      baseURL: process.env.VUE_APP_API_URL+serviceURL,
      headers: this._loadHeaders()
    });
  }

  _loadHeaders() {
    let token_active = $util.getSession();

    let current_token = (token_active
        && token_active.tokenSecurityService
        && token_active.tokenSecurityService.access_token) || $util.getLocalizedToken();

    return {
      Authorization: `Bearer ${current_token}`,
      'Access-Control-Max-Age': 600,
      'Cache-Control': 'max-age=600'
    }
  }

  get(path, params = null) {
    return this.ws.get(path, {
      params: params,
    });
  }

  post(path, data) {
    return this.ws.post(path, data);
  }

  put(path, data) {
    return this.ws.put(path, data);
  }

  delete(path, params = null) {
    return this.ws.delete(path, {
      params: params,
    });
  }

  all(path, params){
    const requests = [];
    params.forEach(param =>{
      requests.push(this.get(path, {
        [param.label] : param.value,
      }))
    })

    return axios.all(requests);
  }
}
