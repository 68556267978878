<template>
  <v-list subheader>
    <v-list-group v-for="filter of configuredFilters" :key="filter.name" v-model="filter.active" append-icon="fas fa-chevron-down">
      <template v-slot:activator>
        <v-list-item-title v-text="filter.name"/>
      </template>

      <v-list-item v-for="type in filter.types" :key="type.name" @click="toggleFilter(filter.name, type.name)" class="pl-2">
        <v-list-item-content class="pt-0 pb-0">
          <div @click="toggleFilter(filter.name, type.name)" v-if="type.type === 'select'" class="pl-3">
            <v-switch v-model="type.selected" inset :label="type.name"/>
          </div>
          <div v-else-if="type.type === 'combobox'" class="px-2">
            <v-select @input="comboBoxItemAction(type, filter)" item-value="value" v-model="itemSelected" :items="type.items" :label="type && type.name ? type.name : 'Selecione uma opção'" :clearable="type.clearable" :multiple="type.multiple"></v-select>
            <v-select @input="comboBoxSubItemAction(type, filter)" v-if="type && type.hasSubItem" :label="type && type.subItemName ? type.subItemName : 'Selecione uma opção'" v-model="subItemSelected" :items="type.subItems"></v-select>
          </div>
          <v-container v-else-if="type.type === 'date'" fluid :key="type.name">
            <v-text-field v-model="type.value" :name="type.name" :label="type.name" v-mask="[type.mask]" @input="toggleFilter(filter.name, type.name)"/>
          </v-container>
          <v-container v-else fluid :key="type.name">
            <v-text-field v-model="type.value" :name="type.name" :label="type.name" @input="toggleFilter(filter.name, type.name)"/>
          </v-container>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>

export default {
  name: 'GenericFilterer',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    baseQuery: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    lastIndex: 0,
    itemSelected: '',
    subItemSelected: '',
    localFilters: [],
    configuredFilters: [],
    configuredData: [],
    originalData: [],
    appliedFilters: [],
    syncedInitialData: false,
  }),
  computed: {
    abledToSearch() {
      return this.$store.state.abledToSearch;
    },
  },
  methods: {
    comboBoxItemAction(type, filter){
      if(type && type.hasSubItem) {
        this.subItemSelected = ''
        this.$emit('loadComboBoxSubItems', this.itemSelected)
      } else {
        type.value = this.itemSelected
      }

      if(type && type.parent && type.parent === 'financialGroup'){
        this.$emit('loadFinancialGroupToInvoice', this.itemSelected)
      }
      this.toggleFilter(filter.name, type.name)
    },
    comboBoxSubItemAction(type, filter){
      type.value = this.subItemSelected
      this.toggleFilter(filter.name, type.name)
    },
    toggleFilter(filterName, orderName) {
      this.configuredFilters = this.configuredFilters.filter((filter) => {
        if (filter.name === filterName) {
          filter.types = filter.types.filter((type) => {
            if(type.name === orderName && type.type == 'combobox'){
              type.selected = true;
            }else if (type.name === orderName && type.type == 'date') {
              type.selected = true;
            } else if (type.name === orderName && type.type == 'input') {
              type.selected = type.value ? true : false;
            }
            return type;
          });
        }
        return filter;
      });
    },
    applyQueryStrings() {
      let finalQuery = '';
      const filtersPerType = {};

      this.appliedFilters.forEach((filter) => {
        if (filtersPerType[filter.parent]) {
          filtersPerType[filter.parent] = [...filtersPerType[filter.parent], filter];
        } else {
          filtersPerType[filter.parent] = [filter];
        }
      });

      for (const filterKey of Object.keys(filtersPerType)) {
        const types = filtersPerType[filterKey];
        const values = types.filter(type => type.value).map(type => type.quotes !== false ? `'${type.value}'` : type.value) || [];

        if (values.length) {
          finalQuery += ` andd ${filterKey} in (`;
          finalQuery += `${values})`;
        }
      }
      return finalQuery;
    },
    applyFilters() {

      this.appliedFilters = [];
      this.configuredFilters.forEach(filter => {
        const selectedTypes = filter.types.filter(type => type.selected);
        this.appliedFilters = [...this.appliedFilters, ...selectedTypes];
      });

      const finalQuery = this.applyQueryStrings();
      this.$store.commit('setQuery', finalQuery);

      if (!finalQuery) {
        this.$emit('filtersRemoved');
        return;
      }

      this.$emit('queryUpdate', finalQuery);
    },
    configureFiltersData() {

      this.configuredFilters = this.localFilters.map((filter) => {
        filter.types = filter.types.map((order) => ({ ...order, selected: false, parent: filter.key || filter.name }));
        filter.active = false;
        return filter;
      });
      this.configuredData = this.data;
      this.originalData = this.data;
      this.syncedInitialData = true;
    },
    clearFilters(){
      this.appliedFilters = [];
      this.localFilters.forEach( (filter) => {
          filter.types.forEach( (filterType) => {
            if(filterType.type == 'input' || filterType.type == 'date') {
              filterType.value = ''
            } else if (filterType.type == 'combobox'){
              filterType.value = ''
              this.itemSelected = ''
              this.subItemSelected = ''
            }
          });
        });
      this.configureFiltersData();
    }
  },
  created() {
    this.localFilters = this.filters
    this.configureFiltersData();
  },
  watch: {
    '$store.state.abledToSearch'(newValue) {
      if (newValue) {
        this.applyFilters();
      }
    },
    data(newData) {
      if (!this.syncedInitialData) {
        this.configuredData = this.data;
        this.originalData = this.data;
        this.syncedInitialData = true;
      }
    },
    filters(newValue){
      if(newValue && newValue.length > 0){
        this.subItemSelected = ''
        this.localFilters = newValue
        this.configureFiltersData();
      }
    }
  },
};
</script>
