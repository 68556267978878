<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap v-if="$resize && $mq.above(window.mobileSize)">
      <assesment-listar/>
    </v-layout>

    <v-row v-else dense class="fitContainer pa-1">

      <v-tabs v-model="selectedTab" background-color="transparent" grow centered>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-list"></v-tab>        
      </v-tabs>

      <v-tabs-items v-model="selectedTab" style="width: 100%">
        <v-tab-item :value="'tab-list'">
          <assesment-listar/>
        </v-tab-item>
      </v-tabs-items>

    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations, mapState } from "vuex";
  import assesmentListar from "./assesmentListar";

  export default {
    name: "Assesment",
    components: { assesmentListar },
    data: () => ({
      cards: [],
      selectedTab: "active"
    }),
    methods: {
      ...mapMutations(["showmsg"])
    },
    computed: {
      ...mapState(["window"])
    },
    beforeMount() {
      if(this.$route.params.parameters && this.$route.params.parameters.selectedTab )
        this.selectedTab = this.$route.params.parameters.selectedTab ;
    }
  };
</script>
