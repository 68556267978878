import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/src/styles/main.sass';
import pt from 'vuetify/es5/locale/pt'

// let thinkcare_theme = {
//   primary: '#ea4965',
//   secondary: '#ffffff',
//   accent: '#ea4965',
//   error: '#E53A5D',
//   info: '#375176',
//   success: '#904C92',
//   warning: '#1d1938',
//   contrastSoft: "#2a244e",
//   contrast: "#333887",
//   contrastHard: "#1d1938",
//   appbgcolor: '#2a244e',
// }

let xipp_theme = {
  primary: '#5555A5',
  secondary: '#5FD3C7',
  accent: '#77559F',
  error: '#FF5252',
  info: '#77C8D0',
  success: '#A081B9',
  warning: '#C9AAD1',
  contrastSoft: "#62C3D8",
  contrast: "#5555A5",
  contrastHard: "#532E88",
  appbgcolor: '#532E88',
  backgroundColor: '#E5E5E5',
}

let theme = {
  themes: {
    light: xipp_theme,
    dark: xipp_theme
  },
  options: {
    customProperties: true,
  },
};

Vue.use(Vuetify);

export default new Vuetify({
  theme,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
});
