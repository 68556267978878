import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[(_vm.$resize && _vm.$mq.above(_vm.window.mobileSize))?_c(VLayout,{attrs:{"row":"","wrap":""}},[_c('assesment-listar')],1):_c(VRow,{staticClass:"fitContainer pa-1",attrs:{"dense":""}},[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabsSlider),_c(VTab,{attrs:{"href":"#tab-list"}})],1),_c(VTabsItems,{staticStyle:{"width":"100%"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabItem,{attrs:{"value":'tab-list'}},[_c('assesment-listar')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }