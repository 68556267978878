<template>
    <v-btn text @click="previousPage()" class="mb-2" >
        <v-icon small color="secondary" >fas fa-arrow-left</v-icon>
        <label class="ml-2 text--disabled" style="letter-spacing: 0.6px;">Voltar</label>
    </v-btn>
</template>
<script>
export default {
    props: {
        route: {
            type: String,
            required: false,
        },
        parameters: {
            type: Object,
            required: false,
        }
    },
    methods: {
        previousPage(){
            console.log(this.$refs);
            if(this.route) {
                this.$router.push({ name: this.route, params: {parameters: this.parameters }});
            }else
                this.$router.go(-1);
        }
    }
}
</script>
