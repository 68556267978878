import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","grid-list-md":""}},[(_vm.$resize && _vm.$mq.above(_vm.window.mobileSize))?_c(VLayout,{attrs:{"row":"","wrap":""}},[_vm._l((_vm.cards),function(card){return _c(VFlex,{key:card.index,staticClass:"scrollbarMenu",attrs:{"xs12":"","md6":"","lg3":""}},[_c(VCard,{staticClass:"contrast",attrs:{"hover":"","to":{
          name: 'inseriratendimento',
          query: {
            solicitation: card.title,
            type: card.type,
            request: card.request,
          },
        }}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VIcon,{staticClass:"white--text",attrs:{"medium":""}},[_vm._v(_vm._s(card.icon))]),_c(VListItemContent,{staticClass:"ml-3 white--text"},[_c(VListItemSubtitle,{staticStyle:{"color":"rgba(255, 255, 255, 0.8)"}},[_vm._v(_vm._s(card.subtitle.toUpperCase()))]),_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(card.title))])],1)],1)],1)],1)}),_c('atendimento-listar')],2):_c(VRow,{staticClass:"fitContainer pa-1",attrs:{"dense":""}},[_c(VTabs,{attrs:{"background-color":"transparent","grow":"","centered":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabsSlider),_c(VTab,{attrs:{"href":"#tab-create"}},[_vm._v("Solicitar")]),_c(VTab,{attrs:{"href":"#tab-list"}},[_vm._v("Histórico")])],1),_c(VTabsItems,{staticStyle:{"width":"100%"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c(VTabItem,{attrs:{"value":'tab-create'}},_vm._l((_vm.cards),function(card){return _c(VCol,{key:card.index,staticClass:"pa-1",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"contrast",attrs:{"elevation":"2","to":{
              name: 'inseriratendimento',
              query: {
                solicitation: card.title,
                type: card.type,
                request: card.request,
              },
            }}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VIcon,{staticClass:"white--text",attrs:{"medium":""}},[_vm._v(_vm._s(card.icon))]),_c(VListItemContent,{staticClass:"ml-3 white--text"},[_c(VListItemSubtitle,{staticStyle:{"color":"rgba(255, 255, 255, 0.8)"}},[_vm._v(_vm._s(card.subtitle.toUpperCase()))]),_c(VListItemTitle,{staticClass:"headline mb-1"},[_vm._v(_vm._s(card.title))])],1)],1)],1)],1)}),1),_c(VTabItem,{attrs:{"value":'tab-list'}},[_c('atendimento-listar')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }