var _vm = null
const SESSION_KEY_NAME = 'auth_user';
const LOCALIZED_TOKEN = 'LOCALIZED_TOKEN'

export default {
  getSession(){
    return JSON.parse(localStorage.getItem(SESSION_KEY_NAME) || '{}');
  },
  getAuthorities(){
    return this.getSession() && this.getSession().authorities ? this.getSession().authorities : null;
  },
  hasMenu(){
    return this.getSession().menu && this.getSession().menu.length > 0
  },
  hasNotificationConfigDriveAuthority(){
    const authorities = this.getAuthorities();
    if(authorities){
      return authorities.some(item => item.authority === 'menu_rh_drive_notificacao' || item.authority === 'menu_dr_drive_notificacao')
    }
    return false
  },
  hasNotificationConfigCarrierBillingAuthority(){
    const authorities = this.getAuthorities();
    if(authorities){
      return authorities.some(item => item.authority === 'menu_rh_carrier_billing_notificacao' || item.authority === 'menu_dr_carrier_billing_notificacao')
    }
    return false
  },
  setSession(session){
    if (session) {
      localStorage.setItem(SESSION_KEY_NAME, JSON.stringify(session));
    }
  },
  user() {
    let session = this.getSession();
    if (session && session.user)
      return session.user
    else
      return false;
  },
  getLocalizedToken() {
    if (localStorage.getItem(LOCALIZED_TOKEN)) {
      return localStorage.getItem(LOCALIZED_TOKEN)
    } else return false
  },
  strToBool(str) {
    return (str.toLowerCase() === 'false' ? false : true)
  },
  formatCNPJ(cnpj) {
    return cnpj ? cnpj
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5") : ''
  },
  formatCPF(cpf) {
    return cpf ? cpf
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4") : ''
  },
  formatMoneyToNumber(value) {
    return value &&
      parseFloat(value.replace(/[R$ .]+/g, '').replace(/[,]+/g, '.')).toFixed(2);
  },
  vueFormatDateToBR(date) {
    if (!date) return null;
    const pdate = date.substring(0, 10);
    const [year, month, day] = pdate.split("-");
    return day.padStart(2, '0') + '/' + month.padStart(2, '0') + '/' + year;
  },
  vueFormatDateHourToBR(date) {
    if (!date) return null;
    const pdate = date.substring(0, 10);
    const pHour = date.substring(11, 19);
    const [year, month, day] = pdate.split("-");
    return (day.padStart(2, '0') + '/' + month.padStart(2, '0') + '/' + year) + ' ' + pHour;
  },
  vueFormatDateFromBR(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
  },
  isMobile() {
    // aler(navigator.userAgent)
    return (/ANDROID|WEBOS|IPHONE|IPAD|IPOD|BLACKBERRY|IEMOBILE|OPERA MINI/i.test(navigator.userAgent.toUpperCase()))
  },
  isIosMobile() {
    // alert(navigator.userAgent)
    return (/IPHONE|IPAD|IPOD/i.test(navigator.userAgent.toUpperCase()))
  },
  //is PWA app installed
  isInStandaloneMode() {
    return (window.matchMedia('(display-mode: standalone)').matches) || (('standalone' in navigator) && (navigator.standalone))
  },
  link(name, params) {
    // _vm.$store.commit('toogleMainView', false)
    return new Promise(resolve => {
      var rota = {
        name: name
      }

      if (params != undefined)
        rota.params = params

      _vm.$router.push(rota)
      resolve()
      // var time = 750
      // setTimeout(() => {
      //   _vm.$store.commit('toogleMainView', true)
      // }, time);
    })
  },
  linkNewTab(url) {
    if (!url.includes('http')) {
      url = window.location.origin + '/' + url
    }
    // debugger
    if (this.isIosMobile()) {
      window.location.assign(url)
    } else {
      window.open(url, '_blank');
    }
  },
  removeAccents(str) {
    return str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : str
  },
  checkIndex() {
    var fromIndex = !!(_vm.$route.redirectedFrom && _vm.$route.redirectedFrom.includes("index."))
    return fromIndex
  },
  isRoute(routename) {
    // console.log(_vm.$route)
    return (_vm.$route && _vm.$route.name == routename)
  },
  vue() {
    return _vm
  },
  round(n, places) {
    return +(Math.round(n + "e+" + places) + "e-" + places)
  },
  breakSpace(str, breaker) {
    return str ? str.replace(" ", (breaker ? breaker : "<br />")) : str
  },
  install(Vue, config) {
    _vm = config.vm
    Vue.prototype.$util = this
  },
  base64ToBytesArray(stringBase64) {
    const sliceSize = 512;
    const byteCharacters = atob(stringBase64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return byteArrays;
  },
  isBrowserCompatible(){
    if(this.isMobile()){
      if(this.isIosMobile()){
        return this.isIosSafari();
      }else if(/ANDROID/i.test(navigator.userAgent.toUpperCase())){
          return true;
      }
    }else if(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)){
        return true;
    }

    return false;
  },
  isIosSafari(){
    return /iP(ad|od|hone)/i.test(navigator.userAgent) && /WebKit/i.test(navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent));
  },
  sanitize(value) {
    let searchMasks = [
      'FSCommand',
      'onAbort',
      'onActivate',
      'onAfterPrint',
      'onAfterUpdate',
      'onBeforeActivate',
      'onBeforeCopy',
      'onBeforeCut',
      'onBeforeDeactivate',
      'onBeforeEditFocus',
      'onBeforePaste',
      'onBeforePrint',
      'onBeforeUnload',
      'onBeforeUpdate',
      'onBegin',
      'onBlur',
      'onBounce',
      'onCellChange',
      'onChange',
      'onClick',
      'onContextMenu',
      'onControlSelect',
      'onCopy',
      'onCut',
      'onDataAvailable',
      'onDataSetChanged',
      'onDataSetComplete',
      'onDblClick',
      'onDeactivate',
      'onDrag',
      'onDragEnd',
      'onDragLeave',
      'onDragEnter',
      'onDragOver',
      'onDragDrop',
      'onDragStart',
      'onDrop',
      'onEnd',
      'onError',
      'onErrorUpdate',
      'onFilterChange',
      'onFinish',
      'onFocus',
      'onFocusIn',
      'onFocusOut',
      'onHashChange',
      'onHelp',
      'onInput',
      'onKeyDown',
      'onKeyPress',
      'onKeyUp',
      'onLayoutComplete',
      'onLoad',
      'onLoseCapture',
      'onMediaComplete',
      'onMediaError',
      'onMessage',
      'onMouseDown',
      'onMouseEnter',
      'onMouseLeave',
      'onMouseMove',
      'onMouseOut',
      'onMouseOver',
      'onMouseUp',
      'onMouseWheel',
      'onMove',
      'onMoveEnd',
      'onMoveStart',
      'onOffline',
      'onOnline',
      'onOutOfSync',
      'onPaste',
      'onPause',
      'onPopState',
      'onProgress',
      'onPropertyChange',
      'onReadyStateChange',
      'onRedo',
      'onRepeat',
      'onReset',
      'onResize',
      'onResizeEnd',
      'onResizeStart',
      'onResume',
      'onReverse',
      'onRowsEnter',
      'onRowExit',
      'onRowDelete',
      'onRowInserted',
      'onScroll',
      'onSeek',
      'onSelect',
      'onSelectionChange',
      'onSelectStart',
      'onStart',
      'onStop',
      'onStorage',
      'onSyncRestored',
      'onSubmit',
      'onTimeError',
      'onTrackChange',
      'onUndo',
      'onUnload',
      'onURLFlip',
      'seekSegmentTime'
    ];

    if(value) {
      for(const searchMask of searchMasks) {
        const regEx = new RegExp(searchMask, "ig");
        const replaceMask = '';
        value = value.replace(regEx, replaceMask);
      }
    }

    return value;
  }
}

// eslint-disable-next-line no-unused-vars
const fd_opts = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
}
